import React, { useContext } from 'react'
import Table from '@material-ui/core/Table'
import TableFooter from '@material-ui/core/TableFooter'
import TableRow from '@material-ui/core/TableRow'
import TablePagination from '@material-ui/core/TablePagination'
import ListViewContext from '../list-view-context'
import { SELECTED_PARAMS_TYPE } from '../data-handler/selected-params-reducer'

const Pagination = props => {
  const {
    Data: { selectedParams, dispatchSelectedParams }
  } = useContext(ListViewContext)

  const handleChangePage = (event, page) => {
    dispatchSelectedParams({
      type: SELECTED_PARAMS_TYPE.setParam,
      fieldName: 'page',
      value: page + 1
    })
  }

  const setRowsPerPage = e => {
    dispatchSelectedParams({
      type: SELECTED_PARAMS_TYPE.setParam,
      fieldName: 'per_page',
      value: e.target.value
    })
    dispatchSelectedParams({
      type: SELECTED_PARAMS_TYPE.setParam,
      fieldName: 'page',
      value: 1
    })
  }

  return (
    <Table id="pagination">
      <TableFooter>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[20, 50, 100, 150, 200]}
            count={99999999}
            rowsPerPage={selectedParams.per_page}
            page={selectedParams.page - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={setRowsPerPage}
            labelDisplayedRows={({ from, to }) => `${from} - ${to}`}
          />
        </TableRow>
      </TableFooter>
    </Table>
  )
}

export default Pagination
