import React, { useState, useEffect } from 'react'
import {
  Grid,
  Button,
  Tabs,
  Tab,
  AppBar,
  FormControlLabel,
  Checkbox
} from '@material-ui/core'
import Publish from '@material-ui/icons/Publish'
import Save from '@material-ui/icons/Save'
import Preview from './preview'
import ConfirmationModal from '../confirmation-modal'
import { useStyles as useSiftStyles } from '../../styles/evidences/sift'

const Editor = ({
  template = '',
  onTemplateChange,
  onStylesheetChange,
  status = 'new',
  templateType,
  onCreateOverride,
  onSaveDraft,
  onPublish,
  onRevert,
  onSaveStylesheet,
  merchantId,
  readOnly,
  templateId,
  globalValue,
  stylesheetValue,
  fieldDisabled,
  useGlobal
}: {
  template: string
  status: 'draft' | 'current' | 'new'
  templateType: string
  onTemplateChange: (v: string) => unknown
  onCreateOverride: () => unknown
  onSaveDraft: () => unknown
  onPublish: () => unknown
  onRevert: () => unknown
  onSaveStylesheet: () => unknown
  onStylesheetChange: (v: string) => unknown
  merchantId: string
  readOnly: boolean
  templateId: string
  globalValue: string
  stylesheetValue: string
  fieldDisabled: boolean
  useGlobal: boolean
}) => {
  const tabBoxStyle = {
    padding: '10px',
    borderTop: 'none',
    borderWidth: '1px',
    borderStyle: 'solid',
    height: '900px'
  }

  const siftClasses = useSiftStyles()
  const [publishModalOpen, setPublishModalOpen] = useState(false)
  const [stylesheetModalOpen, setStylesheetModalOpen] = useState(false)
  const [selectedTemplateTab, setSelectedTemplateTab] = useState(0)

  const publishConfirm = () => {
    setPublishModalOpen(false)
    onPublish()
  }

  const saveStylesheetConfirm = () => {
    setStylesheetModalOpen(false)
    onSaveStylesheet()
  }

  const renderPrimaryButtons = () => (
    <>
      {!useGlobal ? (
        <Grid container className={siftClasses.blueHeader}>
          <Grid item xs={3}>
            <Grid container>
              <Button
                className={siftClasses.siftButton}
                variant="outlined"
                onClick={onSaveDraft}
              >
                <Save style={{ color: 'white' }} />
                Save
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Grid container>
              <Button
                className={siftClasses.siftButton}
                variant="outlined"
                onClick={() => setPublishModalOpen(true)}
                disabled={status !== 'draft' || !templateId}
              >
                <Publish style={{ color: 'white' }} />
                Publish
              </Button>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        ''
      )}
    </>
  )

  useEffect(() => {
    let templateTabIndex = 0
    if (!useGlobal) {
      templateTabIndex = 1
    }
    setSelectedTemplateTab(templateTabIndex)
  }, [useGlobal, merchantId])

  if (fieldDisabled) {
    return (
      <Grid item xs={8} style={{ minHeight: 1000 }}>
        Disabled
      </Grid>
    )
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={6} style={{ padding: '10px' }}>
        <AppBar position="static" color="default">
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Tabs
                value={selectedTemplateTab}
                onChange={(_event: React.ChangeEvent, value: number) => {
                  setSelectedTemplateTab(value)
                }}
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab label="Global Template" id={'0'} />
                <Tab
                  label="Template Override"
                  id={'1'}
                  disabled={useGlobal}
                  data-testid="template-override-global"
                />
                <Tab label="Stylesheet" id={'2'} />
              </Tabs>

              {merchantId && (
                <Grid container item xs={4} alignItems="center">
                  <Grid item style={{ padding: '10px' }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={useGlobal}
                          onChange={() => {
                            if (!useGlobal) {
                              setSelectedTemplateTab(1)
                              onRevert()
                            } else {
                              onCreateOverride()
                            }
                          }}
                          style={{ color: '#FF0000' }}
                        />
                      }
                      label={<b>Use Global</b>}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </AppBar>
        <div style={tabBoxStyle} hidden={selectedTemplateTab !== 0}>
          <textarea
            style={{ width: '100%', height: '700px', padding: 10 }}
            value={globalValue || ''}
            disabled={true}
            onChange={({ target: { value } }) => {
              onTemplateChange(value)
            }}
            data-testid="textarea-global-template"
          />
        </div>
        <div style={tabBoxStyle} hidden={selectedTemplateTab !== 1}>
          <Grid item xs>
            {!useGlobal && !fieldDisabled && (
              <>
                <Grid item container xs={12} style={{ marginBottom: '10' }}>
                  {renderPrimaryButtons()}
                </Grid>
                <span style={{ fontWeight: 'bold', marginRight: '10' }}>
                  Status:
                </span>{' '}
                {status}{' '}
                <span style={{ fontWeight: 'bold' }}>Template Type:</span>{' '}
                {templateType && `${templateType}`}
                <h4></h4>
                <textarea
                  style={{ width: '100%', height: '700px', padding: 10 }}
                  value={template}
                  disabled={readOnly}
                  onChange={({ target: { value } }) => {
                    onTemplateChange(value)
                  }}
                  data-testid="textarea-template-override"
                />
              </>
            )}
          </Grid>
        </div>
        <div style={tabBoxStyle} hidden={selectedTemplateTab !== 2}>
          <Grid item container xs={12} style={{ marginBottom: '10' }}>
            <Grid
              container
              className={siftClasses.blueHeader}
              style={{ padding: '5px', marginBottom: '20px' }}
            >
              <Button
                className={siftClasses.siftButton}
                variant="outlined"
                onClick={() => setStylesheetModalOpen(true)}
                disabled={false}
              >
                <Publish style={{ color: 'white' }} />
                publish
              </Button>
            </Grid>
          </Grid>
          <div>This stylesheet is global</div>
          <div>Published changes here will cascade to all templates</div>
          <textarea
            style={{ width: '100%', height: '700px', padding: 10 }}
            value={stylesheetValue}
            disabled={false}
            onChange={({ target: { value } }) => {
              onStylesheetChange(value)
            }}
            data-testid="stylesheet-textarea"
          />
        </div>
      </Grid>
      <Grid item xs={6}>
        <Preview
          template={useGlobal ? globalValue : template}
          templateType={templateType}
          stylesheet={stylesheetValue}
          templateId={templateId}
        />
      </Grid>
      <ConfirmationModal
        text="Are you sure you want to publish this template?"
        title="Confirm"
        isOpen={publishModalOpen}
        onCancel={() => setPublishModalOpen(false)}
        onConfirm={() => publishConfirm()}
      />
      <ConfirmationModal
        text="Are you sure you want to publish the stylesheet?"
        title="Confirm"
        isOpen={stylesheetModalOpen}
        onCancel={() => setStylesheetModalOpen(false)}
        onConfirm={() => saveStylesheetConfirm()}
      />
    </Grid>
  )
}

export default Editor
