import React, { useState } from 'react'
import axios from '../../utils/http/axios-local'
import Hoverable from '../../common/hoverable'

const ClickableText = ({ children, onClick, style }) => {
  return (
    <Hoverable>
      {({ hover }) => (
        <span
          style={{
            cursor: 'pointer',
            ...style,
            textDecoration: hover ? 'underline' : ''
          }}
          onClick={onClick}
        >
          {children}
        </span>
      )}
    </Hoverable>
  )
}

const UserRow = ({ currentUserIsAdmin, user, enqueueSnackbar }) => {
  const [active, setActive] = useState(user.active)
  const [updatingActive, setUpdatingActive] = useState(false)

  const onActivationClick = () => {
    const deactivate = active
    setActive(!active)
    setUpdatingActive(true)
    axios
      .post(
        `/users/${user.id}/update_deactivated_at`,
        {
          deactivate
        },
        { validateStatus: status => status >= 200 && status < 300 } // TODO: why is this overridden in axios-local?
      )
      .then(() => {
        enqueueSnackbar(`User ${deactivate ? 'deactivated' : 'reactivated'}`, {
          variant: 'success'
        })
      })
      .catch(() => {
        setActive(deactivate)
        enqueueSnackbar(
          `Error ${
            deactivate ? 'deactivating' : 'reactivating'
          } user, please try again.`,
          { variant: 'error' }
        )
      })
      .finally(() => {
        setUpdatingActive(false)
      })
  }

  const tdStyle = { opacity: active ? '' : '30%' }
  return (
    <Hoverable>
      {({ hover }) => (
        <tr>
          <td className="valign-wrapper" style={tdStyle}>
            <a href={`/admin/users/${user.id}/edit`}>
              {user.email}
              {!active && ' (deactivated)'}
            </a>
          </td>
          <td style={tdStyle}>{user.phone}</td>
          <td style={tdStyle}>
            {user.client_id && (
              <a href={`/admin/clients/${user.client_id}`}>
                {user.client_name}
              </a>
            )}
          </td>
          <td data-ot={{}} style={tdStyle}>
            {user.merchant_id && (
              <a href={`/admin/merchants/${user.merchant_id}`}>
                {user.merchant_name}
              </a>
            )}
          </td>
          <td style={tdStyle}>{user.billing ? 'true' : 'false'}</td>
          <td>
            <ClickableText
              style={{
                visibility: hover ? '' : 'hidden',
                color: active ? 'red' : ''
              }}
              onClick={() => !updatingActive && onActivationClick()}
            >
              {active ? 'Deactivate' : 'Reactivate'}
            </ClickableText>
          </td>
        </tr>
      )}
    </Hoverable>
  )
}

export default UserRow
