import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import { Button } from '@material-ui/core'
import linkDisputeId from '../../utils/link-dispute-id'

const ControlColumn = props => {
  const { row } = props
  const classes = useStyles({
    respondBtnColor:
      row.links && Object.keys(row.links).length ? 'green' : 'yellow'
  })

  return (
    <>
      <TableCell className={classes.root}>
        {linkDisputeId ? (
          <Button
            href={`/responses/${linkDisputeId}/add_record_to_group?invoice_id=${row.id}`}
            className={classes.respondBtn}
            variant="contained"
            size="small"
          >
            Select
          </Button>
        ) : (
          <Button
            href={`/invoices/${row.id}`}
            className={classes.reviewBtn}
            variant="contained"
            size="small"
          >
            Details
          </Button>
        )}
      </TableCell>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    textAlign: 'center',
    padding: 5
  },
  reviewBtn: {
    width: 80,
    backgroundColor: theme.custom.columns.textColors['grey']
  },
  respondBtn: props => ({
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.custom.columns.textColors[props.respondBtnColor]
  })
}))

export default ControlColumn
