export const titleizeText = (content: string) => {
  if (content == null) {
    return
  }
  let r = content.replace(/_/g, ' ')
  r = r
    .split(' ')
    .map(s => {
      return s.charAt(0).toUpperCase() + s.slice(1)
    })
    .join(' ')
  return r
}

export const formatDate = (content: string) => {
  if (content == null) {
    return
  }
  const date = new Date(content)
  return date.toLocaleString('default', {
    dateStyle: 'medium',
    timeZone: 'UTC'
  })
}
