import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSnackbar } from 'notistack'
import copy from 'copy-to-clipboard'

const CopyToClipboard = ({ text, children }) => {
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()
  const copyToClipboard = e => {
    copy(text) && enqueueSnackbar(`Copied to clipboard: ${text}`)
  }
  return (
    <div className={classes.clickableDiv} onClick={copyToClipboard}>
      {children}
    </div>
  )
}

const useStyles = makeStyles({
  clickableDiv: {
    cursor: 'copy'
  }
})

export default CopyToClipboard
