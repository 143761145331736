import React, { MutableRefObject, useEffect, useRef, useState } from 'react'
import { Text, Title } from '@siftscience/focus-components/text'
import { makeStyles } from '@material-ui/core/styles'
import { Download } from '@siftscience/focus-components/icons/Download'
import { IconButton } from '@siftscience/focus-components/button'
import RenderingLoader from './rendering-loader'
import FinalizePreviewNavigation from './finalize-preview-navigation'
import axios from '../../utils/http/axios-local'
import { ResponsePreviewDTO } from './dtos'

interface FinalizePreviewProps {
  disputeId: string
  isParentLoading: boolean
}

interface PreviewFieldProps {
  preview: ResponsePreviewDTO
  onSetPreviewRef: (
    preview: ResponsePreviewDTO,
    ref: MutableRefObject<HTMLElement>
  ) => void
}

const useStyles = makeStyles(() => ({
  previewWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  modalContent: {
    overflow: 'scroll'
  },
  previewField: {
    marginBottom: '16px'
  },
  previewTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  preview: {
    maxWidth: '850px'
  }
}))

const PreviewField = ({
  preview,
  onSetPreviewRef
}: PreviewFieldProps): React.ReactElement => {
  const previewRef = useRef(null)
  const classes = useStyles()

  const pdfUrl = preview?.renderingPdfUrl
  const isPdf = !!pdfUrl

  useEffect(() => {
    if (previewRef) {
      onSetPreviewRef(preview, previewRef)
    }
  }, [previewRef])

  return (
    <div className={classes.previewField} ref={previewRef}>
      <div className={classes.previewTitle}>
        <Title size="small" color="secondary">
          {preview.templateDisplayName}
        </Title>
        {isPdf ? (
          <a
            href={pdfUrl}
            target="_blank"
            download={`${preview.templateDisplayName}.pdf`}
          >
            <IconButton variant="secondary-ghost">
              <Download />
            </IconButton>
          </a>
        ) : null}
      </div>
      {isPdf ? (
        <iframe
          src={pdfUrl}
          title={`PDF for ${preview.templateDisplayName}`}
          width="500px"
          height="500px"
        />
      ) : (
        <Text size="small">{preview.responseContent}</Text>
      )}
    </div>
  )
}

const FinalizePreview = ({
  disputeId,
  isParentLoading
}: FinalizePreviewProps) => {
  const previewRefs = useRef<Record<string, MutableRefObject<HTMLElement>>>({})
  const [isLoading, setIsLoading] = useState(false)
  const [previewData, setPreviewData] = useState<ResponsePreviewDTO[]>(null)
  const classes = useStyles()

  useEffect(() => {
    if (disputeId) {
      setIsLoading(true)
      axios
        .post<ResponsePreviewDTO[]>('/det/disputes/preview', {
          dispute_id: disputeId
        })
        .then(({ data }) => {
          setPreviewData(data)
          setIsLoading(false)
        })
        .catch(error => {
          console.log('Error while previewing final response', error)
          setIsLoading(false)
        })
    }
  }, [disputeId])

  const onSetPreviewRef = (
    preview: ResponsePreviewDTO,
    ref: MutableRefObject<HTMLElement>
  ) => {
    previewRefs.current[preview.templateId] = ref
  }

  const onNavigationItemClick = (preview: ResponsePreviewDTO) => {
    const ref = previewRefs?.current?.[preview.templateId]?.current
    if (ref) {
      ref.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }

  return (
    <>
      {(isLoading || isParentLoading) && <RenderingLoader />}
      {!isLoading && !isParentLoading && !previewData && (
        <div> Nothing to preview </div>
      )}
      {!isLoading && !isParentLoading && previewData && (
        <div className={classes.previewWrapper}>
          <div className={classes.preview}>
            {previewData.map(preview => (
              <PreviewField
                preview={preview}
                key={preview.templateId}
                onSetPreviewRef={onSetPreviewRef}
              />
            ))}
          </div>
          <FinalizePreviewNavigation
            previewData={previewData}
            onNavigationItemClick={onNavigationItemClick}
          />
        </div>
      )}
    </>
  )
}

export default FinalizePreview
