import React, {
  createContext,
  useEffect,
  useState,
  useCallback,
  useContext,
  useMemo,
  Dispatch,
  SetStateAction
} from 'react'
import { useDispute, useRenderings } from '../useDet'
import { getRenderingsToSet } from '../../../utils/det'
import axios from '../../../utils/http/axios-local'
import { useDelayedRendering } from '../useDelayedRendering'
import { useDisputeContext } from './dispute-context'
import { DIRTY_STATUS, LOADING_STATUS } from '../enums'
import {
  ExtendedRendering,
  RenderingContent,
  RenderingContent as RenderingContentDTO,
  RenderingDTO
} from '../dtos'
import { IRenderingContent } from '../interfaces'

interface IRenderingsContext {
  dirtyRenderings?: Record<string, DIRTY_STATUS>
  setDirtyRenderings?: Dispatch<SetStateAction<Record<string, DIRTY_STATUS>>>
  renderingsData?: RenderingDTO[]
  setRenderingsData?: Dispatch<SetStateAction<RenderingDTO[]>>
  stylesheetRenderingsData?: RenderingDTO[]
  setStylesheetRenderingsData?: Dispatch<SetStateAction<RenderingDTO[]>>
  renderingsHashData?: Record<string, RenderingDTO>
  setRenderingsHashData?: Dispatch<SetStateAction<Record<string, RenderingDTO>>>
  renderingsContentData?: Record<string, IRenderingContent>
  setRenderingsContentData?: Dispatch<
    SetStateAction<Record<string, IRenderingContent>>
  >
  displayRenderingsData?: ExtendedRendering[]
  setDisplayRenderingsData?: Dispatch<SetStateAction<ExtendedRendering[]>>
  stylesFromBe?: string[]
  setStyles?: Dispatch<SetStateAction<string[]>>
  activeRenderingId: string | number | null
  setActiveRenderingId: Dispatch<SetStateAction<string | number | null>>
  updateRenderingContent?: (
    templateId: number,
    content: string,
    status: LOADING_STATUS,
    workflowDescription?: string
  ) => void
  onUpdateDirtyStatus?: (renderingId: number, status: DIRTY_STATUS) => void
  reRenderAsync?: (
    renderingId: number | string,
    callback: (renderingId: number | string) => void,
    immediate?: boolean
  ) => void
  removeRenderingRequest?: (renderingId: number | string) => void
  onDirtyRenderingClick?: (rendering: RenderingDTO) => void
  onUpdateRenderingData?: (updatedData: Partial<RenderingDTO>) => void
  requestRenderingContent?: (rendering: ExtendedRendering) => void
  onToggleIncludeInRendering?: (
    rendering: ExtendedRendering,
    value: boolean
  ) => void
}

const NOT_DIRTY_TIMEOUT = 5000
const ERROR_TEXT_MESSAGE =
  'Apologies, there was an error loading this template, please try refreshing the page.'

const RenderingsContext = createContext<IRenderingsContext>({})

export const RenderingsProvider = ({ children }) => {
  const { disputeId } = useDisputeContext()
  const { disputeData, isDisputeLoading } = useDispute(disputeId)
  const { renderingsData: renderings, isRenderingsLoading } =
    useRenderings(disputeId)
  const [reRenderAsync, removeRenderingRequest] = useDelayedRendering()

  // Internal provider state
  const [renderingsData, setRenderingsData] = useState<RenderingDTO[]>([])
  const [stylesheetRenderingsData, setStylesheetRenderingsData] = useState<
    RenderingDTO[]
  >([])
  const [dirtyRenderings, setDirtyRenderings] = useState<
    Record<string, DIRTY_STATUS>
  >({})
  const [renderingsHashData, setRenderingsHashData] = useState<
    Record<string, RenderingDTO>
  >({})
  const [renderingsContentData, setRenderingsContentData] = useState<
    Record<string, IRenderingContent>
  >({})
  const [displayRenderingsData, setDisplayRenderingsData] = useState<
    ExtendedRendering[]
  >([])
  const [activeRenderingId, setActiveRenderingId] = useState<
    string | number | null
  >(null)
  const [stylesFromBe, setStyles] = useState<string[]>([])

  useEffect(() => {
    if (
      !(isDisputeLoading || isRenderingsLoading) &&
      disputeData &&
      renderings
    ) {
      const { renderingsToDisplay, stylesheetRenderings, renderingsHash } =
        getRenderingsToSet(disputeData, renderings)

      setRenderingsData(renderingsToDisplay)
      setStylesheetRenderingsData(stylesheetRenderings)
      setRenderingsHashData(renderingsHash)
    }
  }, [isDisputeLoading, isRenderingsLoading, disputeData, renderings])

  useEffect(() => {
    // pull styles content which is a part of renderings
    if (stylesheetRenderingsData && stylesheetRenderingsData.length) {
      const promises = stylesheetRenderingsData.map(rendering =>
        axios.get<RenderingContentDTO>(
          `/det/renderings/${rendering.templateId}?dispute_id=${disputeId}`
        )
      )
      Promise.all(promises).then(results => {
        const styles = results.map(result => result.data.content)
        setStyles(styles)
      })
    }
  }, [stylesheetRenderingsData])

  const updateRenderingContent = useCallback(
    (
      templateId: number,
      content: string,
      status: LOADING_STATUS,
      workflowDescription?: string
    ) => {
      setRenderingsContentData(prevRenderingsContentData => ({
        ...prevRenderingsContentData,
        [templateId]: {
          content,
          status,
          workflowDescription
        }
      }))
    },
    [setRenderingsContentData]
  )

  const onUpdateDirtyStatus = useCallback(
    (renderingId: number, status: DIRTY_STATUS) => {
      setDirtyRenderings(prevValue => ({ ...prevValue, [renderingId]: status }))
    },
    [setDirtyRenderings]
  )

  const onDirtyRenderingClick = useCallback(
    (rendering: RenderingDTO) => {
      onUpdateDirtyStatus(rendering.id, DIRTY_STATUS.Loading)
      updateRenderingContent(
        rendering.templateId,
        renderingsContentData?.[rendering.templateId]?.content,
        LOADING_STATUS.Loading,
        renderingsContentData?.[rendering.templateId]?.workflowDescription
      )
      removeRenderingRequest(rendering.id)
      axios
        .get<RenderingContent>(
          `/det/renderings/${rendering.templateId}?dispute_id=${disputeId}`
        )
        .then(({ data: { content, templateWorkflowDescription } }) => {
          updateRenderingContent(
            rendering.templateId,
            content,
            LOADING_STATUS.Loaded,
            templateWorkflowDescription
          )
          onUpdateDirtyStatus(rendering.id, DIRTY_STATUS.UpToDate)

          setTimeout(() => {
            onUpdateDirtyStatus(rendering.id, DIRTY_STATUS.Latest)
          }, NOT_DIRTY_TIMEOUT)
        })
        .catch(error => {
          console.log(error)
          updateRenderingContent(
            rendering.templateId,
            renderingsContentData?.[rendering.templateId]?.content,
            LOADING_STATUS.Loaded,
            renderingsContentData?.[rendering.templateId]?.workflowDescription
          )
        })
    },
    [onUpdateDirtyStatus, updateRenderingContent, removeRenderingRequest]
  )

  const onUpdateRenderingData = useCallback(
    (updatedData: Partial<RenderingDTO>) => {
      const updatedRenderings = renderingsData.reduce((acc, item) => {
        if (item.templateId === updatedData.templateId) {
          acc.push({ ...item, ...updatedData })
        } else {
          acc.push(item)
        }
        return acc
      }, [])
      setRenderingsData(updatedRenderings)
      const renderingsHash = updatedRenderings.reduce((hash, item) => {
        hash[item.templateId] = item
        return hash
      }, {})
      setRenderingsHashData(renderingsHash)
    },
    [renderingsData, setRenderingsData, setRenderingsHashData]
  )

  const requestRenderingContent = useCallback(
    (rendering: ExtendedRendering) => {
      updateRenderingContent(rendering.templateId, '', LOADING_STATUS.Loading)
      const renderingContentData = renderingsContentData?.[rendering.templateId]

      axios
        .get<RenderingContentDTO>(
          `/det/renderings/${rendering.templateId}?dispute_id=${disputeId}`
        )
        .then(({ data }) => {
          if ((data?.content ?? '') === '') {
            updateRenderingContent(
              rendering.templateId,
              renderingContentData?.content || ERROR_TEXT_MESSAGE,
              LOADING_STATUS.Errored,
              renderingContentData?.workflowDescription
            )
          } else {
            updateRenderingContent(
              data.templateId,
              data?.content,
              LOADING_STATUS.Loaded,
              data.templateWorkflowDescription
            )
          }
        })
        .catch(error => {
          console.log(error)
          updateRenderingContent(
            rendering.templateId,
            renderingContentData?.content || ERROR_TEXT_MESSAGE,
            LOADING_STATUS.Errored,
            renderingContentData?.workflowDescription
          )
        })
    },
    [updateRenderingContent, renderingsContentData]
  )

  const onToggleIncludeInRendering = useCallback(
    (rendering: ExtendedRendering, value: boolean) => {
      const renderingContentData = renderingsContentData?.[rendering.templateId]
      updateRenderingContent(
        rendering.templateId,
        renderingContentData?.content,
        LOADING_STATUS.Loading
      )
      axios
        .patch(
          `/det/renderings/${rendering.templateId}?dispute_id=${disputeId}`,
          { rendering: { includeInResponse: value } }
        )
        .then(() => {
          onUpdateRenderingData({
            templateId: rendering.templateId,
            includeInResponse: value
          })
          updateRenderingContent(
            rendering.templateId,
            renderingContentData?.content,
            LOADING_STATUS.Loaded
          )
        })
        .catch(error => {
          console.log(error)
          updateRenderingContent(
            rendering.templateId,
            renderingContentData?.content,
            LOADING_STATUS.Loaded
          )
        })
    },
    [
      renderingsContentData,
      updateRenderingContent,
      onUpdateRenderingData,
      updateRenderingContent
    ]
  )

  const contextValue = useMemo(
    () => ({
      dirtyRenderings,
      setDirtyRenderings,
      renderingsData,
      setRenderingsData,
      stylesheetRenderingsData,
      setStylesheetRenderingsData,
      renderingsHashData,
      setRenderingsHashData,
      renderingsContentData,
      setRenderingsContentData,
      displayRenderingsData,
      setDisplayRenderingsData,
      stylesFromBe,
      setStyles,
      updateRenderingContent,
      onUpdateDirtyStatus,
      reRenderAsync,
      removeRenderingRequest,
      onDirtyRenderingClick,
      onUpdateRenderingData,
      requestRenderingContent,
      onToggleIncludeInRendering,
      activeRenderingId,
      setActiveRenderingId
    }),
    [
      dirtyRenderings,
      setDirtyRenderings,
      renderingsData,
      setRenderingsData,
      stylesheetRenderingsData,
      setStylesheetRenderingsData,
      renderingsHashData,
      setRenderingsHashData,
      renderingsContentData,
      setRenderingsContentData,
      displayRenderingsData,
      setDisplayRenderingsData,
      stylesFromBe,
      setStyles,
      updateRenderingContent,
      onUpdateDirtyStatus,
      reRenderAsync,
      removeRenderingRequest,
      onDirtyRenderingClick,
      onUpdateRenderingData,
      requestRenderingContent,
      onToggleIncludeInRendering,
      activeRenderingId,
      setActiveRenderingId
    ]
  )

  return (
    <RenderingsContext.Provider value={contextValue}>
      {children}
    </RenderingsContext.Provider>
  )
}

export const useRenderingsContext = () => {
  return useContext(RenderingsContext)
}
