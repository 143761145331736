import React, { useReducer, useContext } from 'react'
import NotificationStore, { INotificationStore } from './NotificationStore'
import Reducers, { Action } from './NotificationReducers'

/**
 * Create Global Session Context Store
 */
interface IContextProps {
  notification: INotificationStore
  dispatchNotification: (action: Action) => void
}

const NotificationContext = React.createContext({} as IContextProps)

/**
 * Create Global Session Store Provider
 */
type ProviderProps = {
  children?: React.ReactChild[]
}

const NotificationProvider = ({ children, ...rest }: ProviderProps) => {
  const [notification, dispatchNotification] = useReducer(Reducers, {
    ...NotificationStore,
    ...rest
  })

  const value = { notification, dispatchNotification }

  return (
    <NotificationContext.Provider value={value}>
      {children}
    </NotificationContext.Provider>
  )
}

const useNotification = () => {
  const context = useContext(NotificationContext)

  if (context === null) {
    throw new Error(
      'useNotification must be implemented within a NotificationProvider'
    )
  }

  return context
}

export { NotificationProvider, useNotification }
