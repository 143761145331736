import React, { useEffect, useMemo, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Checkboxes from './checkboxes'
import NoRecords from './no-records'
import ColumnHeaders from './column-headers'
import {
  CheckboxContext,
  DispatchCheckboxContext
} from '../common/checkboxes/checkbox-context'
import useBindTableHeightToResize from './use-bind-table-height-to-resize'
import formatSelectedColumns from './format-selected-columns'
import ListViewContext from '../list-view-context'
import { CHECKBOX_TYPE } from '../common/checkboxes/checkbox-reducer'

const DataTable = props => {
  const classes = useStyles()

  const {
    Data: { dataAdapter },
    options,
    data,
    columns,
    setShowMassActions,
    ControlColumn,
    isLoading,
    isFetching
  } = useContext(ListViewContext)

  const formattedColumns = useMemo(
    () => formatSelectedColumns(columns, dataAdapter),
    [columns]
  )
  const tableHeight = useBindTableHeightToResize()
  const checkboxes = useContext(CheckboxContext)
  const dispatchCheckboxes = useContext(DispatchCheckboxContext)

  useEffect(() => {
    if (options.useMassActions && data) {
      dispatchCheckboxes({ type: CHECKBOX_TYPE.init, data: data.data })
    }
  }, [isLoading, isFetching])

  useEffect(() => {
    setShowMassActions(!!checkboxes.selectedIds.length)
  }, [checkboxes])

  if (isLoading || isFetching) return null

  return useMemo(() => {
    return (
      <Paper className={classes.root}>
        <div className={classes.tableWrapper} style={{ height: tableHeight }}>
          {!data.data?.length ? (
            <NoRecords />
          ) : (
            <Table id="data-table" stickyHeader>
              <ColumnHeaders {...{ formattedColumns }} />

              <TableBody>
                {data.data.map(row => (
                  <TableRow key={`row-${row.id}`}>
                    {options.useMassActions ? (
                      <TableCell>
                        <Checkboxes id={row.id} />
                      </TableCell>
                    ) : null}

                    {formattedColumns.map(column => (
                      <TableCell key={`cell-${row.id}-${column.field}`}>
                        {typeof column.format === 'function' ? (
                          <column.format value={row[column.field]} />
                        ) : (
                          row[column.field]
                        )}
                      </TableCell>
                    ))}
                    {ControlColumn ? <ControlColumn row={row} /> : null}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </div>
      </Paper>
    )
  }, [data, columns, tableHeight])
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  header: {
    position: 'sticky',
    top: 0,
    zIndex: 10
  },
  tableWrapper: {
    overflow: 'auto'
  },
  inActivate: {
    opacity: 0.5,
    pointerEvents: 'none'
  }
}))

export default DataTable
