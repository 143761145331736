import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import Helmet from 'react-helmet'
import { AxiosResponse } from 'axios'
import { Grid, TextField } from '@material-ui/core'
import axiosSession from '../../utils/http/axios-sessions'
import { useNotification } from '../../common/notifications/NotificationContext'
import { NOTIFICATION_TYPES } from '../../common/notifications/NotificationReducers'
import {
  SessionLayout,
  SessionFormControl,
  CenteredRow,
  SubmitButton
} from './ui/sessions'
import SessionResetPassword from './SessionResetPassword'
import SessionHeader from './SessionHeader'
import SessionNotifications from './SessionNotifications'

const defaults = {
  username: ''
}

const errorNotification =
  'Request Not Processed. Please contact your administrator!'
const checkEmailNotification = 'Check your E-mail for a password reset link'

const SessionResetForm = (): React.ReactChild => {
  const [state, setState] = useState({ ...defaults })
  const [showForm, setShowForm] = useState(true)
  const { dispatchNotification } = useNotification()
  const history = useHistory()

  const onChange = ({ target: { name, value } }) =>
    setState(s => ({ ...s, [name]: value }))

  const notifyAndRouteToLogin = (response: AxiosResponse) => {
    // notify client where to look for password reset link
    const isError = response.status >= 300
    dispatchNotification({
      type: isError ? NOTIFICATION_TYPES.warning : NOTIFICATION_TYPES.info,
      payload: {
        notification: isError ? errorNotification : checkEmailNotification
      }
    })
    // route client to login page
    history.push('/ui/login')
  }

  const onSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault()

    axiosSession
      .post('/forgot_password.json', { username_or_email: state.username })
      .then(notifyAndRouteToLogin)
      .catch(notifyAndRouteToLogin)

    setShowForm(false)
  }

  const renderResetForm = (): React.ReactChild => (
    <>
      <form onSubmit={onSubmit}>
        <Grid
          container
          direction="column"
          alignItems="stretch"
          justify="center"
        >
          <Grid item zeroMinWidth xs={12}>
            <SessionFormControl>
              <TextField
                variant="outlined"
                name="username"
                placeholder="Username or Email"
                label="Username or Email"
                value={state.username}
                onChange={onChange}
              />
            </SessionFormControl>
            <CenteredRow item xs={12} zeroMinWidth>
              <SubmitButton variant="contained" size="large">
                Reset Password
              </SubmitButton>
            </CenteredRow>
          </Grid>
        </Grid>
      </form>
    </>
  )

  return (
    <SessionLayout>
      <Helmet>
        <title>Sift | Password Reset</title>
      </Helmet>
      <SessionHeader title="Are you sure you want to reset your password?" />
      <SessionNotifications />
      {showForm ? renderResetForm() : <br />}
      <SessionResetPassword
        text="Return to Login?"
        linkText="Login"
        to="/ui/login"
      />
    </SessionLayout>
  )
}

export default SessionResetForm
