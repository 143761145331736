import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import capitalize from '../../../../utils/capitalize'

const Stage = ({ value }) => {
  const values = {
    chargeback: { abbr: 'CB', bgColor: 'deepOrange' },
    pre_arbitration: { abbr: 'PA', bgColor: 'red' },
    retrieval_request: { abbr: 'RR', bgColor: 'yellow' }
  }
  const classes = useStyles({
    backgroundColor: values[value] ? values[value].bgColor : 'white'
  })
  const tooltipTitle = value
    ? value
        .split('_')
        .map(s => capitalize(s))
        .join(' ')
    : ''

  return (
    <Tooltip title={tooltipTitle}>
      <span className={classes.root}>
        {values[value] ? values[value].abbr : ''}
      </span>
    </Tooltip>
  )
}

const useStyles = makeStyles(theme => ({
  root: props => ({
    ...theme.custom.columns.base,
    backgroundColor: theme.custom.columns.bgColors[props.backgroundColor]
  })
}))

export default Stage
