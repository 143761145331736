import React from 'react'
import ListView from '../../common/list-view/list-view'
import dataAdapter from './data-adapter'
import fetchInvoices from './fetch-invoices'
import SearchItems from './search-items'
import ControlColumn from './control-column'
import getVisibleColumns from '../../common/list-view/common/get-visible-columns'
import useSelectOptions from './use-select-options'

const Invoices = props => {
  const columns = getVisibleColumns(dataAdapter)
  const selectOptions = useSelectOptions()

  const options = {
    useLinkIndicator: true
  }

  return (
    <ListView
      dataAdapter={dataAdapter}
      selectOptions={selectOptions}
      columns={columns}
      fetchData={fetchInvoices}
      SearchItems={SearchItems}
      ControlColumn={ControlColumn}
      options={options}
    />
  )
}

export default Invoices
