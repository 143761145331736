import React from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { Drag } from '@siftscience/focus-components/icons'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  icon: {
    width: '16px',
    height: '16px'
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))

export const RowDragHandleCell = ({ rowId }: { rowId: string }) => {
  const classes = useStyles()
  const { attributes, listeners } = useSortable({
    id: rowId
  })

  return (
    <div className={classes.iconWrapper}>
      <Drag {...attributes} {...listeners} className={classes.icon} />
    </div>
  )
}
