import Logo from '../../images/sift-logo-white.svg'
import { makeStyles } from '@material-ui/core/styles'

export default () => {
  const classes = useStyles()
  return <Logo className={classes.root} />
}

const useStyles = makeStyles(() => ({
  root: {
    width: 100,
    height: 46
  }
}))
