import { useQuery } from 'react-query'
import axios from '../../utils/http/axios-local'
import { camelCase } from 'lodash'

const getColumns = async () => {
  const response = await axios.get('/api/disputes_list/get_dispute_columns')
  return Object.keys(response.data).reduce((acc, column) => {
    /*
        TODO: this functionality needs a revamp on the server side
        so that we can properly use dataAdapter mappings for these columns
        it only works because the columns were luckily named the same as our
        keys but in snake_case.
        The columns should probably be named the same as their "name" in dataAdapter
        */
    // the server stores these as snake_case, but we need them in camelCase
    acc[camelCase(column)] = response.data[column]
    return acc
  }, {})
}

const useColumns = () => {
  return useQuery('disputeColumns', getColumns, {
    staleTime: Infinity
  })
}

export default useColumns
