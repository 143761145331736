import capitalize from '../../../../utils/capitalize'

const CardType = ({ value }) =>
  value
    ? value
        .split('_')
        .map(ct => capitalize(ct))
        .join(' ')
    : null

export default CardType
