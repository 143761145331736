import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import { useSnackbar } from 'notistack'
import { updateNotes } from './update-assignments'
import { useCurrentUser } from '../../contexts/CurrentUserContext'

const NotesDialog = props => {
  const { enqueueSnackbar } = useSnackbar()
  const {
    showNoteDialog,
    setShowNoteDialog,
    checkboxes,
    refetch,
    setShowMassActions
  } = props
  const { currentUser } = useCurrentUser()
  const [notes, setNotes] = useState('')

  const handleClose = () => {
    setShowNoteDialog(false)
    setNotes('')
  }

  const handleSaveNote = async () => {
    await updateNotes(currentUser.id, checkboxes.selectedIds, notes)
    setShowNoteDialog(false)
    setNotes('')
    enqueueSnackbar('Note saved!', { variant: 'success' })
    setShowMassActions(false)
    refetch()
  }

  return (
    <Dialog open={showNoteDialog} fullWidth={true} maxWidth="md">
      <DialogTitle>Add Note</DialogTitle>
      <DialogContent>
        <TextField
          multiline
          fullWidth
          value={notes}
          onChange={e => setNotes(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSaveNote} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default NotesDialog
