import React from 'react'
import { createRoot } from 'react-dom/client'

import { App } from '../client/app'
import HeaderWrapper from '../client/common/header/header-wrapper'
import Footer from '../client/common/footer/footer'

document.addEventListener('DOMContentLoaded', () => {
  // Header
  const headerContainer = document.getElementById('header-root')
  const headerRoot = createRoot(headerContainer)
  headerRoot.render(<HeaderWrapper />)

  // App
  const appContainer = document.getElementById('root')
  const appRoot = createRoot(appContainer)
  appRoot.render(<App />)

  // Footer
  const footerContainer = document.getElementById('footer-root')
  const footerRoot = createRoot(footerContainer)
  footerRoot.render(<Footer />)
})
