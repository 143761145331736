import axios from '../../utils/http/axios-local'
import { useSnackbar } from 'notistack'
import { useHistory } from 'react-router-dom'

const useSaveMidMerchantGroup = () => {
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()

  return ({ values }) =>
    axios
      .post('/mid-merchant-groups', { mid_merchant_group: values })
      .then(r => {
        enqueueSnackbar('Successfully created mid merchant group!', {
          variant: 'success'
        })
        history.push(
          `/ui/mid-merchant-groups/${r.data.mid_merchant_group.data.id}/edit`
        )
      })
      .catch(() =>
        enqueueSnackbar(
          'There was a problem creating the mid merchant group.',
          {
            variant: 'error'
          }
        )
      )
}

export default useSaveMidMerchantGroup
