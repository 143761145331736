import React, {
  createContext,
  useEffect,
  useState,
  useContext,
  useMemo,
  Dispatch,
  SetStateAction
} from 'react'
import { useDisputeContext } from './dispute-context'
import { useDataset } from '../useDet'
import { DatasetDTO } from '../dtos'

interface IDatasetContext {
  dataset?: DatasetDTO
  setDataset?: Dispatch<SetStateAction<DatasetDTO>>
}

const DatasetContext = createContext<IDatasetContext>({})

export const DatasetProvider = ({ children }) => {
  const { disputeId } = useDisputeContext()
  const { datasetData, isDatasetLoading } = useDataset(disputeId)

  // Internal provider state
  const [dataset, setDataset] = useState<DatasetDTO>({})

  useEffect(() => {
    if (!isDatasetLoading && datasetData) {
      setDataset(datasetData.dataset)
    }
  }, [isDatasetLoading, datasetData])

  const contextValue = useMemo(
    () => ({
      dataset,
      setDataset
    }),
    [dataset, setDataset]
  )

  return (
    <DatasetContext.Provider value={contextValue}>
      {children}
    </DatasetContext.Provider>
  )
}

export const useDatasetContext = () => {
  return useContext(DatasetContext)
}
