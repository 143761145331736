import { useQuery } from 'react-query'
import axios from '../../utils/http/axios-disputer'

const getSelectOptions = async () =>
  axios.get('/api/transactions/select_options')

const useSelectOptions = () => {
  return useQuery('transactionsSearchOptions', getSelectOptions, {
    staleTime: Infinity
  })
}

export default useSelectOptions
