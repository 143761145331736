import { useQuery } from 'react-query'
import axios from '../../utils/http/axios-local'

const getSelectOptions = async () =>
  axios.get('/api/disputes_list/get_all_select_options')

const useSelectOptions = () => {
  return useQuery('disputeSearchOptions', getSelectOptions, {
    staleTime: Infinity
  })
}

export default useSelectOptions
