import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { Icon, IconButton } from '@material-ui/core'
import ListViewContext from '../../list-view-context'

const SearchFilterButtons = props => {
  const { updateSelectedParams } = props
  const classes = useStyles()

  const { clearSearch, setMenuOpen } = useContext(ListViewContext)

  return (
    <div className={classes.controls}>
      <div className={classes.buttonContainer}>
        <Button
          size="small"
          variant="contained"
          color="default"
          onClick={clearSearch}
          className={classes.buttons}
        >
          reset
        </Button>
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={updateSelectedParams}
          className={classes.buttons}
        >
          search
        </Button>
      </div>
      <IconButton
        data-testid="close-search-button"
        onClick={() => setMenuOpen(false)}
      >
        <Icon>clear</Icon>
      </IconButton>
    </div>
  )
}

const useStyles = makeStyles({
  controls: {
    display: 'flex'
  },
  buttonContainer: {
    width: '100%',
    padding: 5
  },
  buttons: {
    marginRight: 4
  }
})

export default SearchFilterButtons
