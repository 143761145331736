export const initialCheckboxState = {
  isLoading: true,
  checkboxes: {},
  selectedIds: []
}

export const CHECKBOX_TYPE = {
  init: 'init',
  initObj: 'initObj',
  toggleAll: 'toggleAll',
  toggle: 'toggle',
  clear: 'clear'
}

export const checkboxReducer = (state, action) => {
  const { type, checked, data, id } = action
  let checkboxes = {}
  const getSelectedIds = cb => Object.keys(cb).filter(c => cb[c])

  switch (type) {
    case CHECKBOX_TYPE.init:
      checkboxes = data.reduce((cbs, row) => {
        cbs[row.id] = false
        return cbs
      }, {})
      return {
        isLoading: false,
        checkboxes,
        selectedIds: []
      }

    case CHECKBOX_TYPE.initObj:
      checkboxes = Object.keys(data).reduce((cbs, row) => {
        cbs[row] = data[row]
        return cbs
      }, {})
      return {
        isLoading: false,
        checkboxes,
        selectedIds: Object.keys(checkboxes).filter(c => c)
      }

    case CHECKBOX_TYPE.toggleAll:
      checkboxes = Object.keys(state.checkboxes).reduce((cbs, row) => {
        cbs[row] = checked
        return cbs
      }, {})
      return {
        isLoading: false,
        checkboxes,
        selectedIds: getSelectedIds(checkboxes)
      }

    case CHECKBOX_TYPE.toggle:
      checkboxes = { ...state.checkboxes, [id]: checked }
      return {
        isLoading: state.isLoading,
        checkboxes,
        selectedIds: getSelectedIds(checkboxes)
      }
    case CHECKBOX_TYPE.clear:
      return { ...initialCheckboxState, isLoading: false }

    default:
      throw new Error('checkboxReducer: Invalid action')
  }
}
