import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const ActionBar = props => {
  const { children } = props
  const classes = useStyles()

  return (
    <div id="action-bar" className={classes.bar}>
      {children}
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  bar: {
    display: 'flex',
    margin: '5px 10px'
  }
}))

export default ActionBar
