import qs from 'qs'

const setParamsFromUrl = ({ emptySearchParams, getColumnByName }) => {
  const params = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
    arrayLimit: 1000
  })

  if (Object.keys(params).length) {
    const formattedParams = Object.keys(params).reduce((acc, param) => {
      if (Object.keys(emptySearchParams).includes(param)) {
        // qs params are all treated as strings, fix the numbers
        if (['page', 'per_page'].includes(param)) {
          acc[param] = Number(params[param])
        } else if (
          getColumnByName(param) &&
          getColumnByName(param) === 'number'
        ) {
          acc[param] = Number(params[param])
        } else {
          acc[param] = params[param]
        }
      }
      return acc
    }, {})

    return {
      // make sure there is a default per_page, page, and sort_by
      per_page: emptySearchParams.per_page,
      page: emptySearchParams.page,
      sort_by: emptySearchParams.sort_by,
      ...formattedParams
    }
  } else {
    return null
  }
}
export default setParamsFromUrl
