import React from 'react'
import { Grid } from '@material-ui/core'
import { styled } from '@material-ui/core/styles'

const Page = styled(Grid)({
  height: '100vh',
  marginTop: '-7px'
})

const SessionPage = ({ children }) => <Page>{children}</Page>

export default SessionPage
