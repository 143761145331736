import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { v1 as uuid } from 'uuid'
import {
  Button,
  Container,
  Grid,
  Paper,
  IconButton,
  Icon,
  FormLabel
} from '@material-ui/core'
import { Link, useParams, useHistory } from 'react-router-dom'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import CustomInput from '../../common/forms/custom-input'
import CustomSelect from '../../common/forms/custom-select'
import LoadingIndicator from '../../common/loading-indicator'

import useMerchantOptions from './useMerchantOptions'
import useMidOptions from './useMidOptions'
import useMidMerchantGroup from './useMidMerchantGroup'
import useSaveMidMerchantGroup from './useSaveMidMerchantGroup'
import useCreateMidMerchantGroup from './useCreateMidMerchantGroup'

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(3, 2)
  },
  formGrid: {
    padding: 7
  },
  actionBar: {
    padding: 7,
    display: 'flex',
    flexFlow: 'row-reverse'
  },
  label: {
    fontSize: 12,
    color: theme.palette.text.secondary
  },
  searchNav: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 8,
    justifyContent: 'space-between'
  }
}))

const MidMerchantGroupsResource = props => {
  const classes = useStyles()
  const { id, action } = useParams<{ id: string; action: 'edit' | 'create' }>()

  const midMerchantGroup = useMidMerchantGroup(id)

  const initialValues = {
    partnerName: '',
    remoteId: uuid().substring(0, 13),
    merchantId: '',
    mids: []
  }

  const validationSchema = Yup.object({
    partnerName: Yup.string().required('Required'),
    remoteId: Yup.string().required('Required'),
    merchantId: Yup.number().required('Required')
  })

  const saveMidMerchantGroup = useSaveMidMerchantGroup()
  const createMidMerchantGroup = useCreateMidMerchantGroup()

  const onSubmit = async rawValues => {
    const values = {
      merchant_id: rawValues.merchantId,
      partner_name: rawValues.partnerName,
      remote_id: rawValues.remoteId,
      mids: rawValues.mids
    }
    if (action === 'edit') {
      await saveMidMerchantGroup({ values, id })
    } else {
      await createMidMerchantGroup({ values })
    }
  }

  const history = useHistory()
  const createNew = () => {
    history.push('/ui/mid-merchant-groups/create')
  }

  return (
    <Container maxWidth="xl">
      {action === 'edit' && !midMerchantGroup ? (
        <LoadingIndicator />
      ) : (
        <Paper className={classes.paper}>
          <div className={classes.searchNav}>
            <Link to={'/ui/mid-merchant-groups'}>{'<-- Back to index'}</Link>
            <IconButton onClick={createNew}>
              <Icon color="primary">add_circle_outline</Icon>
            </IconButton>
          </div>

          <h1>Mid Merchant Group</h1>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({
              values,
              values: { merchantId },
              isSubmitting,
              isValid,
              setFieldValue,
              errors
            }) => {
              const partnerOptions = [
                { value: 'Order Insights', label: 'Order Insights' },
                { value: 'CDRN', label: 'CDRN' },
                { value: 'Eliminator', label: 'Consumer Clarity' },
                { value: 'Ethoca', label: 'Ethoca360' }
              ]
              const merchantOptions = useMerchantOptions()
              const midOptions = useMidOptions({ merchantId })

              useEffect(() => {
                if (action === 'edit') {
                  const fields = [
                    'merchantId',
                    'remoteId',
                    'partnerName',
                    'mids'
                  ]

                  fields.forEach(field =>
                    setFieldValue(field, midMerchantGroup[field], false)
                  )
                }
              }, [])

              return (
                <Form>
                  <Grid container>
                    <Grid item xs={12} className={classes.formGrid}>
                      <CustomSelect
                        name="partnerName"
                        label="Partner"
                        value={values.partnerName}
                        options={partnerOptions}
                        onChange={option =>
                          setFieldValue('partnerName', option.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} className={classes.formGrid}>
                      <FormLabel
                        error={Boolean(errors['remoteId'])}
                        className={classes.label}
                      >
                        Remote id used by partner
                      </FormLabel>
                      <CustomInput name="remoteId" />
                    </Grid>
                    <Grid item xs={12} className={classes.formGrid}>
                      <CustomSelect
                        name="merchantId"
                        label="Merchant"
                        value={values.merchantId}
                        options={merchantOptions.data}
                        isLoading={merchantOptions.isLoading}
                        onChange={option => {
                          setFieldValue('merchantId', option.value)
                          setFieldValue('mids', [])
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} className={classes.formGrid}>
                      <CustomSelect
                        multiple
                        name="mids"
                        label="Mids"
                        value={values.mids}
                        options={midOptions}
                        isLoading={merchantId && !midOptions}
                        onChange={option => {
                          setFieldValue(
                            'mids',
                            Array.isArray(option)
                              ? option.map(o => o.value)
                              : []
                          )
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} className={classes.actionBar}>
                      <Button
                        type="submit"
                        variant="contained"
                        size="small"
                        color="primary"
                        disabled={!isValid || isSubmitting}
                      >
                        {action === 'edit' ? 'Save' : 'Create'}
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )
            }}
          </Formik>
        </Paper>
      )}
    </Container>
  )
}

export default MidMerchantGroupsResource
