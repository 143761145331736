import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'

const ReportingCategory = ({ value }) => {
  const values = {
    fraud: {
      abbr: 'Fraud',
      tooltip: 'Fraud',
      color: 'red'
    },
    non_receipt: {
      abbr: 'NR',
      tooltip: 'Non-receipt',
      color: 'yellow'
    },
    unrecognized: {
      abbr: 'UR',
      tooltip: 'Unrecognized',
      color: 'grey'
    },
    unacceptable: {
      abbr: 'UA',
      tooltip: 'Unacceptable',
      color: 'blue'
    },
    credit_not_processed: {
      abbr: 'Credit',
      tooltip: 'Credit Not Processed',
      color: 'orange'
    },
    cancel_subscription: {
      abbr: 'Cancel',
      tooltip: 'Cancel Subscription',
      color: 'purple'
    },
    duplicate: {
      abbr: 'Dup',
      tooltip: 'Duplicate',
      color: 'grey'
    },
    processing_error: {
      abbr: 'Error',
      tooltip: 'Processing Error',
      color: 'grey'
    },
    authorization: {
      abbr: 'Auth',
      tooltip: 'Authorization',
      color: 'grey'
    }
  }
  const classes = useStyles({
    backgroundColor: values[value] ? values[value].color : 'grey'
  })

  const display = values[value] ? values[value].abbr : 'Other'
  const tooltipTitle = values[value] ? values[value].tooltip : ''

  return (
    <Tooltip title={tooltipTitle}>
      <span className={classes.root}>{display}</span>
    </Tooltip>
  )
}

const useStyles = makeStyles(theme => ({
  root: props => ({
    ...theme.custom.columns.base,
    backgroundColor: theme.custom.columns.bgColors[props.backgroundColor]
  })
}))

export default ReportingCategory
