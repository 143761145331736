import { useRef, useCallback } from 'react'

const MIN_TIMEOUT = 8000 // 8 seconds
const MAX_DELTA = 5000 // 5 seconds

interface DelayedRendering {
  [renderingId: string]: number // timerId
}

export function useDelayedRendering() {
  const delayedRenderings = useRef<DelayedRendering>({})
  const reRenderAsync = useCallback(
    (
      renderingId: number | string,
      callback: (renderingId: number | string) => void,
      immediate?: boolean
    ) => {
      if (delayedRenderings.current[renderingId]) {
        clearTimeout(delayedRenderings.current[renderingId])
        delete delayedRenderings.current[renderingId]
      }

      const delayTime = immediate ? 0 : Math.random() * MAX_DELTA + MIN_TIMEOUT

      const timerId = window.setTimeout(() => {
        callback(renderingId)
        delete delayedRenderings.current[renderingId]
      }, delayTime)

      delayedRenderings.current[renderingId] = timerId
    },
    [delayedRenderings]
  )
  const removeRenderingRequest = useCallback(
    (renderingId: number | string) => {
      if (delayedRenderings.current[renderingId]) {
        clearTimeout(delayedRenderings.current[renderingId])
        delete delayedRenderings.current[renderingId]
      }
    },
    [delayedRenderings]
  )

  return [reRenderAsync, removeRenderingRequest]
}
