import React, { useEffect, useState } from 'react'
import { Grid, Button, Tabs, Tab, AppBar } from '@material-ui/core'
import _ from 'lodash'
import { useStyles as useSiftStyles } from '../../styles/evidences/sift'
import axios from '../../utils/http/axios-local'
import Loop from '@material-ui/icons/Loop'
import PreviewNode from '../preview-node'
import SamplePayload from '../evidence-templates/sample-payload'
import { Base64 } from 'js-base64'
import { useSnackbar } from 'notistack'

const Preview = ({
  template,
  templateType,
  stylesheet,
  templateId
}: {
  template: string
  templateType: string
  stylesheet: string
  templateId: string
}) => {
  const siftClasses = useSiftStyles()
  const [selectedPayPrevTab, setSelectedPayPrevTab] = useState(0)
  const [payload, setPayload] = useState('')
  const [preview, setPreview] = useState('')
  const { enqueueSnackbar } = useSnackbar()
  const tabBoxStyle = {
    padding: '10px',
    borderTop: 'none',
    borderWidth: '1px',
    borderStyle: 'solid',
    height: '900px'
  }
  const renderPreview = payload => {
    const params = {
      payload: Base64.encode(payload),
      template: Base64.encode(template),
      data_type: templateType,
      stylesheet: Base64.encode(stylesheet)
    }
    axios.post('/api/evidence_preview/preview_template', params).then(r => {
      r.data.errors.map(error => {
        enqueueSnackbar(error, {
          variant: 'error'
        })
      })
      setPreview(r.data.content)
    })
  }

  useEffect(() => {
    renderPreview(payload)
  }, [templateId])

  return (
    <>
      <Grid item style={{ padding: '5px' }}>
        <AppBar position="static" color="default">
          <Tabs
            value={selectedPayPrevTab}
            onChange={(_event: React.ChangeEvent, value: number) => {
              setSelectedPayPrevTab(value)
            }}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab label="Payload" id={'0'} />
            <Tab label="Preview" id={'1'} />
          </Tabs>
        </AppBar>
        <div hidden={selectedPayPrevTab !== 0} style={tabBoxStyle}>
          <SamplePayload
            height={600}
            width="100%"
            setPayload={setPayload}
            renderPreview={() => {
              renderPreview(payload)
            }}
          />
        </div>
        <div hidden={selectedPayPrevTab !== 1} style={tabBoxStyle}>
          <Grid container item>
            <Grid
              item
              container
              xs={12}
              className={siftClasses.blueHeader}
              style={{ padding: '5px' }}
            >
              <Grid item xs={5}>
                <Button
                  className={siftClasses.siftButton}
                  variant="outlined"
                  onClick={() => renderPreview(payload)}
                >
                  <Loop style={{ color: 'white' }} />
                  Generate Preview
                </Button>
              </Grid>
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={12}>
                <PreviewNode
                  content={preview}
                  category={''}
                  type={templateType}
                  errors={[]}
                  disabled={false}
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </>
  )
}

export default Preview
