import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Card, CardContent } from '@material-ui/core'
import SessionPage from './SessionPage'

const useStyles = makeStyles({
  body: {
    padding: '35px'
  },
  row: {
    display: 'flex',
    justifyContent: 'center'
  },
  card: {
    padding: '2rem 15rem'
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  }
})

export const SessionLayout = ({ children }) => {
  const cls = useStyles()

  return (
    <SessionPage>
      <div className={cls.body}>
        <div className={cls.row}>
          <Card className={cls.card} variant="outlined">
            <CardContent className={cls.cardContent}>{children}</CardContent>
          </Card>
        </div>
      </div>
    </SessionPage>
  )
}
