import React, { useState, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, IconButton } from '@siftscience/focus-components/button'
import { Text, Title } from '@siftscience/focus-components/text'
import { TextArea, RichTextArea } from '@siftscience/focus-components/input'
import {
  DropdownMenu,
  createSelectableItemRenderer,
  renderSearchFieldHeader,
  Dropdown
} from '@siftscience/focus-components/dropdown'
import {
  ArrowLeft,
  VerificationWarningFilled,
  TagDelete,
  Plus
} from '@siftscience/focus-components/icons'
import { makeStyles } from '@material-ui/core/styles'
import debounce from 'lodash.debounce'
import Badge from './badge'
import { formatDate, titleizeText } from './utils'
import { useDatasetContext } from './context/dataset-context'
import { useDisputeContext } from './context/dispute-context'
import axios from '../../utils/http/axios-local'
import { DisputeDTO, TagDTO, TagsDTO } from './dtos'

const REASON_CODE_CAT_BADGE_COLOR = 'transparent'
const EXPIRES_IN_ICON_COLOR = '#EB3B2E'
const LIFECYCLE_STATUS_MAPPING = {
  new: { title: 'New' },
  accept_liability: { title: 'Accept liability' },
  duplicate: { title: 'Duplicate' },
  invalid: { title: 'Invalid' },
  missing_data: { title: 'Missing data' },
  error_skipped: { title: 'Error skipped' },
  invalid_reason_code: { title: 'Invalid reason code' },
  document_sent: { title: 'Document sent' },
  urgent: { title: 'Urgent' },
  ready_to_send: { title: 'Ready to send' },
  system_completed: { title: 'System Completed' },
  docsend_failed: { title: 'Document sent failure' }
}

const useStyles = makeStyles(() => ({
  headerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 24px 16px 24px',
    justifyContent: 'space-between',
    gap: '8px'
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px 24px 0px 24px',
    justifyContent: 'space-between'
  },
  contentWrapper: {
    marginBottom: '12px'
  },
  navButton: {
    width: '145px'
  },
  badgeChildren: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
    padding: '0px 0px 0px 0px',
    gap: '4px'
  },
  reasonCodeIcon: {
    width: '8px',
    height: '8px',
    borderRadius: '100%',
    backgroundColor: '#EB3B2E',
    wordBreak: 'normal'
  },
  stageIcon: {
    width: '8px',
    height: '8px',
    borderRadius: '100%',
    backgroundColor: '#F2B200',
    wordBreak: 'normal'
  },
  secondaryText: {
    marginTop: '-10px'
  },
  date: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px'
  },
  dateIcon: {
    margin: '0px 8px 0px 8px',
    width: '16px',
    height: '16px'
  },
  dateIconLabel: {
    color: '#914100'
  },
  notesTextInput: {
    width: '312px',
    height: '100px',
    borderRadius: '6px',
    border: '1px'
  },
  tagsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    gap: '4px',
    flexFlow: 'wrap'
  },
  tagIconButton: {
    width: '0px',
    height: '0px',
    borderRadius: '6px',
    '&:hover': {
      background: 'transparent'
    },
    '&:focus': {
      outline: '0px'
    }
  },
  tagBadgeChildren: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    height: '100%',
    padding: '0px 0px 0px 0px',
    gap: '4px'
  },
  tagsDropdown: {
    width: '360px',
    marginLeft: '38px'
  }
}))

const ONCHANGE_DEBOUNCE_TIME = 400

interface LeftSideBarProps {
  isLoading: boolean
}

const LeftSidebar = ({ isLoading }: LeftSideBarProps): React.ReactElement => {
  const { dataset } = useDatasetContext()
  const history = useHistory()
  const [notes, setNotes] = useState<string>('')
  const [tags, setTags] = useState<TagDTO[]>([])
  const [tagsOptions, setTagOptions] = useState<TagDTO[]>([])
  const [displayTagsOptions, setDisplayTagOptions] = useState<TagDTO[]>([])
  const { dispute, disputeId } = useDisputeContext()
  const classes = useStyles()

  useEffect(() => {
    if (!isLoading) {
      setNotes(dispute.notes)
      setTags(dispute.tags)
    }
  }, [dispute, isLoading])

  useEffect(() => {
    axios
      .get<TagsDTO>(`/det/disputes/${disputeId}/tag_options`)
      .then(({ data }) => {
        if (data) {
          setTagOptions(data)
          setDisplayTagOptions(data)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  const pluralize = (count: number, noun: string, suffix = 's') => {
    return `${noun}${count !== 1 ? suffix : ''}`
  }

  const expiresIn = (dueDate: string) => {
    if (dueDate == null) {
      return
    }

    const dueDateT = new Date(dueDate).getTime()
    const currentT = new Date().getTime()

    const days = Math.ceil((dueDateT - currentT) / (1000 * 3600 * 24))

    if (days < 0 || days > 3) {
      return
    }

    return days === 0
      ? 'Today'
      : 'In' + ' ' + days + ' ' + pluralize(days, 'day')
  }

  const onBackToDisputesClick = () => {
    history.push('/ui/disputes')
  }

  const onNotesChange = () => {
    axios
      .patch<DisputeDTO>(`/det/disputes/${disputeId}`, { notes: notes })
      .then(({ data }) => {
        if (data) {
          setNotes(data.notes)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  const onRemoveTag = (tag: TagDTO) => {
    axios
      .delete<DisputeDTO>(`/det/disputes/${disputeId}/tags/${tag.id}`)
      .then(({ data }) => {
        if (data) {
          setTags(data.tags)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  const onToggleTag = (newTag: TagDTO) => {
    const tagIndex = tags.findIndex(tag => tag.id === newTag.id)
    if (tagIndex === -1) {
      const newTagsList = [...tags, newTag]
      setTags(newTagsList)
      axios
        .post<DisputeDTO>(`/det/disputes/${disputeId}/tags`, newTagsList)
        .then(() => {
          console.log('tags list saved')
        })
        .catch(error => {
          console.log(error)
        })
    } else {
      const newTagsList = [...tags]
      newTagsList.splice(tagIndex, 1)
      setTags(newTagsList)
      onRemoveTag(newTag)
    }
  }

  const onTagsSearch = useCallback(
    debounce(event => {
      const value = event.target.value ?? ''
      const tagOptionsToDisplay = tagsOptions.filter(tag =>
        tag?.name?.toLowerCase().includes(value?.toLowerCase())
      )
      setDisplayTagOptions(tagOptionsToDisplay)
    }, ONCHANGE_DEBOUNCE_TIME),
    [setDisplayTagOptions, tagsOptions]
  )

  const clearTagsFilter = () => {
    setDisplayTagOptions([...tagsOptions])
  }

  const stringifyProcessorNotes = (processorNotes: string[]) => {
    if (processorNotes.length > 0) {
      return processorNotes.join('<br/>')
    } else {
      return ''
    }
  }

  const expirationDate = dispute?.expirationDate || dataset.dispute?.due_date
  const requestDate = dispute?.requestDate || dataset.dispute?.request_date
  const orderId = dispute?.remoteInvoiceId || dataset.invoice?.id
  const transactionId = dispute?.remoteTransactionId || dataset.transaction?.id

  return (
    <>
      <div className={classes.headerWrapper}>
        <Button
          variant="secondary"
          lined
          className={classes.navButton}
          onClick={onBackToDisputesClick}
        >
          <ArrowLeft />
          <Text size="xsmall">Back to Disputes</Text>
        </Button>
        {!isLoading && (
          <>
            <Title size="large">Dispute Response</Title>
            <Title size="small" color="secondary">
              Reason code category
            </Title>
            {dispute?.reasonCodeCategory && (
              <Badge
                color={REASON_CODE_CAT_BADGE_COLOR}
                border
                width="fit-content"
                marginTop="-8px"
              >
                <div className={classes.badgeChildren}>
                  <div className={classes.reasonCodeIcon}></div>
                  <Text size="small">
                    {titleizeText(dispute?.reasonCodeCategory as string)}
                  </Text>
                </div>
              </Badge>
            )}
            <Title size="small" color="secondary">
              Reason
            </Title>
            <Text size="medium" className={classes.secondaryText}>
              {dataset.metadata?.reason_code_display_title as string}
            </Text>
            <Title size="small" color="secondary">
              Lifecycle Status
            </Title>
            <Text size="medium" className={classes.secondaryText}>
              {
                LIFECYCLE_STATUS_MAPPING[dispute.lifecycleStatus]
                  ?.title as string
              }
            </Text>
          </>
        )}
      </div>
      {!isLoading && (
        <>
          {/* <div> */}
          {/*    <div>TODO: divider with a shadow</div> */}
          {/* </div> */}
          <div className={classes.wrapper}>
            <div className={classes.contentWrapper}>
              <Title size="small" color="secondary">
                Case Number
              </Title>
              <Text size="medium">{dataset.dispute?.case_number}</Text>
            </div>
            <div className={classes.contentWrapper}>
              <Title size="small" color="secondary">
                Reference Number
              </Title>
              <Text size="medium">{dataset.dispute?.reference_number}</Text>
            </div>
            <div className={classes.contentWrapper}>
              <Title size="small" color="secondary">
                Order ID
              </Title>
              <Text size="medium">{orderId}</Text>
            </div>
            <div className={classes.contentWrapper}>
              <Title size="small" color="secondary">
                Transaction ID
              </Title>
              <Text size="medium">{transactionId}</Text>
            </div>
            <div className={classes.contentWrapper}>
              <Title size="small" color="secondary">
                Stage
              </Title>
              {dispute?.stage && (
                <Badge
                  color={REASON_CODE_CAT_BADGE_COLOR}
                  border
                  width="fit-content"
                  marginTop="4px"
                >
                  <div className={classes.badgeChildren}>
                    <div className={classes.stageIcon}></div>
                    <Text size="small">{titleizeText(dispute?.stage)}</Text>
                  </div>
                </Badge>
              )}
            </div>
            <div className={classes.contentWrapper}>
              <Title size="small" color="secondary">
                Dispute Amount
              </Title>
              <Text size="medium">
                {(dataset.dispute?.currency_code || '') +
                  ' ' +
                  (dataset.dispute?.dispute_amount || '')}
              </Text>
            </div>
            <div className={classes.contentWrapper}>
              <Title size="small" color="secondary">
                Merchant
              </Title>
              <Text size="medium">{dispute?.merchant?.name}</Text>
            </div>
            <div className={classes.contentWrapper}>
              <Title size="small" color="secondary">
                MID
              </Title>
              <Text size="medium">{dispute?.mid?.name}</Text>
            </div>
            <div className={classes.contentWrapper}>
              <Title size="small" color="secondary">
                Expires On
              </Title>
              <Text size="medium">{formatDate(expirationDate)}</Text>
              {expiresIn(expirationDate) && (
                <>
                  <VerificationWarningFilled
                    color={EXPIRES_IN_ICON_COLOR}
                    className={classes.dateIcon}
                  />
                  <Text className={classes.dateIconLabel}>
                    {expiresIn(expirationDate)}
                  </Text>
                </>
              )}
            </div>
            <div className={classes.contentWrapper}>
              <Title size="small" color="secondary">
                Requested On
              </Title>
              <div className={classes.date}>
                <Text size="medium">{formatDate(requestDate)}</Text>
              </div>
            </div>
            <div className={classes.contentWrapper}>
              <Title size="small" color="secondary">
                Processor Notes
              </Title>
              <RichTextArea
                value={stringifyProcessorNotes(
                  dataset.dispute?.processor_notes || []
                )}
                className={classes.notesTextInput}
                disabled={true}
              ></RichTextArea>
            </div>
            <div className={classes.contentWrapper}>
              <Title size="small" color="secondary">
                Notes
              </Title>
              <TextArea
                value={notes}
                placeholder="Add notes here..."
                className={classes.notesTextInput}
                onChange={e => setNotes(e.target.value)}
                onBlur={onNotesChange}
              ></TextArea>
            </div>
            <div className={classes.contentWrapper}>
              <Title size="small" color="secondary">
                Tags
              </Title>
              <div className={classes.tagsWrapper}>
                {tags.map(tag => {
                  return (
                    <Badge
                      color={REASON_CODE_CAT_BADGE_COLOR}
                      border
                      width="fit-content"
                      marginTop="4px"
                      key={tag.id}
                    >
                      <div className={classes.tagBadgeChildren}>
                        <IconButton
                          size="small"
                          variant="secondary-ghost"
                          className={classes.tagIconButton}
                          onClick={() => onRemoveTag(tag)}
                        >
                          <TagDelete />
                        </IconButton>
                        <Text size="small">{tag.name}</Text>
                      </div>
                    </Badge>
                  )
                })}
                <Dropdown
                  id="tags-dropdown"
                  placement="top-end"
                  strategy="fixed"
                  onIsOpenChange={clearTagsFilter}
                  displayRenderer={({ getToggleButtonProps }) => (
                    <Badge
                      color={REASON_CODE_CAT_BADGE_COLOR}
                      border
                      width="fit-content"
                      marginTop="4px"
                      padding="0 4px 0 4px"
                      borderRadius="16px"
                    >
                      <div className={classes.tagBadgeChildren}>
                        <IconButton
                          size="small"
                          variant="secondary-ghost"
                          className={classes.tagIconButton}
                          {...getToggleButtonProps()}
                        >
                          <Plus />
                        </IconButton>
                      </div>
                    </Badge>
                  )}
                  dropdownRenderer={({ isOpen, getMenuProps }) => {
                    if (!isOpen) {
                      return null
                    }

                    const { style, ...props } = getMenuProps(undefined, {
                      suppressRefError: true
                    })

                    return (
                      <DropdownMenu
                        {...props}
                        style={{
                          ...style
                        }}
                        items={displayTagsOptions}
                        selectedItems={tagsOptions.filter(option => {
                          // TODO Think about optimization
                          return !!tags.find(tag => tag.id === option.id)
                        })}
                        getItemProps={({ item }) => {
                          return {
                            onClick: () => onToggleTag(item as TagDTO)
                          }
                        }}
                        multiple
                        className={classes.tagsDropdown}
                        itemRenderer={createSelectableItemRenderer(
                          item => (item as TagDTO).name,
                          item => `${(item as TagDTO).id}`
                        )}
                        headerRenderer={renderSearchFieldHeader({
                          onChange: onTagsSearch
                        })}
                      />
                    )
                  }}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default LeftSidebar
