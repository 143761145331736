import React from 'react'
import ExtendDialog from './extend-dialog'
import useIdleTimer from './use-idle-timer'
import useCountdown from './use-countdown'
import useKeepAlive from './use-keep-alive'
import getExtendSession from './get-extend-session'

const noIdleCheckerRegex = /login|reset|new-password/

const IdleChecker = () => {
  const [idle, setIdle] = useIdleTimer()
  const [expired, clearCountdown, timeRemainingMessage] = useCountdown({
    idle,
    setIdle
  })

  // don't run this on the login page
  if (window.location.pathname.match(noIdleCheckerRegex)) return null

  const handleExtend = async () => {
    await getExtendSession()
    setIdle(false)
    clearCountdown()
  }

  useKeepAlive({ handleExtend, idle, expired })

  return (
    <>
      {idle ? (
        <ExtendDialog {...{ handleExtend, timeRemainingMessage }} />
      ) : null}
    </>
  )
}

export default IdleChecker
