import cookie from 'cookie'

const documentCsrfCookie = (): string => {
  if (document.cookie !== '') {
    const cookies = cookie.parse(document.cookie)
    if (Object.keys(cookies).length > 0 && cookies.csrf_token) {
      // Return extracted cookie parts
      return cookies.csrf_token
    }
  }
  // Return Defaults
  return ''
}

const documentJwtCookie = (): string => {
  if (document.cookie !== '') {
    const cookies = cookie.parse(document.cookie)
    if (Object.keys(cookies).length > 0 && cookies.jwt_access) {
      // Return extracted cookie parts
      return cookies.jwt_access
    }
  }
  // Return Defaults
  return ''
}

export { documentCsrfCookie, documentJwtCookie }
