import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const defaultStyle = {
  opacity: 0.5,
  pointerEvents: 'none'
}

const useStyles = makeStyles({
  red: {
    color: 'red',
    ...defaultStyle
  },
  green: {
    color: 'green',
    ...defaultStyle
  },
  redish: {
    color: '#ce240f',
    ...defaultStyle
  },
  brown: {
    color: 'brown',
    ...defaultStyle
  },
  purple: {
    color: 'purple',
    ...defaultStyle
  }
})

const RemoteDeliveryStatus = ({ value }) => {
  const classes = useStyles()

  if (value === 'open') {
    return <span className={classes.green}>Accepting Submission</span>
  } else if (value === 'closed_submitted') {
    return <span className={classes.red}>Already Sent</span>
  } else if (value === 'paused') {
    return <span className={classes.redish}>Paused at Processor</span>
  } else if (value === 'closed_expired') {
    return <span className={classes.purple}>Expired</span>
  } else if (value === 'closed_system_completed') {
    return <span className={classes.brown}>Completed Outside Console</span>
  } else {
    return <span> Undeclared State</span>
  }
}

export default RemoteDeliveryStatus
