import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, ButtonGroup, Menu } from '@material-ui/core'
import FilterListIcon from '@material-ui/icons/FilterList'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {
  usePopupState,
  bindTrigger,
  bindMenu
} from 'material-ui-popup-state/hooks'
import SavedFiltersMenu from './saved-filters-menu'
import ListViewContext from '../list-view-context'

const SearchToggle = props => {
  const classes = useStyles()
  const { options, setMenuOpen } = useContext(ListViewContext)
  const popupState = usePopupState({ variant: 'popover' })

  return (
    <>
      {options.useSaveSearch ? (
        <>
          <ButtonGroup classes={{ root: classes.buttonGroup }}>
            <Button
              variant="contained"
              color="primary"
              className={classes.filterButton}
              onClick={() => setMenuOpen(true)}
              startIcon={<FilterListIcon />}
            >
              Filters
            </Button>
            <Button
              aria-label="Saved Filters"
              variant="contained"
              color="primary"
              size="small"
              {...bindTrigger(popupState)}
            >
              <ExpandMoreIcon />
            </Button>
          </ButtonGroup>
          <Menu
            {...bindMenu(popupState)}
            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            getContentAnchorEl={null}
            variant="menu"
          >
            <div>
              <SavedFiltersMenu closeMenu={popupState.close} />
            </div>
          </Menu>
        </>
      ) : (
        <Button
          variant="contained"
          color="primary"
          className={classes.filterButton}
          onClick={() => setMenuOpen(true)}
          startIcon={<FilterListIcon />}
        >
          Filters
        </Button>
      )}
    </>
  )
}

const useStyles = makeStyles(theme => ({
  buttonGroup: {
    marginRight: 20
  }
}))

export default SearchToggle
