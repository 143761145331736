import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import NavigationButton from './navigation-button'
import { RenderingDTO, TemplateType } from './dtos'
import { Filters } from './enums'
import { ILockedState } from './interfaces'

const MAIN_HEADER_HEIGHT = 70
const MAX_HEIGHT_MARGIN = 40 // it just looks good with this value

const useStyles = makeStyles(() => ({
  navigationWrapper: {
    position: 'sticky',
    top: `${MAIN_HEADER_HEIGHT}px`,
    maxHeight: `calc(100vh - ${MAIN_HEADER_HEIGHT + MAX_HEIGHT_MARGIN}px)`,
    overflow: 'scroll'
  }
}))

interface DETNavigationProps {
  selectedFilter: Filters
  renderingsData: RenderingDTO[]
  lockedStatus: ILockedState
  renderingsHashData: Record<string, RenderingDTO>
  onNavigationItemClick: (rendering: RenderingDTO) => void
}

const DETNavigation = ({
  selectedFilter,
  renderingsData,
  lockedStatus,
  renderingsHashData,
  onNavigationItemClick
}: DETNavigationProps): React.ReactElement => {
  const classes = useStyles()

  const parentLevelRenderings = useMemo(() => {
    const filterCondition = (rendering: RenderingDTO) => {
      if (
        lockedStatus?.locked ||
        lockedStatus?.expired ||
        lockedStatus?.acceptedLiability
      ) {
        return (
          !rendering.parentTemplateIds?.length &&
          rendering.templateType === TemplateType.configurationPanel
        )
      }

      return !rendering.parentTemplateIds?.length
    }
    return renderingsData.filter(filterCondition)
  }, [renderingsData, lockedStatus])

  const generateRenderingNavigationButton = (rendering: RenderingDTO) => {
    return (
      <NavigationButton
        selectedFilter={selectedFilter}
        renderingData={rendering}
        renderingsHashData={renderingsHashData}
        onClick={onNavigationItemClick}
        key={`navigation-${rendering.id}-${rendering.status}`}
      />
    )
  }

  return (
    <div className={classes.navigationWrapper}>
      {parentLevelRenderings.map(generateRenderingNavigationButton)}
    </div>
  )
}

export default DETNavigation
