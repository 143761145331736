import { useReducer, useEffect } from 'react'
import selectedParamsReducer from './selected-params-reducer'
import setParamsFromUrl from '../common/set-params-from-url'
import {
  getFromLocalStorage,
  saveToLocalStorage,
  deleteFromLocalStorage
} from '../../../utils/local-storage'
import linkDisputeId from '../../../utils/link-dispute-id'

const clearCachedParams = localStorageKey => {
  deleteFromLocalStorage(localStorageKey)
}

const hasInvalidCachedParams = (
  cachedParams,
  emptySearchParams,
  dataAdapter
) => {
  if (!cachedParams) return false
  const cachedKeys = Object.keys(cachedParams)
  const validKeys = Object.keys(emptySearchParams)
  const hasInvalidCachedKeys = !cachedKeys.every(key => validKeys.includes(key))

  const cachedSortByKeys = cachedParams.sort_by
    ? Object.keys(cachedParams.sort_by)
    : []
  const validSortByKeys = Object.keys(dataAdapter.columns)
    .filter(column => dataAdapter.columns[column].sortable)
    .map(column => dataAdapter.columns[column].name)
  const hasInvalidSortParams = !cachedSortByKeys.every(column =>
    validSortByKeys.includes(column)
  )

  return hasInvalidCachedKeys || hasInvalidSortParams
}

const useFetchSelectedParams = ({
  defaultSearchParams,
  emptySearchParams,
  dataAdapter,
  getColumnByName
}) => {
  const paramsFromUrl = setParamsFromUrl({
    dataAdapter,
    getColumnByName,
    emptySearchParams
  })
  const localStorageKey = `${dataAdapter.name}-selectedParams`
  let cachedParams = getFromLocalStorage(localStorageKey)

  // if we have cachedParams, make sure they are valid
  const shouldClearCachedParams = hasInvalidCachedParams(
    cachedParams,
    emptySearchParams,
    dataAdapter
  )

  // we have invalid cached params, clear the localStorage values
  if (shouldClearCachedParams) {
    clearCachedParams(localStorageKey)
    cachedParams = null
  }

  /* 
    set the params based on what we have:
    url params take precidence; we want the users saved urls to load the same search
    cached params are set whenever someone searches; this maintains their current search
    use the defaults if the search was cleared or there was no url or cache
  */
  const [selectedParams, dispatch] = useReducer(
    selectedParamsReducer,
    paramsFromUrl || cachedParams || defaultSearchParams
  )

  useEffect(() => {
    // we don't want to save to local storage if we are linking a dispute
    if (linkDisputeId) return
    saveToLocalStorage(localStorageKey, selectedParams)
  }, [selectedParams])

  return [selectedParams, dispatch]
}

export default useFetchSelectedParams
