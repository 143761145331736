import React, { useState, useEffect } from 'react'
import { Alert, AlertTitle } from '@material-ui/lab'
import { Grid, List, ListItem } from '@material-ui/core'

const noVerticalPadding = {
  paddingTop: 0,
  paddingBottom: 0
}

const TemplateErrorsAlert = ({ errors }) => {
  if (errors.length > 0) {
    return (
      <Grid container justify="space-between">
        <Grid item xs>
          <Alert severity="error">
            <AlertTitle>Evidence Errors:</AlertTitle>
            <List style={{ ...noVerticalPadding }}>
              {errors.map((field: string, i: number) => (
                <ListItem
                  disableGutters
                  style={{ ...noVerticalPadding }}
                  key={i}
                >
                  {field}
                </ListItem>
              ))}
            </List>
          </Alert>
        </Grid>
      </Grid>
    )
  } else {
    return null
  }
}

export default TemplateErrorsAlert
