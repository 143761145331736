/**
 * The Quick Search form toggles to the different types of searches
 * The last search used is saved to localStorage
 * When the form is submitted, a URL is retured to redirect to the proper place
 * Each search takes you to the detail page if there is one record,
 * or the list page if there are many or no records
 */

import React, { useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  IconButton,
  Icon,
  Menu,
  MenuItem
} from '@material-ui/core'
import { useSnackbar } from 'notistack'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {
  usePopupState,
  bindTrigger,
  bindMenu
} from 'material-ui-popup-state/hooks'
import {
  getFromLocalStorage,
  saveToLocalStorage
} from '../../utils/local-storage'
import axios from '../../utils/http/axios-local'

const searches = [
  { label: 'Dispute ID', name: 'id' },
  { label: 'Transaction ID', name: 'transaction_id' },
  { label: 'Case Number', name: 'case_number' },
  { label: 'Reference Number', name: 'reference_number' },
  { label: 'Invoice ID', name: 'invoice_id' }
]

/**
 * The object keys must match the labels in the each of the items
 * of the searches array. This is due to the use of several different
 * search parameters being submitted to the same route. Some are Ints,
 * other are Strings.
 */
const searchCriteria = {
  'Dispute ID': 10,
  'Transaction ID': 255,
  'Reference Number': 255,
  'Case Number': 255,
  'Invoice ID': 255
}

const Search = props => {
  const classes = useStyles()
  const lastSearchOptions =
    getFromLocalStorage('lastSearchOptions') || searches[0]
  const [label, setLabel] = useState(lastSearchOptions.label)
  const [name, setName] = useState(lastSearchOptions.name)
  const [value, setValue] = useState('')
  const { enqueueSnackbar } = useSnackbar()
  const searchRef = useRef(null)
  const popupState = usePopupState({ variant: 'popover' })
  popupState.setAnchorEl(searchRef.current)

  const setSearch = ({ label, name, action }) => {
    setLabel(label)
    setName(name)
    saveToLocalStorage('lastSearchOptions', { label, name })
    popupState.close()
  }

  const hasValidId = () => {
    if (!value) return false
    return value.length <= searchCriteria[label]
  }

  const onSubmit = name => event => {
    event.preventDefault()
    if (!hasValidId()) {
      enqueueSnackbar(`Invalid Search ${label}`, { variant: 'error' })
    } else {
      let url
      switch (name) {
        case 'id':
          url = `/api/quick_search/disputes?id=${value}`
          break
        case 'case_number':
          url = `/api/quick_search/disputes?case_number=${value}`
          break
        case 'reference_number':
          url = `/api/quick_search/disputes?reference_number=${value}`
          break
        case 'transaction_id':
          url = `/api/quick_search/transactions?transaction_id=${value}`
          break
        case 'invoice_id':
          url = `/api/quick_search/invoices?invoice_id=${value}`
      }
      axios.get(url).then(response => {
        window.location.href = response.data.url
      })
    }
  }

  return (
    <div className={classes.root} ref={searchRef}>
      <form autoComplete="off" onSubmit={onSubmit(name)}>
        <FormControl id="quick-search" variant="outlined">
          <InputLabel
            htmlFor="searchInput"
            classes={{
              root: classes.inputLabelRoot
            }}
            disableAnimation={true}
          >{`Search ${label}`}</InputLabel>
          <OutlinedInput
            id="searchInput"
            notched={true}
            classes={{
              root: classes.oulinedInputRoot,
              focused: classes.focused,
              notchedOutline: classes.notchedOutline
            }}
            inputProps={{
              minLength: 1,
              required: true
            }}
            startAdornment={<Icon className={classes.searchIcon}>search</Icon>}
            endAdornment={
              <IconButton
                classes={{ root: classes.iconButton }}
                {...bindTrigger(popupState)}
              >
                <ExpandMoreIcon classes={{ root: classes.iconExpandMore }} />
              </IconButton>
            }
            name={name}
            value={value}
            onChange={({ target: { value } }) => setValue(value.trim())}
          />
        </FormControl>
      </form>

      <Menu
        classes={{ paper: classes.menuPaper }}
        {...bindMenu(popupState)}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        getContentAnchorEl={null}
      >
        {searches.map(({ label, name, action }) => {
          return (
            <MenuItem
              key={name}
              classes={{ root: classes.menuItem }}
              onClick={() => setSearch({ label, name, action })}
            >
              {label}
            </MenuItem>
          )
        })}
      </Menu>
    </div>
  )
}

const useStyles = makeStyles(theme => {
  return {
    '@global': {
      // temp resolve for non-react page style conflict
      '#searchInput': {
        borderBottom: '0 !important',
        height: 'inherit',
        margin: 0,
        padding: 10
      }
    },
    root: {
      marginLeft: 10,
      marginTop: 5,
      width: 210
    },

    inputLabelRoot: {
      color: theme.palette.primary.contrastText + ' !important',
      backgroundColor: theme.palette.primary.main,
      padding: '0 5px 0 5px'
    },
    oulinedInputRoot: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      padding: '0 5px',
      '& $notchedOutline': {
        borderColor: theme.palette.primary.contrastText
      },
      '&:hover:not($focused) $notchedOutline': {
        borderColor: theme.palette.action.hover
      },
      '&$focused $notchedOutline': {
        borderColor: theme.palette.action.focus,
        borderWidth: 1
      }
    },
    focused: {},
    notchedOutline: {},
    searchIcon: {
      marginTop: 10,
      color: theme.palette.primary.contrastText
    },
    iconButton: {
      padding: 0
    },
    iconExpandMore: {
      color: theme.palette.primary.contrastText
    },
    menuPaper: {
      marginTop: 5,
      width: 246
    },
    menuItem: {
      paddingLeft: 30
    }
  }
})

export default Search
