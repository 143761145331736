import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import axios from '../../utils/http/axios-local'

const getMidOptions = async merchantId => {
  const {
    data: { mids }
  } = await axios.get(`/admin/mids/options.json?merchantId=${merchantId}`)

  return mids.map(mid => ({
    label: mid.name,
    value: mid.id
  }))
}

const useMidOptions = ({
  merchantId
}): null | Array<{ label: string; value: number }> => {
  const [midOptions, setMidOptions] = useState(null)
  useEffect(() => {
    if (!merchantId) {
      return
    }
    getMidOptions(merchantId).then(setMidOptions)
  }, [merchantId])
  return midOptions
}

export default useMidOptions
