import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { styled } from '@material-ui/core/styles'

const Row = styled(Grid)({
  display: 'flex',
  justifyContent: 'center'
})

interface SsoLoginProps {
  linkText: string
  to: string
  isSsoOnly: boolean
}

const SsoLogin: React.FC<SsoLoginProps> = ({
  linkText,
  to = 'url',
  isSsoOnly
}) => (
  <Grid container alignItems="center" justify="center" alignContent="center">
    {!isSsoOnly && (
      <Row item xs={12} zeroMinWidth>
        OR
      </Row>
    )}
    <Row item xs={12} zeroMinWidth>
      <Button href={to} variant="contained" color="primary">
        {linkText}
      </Button>
    </Row>
  </Grid>
)

export default SsoLogin
