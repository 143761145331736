import axios from '../../utils/http/axios-local'

export const updateAssignments = (
  selectedIds,
  tags,
  lifecycleStatus,
  disposition,
  assignee
) => {
  const params = {
    assignments: {
      items: selectedIds,
      assignee,
      tags,
      status: lifecycleStatus,
      disposition,
      type: 'Dispute'
    }
  }
  return axios.post('/assignments.json', params)
}

export const massAssignToSelf = (currentUserId, selectedIds) => {
  const params = {
    assignments: {
      items: selectedIds,
      assignee: currentUserId,
      type: 'Dispute'
    }
  }
  return axios.post('/assignments.json', params)
}

export const updateNotes = (currentUserId, selectedIds, notes) => {
  const params = {
    assignments: {
      items: selectedIds,
      assignee: currentUserId,
      type: 'Dispute',
      notes
    }
  }
  return axios.post('/assignments.json', params)
}
