import axios from '../../utils/http/axios-local'
import parseInvoices from './parse-invoices'
import formatQueryParams from '../../utils/http/format-query-params'

const fetchInvoices = async (params = {}) => {
  const invoices = await axios.get('/api/invoices', {
    params: formatQueryParams(params)
  })
  return parseInvoices(invoices)
}
export default fetchInvoices
