import { useEffect, useState } from 'react'

const useBindTableHeightToResize = () => {
  const [tableHeight, setTableHeight] = useState(window.innerHeight)

  useEffect(() => {
    bindTableHeightToResize(setTableHeight)
  }, [])

  return tableHeight
}

const getActualHeight = element => {
  if (!element) return 0
  return [
    element.offsetHeight,
    parseInt(window.getComputedStyle(element).getPropertyValue('margin-top')),
    parseInt(window.getComputedStyle(element).getPropertyValue('padding-top')),
    parseInt(window.getComputedStyle(element).getPropertyValue('margin-bottom'))
  ].reduce((acc, val) => acc + val)
}

const bindTableHeightToResize = setTableHeight => {
  const elementHeights = [
    getActualHeight(document.querySelector('#header')),
    getActualHeight(document.querySelector('#action-bar')),
    getActualHeight(document.querySelector('#pagination')),
    getActualHeight(document.querySelector('#footer'))
  ].reduce((acc, val) => acc + val)

  setTableHeight(window.innerHeight - elementHeights)
  window.onresize = () => setTableHeight(window.innerHeight - elementHeights)
}

export default useBindTableHeightToResize
