import React, { useState, useEffect } from 'react'
import axios from '../../utils/http/axios-local'
import { Grid, Button, Container, TextField } from '@material-ui/core'
import { Base64 } from 'js-base64'
import SamplePayload from '../evidence-templates/sample-payload'
import { Autocomplete } from '@material-ui/lab'
import PreviewNode from '../preview-node'
import TemplateErrorsAlert from './template-errors-alert'
import UnauthorizedAccessError from '../../common/errors/unauthorized-access-error'

const EvidencePreview = ({ processor }) => {
  const valueOrAny = (s: string) => (s === 'any' ? null : s)
  const [payload, setPayload] = useState<string>('')
  const [previews, setPreviews] = useState<Record<string, unknown>[]>([])
  const [templateErrors, setTemplateErrors] = useState<any[]>([])
  const [reasonCodes, setReasonCodes] = useState<any[]>([])
  const [merchants, setMerchants] = useState<any[]>([])
  const [reasonCodeSelected, setReasonCodeSelected] = useState<string>()
  const [merchantSelected, setMerchantSelected] = useState<string>()
  const [permitted, setPermission] = useState<boolean>(true)

  useEffect(() => {
    axios.get('/api/permitted').then(response => {
      setPermission(response.data.permitted)
    })
  }, [])

  const triggerPreview = (previewPayload = payload) => {
    axios
      .post('/api/evidence_preview.json', {
        payload: Base64.encode(previewPayload),
        reason_code_id: valueOrAny(reasonCodeSelected),
        merchant_id: valueOrAny(merchantSelected),
        portal_id: processor
      })
      .then(r => {
        setPreviews(r.data.delivery_fields)
        setTemplateErrors(r.data.errors)
      })
  }

  useEffect(() => {
    axios.get('/api/merchants').then(response => {
      const mappedMerchants = response.data.map(r => {
        return { value: r['id'], label: r['name'] }
      })
      setMerchants(mappedMerchants)
    })
  }, [])

  useEffect(() => {
    axios.get('/api/reason_codes').then(response => {
      const mappedCategories = response.data.map(r => {
        return { value: r['id'], label: r['code'] }
      })
      setReasonCodes(mappedCategories)
    })
  }, [])

  if (!permitted) return <UnauthorizedAccessError />

  return (
    <Container maxWidth="xl">
      {processor ? (
        <>
          <h1>Evidence Preview</h1>
          <Grid container spacing={5} alignItems="flex-start">
            <Grid container item xs={4}>
              <Grid item style={{ marginBottom: 20 }} xs={12}>
                <Autocomplete
                  getOptionSelected={(option, value) =>
                    option.value === value.value
                  }
                  disableClearable={true}
                  renderInput={params => (
                    <TextField {...params} label="Reason Code" />
                  )}
                  getOptionLabel={option => option['label']}
                  onChange={(_, value) => setReasonCodeSelected(value.value)}
                  options={[{ label: 'Any', value: null }, ...reasonCodes]}
                />
              </Grid>
              <Grid item style={{ marginBottom: 20 }} xs={12}>
                <Autocomplete
                  getOptionSelected={(option, value) =>
                    option.value === value.value
                  }
                  disableClearable={true}
                  renderInput={params => (
                    <TextField {...params} label="Merchant" />
                  )}
                  getOptionLabel={option => option['label']}
                  onChange={(_, value) => {
                    setMerchantSelected(value.value)
                  }}
                  options={merchants}
                />
                <Grid item style={{ margin: 10 }}>
                  <Button variant="contained" onClick={() => triggerPreview()}>
                    Preview
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <SamplePayload
                  height={800}
                  width="100%"
                  setPayload={setPayload}
                  renderPreview={triggerPreview}
                />
              </Grid>
            </Grid>
            <Grid container item xs={8}>
              <Grid style={{ marginLeft: 0 }} container item xs={12}>
                <TemplateErrorsAlert errors={templateErrors} />
                {previews
                  .sort(
                    (
                      a: Record<string, unknown>,
                      b: Record<string, unknown>
                    ) => {
                      return a['delivery_field']['name'].localeCompare(
                        b['delivery_field']['name']
                      )
                    }
                  )
                  .map((p: any) => (
                    <Grid container item key={p['delivery_field']['id']}>
                      <Grid item xs={12}>
                        <PreviewNode
                          content={p.content}
                          type={p.delivery_field.data_type}
                          category={p.delivery_field.name}
                          disabled={p.disabled}
                          errors={p.errors}
                        />
                      </Grid>
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <Grid item xs={12}>
          <h2>Select a processor to preview evidence</h2>
        </Grid>
      )}
    </Container>
  )
}

export default EvidencePreview
