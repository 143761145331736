import React, { MutableRefObject } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import DeliveryField from './delivery-field'
import Loader from './loader'
import { DatasetDTO, ImageDTO, RenderingDTO, ExtendedRendering } from './dtos'
import { DIRTY_STATUS, LOADING_STATUS, Tabs } from './enums'
import { IRenderingContent, ISaveResult } from './interfaces'

interface DeliveryFieldsListProps {
  isLoading: boolean
  disputeId: string
  renderingsData: ExtendedRendering[]
  renderingsHashData: Record<string, RenderingDTO>
  selectedTab: Tabs
  dataset: DatasetDTO
  dirtyRenderings: Record<string, DIRTY_STATUS>
  renderingsContentData: Record<string, IRenderingContent>
  onDataFieldClick: (
    rendering: RenderingDTO,
    dataFieldName?: string,
    isArray?: boolean
  ) => void
  onUpdateRenderingData: (updatedData: Partial<RenderingDTO>) => void
  onDirtyRenderingClick: (rendering: RenderingDTO) => void
  onSetListRef: (
    rendering: RenderingDTO,
    ref: MutableRefObject<HTMLElement>
  ) => void
  onDatasetChange: (
    key: string,
    value: string | ImageDTO,
    renderingId: number
  ) => Promise<ISaveResult>
  updateRenderingContent: (
    templateId: number,
    content: string,
    status: LOADING_STATUS,
    workflowDescription?: string
  ) => void
}

const useStyles = makeStyles(() => ({
  deliveryFieldsList: {
    maxWidth: '850px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px'
  }
}))

const DeliveryFieldsList = ({
  isLoading,
  disputeId,
  renderingsData,
  renderingsHashData,
  selectedTab,
  onDataFieldClick,
  onUpdateRenderingData,
  onDirtyRenderingClick,
  onSetListRef,
  dataset,
  dirtyRenderings,
  onDatasetChange,
  renderingsContentData,
  updateRenderingContent
}: DeliveryFieldsListProps): React.ReactElement => {
  const classes = useStyles()

  return (
    <div className={classes.deliveryFieldsList}>
      {isLoading && <Loader />}
      {!isLoading &&
        renderingsData.map(rendering => (
          <DeliveryField
            rendering={rendering}
            renderingsHashData={renderingsHashData}
            key={rendering.id}
            disputeId={disputeId}
            dataset={dataset}
            dirtyRenderings={dirtyRenderings}
            onDataFieldClick={onDataFieldClick}
            onUpdateRenderingData={onUpdateRenderingData}
            onDirtyRenderingClick={onDirtyRenderingClick}
            onSetListRef={onSetListRef}
            selectedTab={selectedTab}
            onDatasetChange={onDatasetChange}
            renderingsContentData={renderingsContentData}
            updateRenderingContent={updateRenderingContent}
          />
        ))}
    </div>
  )
}

export default DeliveryFieldsList
