import axios, { AxiosError, AxiosResponse, AxiosRequestConfig } from 'axios'
import Rollbar from '../rollbar'
import { documentCsrfCookie, documentJwtCookie } from '../Cookie'

/**
 * Helper to determine if a 401 status is returned from
 * an improper login process, which does raise an "Unauthorized"
 * error. This is specific to the login page only.
 */
const isLoginError = (error: AxiosError) => {
  return error?.response?.data?.authentication_error
}

/**
 * Helper to determine if a connection was aborted. This happens in case we
 * had pending request and navigated user to different page.
 * It's ok to ignore this error.
 */
const isConnectionAbortedError = (error: AxiosError) => {
  return error.code === 'ECONNABORTED'
}

/**
 * Helper to register errors in the proper way.
 */
const reportError = (error: AxiosError) => {
  if (!isLoginError(error) && !isConnectionAbortedError(error)) {
    Rollbar.error(error)
  }
  return Promise.reject(error)
}

/**
 * Simple Check to determine whether or not the error is
 * caused by an authorization issue/bug or some other bug
 */
const notAnUnauthorizedError = (error: AxiosError) => {
  return ![401, 302].includes(error?.response?.status)
}

/**
 * Handle any authorization errors regarding missing csrf tokens
 */
const axiosErrorHandler = async (error: AxiosError): Promise<any> => {
  // Reroute only errors for unauthorized access
  if (notAnUnauthorizedError(error)) {
    return reportError(error)
  }

  // Logout user if token refresh didn't work or user is disabled
  const badCsrfTokenRefresh = error.config.url === '/sessions/refresh'
  const unauthorizedCsrfTokenRefresh =
    error.response?.data?.message === 'unauthorized'

  if (badCsrfTokenRefresh || unauthorizedCsrfTokenRefresh) {
    window.location.assign('/ui/login')
    return reportError(error)
  }

  if (isLoginError(error)) {
    return Promise.reject(error)
  }

  // Try to request with new token
  return axios
    .get('/sessions/refresh')
    .then(() => {
      const config = error.config
      const csrfToken = documentCsrfCookie()
      const jwtToken = documentJwtCookie()

      config.headers['X-CSRF-Token'] = csrfToken
      config.headers['Authorization'] = `Bearer ${jwtToken}`

      return new Promise((resolve, reject) => {
        axios
          .request(config)
          .then(res => {
            resolve(res)
            // NOTE: must include to reload current-user info after jwt token refresh
            document.dispatchEvent(new Event('cookieCheck'))
          })
          .catch(res => reject(res))
      })
    })
    .catch(error => reportError(error))
}

// Reload any current user related data that changed in the current pages jwt token
const axiosResponseHandler = (response: AxiosResponse): AxiosResponse => {
  document.dispatchEvent(new Event('cookieCheck'))
  return response
}

/**
 * Update the request headers with the current pages jwt and csrf token
 */
const axiosRequestHandler = (
  config: AxiosRequestConfig
): AxiosRequestConfig => {
  config.headers['X-CSRF-Token'] = documentCsrfCookie()
  config.headers['Authorization'] = `Bearer ${documentJwtCookie()}`
  return config
}

export { axiosErrorHandler, axiosResponseHandler, axiosRequestHandler }
