import React, { useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import UsersTable from './user-table'
import { useSnackbar } from 'notistack'

// Entry point is not at the root of the page, so we have to call ReactDOM.render
// to mount it in the right place
export default () => {
  const { enqueueSnackbar } = useSnackbar()
  useEffect(() => {
    const container = document.getElementById('users-table')
    const props = container.getAttribute('data-props')
    const root = createRoot(container)
    root.render(
      <UsersTable enqueueSnackbar={enqueueSnackbar} {...JSON.parse(props)} />
    )
  }, [])
  return null
}
