import React from 'react'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'

const UnauthorizedAccessError = () => {
  const handleClose = () => {
    window.location = '/'
  }

  return (
    <Dialog onClose={handleClose} open={true}>
      <DialogTitle>Unauthorized access</DialogTitle>
      <DialogContent>
        <DialogContentText color="error">
          Please contact the administrator for access.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="contained" color="primary">
          Return to the app
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default UnauthorizedAccessError
