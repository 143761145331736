import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Chip from '@material-ui/core/Chip'
import Tooltip from '@material-ui/core/Tooltip'
import FilterListIcon from '@material-ui/icons/FilterList'
import SortIcon from '@material-ui/icons/Sort'
import ListViewContext from '../list-view-context'
import { SELECTED_PARAMS_TYPE } from '../data-handler/selected-params-reducer'

const Chips = props => {
  const classes = useStyles()

  const {
    Data: {
      dispatchSelectedParams,
      emptySearchParams,
      getChips,
      dateChips,
      sortChips,
      isLoading,
      isFetching
    },
    selectOptions
  } = useContext(ListViewContext)

  const clearChipParam = param => {
    dispatchSelectedParams({
      type: SELECTED_PARAMS_TYPE.clearParam,
      fieldName: param,
      emptySearchParams
    })
  }

  const clearDateChips = params => {
    dispatchSelectedParams({
      type: SELECTED_PARAMS_TYPE.clearArrayOfParams,
      fieldNames: params,
      emptySearchParams
    })
  }

  const clearSortChip = param => {
    dispatchSelectedParams({
      type: SELECTED_PARAMS_TYPE.updateSort,
      fieldName: param,
      value: undefined
    })
  }

  if (isLoading || isFetching || selectOptions.isLoading) return null

  const chips = getChips(selectOptions.data?.data)

  return (
    <>
      <div className={classes.container}>
        {chips.length || dateChips.length ? (
          <Tooltip title="Filter">
            <FilterListIcon />
          </Tooltip>
        ) : null}

        <>
          {chips.map(chip => {
            // if a chip doesn't have a value, the user doesn't have permissions for
            // any of the selected values
            if (chip.value) {
              return (
                <Tooltip title={chip.title} key={'chip-tt-' + chip.title}>
                  <Chip
                    key={'chip-' + chip}
                    className={classes.chip}
                    size="small"
                    label={chip.value}
                    onDelete={() => clearChipParam(chip.name)}
                  />
                </Tooltip>
              )
            } else {
              return null
            }
          })}

          {dateChips.map(display => {
            return (
              <Chip
                key={display.label}
                className={classes.chip}
                size="small"
                label={display.label}
                onDelete={() => clearDateChips(display.fields)}
              />
            )
          })}
        </>
      </div>
      <div className={classes.container} data-testid="chip-container">
        {sortChips.length ? (
          <Tooltip title="Sort Order">
            <SortIcon />
          </Tooltip>
        ) : null}

        {sortChips.map(param => {
          return (
            <Chip
              variant="outlined"
              color="secondary"
              key={'chip-' + param.name}
              className={classes.chip}
              size="small"
              label={param.title + ' - ' + param.value}
              onDelete={() => clearSortChip(param.name)}
            />
          )
        })}
      </div>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  }
}))

export default Chips
