import cookie from 'cookie'
import jwtDecode from 'jwt-decode'

const getSessionEnd = () => {
  const cookies = cookie.parse(document.cookie)
  if (cookies.jwt_access) {
    const jwtAccess = jwtDecode(cookies.jwt_access)
    return jwtAccess.exp
  } else {
    return false
  }
}

export default getSessionEnd
