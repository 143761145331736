import React, { MutableRefObject, useEffect, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import DeliveryFieldHeader from './delivery-field-header'
import FieldsEditForm from './fields-edit-form'
import RenderingContent from './rendering-content'
import RenderingLoader from './rendering-loader'
import axios from '../../utils/http/axios-local'
import { findImageElement } from './rendering_image_placeholder_helpers'
import {
  DatasetDTO,
  RenderingDTO,
  RenderingContent as RenderingContentDTO,
  ImageDTO,
  ExtendedRendering
} from './dtos'
import { DIRTY_STATUS, LOADING_STATUS, Tabs } from './enums'
import { IRenderingContent, ISaveResult } from './interfaces'

const ARRAY_ITEM_FIELDNAME_SEPARATOR = '#'
const ERROR_TEXT_MESSAGE =
  'Apologies, there was an error loading this template, please try refreshing the page.'

interface DeliveryFieldProps {
  rendering: ExtendedRendering
  renderingsHashData: Record<string, RenderingDTO>
  disputeId: string
  onDataFieldClick: (
    rendering: RenderingDTO,
    dataFieldName?: string,
    isArray?: boolean
  ) => void
  onUpdateRenderingData: (updatedData: Partial<RenderingDTO>) => void
  onDirtyRenderingClick: (rendering: RenderingDTO) => void
  onSetListRef: (
    rendering: RenderingDTO,
    ref: MutableRefObject<HTMLElement>
  ) => void
  selectedTab: Tabs
  dataset: DatasetDTO
  dirtyRenderings: Record<string, DIRTY_STATUS>
  renderingsContentData: Record<string, IRenderingContent>
  onDatasetChange: (
    key: string,
    value: string | ImageDTO,
    renderingId: number
  ) => Promise<ISaveResult>
  updateRenderingContent: (
    templateId: number,
    content: string,
    status: LOADING_STATUS,
    workflowDescription?: string
  ) => void
}

const useStyles = makeStyles(() => ({
  deliveryFieldWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    borderRadius: '8px',
    border: '1px solid #E0E0E0',
    overflow: 'hidden'
  },
  childRendering: {
    margin: '16px',
    marginTop: '0',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px'
  }
}))

const DeliveryField = ({
  rendering,
  renderingsHashData,
  disputeId,
  onDataFieldClick,
  onUpdateRenderingData,
  onDirtyRenderingClick,
  onSetListRef,
  selectedTab,
  dataset,
  dirtyRenderings,
  onDatasetChange,
  renderingsContentData,
  updateRenderingContent
}: DeliveryFieldProps): React.ReactElement => {
  const wrapperRef = useRef(null)
  const classes = useStyles()

  useEffect(() => {
    if (wrapperRef) {
      onSetListRef(rendering, wrapperRef)
    }
  }, [wrapperRef])

  useEffect(() => {
    const renderingContentData = renderingsContentData?.[rendering.templateId]
    if (rendering && !renderingContentData) {
      updateRenderingContent(rendering.templateId, '', LOADING_STATUS.Loading)
      axios
        .get<RenderingContentDTO>(
          `/det/renderings/${rendering.templateId}?dispute_id=${disputeId}`
        )
        .then(({ data }) => {
          if ((data?.content ?? '') === '') {
            updateRenderingContent(
              rendering.templateId,
              renderingContentData?.content || ERROR_TEXT_MESSAGE,
              LOADING_STATUS.Errored,
              renderingContentData?.workflowDescription
            )
          } else {
            updateRenderingContent(
              data.templateId,
              data?.content,
              LOADING_STATUS.Loaded,
              data.templateWorkflowDescription
            )
          }
        })
        .catch(error => {
          const renderingContentData =
            renderingsContentData?.[rendering.templateId]
          console.log(error)
          updateRenderingContent(
            rendering.templateId,
            renderingContentData?.content || ERROR_TEXT_MESSAGE,
            LOADING_STATUS.Errored,
            renderingContentData?.workflowDescription
          )
        })
    }
  }, [rendering, renderingsContentData])

  const onHtmlClick = event => {
    if (event?.target?.classList?.value?.includes?.('det-data-field')) {
      onDataFieldClick(rendering, event?.target?.dataset?.fieldname)
    } else if (
      event?.target?.dataset?.fieldname?.split(ARRAY_ITEM_FIELDNAME_SEPARATOR)
        ?.length > 1
    ) {
      onDataFieldClick(rendering, event?.target?.dataset?.fieldname, true)
    }
    const imageElement = findImageElement(
      event,
      'det-data-image-field',
      'det-rendering-content-wrapper'
    )

    if (imageElement) {
      onDataFieldClick(rendering, imageElement?.dataset?.fieldname)
    }
  }

  const onToggleIncludeInRendering = (value: boolean) => {
    const renderingContentData = renderingsContentData?.[rendering.templateId]
    updateRenderingContent(
      rendering.templateId,
      renderingContentData?.content,
      LOADING_STATUS.Loading
    )
    axios
      .patch(
        `/det/renderings/${rendering.templateId}?dispute_id=${disputeId}`,
        { rendering: { includeInResponse: value } }
      )
      .then(() => {
        onUpdateRenderingData({
          templateId: rendering.templateId,
          includeInResponse: value
        })
        updateRenderingContent(
          rendering.templateId,
          renderingContentData?.content,
          LOADING_STATUS.Loaded
        )
      })
      .catch(error => {
        console.log(error)
        updateRenderingContent(
          rendering.templateId,
          renderingContentData?.content,
          LOADING_STATUS.Loaded
        )
      })
  }

  const renderingContentData = renderingsContentData?.[rendering.templateId]
  const childTemplateIds =
    rendering.filteredChildTemplateIds ?? rendering.childTemplateIds
  const isContentLoading =
    renderingContentData?.status === LOADING_STATUS.Loading

  return (
    <div
      className={`border-radius bg-workspace shadow ${classes.deliveryFieldWrapper}`}
      ref={wrapperRef}
    >
      <>
        <DeliveryFieldHeader
          rendering={rendering}
          onEditClick={() => onDataFieldClick(rendering)}
          onToggleIncludeInRendering={onToggleIncludeInRendering}
          selectedTab={selectedTab}
          dirtyRenderings={dirtyRenderings}
          onDirtyRenderingClick={onDirtyRenderingClick}
          workflowDescription={
            renderingsContentData?.[rendering.templateId]?.workflowDescription
          }
        />
        {selectedTab === Tabs.Rendered && isContentLoading && (
          <RenderingLoader />
        )}
        {selectedTab === Tabs.Rendered && !isContentLoading && (
          <RenderingContent
            onHtmlClick={onHtmlClick}
            renderingContentData={renderingContentData}
          />
        )}
        {selectedTab === Tabs.DataEntry && (
          <FieldsEditForm
            rendering={rendering}
            dataset={dataset}
            onBlur={onDatasetChange}
            disputeId={disputeId}
          />
        )}
      </>
      {childTemplateIds?.length ? (
        <div className={classes.childRendering}>
          {childTemplateIds.map(templateId => {
            const childRendering = renderingsHashData[templateId]
            if (childRendering) {
              return (
                <DeliveryField
                  rendering={childRendering}
                  renderingsHashData={renderingsHashData}
                  key={childRendering.id}
                  disputeId={disputeId}
                  dataset={dataset}
                  dirtyRenderings={dirtyRenderings}
                  onDataFieldClick={onDataFieldClick}
                  onUpdateRenderingData={onUpdateRenderingData}
                  onDirtyRenderingClick={onDirtyRenderingClick}
                  onSetListRef={onSetListRef}
                  selectedTab={selectedTab}
                  onDatasetChange={onDatasetChange}
                  renderingsContentData={renderingsContentData}
                  updateRenderingContent={updateRenderingContent}
                />
              )
            }

            return null
          })}
        </div>
      ) : null}
    </div>
  )
}

export default DeliveryField
