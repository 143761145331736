import { useReducer } from 'react'
import { checkboxReducer, initialCheckboxState } from './checkbox-reducer'

const useCheckboxes = () => {
  const [checkboxes, dispatchCheckboxes] = useReducer(
    checkboxReducer,
    initialCheckboxState
  )

  return [checkboxes, dispatchCheckboxes]
}
export default useCheckboxes
