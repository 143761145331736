import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Card, Button } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const LINKS = {
  dashboards: [
    {
      name: 'Dispute Overview',
      link: '/ui/looker/disputeOverview',
      icon: 'file-invoice-dollar'
    },
    {
      name: 'Dispute Monthly Review',
      link: '/ui/looker/monthlyReviewOverview',
      icon: 'file-invoice-dollar'
    },
    {
      name: 'RTR Overview',
      link: '/ui/looker/rtrOverview',
      icon: 'comment'
    },
    {
      name: 'Alert Overview',
      link: '/ui/looker/alertOverview',
      icon: 'exclamation-triangle'
    },
    {
      name: 'Measure Analysts',
      link: '/ui/looker/analystsOverview',
      icon: 'user-friends'
    }
  ],
  downloads: [
    {
      name: 'Dispute Download',
      link: '/ui/looker/disputeDownload',
      icon: 'file-csv',
      isBlank: false
    },
    {
      name: 'Blank space 1',
      link: '',
      icon: '',
      isBlank: true
    },
    {
      name: 'RTR Download',
      link: '/ui/looker/inquiryDownload',
      icon: 'file-csv',
      isBlank: false
    },
    {
      name: 'Alert Download',
      link: '/ui/looker/alertDownload',
      icon: 'file-csv',
      isBlank: false
    },
    {
      name: 'Analysts Download',
      link: '/ui/looker/analystsDownload',
      icon: 'file-csv',
      isBlank: false
    }
  ]
}

const Reports = () => {
  const classes = useStyles()
  const dashboards = LINKS.dashboards.map(dashboard => (
    <Button
      key={dashboard.name + dashboard.link}
      className={`${classes.reportLink} ${classes.reportWidth}`}
      href={dashboard.link}
    >
      <FontAwesomeIcon
        icon={dashboard.icon}
        size="2x"
        className={classes.icon}
      />
      {dashboard.name}
    </Button>
  ))
  const downloads = LINKS.downloads.map(download => {
    if (download.isBlank) {
      return <div className={classes.reportWidth} />
    }

    return (
      <Button
        key={download.name + download.link}
        className={`${classes.reportLink} ${classes.reportWidth}`}
        href={download.link}
      >
        <FontAwesomeIcon
          icon={download.icon}
          size="2x"
          className={classes.icon}
        />
        {download.name}
      </Button>
    )
  })
  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <div className={classes.groupTitle}>
          <FontAwesomeIcon
            icon="chart-bar"
            size="2x"
            className={classes.icon}
          />
          Analytics Dashboard
        </div>
        {dashboards}
      </Card>
      <Card className={classes.card}>
        <div className={classes.groupTitle}>
          <FontAwesomeIcon
            icon="file-download"
            size="2x"
            className={classes.icon}
          />
          Data Downloads
        </div>
        {downloads}
      </Card>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  root: {},
  card: {
    display: 'flex',
    backgroundColor: '#FFFFFF',
    padding: 20,
    margin: 20
  },
  groupTitle: {
    padding: 10,
    display: 'flex',
    alignItems: 'center',
    width: 280
  },
  icon: {
    marginRight: 12
  },
  reportLink: {
    textTransform: 'none',
    backgroundColor: '#E9EAF7'
  },
  reportWidth: {
    width: 240,
    marginRight: 20
  }
}))
export default Reports
