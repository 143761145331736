import React, { useEffect, useContext, useReducer } from 'react'
import CurrentUserReducer, {
  Action,
  USER_ACTION_TYPES
} from './CurrentUserReducer'
import CurrentUserStore, { ICurrentUserStore } from './CurrentUserStore'

/**
 * Create the Context Object to hold all Current User
 * attributes and data types
 */
interface IContextProps {
  currentUser: ICurrentUserStore
  dispatchCurrentUser: (action: Action) => void
}

const CurrentUserContext = React.createContext({} as IContextProps)

/**
 * Create the Current User Provider so all hook implementing
 * components will have access to the same data/attributes.
 */
interface ProviderProps {
  children?: React.ReactChild[]
}

const CurrentUserProvider = ({
  children,
  ...rest
}: ProviderProps): React.ReactChild => {
  const [currentUser, dispatchCurrentUser] = useReducer(CurrentUserReducer, {
    ...CurrentUserStore,
    ...rest
  })

  const value: IContextProps = { currentUser, dispatchCurrentUser }

  const loadCurrentUser = () => {
    dispatchCurrentUser({ type: USER_ACTION_TYPES.reloadCurrentUser })
  }
  useEffect(loadCurrentUser, [])

  const setDocumentEvent = () => {
    document.addEventListener('cookieCheck', () => {
      dispatchCurrentUser({ type: USER_ACTION_TYPES.reloadCurrentUser })
    })
  }
  useEffect(setDocumentEvent, [])

  return (
    <CurrentUserContext.Provider value={value}>
      {children}
    </CurrentUserContext.Provider>
  )
}

/**
 * Create the Hook that provides access to the context object
 * and also the dispatcher function for editing the current
 * user attributes/data.
 */
const useCurrentUser = (): IContextProps => {
  const context = useContext(CurrentUserContext)

  if (context === null) {
    throw new Error(
      'useCurrentUser must be implemented within a CurrentUserProvider'
    )
  }

  return context
}

export { CurrentUserProvider as default, useCurrentUser }
