import dataAdapter from './data-adapter'
import { get } from 'lodash'

export const disputesParser = response => {
  const parsedDisputeData = response.data.data.map(data => {
    const undisplayedData = { id: data.id, hasLinks: data.hasLinks }
    return Object.keys(dataAdapter.columns).reduce((acc, column) => {
      return {
        ...acc,
        [column]: get(data, dataAdapter.columns[column].path)
      }
    }, undisplayedData)
  })
  return {
    data: parsedDisputeData,
    result_ids: response.data.data.map(data => Number(data.xomi_id))
  }
}
