import React from 'react'
import ListView from '../../common/list-view/list-view'
import MassActions from './mass-actions'
import ControlColumn from './control-column'
import CustomControls from './custom-controls'
import SearchItems from './search-items'
import fetchDisputes from './fetch-disputes'
import dataAdapter from './data-adapter'
import useSelectOptions from './use-select-options'
import useColumns from './use-columns'

const DisputesList = () => {
  const selectOptions = useSelectOptions()
  const columns = useColumns()

  const options = {
    useColumnSelector: true,
    useMassActions: true,
    useSaveSearch: true
  }

  return (
    <ListView
      dataAdapter={dataAdapter}
      selectOptions={selectOptions}
      columns={columns}
      fetchData={fetchDisputes}
      MassActions={MassActions}
      SearchItems={SearchItems}
      ControlColumn={ControlColumn}
      CustomControls={CustomControls}
      options={options}
    />
  )
}

export default DisputesList
