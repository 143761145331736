import React, { useState, useEffect } from 'react'
import ListViewContext from './list-view-context'
import { Helmet } from 'react-helmet'
import Proptypes from 'prop-types'
import ActionBar from './action-bar/action-bar'
import MainBar from './action-bar/main-bar'
import DataTable from './data-table/data-table'
import Pagination from './pagination/pagination'
import SearchMenu from './search/search-menu'
import LoadingIndicator from '../loading-indicator'
import { deleteFromLocalStorage } from '../../utils/local-storage'
import addSearchOptionsToUrl from './common/add-search-options-to-url'
import linkDisputeId from '../../utils/link-dispute-id'
import LinkIndicator from '../list-view/link-indicator/link-indicator'
import capitalize from '../../utils/capitalize'
import { useCurrentUser } from '../../contexts/CurrentUserContext'

import dataHandler from './data-handler/data-handler.ts'
import { useLocation } from 'react-router-dom'
import useData from './hooks/use-data'
import addDynamicDateRanges from './search/add-dynamic-date-ranges'
import { SELECTED_PARAMS_TYPE } from './data-handler/selected-params-reducer'

const ListView = props => {
  const {
    dataAdapter,
    selectOptions,
    columns,
    fetchData,
    SearchItems,
    MassActions,
    ControlColumn,
    CustomControls,
    options
  } = props

  const Data = dataHandler(dataAdapter)

  const location = useLocation()
  const [menuOpen, setMenuOpen] = useState(false)
  const [formState, setFormState] = useState()

  const { data, isFetching, isLoading, refetch, remove } = useData({
    dataAdapter,
    fetchData,
    selectedParams: addDynamicDateRanges(Data)
  })

  const [showMassActions, setShowMassActions] = useState(false)
  const { currentUser } = useCurrentUser()

  useEffect(() => {
    if (!currentUser.isLoggedIn()) return
    // remove the data query from cache so it is forced to be refetched
    if (isFetching === false) {
      remove()
    }
    addSearchOptionsToUrl(Data.selectedParams)
    setFormState(Data.selectedParams)
  }, [Data.selectedParams, currentUser.id])

  const clearSearch = () => {
    setMenuOpen(false)
    deleteFromLocalStorage(`${dataAdapter.name}-selectedParams`)
    history.pushState({}, '', location.pathname)
    Data.dispatchSelectedParams({
      type: SELECTED_PARAMS_TYPE.clearParams,
      defaultSearchParams: Data.defaultSearchParams
    })
  }

  const listViewContextData = {
    Data,
    SearchItems,
    ControlColumn,
    CustomControls,
    data,
    options,
    columns,
    selectOptions,
    formState,
    setFormState,
    menuOpen,
    setMenuOpen,
    clearSearch,
    setShowMassActions,
    isLoading
  }

  return (
    <>
      <Helmet>
        <title>Sift | {capitalize(dataAdapter.name)}</title>
      </Helmet>
      <ListViewContext.Provider value={listViewContextData}>
        <div id="list-view">
          {/* Some pages can direct to a list view and expose a functionality that 
          allows you to "link" a record by selecting it */}
          {options.useLinkIndicator && linkDisputeId ? <LinkIndicator /> : null}
          <SearchMenu />
          <ActionBar>
            {!showMassActions ? (
              <MainBar />
            ) : (
              <MassActions
                {...{
                  selectOptions,
                  refetch,
                  setShowMassActions,
                  data
                }}
              />
            )}
          </ActionBar>
          {isFetching || isLoading || columns.isLoading ? (
            <LoadingIndicator />
          ) : (
            <>
              <DataTable />
              <Pagination />
            </>
          )}
        </div>
      </ListViewContext.Provider>
    </>
  )
}

ListView.defaultProps = {
  options: {
    useColumnSelector: false,
    useMassActions: false,
    useSaveSearch: false,
    useLinkIndicator: false
  }
}

ListView.propTypes = {
  dataAdapter: Proptypes.object.isRequired,
  fetchData: Proptypes.func.isRequired,
  selectOptions: Proptypes.object.isRequired,
  columns: Proptypes.object.isRequired,
  SearchItems: Proptypes.func.isRequired,
  MassActions: Proptypes.func,
  ControlColumn: Proptypes.func,
  CustomControls: Proptypes.func
}

export default ListView
