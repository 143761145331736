import React from 'react'
import { Grid } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import { capitalize } from 'lodash'
import { useNotification } from '../../common/notifications/NotificationContext'
import { NOTIFICATION_TYPES } from '../../common/notifications/NotificationReducers'

const components = {
  warning: ({ children, onClose }) => (
    <Alert severity="warning" onClose={onClose}>
      {children}
    </Alert>
  ),
  info: ({ children, onClose }) => (
    <Alert severity="info" onClose={onClose}>
      {children}
    </Alert>
  ),
  success: ({ children, onClose }) => (
    <Alert severity="success" onClose={onClose}>
      {children}
    </Alert>
  ),
  error: ({ children, onClose }) => (
    <Alert severity="error" onClose={onClose}>
      {children}
    </Alert>
  )
}

const SessionNotifications = () => {
  const { notification: noti, dispatchNotification } = useNotification()

  const onClose = () => {
    dispatchNotification({
      type: NOTIFICATION_TYPES.clearNotification
    })
  }

  const renderNotification = () => {
    const Component = components[noti.notificationType]

    return (
      <Component onClose={onClose}>
        <AlertTitle>{capitalize(noti.notificationType)}</AlertTitle>
        {noti.notification}
      </Component>
    )
  }

  if (noti.notification) {
    return (
      <Grid container direction="column" alignItems="stretch" justify="center">
        {renderNotification()}
      </Grid>
    )
  }

  return <></>
}

export default SessionNotifications
