import React, { useEffect, useMemo, useState } from 'react'
import axios from '../../utils/http/axios-local'
import { useSnackbar } from 'notistack'
import {
  Grid,
  Container,
  TextField,
  FormControlLabel,
  Checkbox
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import Editor from './editor'
import _ from 'lodash'
import ConfirmationModal from '../confirmation-modal'
import { Base64 } from 'js-base64'

interface Options {
  modifiers?: any[]
  reasonCodeCategories?: any[]
  merchants?: any[]
  evidenceCategories?: any[]
  deliveryFields?: any[]
}

const defaultOptions = {
  modifiers: [],
  reasonCodeCategories: [],
  merchants: [],
  evidenceCategories: [],
  deliveryFields: []
}

interface LocalState {
  merchantId?: string
  templateId?: string
  templateOwner?: { type: 'DeliveryField'; value: string }
  templateStatus?: 'new' | 'draft' | 'current'
  templateType?: string
  templateValue?: string
  stylesheetValue?: string
  globalTemplateValue?: string
  disableMerchantModalPresent?: boolean
  revertToGlobalModalPresent?: boolean
  deliveryFieldId?: string | null
  fieldDisabled?: boolean
  useGlobal?: boolean
  options?: Options
  deliveryFields?: []
  indexedDeliveryFields: Record<string, any>
}

const defaultState: LocalState = {
  merchantId: null,
  templateId: '',
  templateOwner: null,
  templateStatus: 'new',
  templateType: '',
  templateValue: '',
  stylesheetValue: '',
  globalTemplateValue: '',
  disableMerchantModalPresent: false,
  revertToGlobalModalPresent: false,
  deliveryFieldId: '',
  fieldDisabled: false,
  useGlobal: false,
  options: { ...defaultOptions },
  deliveryFields: [],
  indexedDeliveryFields: {}
}

const valueOrAny = (s: string) => (s === 'any' ? null : s)

const EvidenceTemplates = ({ processor }) => {
  // TODO convert individual states to local state
  const [state, setState] = useState<LocalState>({ ...defaultState })

  const { enqueueSnackbar } = useSnackbar()

  const autocompleteMerchants = [
    { label: 'Any', value: null },
    ...state.options.merchants
  ]

  const applyState = (vars: Record<string, any>) => {
    setState(s => ({
      ...s,
      ...vars
    }))
  }

  const fetchTemplateForOwner = (overrideGlobal = false) => {
    const { templateOwner, deliveryFieldId, merchantId } = state

    if (!templateOwner || !deliveryFieldId) {
      applyState({
        templateId: '',
        templateStatus: null,
        templateValue: '',
        useGlobal: false
      })
      return
    }
    axios
      .get('/api/evidence_templates/for_owner', {
        params: {
          owner: templateOwner,
          merchant_id: valueOrAny(merchantId)
        }
      })
      .then(r => {
        const {
          value,
          id,
          status,
          data_type: dataType,
          disabled,
          use_global: useGlobal
        } = r.data
        applyState({
          templateId: id,
          templateStatus: status,
          templateType: dataType,
          templateValue: value || '',
          fieldDisabled: disabled,
          useGlobal:
            id === null && !overrideGlobal && merchantId ? true : useGlobal
        })
      })
  }

  const fetchStylesheet = async () => {
    try {
      const { data } = await axios.get(
        '/api/evidence_template_stylesheets/primary.json'
      )
      if (data) {
        applyState({
          stylesheetValue: data['content']
        })
      }
    } catch {
      enqueueSnackbar('Failed to fetch stylesheet', {
        variant: 'error'
      })
    }
  }

  const fieldName = (id: string) => {
    if (!id) {
      return null
    }
    const field = state.indexedDeliveryFields[id.toString()]
    if (!field) {
      return null
    }
    return field['name']
  }

  const revertTemplate = async (reverted: boolean) => {
    if (reverted) {
      const params = {
        evidence_template: {
          merchant_id: state.merchantId,
          owner: state.templateOwner,
          published: true,
          use_global: reverted,
          value: ''
        }
      }
      try {
        const { data } = await axios.post(
          '/api/evidence_templates.json',
          params
        )
        applyState({
          templateId: data.id,
          templateStatus: data.status,
          useGlobal: data.use_global
        })
        enqueueSnackbar('Field using global template', {
          variant: 'success'
        })
      } catch {
        enqueueSnackbar('Error saving change', {
          variant: 'error'
        })
      }
    } else {
      if (state.templateId) {
        try {
          await axios
            .delete(`/api/evidence_templates/${state.templateId}`)
            .then(() => {
              fetchTemplateForOwner(true)
              enqueueSnackbar('Field using merchant template', {
                variant: 'success'
              })
            })
        } catch {
          enqueueSnackbar('Error saving change', {
            variant: 'error'
          })
        }
      } else {
        fetchTemplateForOwner(true)
      }
    }
  }

  const saveStylesheet = async () => {
    try {
      const { data } = await axios.post(
        '/api/evidence_template_stylesheets.json',
        { content: Base64.encode(state.stylesheetValue) }
      )
      enqueueSnackbar('Stylesheet published!', {
        variant: 'success'
      })
    } catch {
      enqueueSnackbar('Error saving stylesheet', {
        variant: 'error'
      })
    }
  }

  const saveTemplate = (publish: boolean) => async () => {
    const { templateId, templateValue, templateOwner, merchantId } = state

    const params = {
      evidence_template: {
        value: btoa(templateValue),
        merchant_id: merchantId,
        owner: templateOwner
      }
    }

    try {
      if (publish) {
        await axios.put(
          `/api/evidence_templates/${templateId}/publish.json`,
          params
        )
        applyState({
          templateStatus: 'current'
        })
        enqueueSnackbar('Template published', {
          variant: 'success'
        })
      } else {
        const { data } = await axios.post(
          '/api/evidence_templates.json',
          params
        )
        applyState({
          templateId: data.id,
          templateStatus: data.status,
          useGlobal: data.use_global
        })
        enqueueSnackbar('Template saved', {
          variant: 'success'
        })
      }
    } catch {
      enqueueSnackbar('Error saving template', {
        variant: 'error'
      })
    }
  }

  const saveFieldDisabledStatus = async (disabled: boolean) => {
    const { templateId, templateOwner, merchantId } = state

    try {
      if (disabled) {
        const params = {
          evidence_template: {
            merchant_id: merchantId,
            owner: templateOwner,
            published: true,
            disabled: disabled,
            value: ''
          }
        }
        const { data } = await axios.post(
          '/api/evidence_templates.json',
          params
        )
        applyState({
          templateId: data.id,
          templateStatus: data.status,
          fieldDisabled: data.disabled
        })
        enqueueSnackbar('Field disabled', {
          variant: 'success'
        })
      } else {
        await axios.delete(`/api/evidence_templates/${templateId}`).then(() => {
          fetchTemplateForOwner()
          enqueueSnackbar('Field enabled', {
            variant: 'success'
          })
        })
      }
    } catch {
      enqueueSnackbar('Error saving change', {
        variant: 'error'
      })
    }
  }

  useEffect(() => {
    const { deliveryFieldId } = state

    if (deliveryFieldId) {
      const owner = {
        type: 'DeliveryField',
        id: deliveryFieldId
      }
      axios
        .get('/api/evidence_templates/for_owner', {
          params: { owner: owner, merchant_id: null }
        })
        .then(r => {
          const { value } = r.data
          applyState({
            globalTemplateValue: value
          })
        })
    }
  }, [state.deliveryFieldId, state.merchantId])

  useEffect(() => {
    if (!processor) {
      return
    }
    axios.get(`/api/portals/${processor}`).then(response => {
      const indexed = {}
      response.data.delivery_fields.map(field => {
        indexed[field['id']] = field
      })
      applyState({
        deliveryFieldId: '',
        deliveryFields: response.data.delivery_fields || [],
        indexedDeliveryFields: indexed
      })
    })
  }, [processor])

  useEffect(() => {
    axios.get('/api/evidence_templates/options.json').then(response => {
      const { data } = response
      applyState({
        options: {
          ...state.options,
          modifiers: data.modifiers,
          reasonCodeCategories: data.reason_code_categories,
          merchants: data.merchants,
          deliveryFields: data.delivery_fields
        }
      })
    })
  }, [])

  useEffect(() => {
    if (!state.deliveryFieldId) {
      applyState({
        templateOwner: null
      })
    }
  }, [state.deliveryFieldId])

  useEffect(() => {
    fetchTemplateForOwner()
    fetchStylesheet()
  }, [
    state.templateOwner,
    state.merchantId,
    state.deliveryFieldId,
    state.stylesheet
  ])

  return (
    <>
      <Container maxWidth="xl">
        {processor ? (
          <>
            <h1>Template Editor</h1>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Autocomplete
                  value={
                    state.deliveryFieldId
                      ? {
                          label:
                            state.indexedDeliveryFields[state.deliveryFieldId]
                              .name,
                          value: state.deliveryFieldId
                        }
                      : null
                  }
                  getOptionSelected={option => {
                    return option['value'] === state.deliveryFieldId
                  }}
                  disableClearable={true}
                  renderInput={params => {
                    return <TextField {...params} label="Delivery Field" />
                  }}
                  getOptionLabel={option => option['label']}
                  onChange={(_, value) => {
                    if (!value) {
                      return
                    }
                    const id = value['value']
                    applyState({
                      deliveryFieldId: id,
                      templateOwner: {
                        type: 'DeliveryField',
                        value: fieldName(id),
                        id: id
                      }
                    })
                  }}
                  options={state.deliveryFields.map(field => {
                    return { label: field['name'], value: field['id'] }
                  })}
                  data-testid="delivery-field-selector"
                />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  getOptionSelected={(option, value) =>
                    option.value === value.value
                  }
                  defaultValue={autocompleteMerchants[0]}
                  disableClearable={true}
                  renderInput={params => (
                    <TextField {...params} label="Merchant" />
                  )}
                  getOptionLabel={option => option.label}
                  onChange={(_, value) => {
                    applyState({ merchantId: value.value })
                  }}
                  options={autocompleteMerchants}
                  data-testid="input-merchant"
                />
              </Grid>
              {state.merchantId && (
                <Grid item xs={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.fieldDisabled}
                        onChange={() => {
                          if (!state.fieldDisabled) {
                            applyState({
                              disableMerchantModalPresent: true
                            })
                          } else {
                            saveFieldDisabledStatus(false)
                          }
                        }}
                        style={{ color: '#FF0000' }}
                      />
                    }
                    label={<b>Disable for merchant</b>}
                  />
                </Grid>
              )}
              {state.deliveryFieldId ? (
                <Grid item xs={12}>
                  <Editor
                    merchantId={valueOrAny(state.merchantId)}
                    template={state.templateValue}
                    stylesheetValue={state.stylesheetValue}
                    status={state.templateStatus}
                    templateId={state.templateId}
                    templateType={state.templateType}
                    onTemplateChange={value => {
                      applyState({ templateValue: value })
                    }}
                    onSaveDraft={saveTemplate(false)}
                    onPublish={saveTemplate(true)}
                    onCreateOverride={() => revertTemplate(false)}
                    onRevert={() => {
                      applyState({ revertToGlobalModalPresent: true })
                    }}
                    onSaveStylesheet={() => saveStylesheet()}
                    onStylesheetChange={value => {
                      applyState({ stylesheetValue: value })
                    }}
                    globalValue={state.globalTemplateValue}
                    readOnly={state.merchantId !== 'any' && state.useGlobal}
                    fieldDisabled={state.fieldDisabled}
                    useGlobal={state.useGlobal}
                  />
                </Grid>
              ) : (
                <Grid item xs={12}></Grid>
              )}
            </Grid>
            <ConfirmationModal
              title="Confirm"
              text="Are you sure you want to disable this field?"
              onCancel={() =>
                applyState({ disableMerchantModalPresent: false })
              }
              isOpen={state.disableMerchantModalPresent}
              onConfirm={() => {
                applyState({
                  disableMerchantModalPresent: false
                })
                saveFieldDisabledStatus(true)
              }}
            />
            <ConfirmationModal
              title="Confirm"
              text="Are you sure you want to use the global template?"
              onCancel={() => {
                applyState({ revertToGlobalModalPresent: false })
              }}
              isOpen={state.revertToGlobalModalPresent}
              onConfirm={() => {
                applyState({ revertToGlobalModalPresent: false })
                revertTemplate(true)
              }}
            />
          </>
        ) : (
          <Grid item xs={12}>
            <h2>Select a processor to edit templates</h2>
          </Grid>
        )}
      </Container>
    </>
  )
}

export default EvidenceTemplates
