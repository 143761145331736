import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import { Icon } from '@material-ui/core'

const LifecycleStatus = ({ value }) => {
  const values = {
    new: { icon: 'fiber_new', color: 'green', tooltip: 'New' },
    accept_liability: {
      icon: 'check_box',
      color: '',
      tooltip: 'Accept liability'
    },
    duplicate: { icon: 'content_copy', color: '', tooltip: 'Duplicate' },
    invalid: {
      icon: 'backspace',
      color: '',
      class: 'icon-flipped',
      tooltip: 'Invalid'
    },
    missing_data: {
      icon: 'note_add',
      color: 'yellow',
      tooltip: 'Missing data'
    },
    error_skipped: {
      icon: 'keyboard_tab',
      color: '',
      tooltip: 'Error skipped'
    },
    invalid_reason_code: {
      icon: 'credit_card',
      color: '',
      tooltip: 'Invalid reason code'
    },
    document_sent: { icon: 'send', color: 'green', tooltip: 'Document sent' },
    urgent: { icon: 'flag', color: 'red', tooltip: 'Urgent' },
    ready_to_send: {
      icon: 'playlist_add_check',
      color: 'green',
      tooltip: 'Ready to send'
    },
    system_completed: {
      icon: 'verified_user',
      color: 'green',
      tooltip: 'System completed'
    },
    docsend_failed: {
      icon: 'block',
      color: 'red',
      tooltip: 'Document sent failure'
    }
  }

  const classes = useStyles({
    color: values[value] ? values[value].color : ''
  })

  const tooltipTitle = values[value] ? values[value].tooltip : 'Not set'

  return (
    <Tooltip title={tooltipTitle}>
      <Icon className={classes.root}>
        {values[value] ? values[value].icon : ''}
      </Icon>
    </Tooltip>
  )
}

const useStyles = makeStyles(theme => ({
  root: props => ({
    overflow: 'inherit',
    color: theme.custom.columns.textColors[props.color]
  })
}))

export default LifecycleStatus
