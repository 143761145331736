import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Icon from '@material-ui/core/Icon'

const NoRecords = props => {
  const classes = useStyles()
  return (
    <>
      <Typography variant="h5" className={classes.root}>
        <div className={classes.noRecords}>No records found</div>
        <Icon>keyboard_backspace</Icon> Try adjusting your filter.
      </Typography>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    textAlign: 'center',
    padding: 40
  },
  noRecords: {
    marginBottom: 20
  }
}))

export default NoRecords
