import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'

const LoadingIndicator = props => {
  const classes = useStyles()
  return (
    <Card className={classes.container}>
      <LinearProgress />
      <Typography variant="h5" className={classes.loading}>
        Loading content...
      </Typography>
    </Card>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    position: 'fixed',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500
  },
  loading: {
    textAlign: 'center',
    padding: 40
  }
}))

export default LoadingIndicator
