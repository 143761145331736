import React, { useState, useEffect } from 'react'
import { useSnackbar } from 'notistack'
import { Grid, Button, TextField, Tabs, Tab, AppBar } from '@material-ui/core'
import { Base64 } from 'js-base64'
import { Autocomplete } from '@material-ui/lab'
import Save from '@material-ui/icons/Save'
import 'ace-builds/src-noconflict/ace'
import AceEditor from 'react-ace'
import 'ace-builds/src-noconflict/mode-handlebars'
import 'ace-builds/src-noconflict/mode-json'
import 'ace-builds/src-noconflict/theme-tomorrow'
import 'ace-builds/src-noconflict/ext-language_tools'
import Preview from '../evidence-templates/preview'
import axios from '../../utils/http/axios-local'
import { useStyles as useSiftStyles } from '../../styles/evidences/sift'
import UnauthorizedAccessError from '../../common/errors/unauthorized-access-error'

const SubTemplates = _ => {
  const { enqueueSnackbar } = useSnackbar()
  const [templates, setTemplates] = useState([])
  const [indexedTemplates, setIndexedTemplates] = useState({})
  const [currentTemplateId, setCurrentTemplateId] = useState(null)
  const [templateValue, setTemplateValue] = useState('')
  const [addFieldValue, setAddFieldValue] = useState('')
  const [stylesheet, setStylesheet] = useState('')
  const [permitted, setPermission] = useState<boolean>(true)
  const siftClasses = useSiftStyles()

  const handleErrors = (errors: [], successMessage: string) => {
    if (!errors) {
      return true
    }
    if (errors.length === 0) {
      if (successMessage) {
        enqueueSnackbar(successMessage, { variant: 'success' })
      }
      return true
    } else {
      errors.map(error => {
        enqueueSnackbar(error, { variant: 'error' })
      })
      return false
    }
  }

  const pullTemplates = async () => {
    const { data } = await axios.get('/api/sub_templates')
    setTemplates(data)
    const indexed = {}
    data.map(element => {
      indexed[element['id']] = element
    })
    setIndexedTemplates(indexed)
  }

  const pullTemplate = async (id: string) => {
    const { data } = await axios.get(`/api/sub_templates/${id}`)
    setTemplateValue(data.content)
  }

  const pullStylesheet = async () => {
    try {
      const { data } = await axios.get(
        '/api/evidence_template_stylesheets/primary.json'
      )
      if (data) {
        setStylesheet(data['content'])
      }
    } catch {
      enqueueSnackbar('Failed to fetch stylesheet', {
        variant: 'error'
      })
    }
  }

  const addTemplate = (name: string) => {
    axios.post('/api/sub_templates', { name: name }).then(r => {
      const data = r.data
      if (handleErrors(data.errors, 'Template Created')) {
        const template = data.template
        pullTemplates().then(() => {
          setCurrentTemplateId(template.id)
        })
      }
    })
  }

  const updateTemplate = async (id: string, content: string) => {
    const { data } = await axios.put(`/api/sub_templates/${id}`, {
      content: Base64.encode(content)
    })
    if (handleErrors(data.errors, 'Template Saved')) {
      setCurrentTemplateId(data.template.id)
      setTemplateValue(data.template.content)
    }
  }

  useEffect(() => {
    axios.get('/api/permitted').then(response => {
      setPermission(response.data.permitted)
    })
  }, [])

  useEffect(() => {
    pullTemplates()
    pullStylesheet()
  }, [])

  useEffect(() => {
    if (!currentTemplateId) {
      return
    }
    pullTemplate(currentTemplateId)
  }, [currentTemplateId])

  const templateToAutoSelect = template => {
    if (template) {
      return { value: template.id, label: template.name }
    }
    return null
  }

  if (!permitted) return <UnauthorizedAccessError />

  return (
    <Grid container>
      <Grid container item xs={12}>
        <Grid container item style={{ margin: '10px' }}>
          <Autocomplete
            data-testid={'subtemplate-select'}
            style={{ width: '300px' }}
            disableClearable={true}
            value={templateToAutoSelect(indexedTemplates[currentTemplateId])}
            renderInput={params => {
              return <TextField {...params} label="Select Sub Template" />
            }}
            getOptionSelected={(option, value) => {
              return value.value === currentTemplateId
            }}
            getOptionLabel={option => {
              return option.label
            }}
            onChange={(_, value) => {
              if (!value) {
                return
              }
              setCurrentTemplateId(value['value'])
            }}
            options={templates.map(value => {
              return {
                value: value.id,
                label: value.name
              }
            })}
          />
        </Grid>
        <Grid container item style={{ margin: '10px' }}>
          <TextField
            value={addFieldValue}
            onChange={event => {
              setAddFieldValue(event.target.value)
            }}
          />
          <Button
            style={{ margin: '10px' }}
            onClick={() => {
              addTemplate(addFieldValue)
            }}
            variant="outlined"
          >
            Add New
          </Button>
        </Grid>
      </Grid>
      {currentTemplateId && (
        <Grid container item data-testid="templateeditsection">
          <Grid item xs={6}>
            <Grid container spacing={1}>
              <Grid item xs={12} style={{ padding: '10px' }}>
                <AppBar position="static" color="default">
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Tabs
                        value={0}
                        indicatorColor="primary"
                        textColor="primary"
                      >
                        <Tab label="Sub Template" id={'0'} />
                      </Tabs>
                    </Grid>
                  </Grid>
                </AppBar>
              </Grid>
            </Grid>
            <Grid item container xs={12} style={{ marginBottom: '10' }}>
              <Grid
                container
                className={siftClasses.blueHeader}
                style={{ padding: '5px', marginBottom: '20px' }}
              >
                <Grid item xs={3}>
                  <Grid container>
                    <Button
                      className={siftClasses.siftButton}
                      variant="outlined"
                      onClick={() =>
                        updateTemplate(currentTemplateId, templateValue)
                      }
                    >
                      <Save style={{ color: 'white' }} />
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <AceEditor
              mode="handlebars"
              theme="tomorrow"
              onChange={setTemplateValue}
              fontSize={14}
              height={'700px'}
              width={'100%'}
              value={templateValue || ''}
              showPrintMargin
              showGutter
              highlightActiveLine
              enableBasicAutocompletion
              enableLiveAutocompletion
            />
          </Grid>
          <Grid item xs={6}>
            <Preview
              template={templateValue}
              templateType={'pdf'}
              stylesheet={stylesheet}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

export default SubTemplates
