import useFetchSelectedParams from './use-fetch-selected-params'

import DataAdapter from './interfaces/data-adapter-interface'
import DataHandler from './interfaces/data-handler-interface'
import SearchParams, {
  ArrayParams,
  StringParams,
  NumberParams,
  DateParams,
  SortBy
} from './interfaces/search-params-interface'
import { Chips, DateChips, SortChips } from './interfaces/chips-interface'

const getDateColumns = (dataAdapter: DataAdapter) =>
  Object.keys(dataAdapter.columns)
    .filter(column => dataAdapter.columns[column].type === 'date')
    .map(column => dataAdapter.columns[column])

const getSearchParams = ({
  dataAdapter,
  includeDefaults = false,
  includeEmptyValues = false
}: {
  dataAdapter: DataAdapter
  includeDefaults?: boolean
  includeEmptyValues?: boolean
}): SearchParams => {
  const pageParams = {
    page: dataAdapter.pagination.page,
    per_page: dataAdapter.pagination.perPage
  }

  const getEmptyValue = (
    type: 'string' | 'date' | 'number' | 'string[]' | 'number[]' | 'boolean'
  ): '' | [] | null => {
    switch (type) {
      case 'string':
      case 'date':
      case 'number':
        return ''
      case 'string[]':
      case 'number[]':
        return []
      case 'boolean':
        return null
    }
  }

  const params: ArrayParams | StringParams | NumberParams = Object.keys(
    dataAdapter.columns
  )
    .filter(column => dataAdapter.columns[column].type !== 'date')
    .map(column => dataAdapter.columns[column])
    .reduce((acc, param) => {
      const emptyValue = getEmptyValue(param.type)

      if (includeDefaults) {
        if ('default' in param) {
          acc[param.name] = param['default']
        }
      } else if (includeEmptyValues) {
        acc[param.name] = emptyValue
      }

      return acc
    }, {})

  const derivedDateParams: DateParams = getDateColumns(dataAdapter).reduce(
    (acc, param) => {
      let value = includeEmptyValues ? '' : null
      if (includeDefaults && 'defaultRange' in param) {
        value = param['defaultRange']
      }
      if (value !== null) acc[`${param.name}_range`] = value

      if (
        includeEmptyValues &&
        'rangeStartName' in param &&
        'rangeEndName' in param
      ) {
        acc[param.rangeStartName] = ''
        acc[param.rangeEndName] = ''
      }
      return acc
    },
    {}
  )

  const sortByParams: SortBy = Object.keys(dataAdapter.columns)
    .filter(param => dataAdapter.columns[param].sortable)
    .map(param => dataAdapter.columns[param])
    .reduce((acc, param) => {
      if (includeDefaults && 'sortDefault' in param) {
        acc[param.name] = param.sortDefault
      }
      return acc
    }, {})

  return {
    ...pageParams,
    ...params,
    ...derivedDateParams,
    sort_by: sortByParams
  }
}

const getChips =
  (
    dataAdapter: DataAdapter,
    selectedParams: ArrayParams | StringParams | NumberParams
  ) =>
  (selectOptions): Chips[] => {
    return Object.keys(dataAdapter.columns)
      .filter(column => dataAdapter.columns[column].name in selectedParams)
      .map(key => {
        const column = dataAdapter.columns[key]
        const options = selectOptions[column.name]
        let value = selectedParams[column.name]

        // there are selectOptions for this param
        if (Array.isArray(options)) {
          // multiple values are selected
          if (Array.isArray(value)) {
            value = options
              .filter(o => value.find(v => String(v) === String(o.value)))
              .map(o => o.label)
              .join(', ')
            // single value (no array)
          } else {
            value = options.find(o => String(value) === String(o.value))?.label
          }
          // no select options, just use the value
        } else {
          value = selectedParams[column.name]
        }

        return {
          title: column.title,
          name: column.name,
          value
        }
      })
  }

const getDateChips = (
  dataAdapter: DataAdapter,
  selectedParams: SearchParams
): DateChips[] => {
  const columns = dataAdapter.columns
  return Object.keys(columns)
    .filter(column => dataAdapter.columns[column].type === 'date')
    .reduce((acc, param) => {
      const column = columns[param]
      if (!('rangeStartName' in column) || !('rangeEndName' in column)) return
      const startField = column.rangeStartName
      const endField = column.rangeEndName
      const rangeField = `${column.name}_range`
      const startValue = selectedParams[startField]
      const endValue = selectedParams[endField]
      const rangeValue = selectedParams[rangeField]

      if (startValue && endValue) {
        acc.push({
          fields: [startField, endField],
          label: `${columns[param].title}: ${startValue} - ${endValue}`
        })
      } else if (rangeValue) {
        acc.push({
          fields: [rangeField],
          label: `${columns[param].title}: ${rangeValue}`
        })
      }
      return acc
    }, [])
}

const getColumnByName = (dataAdapter: DataAdapter) => (name: string) => {
  return Object.keys(dataAdapter.columns)
    .filter(field => dataAdapter.columns[field].name === name)
    .map(column => dataAdapter.columns[column])[0]
}

const getSortChips = (
  dataAdapter: DataAdapter,
  selectedParams: SearchParams
): SortChips[] => {
  return Object.keys(selectedParams.sort_by).reduce((acc, param) => {
    const column = getColumnByName(dataAdapter)(param)
    if (column) {
      acc.push({
        title: column.title,
        name: column.name,
        value: selectedParams.sort_by[param]
      })
    }
    return acc
  }, [])
}

const dataHandler = (dataAdapter: DataAdapter): DataHandler => {
  const emptySearchParams = getSearchParams({
    dataAdapter,
    includeEmptyValues: true
  })
  const defaultSearchParams = getSearchParams({
    dataAdapter,
    includeDefaults: true
  })

  const [selectedParams, dispatchSelectedParams] = useFetchSelectedParams({
    defaultSearchParams,
    emptySearchParams,
    dataAdapter,
    getColumnByName: getColumnByName(dataAdapter)
  })

  return {
    emptySearchParams,
    defaultSearchParams,
    getChips: getChips(dataAdapter, selectedParams),
    dateChips: getDateChips(dataAdapter, selectedParams),
    sortChips: getSortChips(dataAdapter, selectedParams),
    dateColumns: getDateColumns(dataAdapter),
    dataAdapter,
    selectedParams,
    getColumnByName: getColumnByName(dataAdapter),
    dispatchSelectedParams
    // serverState: () => {} // function to get data from the server?
  }
}

export default dataHandler
