import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import dataAdapter from './data-adapter'
import Grid from '@material-ui/core/Grid'
import MultipleSelect from '../../common/list-view/search/components/multiple-select'
import SearchFilterInput from '../../common/list-view/search/components/input'
import CustomDateRangePicker from '../../common/list-view/search/components/date-pickers/custom-date-range-picker'
import SelectionDisplay from '../../common/list-view/search/components/date-pickers/selection-display'
import { useCurrentUser } from '../../contexts/CurrentUserContext'
import parseParamsForMultiSelect from '../../common/list-view/common/parse-params-for-multiselect'

const SearchItems = props => {
  const {
    formState,
    selectOptions,
    handleChange,
    handleMultiSelectChange,
    handleSetDates
  } = props
  const classes = useStyles()
  const { currentUser } = useCurrentUser()
  const [outcomeOptions, setOutcomeOptions] = useState([])

  const loadOutcomeOptions = () => {
    if (
      selectOptions &&
      selectOptions.data.data[dataAdapter.columns['outcome'].name]
    ) {
      const dataFieldName = dataAdapter.columns['outcome'].name
      const newOutcomeOptions = selectOptions.data.data[dataFieldName]
      setOutcomeOptions([...newOutcomeOptions])
    }
  }
  useEffect(loadOutcomeOptions, [selectOptions])

  useEffect(() => {
    if (!formState['portal']) {
      return
    }

    const filteredOptions = selectOptions.data.data[
      dataAdapter.columns['outcome'].name
    ].filter(o => o.alert_portal_id === formState['portal'])

    if (filteredOptions && filteredOptions.length > 0) {
      setOutcomeOptions(filteredOptions)
    }
  }, [formState['portal']])

  const parseParams = parseParamsForMultiSelect({
    dataAdapter,
    formState,
    handleMultiSelectChange,
    selectOptions
  })

  const alertTs = dataAdapter.columns['alertTs']

  return (
    <>
      <Grid container className={classes.darkRow}>
        <Grid item xs={6}>
          <MultipleSelect {...parseParams('merchant')} />
        </Grid>
        <Grid item xs={6}>
          <MultipleSelect {...parseParams('portal')} multiple={false} />
        </Grid>
        <Grid item xs={6}>
          <MultipleSelect
            {...parseParams('outcome')}
            options={outcomeOptions}
          />
        </Grid>
        <Grid item xs={6}>
          <MultipleSelect {...parseParams('alertStatus')} multiple={false} />
        </Grid>
        {currentUser.chargebackEmployee && (
          <Grid item xs={6}>
            <MultipleSelect
              {...parseParams('professionalService')}
              multiple={false}
            />
          </Grid>
        )}
      </Grid>

      <Grid container className={classes.lightRow}>
        <Grid item xs={6}>
          <SearchFilterInput
            {...{ handleChange, formState }}
            {...dataAdapter.columns['caseId']}
          />
        </Grid>
        <Grid item xs={6}>
          <div className={classes.cardInfo}>
            <SearchFilterInput
              {...{ handleChange, formState }}
              {...dataAdapter.columns['cardPrefix']}
              type="number"
            />
            <SearchFilterInput
              {...{ handleChange, formState }}
              {...dataAdapter.columns['cardSuffix']}
              type="number"
            />
            <SearchFilterInput
              {...{ handleChange, formState }}
              {...dataAdapter.columns['amount']}
              type="number"
            />
          </div>
        </Grid>
      </Grid>

      <Grid container className={classes.darkRow}>
        <Grid item xs={6}>
          <div className={classes.datePicker}>
            Alert Date:
            <CustomDateRangePicker
              name={alertTs.name}
              allowDynamicRanges
              handleSetDates={handleSetDates(alertTs.name)}
              initialStartDate={formState[alertTs.rangeStartName]}
              initialEndDate={formState[alertTs.rangeEndName]}
              initialDynamicRange={formState[`${alertTs.name}_range`]}
            />
          </div>
        </Grid>
        <Grid item xs={6}>
          <SelectionDisplay {...props} />
        </Grid>
      </Grid>
    </>
  )
}

const allRows = {
  borderRadius: 5,
  padding: '10px 0px'
}
const useStyles = makeStyles(theme => {
  return {
    lightRow: {
      ...allRows
    },
    darkRow: {
      ...allRows,
      backgroundColor: theme.palette.secondary[50]
    },
    cardInfo: {
      display: 'flex'
    },
    datePicker: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      marginTop: 8
    }
  }
})

export default SearchItems
