import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Header from './header'
import DeliveryFieldsList from './delivery-fields-list'
import DETNavigation from './navigation'
import LeftSidebar from './left-sidebar'
import FinalizeModal from './finalize-modal'
import FinalizePreview from './finalize-preview'
import EditModal from './edit-modal'
import Styles from './styles'
import { useDelayedRendering } from './useDelayedRendering'
import axios from '../../utils/http/axios-local'
import capitalize from '../../utils/capitalize'
import {
  DatasetDTO,
  DisputeDTO,
  ExtendedRendering,
  ImageDTO,
  LifecycleStatus,
  RenderingContent,
  RenderingContent as RenderingContentDTO,
  RenderingDTO,
  TemplateType
} from './dtos'
import { ILockedState, IRenderingContent, ISaveResult } from './interfaces'
import {
  DataFieldType,
  DIRTY_STATUS,
  Filters,
  LOADING_STATUS,
  SAVING_STATUS,
  Tabs
} from './enums'
import {
  getRenderingsByTypeHash,
  getRenderingsToDisplay,
  getRenderingsToRender,
  prepareChanges,
  saveAllChanges
} from '../../utils/det'

const HEADER_HEIGHT = 64
const NOT_DIRTY_TIMEOUT = 5000
const DEFAULT_FILTER_INDEX = Filters.Incomplete
const DEFAULT_TAB_INDEX = Tabs.Rendered

interface ModalState {
  rendering?: RenderingDTO
  fieldName?: string
  isArray?: boolean
}

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    minWidth: '100vw'
  },
  leftSide: {
    display: 'flex',
    flexDirection: 'column',
    width: '360px',
    height: '100%',
    minHeight: '100vh',
    padding: '16px 0px 16px 0px',
    backgroundColor: 'white', // TODO check for proper bg color const
    zIndex: 0
  },
  centralArea: {
    width: '100%'
  },
  centralAreaMain: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '30px',
    width: '100%',
    paddingLeft: '48px'
  },
  finalizedPreview: {
    marginTop: '16px',
    marginLeft: '48px'
  }
}))

const DETEdit = (): React.ReactElement => {
  const { id: disputeId } = useParams<{ id: string }>()
  const [savingStatus, setSavingStatus] = useState<SAVING_STATUS | null>(null)
  const [dirtyRenderings, setDirtyRenderings] = useState<
    Record<string, DIRTY_STATUS>
  >({})
  const [dataset, setDataset] = useState<DatasetDTO>({})
  const [changes, setChanges] = useState<Record<string, string | ImageDTO>>({})
  const [dispute, setDispute] = useState<DisputeDTO>({})
  const [lockedStatus, setLockedStatus] = useState<ILockedState>({})
  const [isManualDelivery, setIsManualDelivery] = useState<boolean>(false)
  const [manuallySubmittedStatus, setManuallySubmittedStatus] =
    useState<boolean>(false)
  const [renderingsData, setRenderingsData] = useState<RenderingDTO[]>([])
  const [stylesheetRenderingsData, setStylesheetRenderingsData] = useState<
    RenderingDTO[]
  >([])
  const [stylesFromBe, setStyles] = useState<string[]>([])
  const [renderingsHashData, setRenderingsHashData] = useState<
    Record<string, RenderingDTO>
  >({})
  const [renderingsContentData, setRenderingsContentData] = useState<
    Record<string, IRenderingContent>
  >({})
  const [displayRenderingsData, setDisplayRenderingsData] = useState<
    ExtendedRendering[]
  >([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [selectedTab, setSelectedTab] = useState<Tabs>(DEFAULT_TAB_INDEX)
  const [modalState, setModalState] = useState<ModalState | null>(null)
  const [selectedFilter, setSelectedFilter] =
    useState<Filters>(DEFAULT_FILTER_INDEX)
  const [isFinalizedModalOpen, setIsFinalizedModalOpen] = useState(false)
  const listRefs = useRef<Record<string, MutableRefObject<HTMLElement>>>({})
  const [reRenderAsync, removeRenderingRequest] = useDelayedRendering()
  const classes = useStyles()

  const disableEditing = (dispute: DisputeDTO) => {
    const lockedStatus: ILockedState = {}
    if (dispute.locked) {
      lockedStatus.locked = true
    }

    if (dispute.expired) {
      lockedStatus.expired = true
    }

    if (dispute.lifecycleStatus === LifecycleStatus.acceptLiability) {
      lockedStatus.acceptedLiability = true
    }

    setLockedStatus(lockedStatus)
  }

  const disableManualSubmit = (dispute: DisputeDTO) => {
    if (
      dispute.manualDelivery &&
      dispute.lifecycleStatus === LifecycleStatus.documentSent
    ) {
      setManuallySubmittedStatus(true)
    }
  }

  useEffect(() => {
    setIsLoading(true)
    Promise.allSettled([
      axios.get<DisputeDTO>(`/det/disputes/${disputeId}`),
      axios.get<RenderingDTO[]>(`/det/renderings?dispute_id=${disputeId}`),
      axios.get<{ dataset: DatasetDTO }>(`/det/datasets/${disputeId}`)
    ])
      .then(results => {
        let dispute: DisputeDTO

        if (results[0].status === 'fulfilled') {
          // Dispute
          dispute = results[0]?.value?.data
          if (dispute) {
            setDispute(dispute)
            disableEditing(dispute)
            disableManualSubmit(dispute)
            setIsLoading(false)
            setIsManualDelivery(dispute.manualDelivery)
          }
        }

        if (results[1].status === 'fulfilled') {
          // Renderings
          const renderings = results[1]?.value?.data
          const renderingsByType = getRenderingsByTypeHash(renderings)

          const renderingsToDisplay = getRenderingsToRender(
            renderingsByType,
            dispute
          )

          setRenderingsData(renderingsToDisplay)
          setStylesheetRenderingsData(renderingsByType[TemplateType.stylesheet])

          const renderingsHash = renderingsToDisplay.reduce((hash, item) => {
            hash[item.templateId] = item
            return hash
          }, {})
          setRenderingsHashData(renderingsHash)
        }

        if (results[2].status === 'fulfilled') {
          // Dataset
          const dataset = results[2]?.value.data?.dataset
          if (dataset) {
            setDataset(dataset)
          }
        }

        setIsLoading(false)
      })
      .catch(error => {
        setIsLoading(false)
        console.log(error)
      })
  }, [manuallySubmittedStatus])

  useEffect(() => {
    // filter display renderings after user clicks on filter.
    // It's done through useEffect, because Tab component from
    // focus-components library has onSelect memoization....
    const displayRenderings = getRenderingsToDisplay(
      selectedFilter,
      renderingsData,
      renderingsHashData,
      lockedStatus
    )
    setDisplayRenderingsData(displayRenderings)
  }, [
    renderingsData,
    renderingsHashData,
    setDisplayRenderingsData,
    selectedFilter,
    lockedStatus
  ])

  useEffect(() => {
    // pull styles content which is a part of renderings
    if (stylesheetRenderingsData && stylesheetRenderingsData.length) {
      const promises = stylesheetRenderingsData.map(rendering =>
        axios.get<RenderingContentDTO>(
          `/det/renderings/${rendering.templateId}?dispute_id=${disputeId}`
        )
      )
      Promise.all(promises).then(results => {
        const styles = results.map(result => result.data.content)
        setStyles(styles)
      })
    }
  }, [stylesheetRenderingsData])

  useEffect(() => {
    if (
      lockedStatus?.locked ||
      lockedStatus?.expired ||
      lockedStatus?.acceptedLiability
    ) {
      setSelectedTab(Tabs.Rendered)
    }
  }, [lockedStatus])

  const onFilterSelect = (filter: Filters, index: number) => {
    setSelectedFilter(index)
  }

  const onTabSelect = (tabs: Tabs, index: number) => {
    setSelectedTab(index)
  }

  const onCloseModal = useCallback(() => {
    setChanges({})
    setModalState(null)
  }, [setChanges, setModalState])

  const onDataFieldClick = (
    rendering: RenderingDTO,
    fieldName: string,
    isArray?: boolean
  ) => {
    setModalState({ rendering, fieldName, isArray })
  }

  const updateRenderingContent = (
    templateId: number,
    content: string,
    status: LOADING_STATUS,
    workflowDescription?: string
  ) => {
    setRenderingsContentData(prevRenderingsContentData => ({
      ...prevRenderingsContentData,
      [templateId]: {
        content,
        status,
        workflowDescription
      }
    }))
  }

  const onUpdateDirtyStatus = (renderingId: number, status: DIRTY_STATUS) => {
    setDirtyRenderings(prevValue => ({ ...prevValue, [renderingId]: status }))
  }

  const onDirtyRenderingClick = (rendering: RenderingDTO) => {
    onUpdateDirtyStatus(rendering.id, DIRTY_STATUS.Loading)
    updateRenderingContent(
      rendering.templateId,
      renderingsContentData?.[rendering.templateId]?.content,
      LOADING_STATUS.Loading,
      renderingsContentData?.[rendering.templateId]?.workflowDescription
    )
    removeRenderingRequest(rendering.id)
    axios
      .get<RenderingContent>(
        `/det/renderings/${rendering.templateId}?dispute_id=${disputeId}`
      )
      .then(({ data: { content, templateWorkflowDescription } }) => {
        updateRenderingContent(
          rendering.templateId,
          content,
          LOADING_STATUS.Loaded,
          templateWorkflowDescription
        )
        onUpdateDirtyStatus(rendering.id, DIRTY_STATUS.UpToDate)

        setTimeout(() => {
          onUpdateDirtyStatus(rendering.id, DIRTY_STATUS.Latest)
        }, NOT_DIRTY_TIMEOUT)
      })
      .catch(error => {
        console.log(error)
        updateRenderingContent(
          rendering.templateId,
          renderingsContentData?.[rendering.templateId]?.content,
          LOADING_STATUS.Loaded,
          renderingsContentData?.[rendering.templateId]?.workflowDescription
        )
      })
  }

  const saveChanges = useCallback(
    async (
      changes: Record<string, string | ImageDTO>,
      disputeId: string,
      currentRenderingId: number,
      setDataset: (data) => void
    ): Promise<ISaveResult> => {
      if (Object.keys(changes || {})?.length) {
        setSavingStatus(SAVING_STATUS.saving)
        const { dataset, dirtyRenderingIds, ...restSaveResults } =
          await saveAllChanges(disputeId, changes)

        if (dataset) {
          setDataset(dataset)
        }

        if (dirtyRenderingIds) {
          const newDirtyRenderings = dirtyRenderingIds.reduce(
            (hash, item) => {
              hash[item] = DIRTY_STATUS.Dirty
              return hash
            },
            { ...dirtyRenderings }
          )

          setDirtyRenderings(newDirtyRenderings)

          dirtyRenderingIds.forEach(renderingId => {
            const rendering = renderingsData.find(
              rendering => rendering.id === +renderingId
            )

            if (currentRenderingId === rendering.id) {
              reRenderAsync(
                renderingId,
                () => onDirtyRenderingClick(rendering),
                true
              )
            } else if (rendering) {
              reRenderAsync(renderingId, () => onDirtyRenderingClick(rendering))
            }
          })
        }

        setSavingStatus(SAVING_STATUS.saved)

        try {
          // reload renderings list with the latest applied segmentation rules
          // temporary solution for the demo
          const { data: renderings } = await axios.get<RenderingDTO[]>(
            `/det/renderings?dispute_id=${disputeId}`
          )
          const renderingsByType = getRenderingsByTypeHash(renderings)

          const renderingsToDisplay = getRenderingsToRender(
            renderingsByType,
            dispute
          )

          setRenderingsData(renderingsToDisplay)
          setStylesheetRenderingsData(renderingsByType[TemplateType.stylesheet])

          const renderingsHash = renderingsToDisplay.reduce((hash, item) => {
            hash[item.templateId] = item
            return hash
          }, {})
          setRenderingsHashData(renderingsHash)
        } catch (error) {
          console.log(error)
        }

        return {
          ...restSaveResults,
          dataset,
          dirtyRenderingIds
        }
      }
    },
    [prepareChanges, renderingsData]
  )

  const onDatasetChange = async (
    key: string,
    value: string | ImageDTO,
    renderingId: number
  ) => {
    if ((value as ImageDTO)?.type === DataFieldType.IMAGE) {
      // custom logic for uploading images. In modal each image is uploaded at
      // the moment of time when the user selected it, not when the user clicks
      // on 'Save' button in the modal.
      return saveChanges({ [key]: value }, disputeId, renderingId, setDataset)
    } else {
      setChanges(prevState => ({ ...prevState, [key]: value }))
    }
  }

  const onDatasetChangeAutoSave = async (
    key: string,
    value: string | ImageDTO,
    renderingId: number
  ) => {
    // triggered by the user losing focus of field on Data Entry view
    return saveChanges({ [key]: value }, disputeId, renderingId, setDataset)
  }

  const onUpdateRenderingData = (updatedData: Partial<RenderingDTO>) => {
    const updatedRenderings = renderingsData.reduce((acc, item) => {
      if (item.templateId === updatedData.templateId) {
        acc.push({ ...item, ...updatedData })
      } else {
        acc.push(item)
      }
      return acc
    }, [])
    setRenderingsData(updatedRenderings)
    const renderingsHash = updatedRenderings.reduce((hash, item) => {
      hash[item.templateId] = item
      return hash
    }, {})
    setRenderingsHashData(renderingsHash)
  }

  const onSetListRef = (
    rendering: RenderingDTO,
    ref: MutableRefObject<HTMLElement>
  ) => {
    listRefs.current[rendering.templateId] = ref
  }

  const onNavigationItemClick = (rendering: RenderingDTO) => {
    const ref = listRefs?.current?.[rendering.templateId]?.current
    if (ref) {
      // Offset inside the viewport
      const viewPortOffset = ref.getBoundingClientRect().top - HEADER_HEIGHT
      // How much user already scrolled
      const scrollTop = window.scrollY

      const offset = viewPortOffset + scrollTop
      window.scrollTo({ top: offset, behavior: 'smooth' })

      // Highlight rendering which we are scrolling to
      ref.style.borderColor = '#295ded'
      setTimeout(() => {
        ref.style.borderColor = ''
      }, 2000)
    }
  }

  return (
    <>
      <Helmet>
        <title>Sift | {capitalize('DET')}</title>
      </Helmet>
      <Styles styles={stylesFromBe} />
      <div className={classes.wrapper}>
        <div className={`shadow-s ${classes.leftSide}`}>
          <LeftSidebar
            isLoading={isLoading}
            dataset={dataset}
            dispute={dispute}
            disputeId={disputeId}
          />
        </div>
        <div className={classes.centralArea}>
          <Header
            renderingsData={renderingsData}
            onFilterSelect={onFilterSelect}
            selectedFilter={selectedFilter}
            onFinalize={() => setIsFinalizedModalOpen(true)}
            selectedTab={selectedTab}
            onSelectTab={onTabSelect}
            savingStatus={savingStatus}
            dispute={dispute}
            disputeId={disputeId}
            lockedStatus={lockedStatus}
            disableEditing={disableEditing}
            setPageLoading={setIsLoading}
            disableManualSubmit={disableManualSubmit}
            isManualDelivery={isManualDelivery}
            manuallySubmittedStatus={manuallySubmittedStatus}
          />
          <div className={classes.centralAreaMain}>
            <div>
              <DeliveryFieldsList
                isLoading={isLoading}
                renderingsData={displayRenderingsData}
                renderingsHashData={renderingsHashData}
                selectedTab={selectedTab}
                disputeId={disputeId}
                dataset={dataset}
                dirtyRenderings={dirtyRenderings}
                onDataFieldClick={onDataFieldClick}
                onUpdateRenderingData={onUpdateRenderingData}
                onDirtyRenderingClick={onDirtyRenderingClick}
                onSetListRef={onSetListRef}
                onDatasetChange={onDatasetChangeAutoSave}
                renderingsContentData={renderingsContentData}
                updateRenderingContent={updateRenderingContent}
              />
            </div>
            <DETNavigation
              renderingsData={renderingsData}
              renderingsHashData={renderingsHashData}
              onNavigationItemClick={onNavigationItemClick}
              selectedFilter={selectedFilter}
              lockedStatus={lockedStatus}
            />
          </div>
          {lockedStatus?.locked && !lockedStatus.acceptedLiability ? (
            <div className={classes.finalizedPreview}>
              <FinalizePreview
                disputeId={disputeId}
                isParentLoading={isLoading}
              />
            </div>
          ) : null}
        </div>
      </div>
      <EditModal
        changes={changes}
        modalState={modalState}
        onCloseModal={onCloseModal}
        onSave={saveChanges}
        disputeId={disputeId}
        dataset={dataset}
        onDatasetChange={onDatasetChange}
        setDataset={setDataset}
      />
      {isFinalizedModalOpen && (
        <FinalizeModal
          disputeId={disputeId}
          onCloseFinalizeModal={() => setIsFinalizedModalOpen(false)}
          onFinalize={data => disableEditing(data)}
          isManualDelivery={isManualDelivery}
        />
      )}
    </>
  )
}

export default DETEdit
