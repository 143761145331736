const formatSelectedColumns = (columns, dataAdapter) => {
  return Object.keys(columns.data)
    .filter(c => columns.data[c])
    .sort((a, b) => dataAdapter.columns[a].order - dataAdapter.columns[b].order)
    .map(column => {
      const columnInfo = dataAdapter.columns[column]
      return {
        title: columnInfo.title,
        name: columnInfo.name,
        sortable: columnInfo.sortable || false,
        field: column,
        format: columnInfo.format
      }
    })
}

export default formatSelectedColumns
