import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'
import useHttp from '../../hooks/useHttp'
import useBindTableHeightToResize from './use-bind-table-height-to-resize'

const REPORTS = {
  rtrOverview: '/embed/dashboards/deflector_client::rtr_summary',
  disputeOverview: '/embed/dashboards-next/disputer_client::dispute_summary_v2',
  alertOverview: '/embed/dashboards/disputer_client::alert_summary',
  analystsOverview: '/embed/dashboards/operations_client::measure_analysts',
  monthlyReviewOverview:
    '/embed/dashboards/disputer_client::monthly_review_report',
  disputeDownload: '/embed/dashboards/disputer_client::dispute_download',
  inquiryDownload: '/embed/dashboards/deflector_client::rtr_download_raw',
  alertDownload: '/embed/dashboards/disputer_client::alert_download',
  analystsDownload:
    '/embed/dashboards/operations_client::measure_analysts_download'
}

const Looker = () => {
  const [isLoading, setIsLoading] = useState(true)
  const classes = useStyles()
  const { report } = useParams()
  const frameHeight = useBindTableHeightToResize()

  const [url, getUrl] = useHttp({
    url: '/looker/get-url',
    source: 'local',
    params: {
      embed_url: REPORTS[report]
    }
  })

  useEffect(() => {
    getUrl()
  }, [])

  const onIframeLoad = () => {
    setIsLoading(false)
  }

  return (
    <>
      {isLoading && (
        <div
          className={classes.loaderWrapper}
          style={{ height: frameHeight - 40 }}
        >
          <LinearProgress className={classes.loader} />
        </div>
      )}
      {url && (
        <iframe
          src={url.data}
          className={classes.iframe}
          style={{
            height: frameHeight - 40,
            display: isLoading ? 'none' : 'block'
          }}
          onLoad={onIframeLoad}
        ></iframe>
      )}
    </>
  )
}

const useStyles = makeStyles(theme => ({
  filterContainer: {
    display: 'flex'
  },
  select: {
    width: '100%'
  },
  iframe: {
    border: 'none',
    padding: 0,
    width: '100%'
  },
  loaderWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  loader: {
    width: '80%'
  }
}))

export default Looker
