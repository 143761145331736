import React, { useEffect, useState } from 'react'
import {
  InputAdornment,
  FormControlLabel,
  TextField,
  Switch,
  Button,
  IconButton,
  Icon
} from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import { Link, useParams, useHistory } from 'react-router-dom'
import Select from 'react-select'
import Paper from '@material-ui/core/Paper'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'
import set from 'lodash/fp/set'
import { get, map, concat, slice, find, includes, gte } from 'lodash'
import Jsona from 'jsona'
import { useSnackbar } from 'notistack'
import http from '../../utils/http/local-with-errors'
import LoadingIndicator from '../../common/loading-indicator'
import Mastercard from '../../images/mastercard.svg'
import Visa from '../../images/visa.svg'

const apiFormatter = new Jsona()

const InquiryDefaultsResource = props => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const { id, action } = useParams()

  const [inquiryDefault, setInquiryDefault] = useState({
    amount: '',
    lower_amount: '',
    enabled: true,
    merchant_id: '',
    caid_id: '',
    mid_merchant_group_id: '',
    start_date: '-4713-11-24',
    end_date: '5874897-12-31',
    defaults: {
      phone: '',
      websiteLink: '',
      businessSummary: '',
      csInstructions: '',
      specialInstructions: '',
      consequence: '',
      storeAddress: '',
      storeCity: '',
      storeState: '',
      storePostal: '',
      storeCountry: '',
      storeLatitude: '',
      storeLongitude: '',
      refundEligible: false,
      refundSummary: '',
      refundLink: '',
      refundDetail: '',
      termsSummary: '',
      termsLink: '',
      termsDetail: '',
      proofConsent: '',
      cancellationSummary: '',
      cancellationLink: '',
      cancellationDetail: '',
      penaltyIndicator: false,
      penaltyDetail: '',
      items: []
    }
  })
  const [merchantOptions, setMerchantOptions] = useState([])
  const [merchant, setMerchant] = useState()
  const [caid, setCaid] = useState()
  const [loadingStatus, setLoadingStatus] = useState('loading')

  const saveInquiryDefault = async inquiryDefault => {
    setLoadingStatus('loading')
    const {
      data: { inquiryDefault: rawInquiryDefault, errorMessage },
      status
    } = await http({
      method: 'put',
      params: { inquiryDefault },
      url: `/inquiry-defaults/${id}.json`
    })
    setLoadingStatus('loaded')
    if (status === 200) {
      const inquiryDefault = apiFormatter.deserialize(rawInquiryDefault)
      setInquiryDefault(inquiryDefault)
      enqueueSnackbar('Successfully saved inquiry default', {
        variant: 'success'
      })
    } else {
      enqueueSnackbar(errorMessage, { variant: 'error' })
    }
  }

  const history = useHistory()
  const createInquiryDefault = async newInquiryDefault => {
    setLoadingStatus('loading')
    const {
      data: { inquiryDefault: rawInquiryDefault, errorMessage },
      status
    } = await http({
      method: 'post',
      params: { inquiryDefault: newInquiryDefault },
      url: '/inquiry-defaults'
    })
    setLoadingStatus('loaded')
    if (status === 201) {
      enqueueSnackbar('Successfully created inquiry default', {
        variant: 'success'
      })
      const inquiryDefault = apiFormatter.deserialize(rawInquiryDefault)
      setInquiryDefault(inquiryDefault)
      history.push(`/ui/inquiry-defaults/${inquiryDefault.id}/edit`)
    } else {
      enqueueSnackbar(errorMessage, { variant: 'error' })
    }
  }

  const cloneInquiryDefault = async cloneInquiryDefault => {
    setLoadingStatus('loading')
    const {
      data: { inquiryDefault: rawInquiryDefault, errorMessage },
      status
    } = await http({
      method: 'post',
      params: { inquiryDefault: cloneInquiryDefault },
      url: `/inquiry-defaults/${cloneInquiryDefault.id}/clone`
    })
    setLoadingStatus('loaded')
    if (status === 201) {
      enqueueSnackbar('Successfully cloned inquiry default', {
        variant: 'success'
      })
      const inquiryDefault = apiFormatter.deserialize(rawInquiryDefault)
      history.push(`/ui/inquiry-defaults/${inquiryDefault.id}/edit`)
    } else {
      enqueueSnackbar(errorMessage, { variant: 'error' })
    }
  }

  useEffect(() => {
    async function fetchMerchantOptions() {
      setLoadingStatus('loading')
      const {
        data: { merchants, errorMessage },
        status
      } = await http({
        url: '/admin/merchants/options.json'
      })
      if (status === 200) {
        setLoadingStatus('loaded')
        const merchantOptions = map(merchants, merchant => ({
          label: merchant.name,
          value: merchant.id
        }))
        setMerchantOptions(merchantOptions)
      } else {
        setLoadingStatus('error')
        errorMessage && enqueueSnackbar(errorMessage, { variant: 'error' })
      }
    }
    fetchMerchantOptions()
  }, [])

  useEffect(() => {
    async function getInquiryDefault() {
      setLoadingStatus('loading')
      const {
        data: { inquiryDefault: rawInquiryDefault, errorMessage },
        status
      } = await http({
        url: `/inquiry-defaults/${id}.json`
      })
      if (status === 200) {
        const inquiryDefault = apiFormatter.deserialize(rawInquiryDefault)
        setInquiryDefault(inquiryDefault)
        setLoadingStatus('loaded')
      } else {
        errorMessage && enqueueSnackbar(errorMessage, { variant: 'error' })
        setLoadingStatus('error')
      }
    }
    if (includes(['edit', 'clone'], action)) getInquiryDefault()
  }, [])

  useEffect(() => {
    if (inquiryDefault && merchantOptions) {
      const merchant = find(
        merchantOptions,
        option => option.value === inquiryDefault.merchant_id
      )
      setMerchant(merchant)
    }
  }, [inquiryDefault, merchantOptions])

  const [reloadingCaid, setReloadingCaid] = useState(true)
  const [caidOptions, setCaidOptions] = useState([])
  useEffect(() => {
    async function fetchCaidOptions() {
      setLoadingStatus('loading')
      const {
        data: { caids, errorMessage },
        status
      } = await http({
        url: '/inquiry-defaults/caid-options.json',
        params: {
          merchantId: merchant.value
        }
      })
      if (status === 200) {
        setLoadingStatus('loaded')
        const caidOptions = map(caids, caid => ({
          label: caid.caid,
          value: caid.id
        }))
        caidOptions.unshift({ label: 'None', value: '' })
        setCaidOptions(caidOptions)
        setReloadingCaid(false)
      } else {
        setLoadingStatus('error')
        errorMessage && enqueueSnackbar(errorMessage, { variant: 'error' })
      }
    }
    if (merchant && inquiryDefault && reloadingCaid) fetchCaidOptions()
  }, [reloadingCaid, merchant, inquiryDefault])

  useEffect(() => {
    if (inquiryDefault && caidOptions) {
      const caid = find(
        caidOptions,
        option => option.value === inquiryDefault.caid_id
      )
      setCaid(caid)
    }
  }, [inquiryDefault, caidOptions])

  const [midMerchantGroup, setMidMerchantGroup] = useState()
  const [reloadingMMG, setReloadingMMG] = useState(true)
  const [midMerchantGroupOptions, setMidMerchantGroupOptions] = useState([])
  useEffect(() => {
    async function fetchMidMerchantGroupOptions() {
      setLoadingStatus('loading')
      const {
        data: { mid_merchant_groups: midMerchantGroups, errorMessage },
        status
      } = await http({
        url: '/mid-merchant-groups/options.json',
        params: {
          merchantId: merchant.value
        }
      })
      if (status === 200) {
        setLoadingStatus('loaded')
        const midMerchantGroupOptions = map(midMerchantGroups, mmg => ({
          label: mmg.remote_id,
          value: mmg.id
        }))
        midMerchantGroupOptions.unshift({ label: 'None', value: '' })
        setMidMerchantGroupOptions(midMerchantGroupOptions)
        setReloadingMMG(false)
      } else {
        setLoadingStatus('error')
        errorMessage && enqueueSnackbar(errorMessage, { variant: 'error' })
      }
    }
    if (merchant && inquiryDefault && reloadingMMG)
      fetchMidMerchantGroupOptions()
  }, [reloadingMMG, merchant, inquiryDefault])

  useEffect(() => {
    if (inquiryDefault && midMerchantGroupOptions) {
      const midMerchantGroup = find(
        midMerchantGroupOptions,
        option => option.value === inquiryDefault.mid_merchant_group_id
      )
      setMidMerchantGroup(midMerchantGroup)
    }
  }, [inquiryDefault, midMerchantGroupOptions])

  const addItem = () => {
    updateInquiryDefault(
      'defaults.items',
      concat(get(inquiryDefault, 'defaults.items', []), [
        {
          seller: '',
          type: '',
          quantity: 0,
          price: 0.0,
          linkToItemSold: '',
          description: ''
        }
      ])
    )
  }

  const removeItem = index => {
    const items = get(inquiryDefault, 'defaults.items')
    const newItems = concat(slice(items, 0, index), slice(items, index + 1))
    updateInquiryDefault('defaults.items', newItems)
  }

  const updateInquiryDefault = (key, value) => {
    setInquiryDefault(prevInquiryDefault => {
      return set(key, value, prevInquiryDefault)
    })
  }

  const areAmountsValid = () =>
    inquiryDefault.amount && inquiryDefault.lower_amount
      ? gte(
          parseInt(inquiryDefault.amount),
          parseInt(inquiryDefault.lower_amount)
        )
      : true

  const atMostOneCaidMMG = () =>
    !(!!inquiryDefault.mid_merchant_group_id && !!inquiryDefault.caid_id)

  const isValid = () => areAmountsValid() && atMostOneCaidMMG()

  const actionBar = () => {
    switch (action) {
      case 'edit':
        return (
          <Button
            variant="contained"
            size="small"
            color="primary"
            className={classes.editBtn}
            onClick={() => saveInquiryDefault(inquiryDefault)}
            disabled={!isValid()}
          >
            Save
          </Button>
        )
      case 'create':
        return (
          <Button
            variant="contained"
            size="small"
            color="primary"
            className={classes.editBtn}
            onClick={() => createInquiryDefault(inquiryDefault)}
            disabled={!isValid()}
          >
            Create
          </Button>
        )
      case 'clone':
        return (
          <Button
            variant="contained"
            size="small"
            color="primary"
            className={classes.editBtn}
            onClick={() => cloneInquiryDefault(inquiryDefault)}
          >
            Clone
          </Button>
        )
    }
  }

  const renderContent = () => {
    switch (loadingStatus) {
      case 'loading':
        return <LoadingIndicator />
      case 'loaded':
        return (
          <>
            <Link to={'/ui/inquiry-defaults'}>{'<-- Back to index'}</Link>
            <h1>{'Inquiry Default'}</h1>
            <div className={classes.row}>
              <Select
                name="merchant_id"
                value={merchant}
                onChange={({ value }) => {
                  updateInquiryDefault('merchant_id', value)
                  updateInquiryDefault('caid_id', '')
                  setReloadingCaid(true)
                  setReloadingMMG(true)
                }}
                options={merchantOptions}
                className={classes.selects}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={!!inquiryDefault.enabled}
                    onChange={() => {
                      updateInquiryDefault(
                        'enabled',
                        !get(inquiryDefault, 'enabled')
                      )
                    }}
                    color="default"
                  />
                }
                label="Enabled"
              />
            </div>
            <div className={classes.row}>
              {caidOptions.length > 1 ? (
                <Select
                  name="caid_id"
                  value={caid}
                  onChange={({ value }) => {
                    updateInquiryDefault('caid_id', value)
                  }}
                  options={caidOptions}
                  className={classes.selects}
                />
              ) : (
                ''
              )}
              {midMerchantGroupOptions.length > 1 ? (
                <Select
                  name="mid_merchant_group_id"
                  value={midMerchantGroup}
                  onChange={({ value }) => {
                    updateInquiryDefault('mid_merchant_group_id', value)
                  }}
                  options={midMerchantGroupOptions}
                  className={classes.selects}
                />
              ) : (
                ''
              )}
            </div>
            <div className={classes.mainFilters}>
              <p>Specific dispute amount/range these settings apply to...</p>
              <div className={classes.row} style={{ alignItems: 'normal' }}>
                <TextField
                  id="lower_amount"
                  value={inquiryDefault.lower_amount}
                  label="Lower Amount"
                  variant="outlined"
                  error={!areAmountsValid()}
                  helperText="Lower amount must be less than amount"
                  fullWidth
                  onChange={event => {
                    updateInquiryDefault('lower_amount', event.target.value)
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    )
                  }}
                />
                <TextField
                  id="amount"
                  value={inquiryDefault.amount}
                  label="Upper Amount"
                  variant="outlined"
                  fullWidth
                  onChange={event => {
                    updateInquiryDefault('amount', event.target.value)
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    )
                  }}
                />
              </div>
              <div className={classes.row} style={{ alignItems: 'normal' }}>
                <TextField
                  id="start_date"
                  value={inquiryDefault.start_date}
                  label="Start Date"
                  placeholder="2020-01-01"
                  helperText="date format: YYYY-MM-DD"
                  variant="outlined"
                  fullWidth
                  onChange={event => {
                    updateInquiryDefault('start_date', event.target.value)
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon>calendar_today</Icon>
                      </InputAdornment>
                    )
                  }}
                />
                <TextField
                  id="end_date"
                  value={inquiryDefault.end_date}
                  label="End Date"
                  helperText="date format: YYYY-MM-DD"
                  placeholder="2030-03-01"
                  variant="outlined"
                  fullWidth
                  onChange={event => {
                    updateInquiryDefault('end_date', event.target.value)
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon>calendar_today</Icon>
                      </InputAdornment>
                    )
                  }}
                />
              </div>
            </div>
            <div className={classes.row}>
              <TextField
                id="phone"
                value={get(inquiryDefault, 'defaults.phone') || ''}
                label="Phone Number"
                helperText={
                  <>
                    <Mastercard className={classes.ccLogos} />
                    <Visa className={classes.ccLogos} />
                    <span>
                      If possible, we recommend setting up a priority line
                    </span>
                  </>
                }
                variant="outlined"
                fullWidth
                onChange={event => {
                  updateInquiryDefault('defaults.phone', event.target.value)
                }}
              />
              <TextField
                id="websiteLink"
                value={get(inquiryDefault, 'defaults.websiteLink') || ''}
                label="Website Link"
                helperText={
                  <>
                    <Mastercard className={classes.ccLogos} />
                    <Visa className={classes.ccLogos} />
                    <span>The front page of your e-commerce website</span>
                  </>
                }
                variant="outlined"
                fullWidth
                onChange={event => {
                  updateInquiryDefault(
                    'defaults.websiteLink',
                    event.target.value
                  )
                }}
              />
            </div>
            <div className={classes.row}>
              <TextField
                id="businessSummary"
                value={get(inquiryDefault, 'defaults.businessSummary') || ''}
                label="Business Summary"
                helperText={
                  <>
                    <Mastercard className={classes.ccLogos} />
                    <span>
                      Brief description of your business including how you
                      deliver products/services
                    </span>
                  </>
                }
                variant="outlined"
                multiline
                fullWidth
                onChange={event => {
                  updateInquiryDefault(
                    'defaults.businessSummary',
                    event.target.value
                  )
                }}
              />
            </div>
            <div className={classes.row}>
              <TextField
                id="csInstructions"
                value={get(inquiryDefault, 'defaults.csInstructions') || ''}
                label="Customer Service Instructions"
                helperText={
                  <>
                    <Mastercard className={classes.ccLogos} />
                    <span>
                      Statement instructing customers how they can resolve their
                      dispute without involving the bank (250 char.)
                    </span>
                  </>
                }
                variant="outlined"
                fullWidth
                multiline
                onChange={event => {
                  updateInquiryDefault(
                    'defaults.csInstructions',
                    event.target.value
                  )
                }}
              />
            </div>
            <div className={classes.row}>
              <TextField
                id="specialInstructions"
                value={
                  get(inquiryDefault, 'defaults.specialInstructions') || ''
                }
                label="Special Instructions"
                helperText={
                  <>
                    <Mastercard className={classes.ccLogos} />
                    <Visa className={classes.ccLogos} />
                    <span>
                      This field can be used to advise the cardholder and bank
                      about your policies (2000 char.)
                    </span>
                  </>
                }
                variant="outlined"
                fullWidth
                multiline
                onChange={event => {
                  updateInquiryDefault(
                    'defaults.specialInstructions',
                    event.target.value
                  )
                }}
              />
            </div>
            <div className={classes.row}>
              <TextField
                id="consequence"
                value={get(inquiryDefault, 'defaults.consequence') || ''}
                label="Consequence"
                helperText={
                  <>
                    <Mastercard className={classes.ccLogos} />
                    <span>
                      Inform the cardholder and issuing bank of the consequences
                      of the cardholder proceeding with the dispute
                    </span>
                  </>
                }
                variant="outlined"
                fullWidth
                multiline
                onChange={event => {
                  updateInquiryDefault(
                    'defaults.consequence',
                    event.target.value
                  )
                }}
              />
            </div>
            <h2>Store Location</h2>
            <div className={classes.row}>
              <TextField
                id="storeAddress"
                value={get(inquiryDefault, 'defaults.storeAddress') || ''}
                label="Street Address"
                variant="outlined"
                style={{ width: '350px' }}
                onChange={event => {
                  updateInquiryDefault(
                    'defaults.storeAddress',
                    event.target.value
                  )
                }}
              />
              <TextField
                id="storeCity"
                value={get(inquiryDefault, 'defaults.storeCity') || ''}
                label="City"
                variant="outlined"
                onChange={event => {
                  updateInquiryDefault('defaults.storeCity', event.target.value)
                }}
              />
              <TextField
                id="storeState"
                value={get(inquiryDefault, 'defaults.storeState') || ''}
                label="State"
                variant="outlined"
                style={{ width: '75px' }}
                onChange={event => {
                  updateInquiryDefault(
                    'defaults.storeState',
                    event.target.value
                  )
                }}
              />
              <TextField
                id="storePostal"
                value={get(inquiryDefault, 'defaults.storePostal') || ''}
                label="Postal Code"
                variant="outlined"
                style={{ width: '125px' }}
                onChange={event => {
                  updateInquiryDefault(
                    'defaults.storePostal',
                    event.target.value
                  )
                }}
              />
              <TextField
                id="storeCountry"
                value={get(inquiryDefault, 'defaults.storeCountry') || ''}
                label="Country"
                variant="outlined"
                onChange={event => {
                  updateInquiryDefault(
                    'defaults.storeCountry',
                    event.target.value
                  )
                }}
              />
            </div>
            <div className={classes.latLongRow}>
              <TextField
                id="storeLatitude"
                value={get(inquiryDefault, 'defaults.storeLatitude') || ''}
                label="Latitude"
                variant="outlined"
                style={{ width: '200px' }}
                onChange={event => {
                  updateInquiryDefault(
                    'defaults.storeLatitude',
                    event.target.value
                  )
                }}
              />
              <TextField
                id="storeLongitude"
                value={get(inquiryDefault, 'defaults.storeLongitude') || ''}
                label="Longitude"
                variant="outlined"
                style={{ width: '200px' }}
                onChange={event => {
                  updateInquiryDefault(
                    'defaults.storeLongitude',
                    event.target.value
                  )
                }}
              />
              <Mastercard className={classes.ccLogos} />
              <Visa className={classes.ccLogos} />
            </div>
            <h2>Refund Policy</h2>
            <div>
              <FormControlLabel
                control={
                  <Switch
                    checked={!!get(inquiryDefault, 'defaults.refundEligible')}
                    onChange={() => {
                      updateInquiryDefault(
                        'defaults.refundEligible',
                        !get(inquiryDefault, 'defaults.refundEligible')
                      )
                    }}
                    color="default"
                  />
                }
                label="Refund Eligible"
              />
              <div style={{ marginLeft: '14px' }}>
                <Mastercard className={classes.ccLogos} />
                <span>Is this order eligible for a refund? (yes/no)</span>
              </div>
            </div>
            <div className={classes.row}>
              <TextField
                id="refundSummary"
                value={get(inquiryDefault, 'defaults.refundSummary') || ''}
                label="Refund Summary"
                helperText={
                  <>
                    <Mastercard className={classes.ccLogos} />
                    <span>Brief overview of your refund policy</span>
                  </>
                }
                variant="outlined"
                fullWidth
                multiline
                onChange={event => {
                  updateInquiryDefault(
                    'defaults.refundSummary',
                    event.target.value
                  )
                }}
              />
            </div>
            <div className={classes.row}>
              <TextField
                id="refundLink"
                value={get(inquiryDefault, 'defaults.refundLink') || ''}
                label="Refund Link"
                helperText={
                  <>
                    <Mastercard className={classes.ccLogos} />
                    <Visa className={classes.ccLogos} />
                    <span>Link to the refund policies on your website</span>
                  </>
                }
                variant="outlined"
                fullWidth
                onChange={event => {
                  updateInquiryDefault(
                    'defaults.refundLink',
                    event.target.value
                  )
                }}
              />
            </div>
            <div className={classes.row}>
              <TextField
                id="refundDetail"
                value={get(inquiryDefault, 'defaults.refundDetail') || ''}
                label="Refund Detail"
                helperText={
                  <>
                    <Mastercard className={classes.ccLogos} />
                    <Visa className={classes.ccLogos} />
                    <span>
                      Full text of any portion of your refund policy that would
                      apply to this dispute
                    </span>
                  </>
                }
                variant="outlined"
                fullWidth
                multiline
                onChange={event => {
                  updateInquiryDefault(
                    'defaults.refundDetail',
                    event.target.value
                  )
                }}
              />
            </div>
            <h2>Terms & Conditions</h2>
            <div className={classes.row}>
              <TextField
                id="termsSummary"
                value={get(inquiryDefault, 'defaults.termsSummary') || ''}
                label="T&C Summary"
                helperText={
                  <>
                    <Mastercard className={classes.ccLogos} />
                    <span>Brief overview of your terms & conditions</span>
                  </>
                }
                variant="outlined"
                fullWidth
                multiline
                onChange={event => {
                  updateInquiryDefault(
                    'defaults.termsSummary',
                    event.target.value
                  )
                }}
              />
            </div>
            <div className={classes.row}>
              <TextField
                id="termsLink"
                value={get(inquiryDefault, 'defaults.termsLink') || ''}
                label="T&C Link"
                helperText={
                  <>
                    <Mastercard className={classes.ccLogos} />
                    <Visa className={classes.ccLogos} />
                    <span>Link to the terms & conditions on your website</span>
                  </>
                }
                variant="outlined"
                fullWidth
                onChange={event => {
                  updateInquiryDefault('defaults.termsLink', event.target.value)
                }}
              />
            </div>
            <div className={classes.row}>
              <TextField
                id="termsDetail"
                value={get(inquiryDefault, 'defaults.termsDetail') || ''}
                label="T&C Detail"
                helperText={
                  <>
                    <Mastercard className={classes.ccLogos} />
                    <Visa className={classes.ccLogos} />
                    <span>
                      Full text of any portion of your terms & conditions that
                      would apply to this dispute
                    </span>
                  </>
                }
                variant="outlined"
                fullWidth
                multiline
                onChange={event => {
                  updateInquiryDefault(
                    'defaults.termsDetail',
                    event.target.value
                  )
                }}
              />
            </div>
            <h2>Proof of Consent</h2>
            <div className={classes.row}>
              <TextField
                id="proofConsent"
                value={get(inquiryDefault, 'defaults.proofConsent') || ''}
                label="Proof of Consent"
                helperText={
                  <>
                    <Mastercard className={classes.ccLogos} />
                    <span>
                      Describe when and how the customer agreed to the terms and
                      conditions at checkout
                    </span>
                  </>
                }
                variant="outlined"
                fullWidth
                multiline
                onChange={event => {
                  updateInquiryDefault(
                    'defaults.proofConsent',
                    event.target.value
                  )
                }}
              />
            </div>
            <h2>Cancellation Policy</h2>
            <div className={classes.row}>
              <TextField
                id="cancellationSummary"
                value={
                  get(inquiryDefault, 'defaults.cancellationSummary') || ''
                }
                label="Cancellation Policy Summary"
                helperText={
                  <>
                    <Mastercard className={classes.ccLogos} />
                    <span>Brief overview of your cancellation policy</span>
                  </>
                }
                variant="outlined"
                fullWidth
                multiline
                onChange={event => {
                  updateInquiryDefault(
                    'defaults.cancellationSummary',
                    event.target.value
                  )
                }}
              />
            </div>
            <div className={classes.row}>
              <TextField
                id="cancellationLink"
                value={get(inquiryDefault, 'defaults.cancellationLink') || ''}
                label="Cancellation Policy Link"
                helperText={
                  <>
                    <Mastercard className={classes.ccLogos} />
                    <Visa className={classes.ccLogos} />
                    <span>Link to the cancellation policy on your website</span>
                  </>
                }
                variant="outlined"
                fullWidth
                onChange={event => {
                  updateInquiryDefault(
                    'defaults.cancellationLink',
                    event.target.value
                  )
                }}
              />
            </div>
            <div className={classes.row}>
              <TextField
                id="cancellationDetail"
                value={get(inquiryDefault, 'defaults.cancellationDetail') || ''}
                label="Cancellation Policy Detail"
                helperText={
                  <>
                    <Mastercard className={classes.ccLogos} />
                    <Visa className={classes.ccLogos} />
                    <span>
                      Full text of any portion of your cancellation policy that
                      would apply to this dispute
                    </span>
                  </>
                }
                variant="outlined"
                fullWidth
                multiline
                onChange={event => {
                  updateInquiryDefault(
                    'defaults.cancellationDetail',
                    event.target.value
                  )
                }}
              />
            </div>
            <h2>Cancellation Penalty (subscriptions)</h2>
            <div>
              <FormControlLabel
                control={
                  <Switch
                    checked={!!get(inquiryDefault, 'defaults.penaltyIndicator')}
                    onChange={() => {
                      if (get(inquiryDefault, 'defaults.penaltyIndicator')) {
                        updateInquiryDefault('defaults.penaltyDetail', '')
                        updateInquiryDefault('defaults.penaltyIndicator', false)
                      } else {
                        updateInquiryDefault('defaults.penaltyIndicator', true)
                      }
                    }}
                    color="default"
                  />
                }
                label="Cancellation Penalty?"
              />
              <div style={{ marginLeft: '14px' }}>
                <Mastercard className={classes.ccLogos} />
                <span>Will a cancellation penalty be applied?</span>
              </div>
            </div>
            {get(inquiryDefault, 'defaults.penaltyIndicator') ? (
              <div className={classes.row}>
                <TextField
                  id="penaltyDetail"
                  value={get(inquiryDefault, 'defaults.penaltyDetail') || ''}
                  label="Cancelation Penalty (subscription)"
                  helperText={
                    <>
                      <Mastercard className={classes.ccLogos} />
                      <span>
                        Details about the consequences of a customer’s
                        subscription or account being cancelled
                      </span>
                    </>
                  }
                  variant="outlined"
                  fullWidth
                  multiline
                  onChange={event => {
                    updateInquiryDefault(
                      'defaults.penaltyDetail',
                      event.target.value
                    )
                  }}
                />
              </div>
            ) : (
              ''
            )}
            <h2>Items (products or services sold)</h2>
            <div>
              {map(get(inquiryDefault, 'defaults.items'), (item, index) => (
                <div key={index} className={classes.itemRowWrapper}>
                  <div>
                    <div className={classes.itemRow}>
                      <TextField
                        id="seller"
                        value={item.seller}
                        label="Artist or Seller"
                        variant="outlined"
                        onChange={event => {
                          updateInquiryDefault(
                            `defaults.items[${index}].seller`,
                            event.target.value
                          )
                        }}
                      />
                      <TextField
                        id="type"
                        value={item.type}
                        label="Item Type"
                        variant="outlined"
                        onChange={event => {
                          updateInquiryDefault(
                            `defaults.items[${index}].type`,
                            event.target.value
                          )
                        }}
                      />
                      <TextField
                        id="quantity"
                        value={item.quantity}
                        className={classes.itemRowNumber}
                        label="Quantity"
                        variant="outlined"
                        onChange={event => {
                          updateInquiryDefault(
                            `defaults.items[${index}].quantity`,
                            event.target.value
                          )
                        }}
                      />
                      <TextField
                        id="price"
                        value={item.price}
                        className={classes.itemRowNumber}
                        label="Price"
                        variant="outlined"
                        onChange={event => {
                          updateInquiryDefault(
                            `defaults.items[${index}].price`,
                            event.target.value
                          )
                        }}
                      />
                      <TextField
                        id="linkToItemSold"
                        value={item.linkToItemSold}
                        className={classes.itemRowLarge}
                        label="Link to item sold"
                        variant="outlined"
                        onChange={event => {
                          updateInquiryDefault(
                            `defaults.items[${index}].linkToItemSold`,
                            event.target.value
                          )
                        }}
                      />
                    </div>
                    <TextField
                      id="description"
                      value={item.description}
                      label="Item Description"
                      variant="outlined"
                      fullWidth
                      onChange={event => {
                        updateInquiryDefault(
                          `defaults.items[${index}].description`,
                          event.target.value
                        )
                      }}
                    />
                  </div>
                  <div>
                    <IconButton onClick={() => removeItem(index)}>
                      <Icon style={{ color: red[500] }}>delete</Icon>
                    </IconButton>
                  </div>
                </div>
              ))}
              <Button
                variant="contained"
                size="small"
                color="primary"
                className={classes.editBtn}
                onClick={addItem}
              >
                Add Item
              </Button>
            </div>
            <div className={classes.actionBar}>{actionBar()}</div>
          </>
        )
      case 'error':
        return <div>There was a problem</div>
    }
  }

  return (
    <Container maxWidth="xl">
      <Paper className={classes.paper}>{renderContent()}</Paper>
    </Container>
  )
}

const useStyles = makeStyles(theme => ({
  paper: {
    padding: 24,
    '& h2': {
      borderBottom: 'solid 1px black'
    },
    '& h1': {
      textTransform: 'capitalize'
    }
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    margin: '12px 0',
    '& > *': {
      flexGrow: 1,
      marginRight: 8
    },
    '& > *:last-child': {
      marginRight: 0
    }
  },
  latLongRow: {
    display: 'flex',
    alignItems: 'center',
    margin: '12px 0',
    '& > *': {
      flexGrow: 0,
      marginRight: 8
    }
  },
  itemRowWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 24,
    '& div:first-child': {
      flexGrow: 1
    }
  },
  itemRow: {
    '& div': {
      flexGrow: 1,
      flexShring: 1,
      marginRight: 10
    },
    '& div:last-child': {
      marginRight: 0
    },
    display: 'flex',
    marginBottom: 10
  },
  itemRowNumber: {
    flexBasis: 60,
    flexGrow: 0
  },
  itemRowLarge: {
    flexBasis: 300
  },
  mainFilters: {
    backgroundColor: theme.custom.columns.bgColors.lightGrey,
    padding: '1px 20px'
  },
  enabledSwitch: {
    color: theme.custom.columns.bgColors.green
  },
  ccLogos: {
    height: 9,
    marginRight: 10
  },
  actionBar: {
    display: 'flex',
    flexFlow: 'row-reverse'
  },
  selects: {
    zIndex: 100
  }
}))

export default InquiryDefaultsResource
