import React from 'react'
import { createRoot } from 'react-dom/client'
import { flushSync } from 'react-dom'
import {
  LightbulbFilled,
  AddImage,
  VerificationWarningFilled
} from '@siftscience/focus-components/icons'
import { Text } from '@siftscience/focus-components/text'

const optionalImagePlaceholder = document.createElement('div')
const optionalImagePlaceholderRoot = createRoot(optionalImagePlaceholder)

const requiredImagePlaceholder = document.createElement('div')
const requiredImagePlaceholderRoot = createRoot(requiredImagePlaceholder)
flushSync(() => {
  optionalImagePlaceholderRoot.render(
    <div className="det-image-placeholder">
      <div className="det-image-placeholder-icon">
        <LightbulbFilled color="#F2B200" />
      </div>
      <div className="det-image-placeholder-middle-section">
        <AddImage />
        <Text size="xsmall">Add image</Text>
      </div>
    </div>
  )

  requiredImagePlaceholderRoot.render(
    <div className="det-image-placeholder">
      <div className="det-image-placeholder-icon">
        <VerificationWarningFilled color="#EB3B2E" />
      </div>
      <div className="det-image-placeholder-middle-section">
        <AddImage />
        <Text size="xsmall">Add image</Text>
      </div>
    </div>
  )
})

export const OPTIONAL_IMAGE_PLACEHOLDER = optionalImagePlaceholder.innerHTML
export const REQUIRED_IMAGE_PLACEHOLDER = requiredImagePlaceholder.innerHTML

export const insertIntoDiv = (
  initialString: string,
  insertString: string,
  selector: string
) => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(initialString, 'text/html')

  const divElements = doc.querySelectorAll(selector)
  if (divElements && divElements.length) {
    divElements.forEach(div => {
      div.innerHTML = insertString
    })
  }

  const modifiedHtmlString = doc.body.innerHTML
  return modifiedHtmlString
}

export const insertImagePlaceholders = (initialString: string): string => {
  const withRequiredImages = insertIntoDiv(
    initialString,
    REQUIRED_IMAGE_PLACEHOLDER,
    '.det-data-image-field.missing.required'
  )
  const withOptionalAndRequiredImages = insertIntoDiv(
    withRequiredImages,
    OPTIONAL_IMAGE_PLACEHOLDER,
    '.det-data-image-field.optional.required'
  )

  return withOptionalAndRequiredImages
}

export const findImageElement = (
  event,
  searchableClass: string,
  classNameToStop: string
) => {
  const clickedElement = event.target

  if (clickedElement) {
    let currentElement = clickedElement
    let parentWithSearchableClass = null

    // Traverse up the DOM tree
    while (currentElement) {
      // Check if parent has searchableClass
      if (currentElement.classList.contains(searchableClass)) {
        parentWithSearchableClass = currentElement
        break
      }

      // Check if parent has classNameToStop. It means that we reached the end
      // of searchable area which we narrowed beforehand.
      if (currentElement.classList.contains(classNameToStop)) {
        break
      }

      // Move to parent element
      currentElement = currentElement.parentElement
    }

    // Check if parent with class "class1" is found
    if (parentWithSearchableClass) {
      return parentWithSearchableClass
    }
  }

  return null
}
