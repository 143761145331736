import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Paper from '@material-ui/core/Paper'
import Container from '@material-ui/core/Container'
import Select from 'react-select'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import { Button, IconButton, Icon } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { map } from 'lodash'
import { useSnackbar } from 'notistack'
import Jsona from 'jsona'

import http from '../../utils/http/local-with-errors'

const apiFormatter = new Jsona()

const CaidsIndex = props => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  const [clientOptions, setClientOptions] = useState([])
  const defaultClientOption = {
    label: 'Select a client',
    value: ''
  }
  const [client, setClient] = useState(defaultClientOption)
  const [caidCaidsOptions, setCaidCaidsOptions] = useState([])
  const defaultCaidCaidOption = {
    label: 'Select a caid',
    value: ''
  }
  const [caidCaidOption, setCaidCaidOption] = useState(defaultCaidCaidOption)

  useEffect(() => {
    async function fetchAllCaidCaids() {
      const {
        data: { caidCaids, errorMessage, redirect_url: redirectUrl },
        status
      } = await http({
        url: '/caids/caids.json'
      })
      if (status === 200) {
        const caidCaidsOptions = map(caidCaids, caidCaid => ({
          label: caidCaid,
          value: caidCaid
        }))

        caidCaidsOptions.unshift(defaultCaidCaidOption)
        setCaidCaidsOptions(caidCaidsOptions)
      } else {
        errorMessage && enqueueSnackbar(errorMessage, { variant: 'error' })
        window.location.href = redirectUrl
      }
    }
    fetchAllCaidCaids()
  }, [])

  useEffect(() => {
    async function fetchClientOptions() {
      const {
        data: { clients, errorMessage },
        status
      } = await http({
        url: '/clients/options.json'
      })
      if (status === 200) {
        const clientOptions = map(clients, client => ({
          label: client.name,
          value: client.id
        }))
        clientOptions.unshift(defaultClientOption)
        setClientOptions(clientOptions)
      } else {
        errorMessage && enqueueSnackbar(errorMessage, { variant: 'error' })
      }
    }
    fetchClientOptions()
  }, [])

  const [caids, setCaids] = useState([])
  const fetchCaids = async (searchTerm, value) => {
    const {
      data: { caids: rawCaids, errorMessage },
      status
    } = await http({
      url: `/caids.json?${searchTerm}=${value}`
    })
    if (status === 200) {
      const caids = apiFormatter.deserialize(rawCaids)
      setCaids(caids)
    } else {
      errorMessage && enqueueSnackbar(errorMessage, { variant: 'error' })
    }
  }
  useEffect(() => {
    if (client.value !== '') {
      setCaidCaidOption(defaultCaidCaidOption)
      fetchCaids('clientId', client.value)
    }
  }, [client])
  useEffect(() => {
    if (caidCaidOption.value !== '') {
      setClient(defaultClientOption)
      fetchCaids('caidCaid', caidCaidOption.value)
    }
  }, [caidCaidOption])

  const history = useHistory()
  const createNew = () => {
    history.push('/ui/caids/create')
  }

  const deleteCaid = async caidId => {
    const { status } = await http({
      method: 'delete',
      validateStatus: status => status < 500,
      url: `/caids/${caidId}`
    })
    if (status === 204) {
      enqueueSnackbar('Successfully deleted caid', {
        variant: 'success'
      })
      setClient({ ...client })
    } else {
      enqueueSnackbar("There was an issue and we've been nontified", {
        variant: 'error'
      })
    }
  }

  return (
    <Container maxWidth="xl">
      <Paper className={classes.root}>
        <div className={classes.searchNav}>
          <Select
            name="clientId"
            value={client}
            onChange={setClient}
            options={clientOptions}
            className={classes.clientOptions}
          />
          <div>- Or -</div>
          <Select
            name="caidCaidOption"
            value={caidCaidOption}
            onChange={setCaidCaidOption}
            options={caidCaidsOptions}
            className={classes.clientOptions}
          />
          <IconButton onClick={createNew}>
            <Icon color="primary">add_circle_outline</Icon>
          </IconButton>
        </div>
        {caids.length > 0 ? (
          <>
            <h1>Listing caids for client {client.label}</h1>
            <div>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>Merchant</TableCell>
                    <TableCell>Mid</TableCell>
                    <TableCell>BIN (Bank Identification Num)</TableCell>
                    <TableCell>CAID (Card Acceptor ID)</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {map(caids, caid => (
                    <TableRow key={caid.id}>
                      <TableCell>{caid.merchant_name}</TableCell>
                      <TableCell>{caid.mid_id}</TableCell>
                      <TableCell>{caid.bin}</TableCell>
                      <TableCell>{caid.caid}</TableCell>
                      <TableCell>
                        <Link to={`/ui/caids/${caid.id}/edit`}>
                          <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            className={classes.editBtn}
                          >
                            Edit
                          </Button>
                        </Link>
                        <Button
                          variant="contained"
                          size="small"
                          className={classes.deleteBtn}
                          onClick={() => {
                            const resp = confirm(
                              'Are you sure you want to delete?'
                            )
                            if (resp) deleteCaid(caid.id)
                          }}
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </>
        ) : (
          ''
        )}
      </Paper>
    </Container>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  searchNav: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 8,
    height: 60,
    justifyContent: 'space-between'
  },
  table: {
    '& tr td': {
      lineHeight: '42px'
    },
    '& tr th': {
      lineHeight: '42px'
    }
  },
  clientOptions: {
    width: 400
  }
}))

export default CaidsIndex
