import React from 'react'
import HeaderMenu from './header-menu'

import SidekiqIcon from '@material-ui/icons/HourglassEmpty'
import FlipperIcon from '@material-ui/icons/Compare'
import UploadMappingsIcon from '@material-ui/icons/Cake'
import CaidsIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import MMGIcon from '@material-ui/icons/HotTub'
import DescriptorsIcon from '@material-ui/icons/Landscape'
import UserIcon from '@material-ui/icons/SupervisedUserCircle'
import EvidenceTemplatesIcon from '@material-ui/icons/Description'
import WebhooksIcon from '@material-ui/icons/RvHookup'

const menuItems = [
  {
    href: '/admin/descriptors',
    text: 'Descriptors',
    icon: <DescriptorsIcon />
  },
  { href: '/admin/jobs', text: 'Sidekiq', icon: <SidekiqIcon /> },
  { href: '/admin/flipper', text: 'Flipper', icon: <FlipperIcon /> },
  {
    href: '/admin/flipper_histories',
    text: 'Flipper history',
    icon: <FlipperIcon />
  },
  {
    href: '/admin/webhooks_subscriptions',
    text: 'Webhooks',
    icon: <WebhooksIcon />
  },
  { href: '/api_users', text: 'API Tokens', icon: <UserIcon /> },
  {
    href: '/ui/evidence_configuration',
    text: 'Evidence Configuration',
    icon: <EvidenceTemplatesIcon />
  },
  {
    href: '/upload_mappings',
    text: 'Upload Mappings',
    icon: <UploadMappingsIcon />
  },
  { href: '/ui/caids', text: 'CAIDs', icon: <CaidsIcon /> },
  {
    href: '/ui/mid-merchant-groups',
    text: 'Mid Merchant Groups',
    icon: <MMGIcon />
  }
]

const TOOLTIP_TITLE = 'Developer menu'

const DeveloperMenu = () => (
  <HeaderMenu
    icon="code"
    menuItems={menuItems}
    popupId="developer"
    tooltipTitle={TOOLTIP_TITLE}
  />
)

export default DeveloperMenu
