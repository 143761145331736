import React, { useState, useContext, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import NoteAddTwoToneIcon from '@material-ui/icons/NoteAddTwoTone'
import Select from 'react-select'
import NotesDialog from './notes-dialog'
import { useSnackbar } from 'notistack'
import { updateAssignments } from './update-assignments'

import {
  DispatchCheckboxContext,
  CheckboxContext
} from '../../common/list-view/common/checkboxes/checkbox-context'
import { CHECKBOX_TYPE } from '../../common/list-view/common/checkboxes/checkbox-reducer'

const MassActions = props => {
  const { refetch, setShowMassActions, data, selectOptions } = props
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [outcome, setOutcome] = useState('')
  const [showNoteDialog, setShowNoteDialog] = useState(false)
  const dispatchCheckboxes = useContext(DispatchCheckboxContext)
  const checkboxes = useContext(CheckboxContext)
  const count = checkboxes.selectedIds.length
  const [outcomeOptions, setOutcomeOptions] = useState(
    selectOptions.data.data['outcome']
  )

  useEffect(() => {
    if (checkboxes.selectedIds && checkboxes.selectedIds.length) {
      const selectedAlerts = data.data.filter(alert =>
        checkboxes.selectedIds.includes(String(alert.id))
      )

      const selectedPortals = new Set(
        selectedAlerts.map(alert => alert.alertPortalId)
      )
      const selectedTypes = new Set(
        selectedAlerts.map(alert => alert.alertType)
      )

      // if one portal is selected, show only the outcomes that go with that portal
      if (selectedPortals.size === 1) {
        setOutcomeOptions(
          selectOptions.data.data['outcome'].filter(
            outcome =>
              outcome.alert_portal_id ===
                selectedPortals.values().next().value &&
              outcome.alert_types.filter(alertType =>
                selectedTypes.has(alertType)
              ).length
          )
        )
        // if two portals are selected, show no outcomes
      } else {
        setOutcomeOptions([])
      }
    }
  }, [checkboxes.selectedIds])

  const saveAssignmentsForm = async () => {
    if (!hasValuesToUpdate(outcome)) {
      enqueueSnackbar('Nothing was selected!', { variant: 'warning' })
      return false
    }

    await updateAssignments(checkboxes.selectedIds, outcome.value)
    enqueueSnackbar(`${count} Alert${count > 1 ? 's' : ''} updated.`, {
      variant: 'success'
    })
    clearForm()
    setShowMassActions(false)
    refetch()
  }

  const hasValuesToUpdate = outcome => {
    return !!(outcome && outcome.value)
  }

  const closeMassActions = () => {
    clearForm()
    dispatchCheckboxes({ type: CHECKBOX_TYPE.toggleAll, checked: false })
  }

  const clearForm = () => {
    setOutcome('')
  }

  return (
    <div id="mass-actions" className={classes.container}>
      <div className={classes.actions}>
        <Tooltip title="Add a note">
          <IconButton onClick={() => setShowNoteDialog(true)}>
            <NoteAddTwoToneIcon />
          </IconButton>
        </Tooltip>

        <NotesDialog
          showNoteDialog={showNoteDialog}
          setShowNoteDialog={setShowNoteDialog}
          checkboxes={checkboxes}
        />
      </div>

      <div className={classes.form}>
        <Select
          name="outcome"
          placeholder="Outcome"
          value={outcome}
          options={outcomeOptions}
          onChange={value => setOutcome(value || '')}
          isLoading={outcomeOptions.isLoading}
          className={classes.selects}
          isClearable={true}
        />
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={saveAssignmentsForm}
          className={classes.apply}
        >
          Apply
        </Button>
        <Button
          variant="contained"
          size="small"
          color="default"
          onClick={closeMassActions}
        >
          Quit
        </Button>
      </div>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    zIndex: 20
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-evenly'
  },
  form: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%'
  },
  tags: {
    marginRight: 5,
    width: '100%'
  },
  selects: {
    marginRight: 5,
    width: 300
  },
  apply: {
    marginRight: 5
  }
}))

export default MassActions
