import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { DialogContent, DialogContentText } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'

const ExtendDialog = props => {
  const { handleExtend, timeRemainingMessage } = props
  const classes = useStyles()

  return (
    <>
      {timeRemainingMessage.length ? (
        <Dialog
          open={true}
          onClose={handleExtend}
          fullWidth={true}
          className={classes.dialog}
        >
          <DialogContent>
            <DialogContentText>
              You will be logged out due to inactivity in
            </DialogContentText>
            <DialogContentText className={classes.countdown}>
              {timeRemainingMessage}
            </DialogContentText>
            <DialogContentText>
              Move your mouse to extend your session.
            </DialogContentText>
          </DialogContent>
        </Dialog>
      ) : null}
    </>
  )
}

const useStyles = makeStyles({
  dialog: {
    padding: '20px',
    textAlign: 'center'
  },
  actionContainer: {
    justifyContent: 'center',
    paddingBottom: '25px'
  },
  countdown: {
    color: 'red'
  }
})

export default ExtendDialog
