import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import { Helmet } from 'react-helmet'
import { makeStyles } from '@material-ui/core/styles'
import Header from './header'
import DeliveryFieldsList from './delivery-fields-list'
import DETNavigation from './navigation'
import LeftSidebar from './left-sidebar'
import FinalizeModal from './finalize-modal'
import FinalizePreview from './finalize-preview'
import EditModal from './edit-modal'
import { DETProvider } from './context'
import Styles from './styles'
import capitalize from '../../utils/capitalize'
import { DisputeDTO, ImageDTO, LifecycleStatus, RenderingDTO } from './dtos'
import { ILockedState } from './interfaces'
import { DataFieldType, Filters, Tabs } from './enums'
import { getRenderingsToDisplay } from '../../utils/det'
import { useDetData } from './useDet'
import { useRenderingsContext } from './context/renderings-context'
import { useDatasetContext } from './context/dataset-context'
import { useDisputeContext } from './context/dispute-context'
import { useSavingContext } from './context/saving-context'

const HEADER_HEIGHT = 64
const DEFAULT_FILTER_INDEX = Filters.Incomplete
const DEFAULT_TAB_INDEX = Tabs.Rendered

interface ModalState {
  rendering?: RenderingDTO
  fieldName?: string
  isArray?: boolean
}

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    minWidth: '100vw'
  },
  leftSide: {
    display: 'flex',
    flexDirection: 'column',
    width: '360px',
    height: '100%',
    minHeight: '100vh',
    padding: '16px 0px 16px 0px',
    backgroundColor: 'white', // TODO check for proper bg color const
    zIndex: 0
  },
  centralArea: {
    width: '100%'
  },
  centralAreaMain: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '30px',
    width: '100%',
    paddingLeft: '48px'
  },
  finalizedPreview: {
    marginTop: '16px',
    marginLeft: '48px'
  }
}))

const DETEdit = (): React.ReactElement => {
  const [changes, setChanges] = useState<Record<string, string | ImageDTO>>({})
  const [lockedStatus, setLockedStatus] = useState<ILockedState>({})
  const [isManualDelivery, setIsManualDelivery] = useState<boolean>(false)
  const [manuallySubmittedStatus, setManuallySubmittedStatus] =
    useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [selectedTab, setSelectedTab] = useState<Tabs>(DEFAULT_TAB_INDEX)
  const [modalState, setModalState] = useState<ModalState | null>(null)
  const [selectedFilter, setSelectedFilter] =
    useState<Filters>(DEFAULT_FILTER_INDEX)
  const [isFinalizedModalOpen, setIsFinalizedModalOpen] = useState(false)
  const listRefs = useRef<Record<string, MutableRefObject<HTMLElement>>>({})
  const { disputeId, dispute } = useDisputeContext()
  const { isDETLoading } = useDetData(disputeId, manuallySubmittedStatus)
  const {
    renderingsData,
    renderingsHashData,
    setDisplayRenderingsData,
    stylesFromBe
  } = useRenderingsContext()
  const { setDataset } = useDatasetContext()
  const { saveChanges } = useSavingContext()
  const classes = useStyles()

  const disableEditing = (dispute: DisputeDTO) => {
    const lockedStatus: ILockedState = {}
    if (dispute.locked) {
      lockedStatus.locked = true
    }

    if (dispute.expired) {
      lockedStatus.expired = true
    }

    if (dispute.lifecycleStatus === LifecycleStatus.acceptLiability) {
      lockedStatus.acceptedLiability = true
    }

    setLockedStatus(lockedStatus)
  }

  const disableManualSubmit = (dispute: DisputeDTO) => {
    if (
      dispute.manualDelivery &&
      dispute.lifecycleStatus === LifecycleStatus.documentSent
    ) {
      setManuallySubmittedStatus(true)
    }
  }

  useEffect(() => {
    if (!isDETLoading && dispute) {
      disableEditing(dispute)
      disableManualSubmit(dispute)
      setIsManualDelivery(dispute.manualDelivery)
    }
  }, [isDETLoading, dispute])

  useEffect(() => {
    // filter display renderings after user clicks on filter.
    // It's done through useEffect, because Tab component from
    // focus-components library has onSelect memoization....
    const displayRenderings = getRenderingsToDisplay(
      selectedFilter,
      renderingsData,
      renderingsHashData,
      lockedStatus
    )
    setDisplayRenderingsData(displayRenderings)
  }, [
    renderingsData,
    renderingsHashData,
    setDisplayRenderingsData,
    selectedFilter,
    lockedStatus
  ])

  useEffect(() => {
    if (
      lockedStatus?.locked ||
      lockedStatus?.expired ||
      lockedStatus?.acceptedLiability
    ) {
      setSelectedTab(Tabs.Rendered)
    }
  }, [lockedStatus])

  const onFilterSelect = (filter: Filters, index: number) => {
    setSelectedFilter(index)
  }

  const onTabSelect = (tabs: Tabs, index: number) => {
    setSelectedTab(index)
  }

  const onCloseModal = useCallback(() => {
    setChanges({})
    setModalState(null)
  }, [setChanges, setModalState])

  const onDataFieldClick = (
    rendering: RenderingDTO,
    fieldName: string,
    isArray?: boolean
  ) => {
    setModalState({ rendering, fieldName, isArray })
  }

  const onDatasetChange = async (
    key: string,
    value: string | ImageDTO,
    renderingId: number
  ) => {
    if ((value as ImageDTO)?.type === DataFieldType.IMAGE) {
      // custom logic for uploading images. In modal each image is uploaded at
      // the moment of time when the user selected it, not when the user clicks
      // on 'Save' button in the modal.
      return saveChanges({ [key]: value }, disputeId, renderingId, setDataset)
    } else {
      setChanges(prevState => ({ ...prevState, [key]: value }))
    }
  }

  const onDatasetChangeAutoSave = async (
    key: string,
    value: string | ImageDTO,
    renderingId: number
  ) => {
    // triggered by the user losing focus of field on Data Entry view
    return saveChanges({ [key]: value }, disputeId, renderingId, setDataset)
  }

  const onSetListRef = (
    rendering: RenderingDTO,
    ref: MutableRefObject<HTMLElement>
  ) => {
    listRefs.current[rendering.templateId] = ref
  }

  const onNavigationItemClick = (rendering: RenderingDTO) => {
    const ref = listRefs?.current?.[rendering.templateId]?.current
    if (ref) {
      // Offset inside the viewport
      const viewPortOffset = ref.getBoundingClientRect().top - HEADER_HEIGHT
      // How much user already scrolled
      const scrollTop = window.scrollY

      const offset = viewPortOffset + scrollTop
      window.scrollTo({ top: offset, behavior: 'smooth' })

      // Highlight rendering which we are scrolling to
      ref.style.borderColor = '#295ded'
      setTimeout(() => {
        ref.style.borderColor = ''
      }, 2000)
    }
  }

  return (
    <>
      <Helmet>
        <title>Sift | {capitalize('DET')}</title>
      </Helmet>
      <Styles styles={stylesFromBe} />
      <div className={classes.wrapper}>
        <div className={`shadow-s ${classes.leftSide}`}>
          <LeftSidebar isLoading={isDETLoading || isLoading} />
        </div>
        <div className={classes.centralArea}>
          <Header
            onFilterSelect={onFilterSelect}
            selectedFilter={selectedFilter}
            onFinalize={() => setIsFinalizedModalOpen(true)}
            selectedTab={selectedTab}
            onSelectTab={onTabSelect}
            lockedStatus={lockedStatus}
            disableEditing={disableEditing}
            setPageLoading={setIsLoading}
            disableManualSubmit={disableManualSubmit}
            isManualDelivery={isManualDelivery}
            manuallySubmittedStatus={manuallySubmittedStatus}
          />
          <div className={classes.centralAreaMain}>
            <div>
              <DeliveryFieldsList
                isLoading={isDETLoading || isLoading}
                selectedTab={selectedTab}
                onDataFieldClick={onDataFieldClick}
                onSetListRef={onSetListRef}
                onDatasetChange={onDatasetChangeAutoSave}
              />
            </div>
            <DETNavigation
              onNavigationItemClick={onNavigationItemClick}
              selectedFilter={selectedFilter}
              lockedStatus={lockedStatus}
            />
          </div>
          {lockedStatus?.locked && !lockedStatus.acceptedLiability ? (
            <div className={classes.finalizedPreview}>
              <FinalizePreview isParentLoading={isDETLoading || isLoading} />
            </div>
          ) : null}
        </div>
      </div>
      <EditModal
        changes={changes}
        modalState={modalState}
        onCloseModal={onCloseModal}
        onSave={saveChanges}
        onDatasetChange={onDatasetChange}
      />
      {isFinalizedModalOpen && (
        <FinalizeModal
          onCloseFinalizeModal={() => setIsFinalizedModalOpen(false)}
          onFinalize={data => disableEditing(data)}
          isManualDelivery={isManualDelivery}
        />
      )}
    </>
  )
}

const DETEditWithProviders = () => (
  <DETProvider>
    <DETEdit />
  </DETProvider>
)

export default DETEditWithProviders
