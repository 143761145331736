import { makeStyles } from '@material-ui/core/styles'

const GlobalStyleOverrides = () => {
  useStyles()
  return null
}

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      fontSize: theme.typography.body1.fontSize
    },
    // position the main section on all layouts (ruby generated or otherwise) to
    // make room for the fixed header
    main: {
      position: 'absolute',
      paddingTop: 70,
      paddingBottom: 34,
      width: '100%'
    }
  }
}))

export default GlobalStyleOverrides
