const timers = {}

export const createTimer = ({ name, fn, ms, isInterval }) => {
  isInterval
    ? (timers[name] = setInterval(fn, ms))
    : (timers[name] = setTimeout(fn, ms))
}

export const clearTimer = name => clearTimeout(timers[name])
export const clearAllTimers = () => Object.values(timers).map(clearTimeout)
