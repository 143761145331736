import React, { useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { AxiosError, AxiosResponse } from 'axios'
import { TextField, Grid } from '@material-ui/core'
import Helmet from 'react-helmet'
import axiosSession from '../../utils/http/axios-sessions'
import { useNotification } from '../../common/notifications/NotificationContext'
import { NOTIFICATION_TYPES } from '../../common/notifications/NotificationReducers'
import {
  SessionLayout,
  SessionFormControl,
  CenteredRow,
  SubmitButton
} from './ui/sessions'
import SessionFooter from './SessionFooter'
import SessionHeader from './SessionHeader'
import SessionNotifications from './SessionNotifications'
import Rollbar from '../../utils/rollbar'

const defaults = {
  password: '',
  passwordConfirmation: ''
}

interface Params {
  userId: string
  passwordResetToken: string
}

const statusMessages = {
  401: 'Not Logged In. Request not Processed.',
  403: 'Incorrect Permissions. Request Not Processed.',
  500: 'Internal Server Error. Request Not Processed.'
}

const SessionNewPasswordForm = (): React.ReactChild => {
  const [state, setState] = useState({ ...defaults })
  const [showForm, setShowForm] = useState(true)
  const { dispatchNotification } = useNotification()
  const { userId, passwordResetToken }: Params = useParams()
  const history = useHistory()

  const onChange = ({ target: { name, value } }) =>
    setState(s => ({ ...s, [name]: value }))

  const notificationMessage = (res: AxiosResponse) => {
    if (res.status === 422) {
      return res.data.message
    } else if (res.status === 500) {
      const msg = statusMessages[res.status]
      Rollbar.error(msg, res)
      return msg
    } else if (res.status in statusMessages) {
      return statusMessages[res.status]
    } else if (res.status >= 200 && res.status < 300) {
      return 'Password Update Succeeded'
    } else {
      return 'Request Not Processed. Please contact your administrator!'
    }
  }

  const onSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault()

    if (userId) {
      axiosSession
        .patch(`/users/${userId}/reset_password/${passwordResetToken}.json`, {
          user: {
            password: state.password,
            password_confirmation: state.passwordConfirmation
          }
        })
        .then((res: AxiosResponse) => {
          dispatchNotification({
            type: NOTIFICATION_TYPES.info,
            payload: { notification: notificationMessage(res) }
          })
          setShowForm(false)
          history.push('/ui/login')
        })
        .catch((res: AxiosError) => {
          dispatchNotification({
            type: NOTIFICATION_TYPES.error,
            payload: { notification: notificationMessage(res.response) }
          })
          if (res.response.data.redirect) {
            setShowForm(false)
            history.push('/ui/login')
          } else {
            setShowForm(true)
          }
        })
    }
  }

  const renderNewPasswordForm = (): React.ReactChild => (
    <form onSubmit={onSubmit}>
      <Grid container direction="column" justify="center" alignItems="stretch">
        <Grid item xs={12} zeroMinWidth>
          <SessionFormControl>
            <TextField
              variant="outlined"
              type="password"
              name="password"
              value={state.password}
              placeholder="Password"
              label="Password"
              onChange={onChange}
            />
          </SessionFormControl>
        </Grid>
        <Grid item xs={12} zeroMinWidth>
          <SessionFormControl>
            <TextField
              variant="outlined"
              type="password"
              name="passwordConfirmation"
              placeholder="Password Confirmation"
              label="Password Confirmation"
              value={state.passwordConfirmation}
              onChange={onChange}
            />
          </SessionFormControl>
        </Grid>
        <CenteredRow item xs={12} zeroMinWidth>
          <SubmitButton variant="contained" size="large">
            update password
          </SubmitButton>
        </CenteredRow>
      </Grid>
    </form>
  )

  return (
    <SessionLayout>
      <Helmet>
        <title>Chargeback | New Password</title>
      </Helmet>
      <SessionHeader
        title="Please Select a Password for Your Account"
        subTitle="We've got your back"
      />
      <SessionNotifications />
      {showForm ? renderNewPasswordForm() : <br />}
      <SessionFooter text="Back to Login?" linkText="Login" to="/ui/login" />
    </SessionLayout>
  )
}

export default SessionNewPasswordForm
