import dataAdapter from './data-adapter'
import { get } from 'lodash'

const parseInvoices = response => {
  const parsedInvoiceData = response.data.data.map(invoice => {
    const undisplayedData = { id: invoice.id, links: invoice.links }
    return Object.keys(dataAdapter.columns).reduce((acc, column) => {
      return {
        ...acc,
        [column]: get(invoice, dataAdapter.columns[column].path)
      }
    }, undisplayedData)
  })
  return {
    data: parsedInvoiceData,
    result_ids: response.data.data.map(data => data.xomi_id)
  }
}

export default parseInvoices
