import Rollbar from 'rollbar'

const isProduction = process.env.NODE_ENV === 'production'
const env = process.env.FRONTEND_ROLLBAR_ENV || process.env.NODE_ENV

export default new Rollbar({
  accessToken: '367da0b0387d4e4ab918f338196e18ed',
  enabled: isProduction,
  payload: {
    environment: env,
    client: {
      javascript: {
        source_map_enabled: true,
        guess_uncaught_frames: true
      }
    }
  },
  // Just to be sure that the user is using updated UI
  code_version: '1.0.0'
})
