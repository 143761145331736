import axios from '../../utils/http/axios-local'
import Jsona from 'jsona'
import { useEffect, useState } from 'react'

const apiFormatter = new Jsona()

const formatData = data => {
  const {
    merchant_id: merchantId,
    remote_id: remoteId,
    partner_name: partnerName,
    mids
  } = apiFormatter.deserialize(data) as any // TODO: type this correctly

  return { merchantId, remoteId, partnerName, mids }
}

const getMidMerchantGroup = async id => {
  const {
    data: { mid_merchant_group: rawMidMerchantGroup }
  } = await axios.get(`/mid-merchant-groups/${id}.json`)
  return formatData(rawMidMerchantGroup)
}

const useMidMerchantGroup = id => {
  const [midMerchantGroup, setMidMerchantGroup] = useState(null)
  useEffect(() => {
    if (!id) {
      return
    }
    getMidMerchantGroup(id).then(setMidMerchantGroup)
  }, [id])
  return midMerchantGroup
}

export default useMidMerchantGroup
