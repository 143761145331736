import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { RenderingDTO, TemplateType } from './dtos'
import { Button } from '@siftscience/focus-components/button'
import {
  LightbulbFilled,
  VerificationWarningFilled
} from '@siftscience/focus-components/icons'
import { Filters, RenderingStatus } from './enums'

const useStyles = makeStyles(() => ({
  navButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  childRenderingNavigation: {
    marginLeft: '16px',
    borderLeft: '1px solid #E0E0E0'
  }
}))

interface NavigationButtonProps {
  selectedFilter: Filters
  renderingData: RenderingDTO
  renderingsHashData: Record<string, RenderingDTO>
  onClick: (rendering: RenderingDTO) => void
}

const NavigationButton = ({
  selectedFilter,
  renderingData,
  renderingsHashData,
  onClick
}: NavigationButtonProps): React.ReactElement => {
  const classes = useStyles()
  const isDisabled = useMemo(() => {
    if (renderingData.templateType === TemplateType.configurationPanel) {
      return false
    }

    if (selectedFilter === Filters.AllFields) {
      return false
    }

    if (
      selectedFilter === Filters.Incomplete &&
      renderingData.status === RenderingStatus.INCOMPLETE
    ) {
      return false
    }
    if (
      selectedFilter === Filters.Enhanceable &&
      renderingData.status === RenderingStatus.ENHANCEABLE
    ) {
      return false
    }

    return true
  }, [selectedFilter, renderingData, renderingsHashData])

  return (
    <div>
      <Button
        className={classes.navButton}
        variant="primary-ghost"
        size="large"
        onClick={() => onClick(renderingData)}
        disabled={isDisabled}
      >
        <span>{renderingData.displayName}</span>
        {renderingData.templateType ===
        TemplateType.configurationPanel ? null : (
          <>
            {renderingData?.status === RenderingStatus.ENHANCEABLE && (
              <LightbulbFilled color="#F2B200" />
            )}
            {renderingData?.status === RenderingStatus.INCOMPLETE && (
              <VerificationWarningFilled color="#EB3B2E" />
            )}
          </>
        )}
      </Button>
      {renderingData.childTemplateIds?.length ? (
        <div className={classes.childRenderingNavigation}>
          {renderingData.childTemplateIds.map(templateId => {
            const rendering = renderingsHashData[templateId]
            if (!rendering) {
              return null
            }
            return (
              <NavigationButton
                selectedFilter={selectedFilter}
                renderingData={rendering}
                renderingsHashData={renderingsHashData}
                onClick={onClick}
                key={`navigation-button-${templateId}`}
              />
            )
          })}
        </div>
      ) : null}
    </div>
  )
}

export default NavigationButton
