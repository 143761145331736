import React, { useState, useEffect, useRef, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { TextArea } from '@siftscience/focus-components/input'
import { get } from 'lodash'
import ImageArea from './image-area'
import { getDatasetPath } from '../../../utils/det'
import { useDisputeContext } from '../context/dispute-context'
import { DataField, DatasetDTO, ImageDTO } from '../dtos'
import { DataFieldType } from '../enums'
import { ISaveResult } from '../interfaces'

const useStyles = makeStyles(() => ({
  description: {
    width: '100%'
  }
}))

const validateImageSize = (file: File) => {
  const MAX_IMAGE_SIZE = 5 * 1024 * 1024 // 5 MB in bytes
  return file.size <= MAX_IMAGE_SIZE
}

interface ImageUploaderProps {
  dataset: DatasetDTO
  changes: Record<string, string | Record<string, string>[] | ImageDTO>
  dataField: DataField
  focusedField?: { name: string; isArray: boolean }
  onUploadImage: (field: DataField, imageInfo: ImageDTO) => Promise<ISaveResult>
  onDeleteImage: (field: DataField, imageInfo: ImageDTO) => Promise<ISaveResult>
  onChangeCaption: (field: DataField, newCaption: string) => void
  onBlurCaption: (field: DataField, newCaption: string) => void
}

const ImageUploader = ({
  dataset,
  changes,
  dataField,
  focusedField,
  onUploadImage,
  onDeleteImage,
  onChangeCaption,
  onBlurCaption
}: ImageUploaderProps): React.ReactElement => {
  const { disputeId } = useDisputeContext()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [imageError, setImageError] = useState<boolean>(false)
  const [imageFile, setImageFile] = useState<File | null>(null)
  const [imageInfo, setImageInfo] = useState<ImageDTO | null>(null)
  const imageRef = useRef<HTMLInputElement | null>(null)
  const classes = useStyles()
  const isMerchantLibraryImage =
    imageInfo?.name && !imageInfo?.id && imageInfo?.fallback_to_library

  useEffect(() => {
    const imageInfo = get(
      dataset,
      getDatasetPath(dataField?.datasetPath)
    ) as ImageDTO

    if (imageInfo?.id || isMerchantLibraryImage) {
      // `rand` param is to prevent browser from caching the image
      const uploadedSrc = `/det/evidences/retrieve?id=${disputeId}&path=${
        dataField.datasetPath
      }&template_id=${dataField.templateId}&rand=${Date.now()}`
      setImageInfo({ ...imageInfo, src: imageInfo.src || uploadedSrc })
    } else {
      setImageInfo(null)
    }
  }, [dataset, isMerchantLibraryImage])

  const captionValue = useMemo(() => {
    if (dataField) {
      const captionPath = `${dataField?.datasetPath}.caption`

      return (
        ((changes[captionPath] ??
          get(dataset, getDatasetPath(captionPath))) as string) || ''
      )
    }
  }, [dataField, changes, dataset, getDatasetPath, get])

  const handleUpload = (fileToUpload: File) => {
    setImageError(false)
    if (fileToUpload && fileToUpload.type.startsWith('image/')) {
      const reader = new FileReader()
      reader.readAsDataURL(fileToUpload)
      reader.onloadend = async () => {
        setIsLoading(true)

        try {
          const imageInfoToPass: ImageDTO = {
            name: dataField.name,
            group: dataField.name,
            src: reader.result,
            image: fileToUpload,
            templateId: dataField.templateId,
            type: DataFieldType.IMAGE
          }

          if (captionValue) {
            imageInfoToPass.caption = captionValue
          }

          if (fileToUpload) {
            const saveResult = await onUploadImage(dataField, imageInfoToPass)
            if (saveResult.addImageError) {
              setImageError(true)
              setImageFile(null)
            }
            setIsLoading(false)
          }
        } catch (error) {
          setImageError(true)
          setImageFile(null)
        }
        setIsLoading(false)
      }
    }
  }

  const handleDragOver = event => {
    event.preventDefault()
  }

  const handleDrop = event => {
    event.preventDefault()
    const droppedFile = event.dataTransfer.files[0]
    if (
      droppedFile &&
      droppedFile.type.startsWith('image/') &&
      validateImageSize(droppedFile)
    ) {
      setImageFile(droppedFile)
      handleUpload(droppedFile)
    }
  }

  const handlePaste = event => {
    event.preventDefault()
    const clipboardData = event.clipboardData
    if (
      clipboardData &&
      clipboardData.items &&
      clipboardData.items[0].kind === 'file'
    ) {
      const pastedImage = clipboardData.items[0].getAsFile()
      if (
        pastedImage.type.startsWith('image/') &&
        validateImageSize(pastedImage)
      ) {
        setImageFile(pastedImage)
        handleUpload(pastedImage)
      }
    }
  }

  const handleSelectImage = event => {
    const selectedFile = event.target.files[0]
    if (
      selectedFile &&
      selectedFile.type.startsWith('image/') &&
      validateImageSize(selectedFile)
    ) {
      setImageFile(selectedFile)
      handleUpload(selectedFile)
    }
  }

  const onUploadClick = () => imageRef?.current.click()

  const onDeleteClick = async () => {
    try {
      setImageError(false)
      setIsLoading(true)
      const imageInfoToPass = {
        templateId: dataField.templateId,
        type: DataFieldType.IMAGE
      }
      const saveResult = await onDeleteImage(dataField, imageInfoToPass)
      if (saveResult.removeImageError) {
        setImageError(true)
      } else {
        setImageFile(null)
      }
    } catch (e) {
      setImageError(true)
    }
    setIsLoading(false)
  }

  const onViewImage = () => {
    if (imageFile) {
      // when we have only local file uploaded to browser only
      const imageUrl = URL.createObjectURL(imageFile)
      window.open(imageUrl, '_blank')
    } else if (imageInfo && imageInfo.src) {
      // when we have file saved to BE
      window.open(imageInfo.src as string, '_blank')
    }
  }

  const onCaptionChange = event => {
    const value = event?.target?.value
    onChangeCaption(dataField, value)
  }

  const onCaptionBlur = event => {
    const value = event?.target?.value
    onBlurCaption(dataField, value)
  }

  return (
    <div>
      <TextArea
        placeholder="Write evidence caption here..."
        className={classes.description}
        value={captionValue}
        onChange={onCaptionChange}
        onBlur={onCaptionBlur}
      />
      <ImageArea
        imageInfo={imageInfo}
        imageError={imageError}
        handleSelectImage={handleSelectImage}
        handleDragOver={handleDragOver}
        imageRef={imageRef}
        onViewImage={onViewImage}
        onUploadClick={onUploadClick}
        isLoading={isLoading}
        dataField={dataField}
        focusedField={focusedField}
        handleDrop={handleDrop}
        handlePaste={handlePaste}
        onDeleteClick={onDeleteClick}
        isMerchantLibraryImage={isMerchantLibraryImage}
      />
    </div>
  )
}

export default ImageUploader
