import React, { useEffect, useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Chip from '@material-ui/core/Chip'
import ListViewContext from '../../../list-view-context'

const getDateSelectionDisplay = (formState, dateColumns) => {
  return dateColumns.reduce(
    (acc, { rangeStartName, rangeEndName, name, title }) => {
      const startField = rangeStartName
      const endField = rangeEndName
      const rangeField = `${name}_range`

      if (formState[startField] && formState[endField]) {
        acc.push({
          value: [startField, endField],
          label: `${title}: ${formState[startField]} - ${formState[endField]}`
        })
      } else if (formState[rangeField]) {
        acc.push({
          value: [rangeField],
          label: `${title}: ${formState[rangeField]}`
        })
      }
      return acc
    },
    []
  )
}

const SelectionDisplay = props => {
  const { setFormState, formState } = props
  const [dates, setDates] = useState([])
  const classes = useStyles()
  const {
    Data: { dateColumns }
  } = useContext(ListViewContext)

  useEffect(() => {
    setDates(getDateSelectionDisplay(formState, dateColumns))
  }, [formState])

  const clearDates = fields => {
    const newState = fields.reduce((acc, field) => {
      delete acc[field]
      return acc
    }, formState)

    setFormState({
      ...newState
    })
  }

  return (
    <div className={classes.inputContainer}>
      <Paper className={classes.paper}>
        {dates.length ? (
          dates.map(date => {
            return (
              <Chip
                key={date.label}
                className={classes.chips}
                size="small"
                label={date.label}
                onDelete={() => clearDates(date.value)}
              />
            )
          })
        ) : (
          <div>Use the controls to the left to choose a date...</div>
        )}
      </Paper>
    </div>
  )
}

const useStyles = makeStyles({
  inputContainer: {
    margin: '5px 10px'
  },
  paper: {
    padding: 7
  },
  chips: {
    margin: 2
  }
})

export default SelectionDisplay
