import cookie from 'cookie'
import jwtDecode from 'jwt-decode'
import { isEmpty, mapKeys, camelCase } from 'lodash'

interface IMasqueradeUser {
  name: string
  clientName: string
}

interface ICurrentUserCookie {
  id: number
  userId: number
  name: string
  email: string
  clientId: number
  roles: string[]
  chargebackEmployee: boolean
  admin: boolean
  masqueradeUser: IMasqueradeUser | null
}

// Create a list of all the Header Cookies Present
const cookies = () => {
  if (document.cookie !== '') {
    return cookie.parse(document.cookie)
  }
}

const currentUserCookie = (): ICurrentUserCookie => {
  // List of all header Cookies
  const listOfCookies = cookies()

  if (listOfCookies) {
    // Check for presence of Cookies
    const hasCookies = Object.keys(listOfCookies).length > 0
    // Check for presence of JWT Token
    const hasJwt = !isEmpty(listOfCookies.jwt_access)

    if (hasCookies && hasJwt) {
      // Decode JWT Token and return CurrentUser data
      const jwtObject = jwtDecode(listOfCookies.jwt_access)

      return mapKeys(jwtObject, (_value, key) => {
        return camelCase(key)
      }) as ICurrentUserCookie
    }
  }
}

export { currentUserCookie, ICurrentUserCookie }
