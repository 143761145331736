import React from 'react'
import ContentLoader from 'react-content-loader'

const RectLoader = () => (
  <ContentLoader speed={2} width="100%" height={60}>
    <rect width="100%" height={60} />
  </ContentLoader>
)

export default RectLoader
