import React from 'react'
import { Route } from 'react-router-dom'
import CaidsIndex from './index'
import CaidsResource from './resource'

const CaidsRouter = () => {
  return [
    <Route exact key={1} path="/ui/caids" component={CaidsIndex} />,
    <Route exact key={2} path="/ui/caids/:action" component={CaidsResource} />,
    <Route
      exact
      key={3}
      path="/ui/caids/:id/:action"
      component={CaidsResource}
    />
  ]
}

export default CaidsRouter
