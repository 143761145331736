import React from 'react'
import UserRow from './user-row'

const UsersTable = ({ users, ...props }) => {
  return (
    <table>
      <thead>
        <tr>
          <th>Email</th>
          <th>Phone</th>
          <th>Client</th>
          <th>Merchant</th>
          <th>Billing</th>
        </tr>
      </thead>
      <tbody>
        {users.map(user => (
          <UserRow key={user.id} user={user} {...props} />
        ))}
      </tbody>
    </table>
  )
}

export default UsersTable
