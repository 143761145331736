import React from 'react'
import { useHistory } from 'react-router-dom'
import Helmet from 'react-helmet'
import { AxiosResponse } from 'axios'
import { Grid, Link } from '@material-ui/core'
import { styled } from '@material-ui/core/styles'
import axiosSession from '../../utils/http/axios-sessions'
import { useNotification } from '../../common/notifications/NotificationContext'
import { SessionLayout } from './ui/sessions'
import SessionResetPassword from './SessionResetPassword'
import SessionHeader from './SessionHeader'
import SessionNotifications from './SessionNotifications'
import Rollbar from '../../utils/rollbar'
import { NOTIFICATION_TYPES } from '../../common/notifications/NotificationReducers'

const Logout = styled(Link)({
  textTransform: 'uppercase',
  color: '#00ADF3'
})

const Row = styled(Grid)({
  marginTop: '1rem',
  marginBottom: '2rem'
})

const SessionLogoutForm = () => {
  const { dispatchNotification } = useNotification()
  const history = useHistory()

  const onClick = () => {
    axiosSession
      .delete('/log_out.json')
      .then((res: AxiosResponse) => {
        const { url } = res.data
        if (url && url !== '') {
          history.push(url)
        } else {
          Rollbar.error('Missing Redirect URL', res)
          history.push('/ui/login')
        }
      })
      .catch(() => {
        dispatchNotification({
          type: NOTIFICATION_TYPES.error,
          payload: { notification: 'Unable to Logout User' }
        })
      })
  }

  return (
    <SessionLayout>
      <Helmet>
        <title>Sift | Logout</title>
      </Helmet>
      <SessionHeader
        title="You are currently Logged In"
        subTitle="Would you like to logout?"
      />
      <SessionNotifications />
      <Grid container justify="center" alignItems="center">
        <Row item zeroMinWidth>
          <Logout onClick={onClick}>log out</Logout>
        </Row>
      </Grid>
      <SessionResetPassword
        text="Or"
        linkText="Reset Password"
        to="/ui/reset"
      />
    </SessionLayout>
  )
}

export default SessionLogoutForm
