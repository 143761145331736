import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import Rollbar from './utils/rollbar'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { error: null, errorInfo: null }
    this.handleClose = this.handleClose.bind(this)
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo
    })

    Rollbar.error(error)
  }

  handleClose() {
    window.location = '/'
  }

  clearCache() {
    localStorage.clear()
  }

  render() {
    return this.state.errorInfo ? (
      <Dialog onClose={this.handleClose} open={true}>
        <DialogTitle>Unexpected Error</DialogTitle>
        <DialogContent>
          <DialogContentText color="error">
            Oops! Something went wrong and the engineers have been notified.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.handleClose}
            variant="contained"
            color="primary"
            autoFocused
          >
            Return to the app
          </Button>
          <Button
            onClick={this.clearCache}
            variant="contained"
            color="primary"
            autoFocused
          >
            Clear cache
          </Button>
        </DialogActions>
      </Dialog>
    ) : (
      this.props.children
    )
  }
}

export default ErrorBoundary
