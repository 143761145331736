import React, { useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Switch } from '@siftscience/focus-components/switch'
import { IconButton } from '@siftscience/focus-components/button'
import { Text, Title } from '@siftscience/focus-components/text'
import { Edit } from '@siftscience/focus-components/icons/Edit'
import { Checks } from '@siftscience/focus-components/icons/Checks'
import { VerificationWarningFilled } from '@siftscience/focus-components/icons/VerificationWarningFilled'
import { LightbulbFilled } from '@siftscience/focus-components/icons/LightbulbFilled'
import { TimeRefreshed } from '@siftscience/focus-components/icons/TimeRefreshed'
import { Checkmark, Info } from '@siftscience/focus-components/icons'
import { Tooltip, TooltipTrigger } from '@siftscience/focus-components/tooltip'
import Badge from './badge'
import { useRenderingsContext } from './context/renderings-context'
import { ExtendedRendering, RenderingDTO, TemplateType } from './dtos'
import { DIRTY_STATUS, RenderingStatus, Tabs } from './enums'

const COMPLETE_ICON_COLOR = '#0AA636'
const INCOMPLETE_ICON_COLOR = '#EB3B2E'
const INCOMPLETE_BADGE_COLOR = '#FFEDEC'
const ENHANCEABLE_BADGE_COLOR = 'transparent'
const ENHANCEABLE_ICON_COLOR = '#F2B200'

const REFRESH_TOOLTIP_TEXT =
  'This template is out of date. It will be automatically updated in several seconds. Click to update it now.'

interface DeliveryFieldHeaderProps {
  rendering: RenderingDTO
  onEditClick: () => void
  onToggleIncludeInRendering: (
    rendering: ExtendedRendering,
    value: boolean
  ) => void
  selectedTab: Tabs
  workflowDescription: string | null
}

const useStyles = makeStyles(() => ({
  deliveryFieldHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#F7F7F7',
    height: '48px',
    padding: '0 16px',
    borderBottom: '1px solid #E0E0E0'
  },
  deliveryFieldHeaderLeft: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '12px'
  },
  deliveryFieldHeaderRight: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '12px'
  },
  badgeChildren: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    gap: '4px'
  },
  badgeIcon: {
    width: '16px',
    height: '16px'
  },
  dirtyUpToDate: {
    display: 'flex',
    paddingBottom: '2px'
  },
  completeIconWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '32px',
    height: '32px',
    border: '1px solid #E0E0E0',
    borderRadius: '50%'
  },
  checkmarkIcon: {
    width: '16px',
    height: '16px',
    marginRight: '4px'
  },
  info: {
    width: 'auto',
    height: 'auto',
    borderRadius: '50%'
  }
}))

const DeliveryFieldHeader = ({
  rendering,
  onEditClick,
  onToggleIncludeInRendering,
  selectedTab,
  workflowDescription
}: DeliveryFieldHeaderProps): React.ReactElement => {
  const { onDirtyRenderingClick, dirtyRenderings } = useRenderingsContext()
  const [switchStatus, setSwitchStatus] = useState(false)
  const classes = useStyles()

  useEffect(() => {
    setSwitchStatus(rendering?.includeInResponse)
  }, [rendering?.includeInResponse])

  const onSwitchChange = () => {
    onToggleIncludeInRendering(rendering, !switchStatus)
  }

  const dirtyStatus = useMemo(() => {
    if (dirtyRenderings?.[rendering?.id] !== undefined) {
      return dirtyRenderings[rendering?.id]
    }
    if (rendering.dirty) {
      return DIRTY_STATUS.Dirty
    } else {
      return DIRTY_STATUS.Latest
    }
  }, [rendering, dirtyRenderings?.[rendering?.id]])

  return (
    <div className={classes.deliveryFieldHeader}>
      <div className={classes.deliveryFieldHeaderLeft}>
        {rendering.templateType === TemplateType.configurationPanel ? null : (
          <Switch checked={switchStatus} onChange={onSwitchChange} />
        )}
        <Title size="medium">{rendering?.displayName}</Title>
        {rendering?.templateName ? (
          <Text size="small" color="secondary">
            {rendering?.templateName}
          </Text>
        ) : null}
        {workflowDescription ? (
          <TooltipTrigger content={workflowDescription} placement="top">
            <IconButton
              className={classes.info}
              variant="secondary-ghost"
              size="medium"
            >
              <Info />
            </IconButton>
          </TooltipTrigger>
        ) : null}
      </div>
      <div className={classes.deliveryFieldHeaderRight}>
        {dirtyStatus === DIRTY_STATUS.UpToDate && (
          <Text
            className={classes.dirtyUpToDate}
            size="xsmall"
            color="secondary"
          >
            <Checkmark className={classes.checkmarkIcon} color="#B3B3B3" />
            Up to date
          </Text>
        )}
        {dirtyStatus === DIRTY_STATUS.Loading && (
          // TODO add proper loader according to designs
          <Text
            className={classes.dirtyUpToDate}
            size="xsmall"
            color="secondary"
          >
            Loading...
          </Text>
        )}
        {dirtyStatus === DIRTY_STATUS.Dirty && (
          <IconButton
            variant="secondary-ghost"
            onClick={() => onDirtyRenderingClick(rendering)}
          >
            <Tooltip content={REFRESH_TOOLTIP_TEXT}>
              <TimeRefreshed />
            </Tooltip>
          </IconButton>
        )}
        {rendering.templateType === TemplateType.configurationPanel ? null : (
          <>
            {rendering?.status === RenderingStatus.INCOMPLETE && (
              <Badge color={INCOMPLETE_BADGE_COLOR}>
                <div className={classes.badgeChildren}>
                  <VerificationWarningFilled
                    color={INCOMPLETE_ICON_COLOR}
                    className={classes.badgeIcon}
                  />
                  <Title size="xsmall">Incomplete</Title>
                </div>
              </Badge>
            )}
            {rendering?.status === RenderingStatus.ENHANCEABLE && (
              <Badge color={ENHANCEABLE_BADGE_COLOR} border>
                <div className={classes.badgeChildren}>
                  <LightbulbFilled
                    color={ENHANCEABLE_ICON_COLOR}
                    className={classes.badgeIcon}
                  />
                  <Title size="xsmall">Enhanceable</Title>
                </div>
              </Badge>
            )}
            {rendering?.status === RenderingStatus.COMPLETE && (
              <div className={classes.completeIconWrapper}>
                <Checks
                  className={classes.badgeIcon}
                  color={COMPLETE_ICON_COLOR}
                />
              </div>
            )}
          </>
        )}
        {selectedTab === Tabs.Rendered && (
          <IconButton variant="secondary-ghost" onClick={onEditClick}>
            <Edit />
          </IconButton>
        )}
      </div>
    </div>
  )
}

export default DeliveryFieldHeader
