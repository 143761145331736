import axios from '../../utils/http/axios-local'
import { useSnackbar } from 'notistack'

const saveMidMerchantGroup = ({ values, id }) =>
  axios.put(`/mid-merchant-groups/${id}.json`, {
    mid_merchant_group: values
  })

const useSaveMidMerchantGroup = () => {
  const { enqueueSnackbar } = useSnackbar()
  return params =>
    saveMidMerchantGroup(params)
      .then(() =>
        enqueueSnackbar('Successfully saved mid merchant group!', {
          variant: 'success'
        })
      )
      .catch(() =>
        enqueueSnackbar('There was a problem saving the mid merchant group.', {
          variant: 'error'
        })
      )
}

export default useSaveMidMerchantGroup
