import axios from '../../utils/http/axios-disputer'

export const updateAssignments = (selectedIds, outcome) => {
  const params = {
    assignments: {
      items: selectedIds,
      outcome
    }
  }
  return axios.patch('/api/alerts/mass_update_outcomes', params)
}

export const updateNotes = (currentUserId, selectedIds, notes) => {
  const params = {
    assignments: {
      items: selectedIds,
      assignee: currentUserId,
      notes
    }
  }
  return axios.patch('/api/alerts/mass_update_notes', params)
}
