import React from 'react'
import { Text } from '@siftscience/focus-components/text'
import { makeStyles } from '@material-ui/core/styles'

interface CounterProps {
  value: string
}

const useStyles = makeStyles(() => ({
  counterWrapper: {
    padding: '2px 4px',
    backgroundColor: '#6A6A6A', // TODO check for proper color const
    color: '#ffffff',
    borderRadius: '16px',
    lineHeight: '12px'
  }
}))

const Counter = ({ value }: CounterProps): React.ReactElement => {
  const classes = useStyles()
  return (
    <span className={classes.counterWrapper}>
      <Text size="xsmall">{value}</Text>
    </span>
  )
}

export default Counter
