import React, { useState, useEffect } from 'react'
import Alert from '@material-ui/lab/Alert'
import { Grid } from '@material-ui/core'

const EvidenceFieldErrorsAlert = ({ deliveryFields }) => {
  const hasErrors = () => {
    return Object.values(deliveryFields).some(
      deliveryField => deliveryField.errors.length > 0
    )
  }

  if (hasErrors()) {
    return (
      <Grid container justify="space-between">
        <Grid item xs>
          <Alert severity="error">Some fields do not meet requirements</Alert>
        </Grid>
      </Grid>
    )
  } else {
    return null
  }
}

export default EvidenceFieldErrorsAlert
