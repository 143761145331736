/* eslint-disable camelcase */

import { DataFieldType, ProcessorFieldType, RenderingStatus } from './enums'

export interface ProcessorField {
  id: string | number
  displayName: string
  fieldName: string
  portalId: number
  data_type: ProcessorFieldType
}

export enum TemplateType {
  responseTemplate = 'response_template',
  stylesheet = 'stylesheet',
  configurationPanel = 'configuration_panel'
}

export interface RenderingGeneralData {
  id: number
  templateId: number
  displayName: string
  templateName: string
  templateType: TemplateType
  childTemplateIds: number[]
  parentTemplateIds: number[]
  status: RenderingStatus
  dirty: boolean
  finalized: boolean
  includeInResponse: boolean
  processorField: ProcessorField
}

export interface RenderingContent {
  templateId: number
  content: string
  templateWorkflowDescription: string
}

export interface JSONSchema {
  items: {
    properties: { [key: string]: { name: string; type: string[] } }
    required?: string[]
  }[]
}

export interface DataField {
  id: number
  name: string
  dataType: DataFieldType
  jsonSchema: JSONSchema
  required: true
  datasetPath: string
  templateId: number
}

export interface RenderingDTO extends RenderingGeneralData {
  dataFields: DataField[]
}

export interface ExtendedRendering extends RenderingDTO {
  filteredParentTemplateIds?: number[]
  filteredChildTemplateIds?: number[]
}

export interface DatasetDTO {
  [key: string]: any
}

export interface ImageDTO {
  caption?: string
  group?: string
  id?: number
  name?: string
  src?: string | ArrayBuffer
  image?: File
  templateId?: number
  type?: DataFieldType
  fallback_to_library?: boolean
}

export interface DisputeDTO {
  notes: string
  lifecycleStatus: LifecycleStatus
  tags: TagsDTO
  assignee: UserDTO
  locked: boolean
  expired: boolean
  manualDelivery: boolean
  expirationDate: string
  requestDate: string
  mid: MidDTO
  merchant: MerchantDTO
  stage: string
  remoteInvoiceId: string
  remoteTransactionId: string
  reasonCodeCategory: string
  sentOn: string
  deliveredOn: string
}

export type TagsDTO = Array<TagDTO>

export interface TagDTO {
  id: number
  name: string
}

export interface UserDTO {
  id: number
  name: string
}
export interface MidDTO {
  id: number
  name: string
}
export interface MerchantDTO {
  id: number
  name: string
}

export interface ResponsePreviewDTO extends RenderingContent {
  responseContent: string
  renderingPdfUrl: string
  templateDisplayName: string
}

export enum LifecycleStatus {
  new = 'new',
  documentSent = 'document_sent',
  acceptLiability = 'accept_liability'
}
