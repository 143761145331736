import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Checkbox from '@material-ui/core/Checkbox'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import ButtonBase from '@material-ui/core/ButtonBase'
import {
  DispatchCheckboxContext,
  CheckboxContext
} from '../common/checkboxes/checkbox-context'
import ListViewContext from '../list-view-context'
import { CHECKBOX_TYPE } from '../common/checkboxes/checkbox-reducer'
import { SELECTED_PARAMS_TYPE } from '../data-handler/selected-params-reducer'

const ColumnHeaders = ({ formattedColumns }) => {
  const classes = useStyles()
  const {
    Data: { selectedParams, dispatchSelectedParams, dataAdapter },
    options,
    data,
    ControlColumn
  } = useContext(ListViewContext)

  const dispatchCheckboxes = useContext(DispatchCheckboxContext)
  const checkboxes = useContext(CheckboxContext)

  const setSort = (field, direction) => {
    dispatchSelectedParams({
      type: SELECTED_PARAMS_TYPE.updateSort,
      fieldName: dataAdapter.columns[field].name,
      value: getNextDirection(direction)
    })
  }
  const getNextDirection = direction => {
    switch (direction) {
      case undefined:
        return 'desc'
      case 'desc':
        return 'asc'
      case 'asc':
        return undefined
    }
  }

  const isAllChecked = data.data.length === checkboxes.selectedIds.length

  return (
    <TableHead>
      <TableRow>
        {options.useMassActions ? (
          <TableCell className={classes.cell}>
            {!checkboxes.isLoading ? (
              <Checkbox
                checked={isAllChecked}
                onChange={e =>
                  dispatchCheckboxes({
                    type: CHECKBOX_TYPE.toggleAll,
                    checked: e.target.checked
                  })
                }
              />
            ) : null}
          </TableCell>
        ) : null}

        {formattedColumns.map(({ field, title, sortable, name }) => {
          const direction = selectedParams.sort_by[name]
          return (
            <TableCell key={`header-${title}`} className={classes.cell}>
              {sortable ? (
                <ButtonBase
                  className={clsx(classes.root, { [classes.active]: sortable })}
                  component="span"
                  disableRipple
                  onClick={
                    sortable
                      ? () => setSort(field, selectedParams.sort_by[name])
                      : () => {}
                  }
                >
                  {title}
                  {!sortable || !direction ? null : (
                    <ArrowDownwardIcon
                      className={clsx(
                        classes.icon,
                        classes[`iconDirection-${direction}`]
                      )}
                    />
                  )}
                </ButtonBase>
              ) : (
                <span>{title}</span>
              )}
            </TableCell>
          )
        })}
        {ControlColumn ? (
          <>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </>
        ) : null}
      </TableRow>
    </TableHead>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    cursor: 'pointer',
    display: 'inline-flex',
    justifyContent: 'flex-start',
    flexDirection: 'inherit',
    alignItems: 'center',
    '&:focus': {
      color: theme.palette.text.secondary
    },
    '&:hover': {
      color: theme.palette.text.secondary,
      '& $icon': {
        opacity: 1
      }
    },
    '&$active': {
      color: theme.palette.text.primary,
      '&& $icon': {
        opacity: 1,
        color: theme.palette.text.secondary
      }
    }
  },
  cell: {
    zIndex: 10
  },
  active: {},
  icon: {
    marginRight: 4,
    marginLeft: 4,
    opacity: 0,
    transition: theme.transitions.create(['opacity', 'transform'], {
      duration: theme.transitions.duration.shorter
    }),
    userSelect: 'none'
  },
  'iconDirection-desc': {
    transform: 'rotate(0deg)'
  },
  'iconDirection-asc': {
    transform: 'rotate(180deg)'
  }
}))

export default ColumnHeaders
