import { makeStyles } from '@material-ui/core/styles'

export const siftBlue = '#248de3'
export const useStyles = makeStyles({
  siftButton: {
    userSelect: 'none',
    alignItems: 'center',
    backgroundColor: siftBlue,
    border: 'none',
    borderRadius: '4px',
    color: '#fff',
    cursor: 'pointer',
    display: 'inline-flex',
    font: '400 14px/ 1.3333 proximanova,"Helvetica Neue",helvetica,arial',
    height: '32px',
    justifyContent: 'center',
    outline: 'none',
    padding: '0 12px',
    position: 'relative',
    whiteSpace: 'nowrap'
  },
  blueHeader: {
    backgroundColor: siftBlue,
    padding: '5px',
    marginBottom: '5px',
    height: '42px'
  }
})
