import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import RectLoader from '../rect-loader'
import UploadBlock from './upload-block'
import UploadedImageViewer from './uploaded-image-viewer'
import { DataField, ImageDTO } from '../dtos'

const useStyles = makeStyles(() => ({
  loader: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    flexGrow: 1,
    padding: '16px 46px',
    gap: '8px',
    border: '1px dashed #E0E0E0'
  },
  imageAreaWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px'
  }
}))

interface ImageAreaProps {
  isLoading: boolean
  imageError: boolean
  imageInfo: ImageDTO
  dataField: DataField
  focusedField: { name: string; isArray: boolean }
  imageRef: React.Ref<HTMLInputElement>
  handleSelectImage: (event) => void
  handleDragOver: (event) => void
  handleDrop: (event) => void
  onUploadClick: (event) => void
  handlePaste: (event) => void
  onViewImage: () => void
  onDeleteClick: () => Promise<void>
}

const ImageArea = ({
  isLoading,
  imageError,
  imageInfo,
  dataField,
  focusedField,
  imageRef,
  handleSelectImage,
  handleDragOver,
  handleDrop,
  onUploadClick,
  handlePaste,
  onViewImage,
  onDeleteClick
}: ImageAreaProps): React.ReactElement => {
  const classes = useStyles()

  if (isLoading) {
    return (
      <div className={classes.loader}>
        <RectLoader />
      </div>
    )
  }

  if (!imageInfo?.src) {
    // No image, showing upload block
    return (
      <UploadBlock
        imageError={imageError}
        handleSelectImage={handleSelectImage}
        handleDragOver={handleDragOver}
        imageRef={imageRef}
        onUploadClick={onUploadClick}
        dataField={dataField}
        focusedField={focusedField}
        handleDrop={handleDrop}
        handlePaste={handlePaste}
      />
    )
  }

  const isMerchantLibraryImage = imageInfo.name && !imageInfo.id

  return (
    <div className={classes.imageAreaWrapper}>
      <UploadedImageViewer
        isMerchantLibraryImage={isMerchantLibraryImage}
        imageError={imageError}
        imageInfo={imageInfo}
        onViewImage={onViewImage}
        onDeleteClick={onDeleteClick}
      />
      {isMerchantLibraryImage ? (
        <UploadBlock
          imageError={imageError}
          handleSelectImage={handleSelectImage}
          handleDragOver={handleDragOver}
          imageRef={imageRef}
          onUploadClick={onUploadClick}
          dataField={dataField}
          focusedField={focusedField}
          handleDrop={handleDrop}
          handlePaste={handlePaste}
        />
      ) : null}
    </div>
  )
}

export default ImageArea
