import axios from 'axios'
import {
  axiosErrorHandler,
  axiosResponseHandler,
  axiosRequestHandler
} from './axios-interceptors'

const instance = axios.create({
  headers: {
    Accept: '*/*',
    'Content-Type': 'application/json'
  }
})

instance.interceptors.request.use(axiosRequestHandler, error => error)
instance.interceptors.response.use(axiosResponseHandler, axiosErrorHandler)

export default instance
