interface IMasqueradeUser {
  name: string
  clientName: string
}

interface ICurrentUserStore {
  id: number
  userId: number
  name: string
  email: string
  clientId: number
  roles: string[]
  chargebackEmployee: boolean
  admin: boolean
  masqueradeUser: IMasqueradeUser
  hasAccess: (allowedRules: string[]) => boolean
  isLoggedIn: () => boolean
}

const CurrentUserStore: ICurrentUserStore = {
  id: -1,
  userId: -1,
  name: '',
  email: '',
  clientId: -1,
  roles: [],
  chargebackEmployee: false,
  admin: false,
  masqueradeUser: null,
  hasAccess: function (allowedRoles: string[]): boolean {
    return allowedRoles.some((role: string) => this.roles.includes(role))
  },
  isLoggedIn: function (): boolean {
    return this.email !== '' && this.id > 0
  }
}

export { CurrentUserStore as default, ICurrentUserStore }
