import CardPrefix from '../../common/list-view/data-table/custom-columns/card-prefix'
import CardSuffix from '../../common/list-view/data-table/custom-columns/card-suffix'
import Date from '../../common/list-view/data-table/custom-columns/date'
import InvoiceId from '../../common/list-view/data-table/custom-columns/invoice-id'
import TransactionId from '../../common/list-view/data-table/custom-columns/transaction-id'
import Currency from '../../common/list-view/data-table/custom-columns/currency'

import DataAdapter from '../../common/list-view/data-handler/interfaces/data-adapter-interface'

const dataAdapter: DataAdapter = {
  name: 'invoices',
  pagination: {
    page: 1,
    perPage: 50
  },
  columns: {
    crm: {
      type: 'number[]',
      title: 'CRM',
      name: 'crm',
      path: 'crm',
      order: 1
    },
    merchant: {
      type: 'number[]',
      title: 'Merchant',
      name: 'merchant',
      path: 'merchantName',
      order: 2
    },
    invoiceId: {
      type: 'string',
      title: 'Invoice ID',
      name: 'invoiceId',
      path: 'invoiceId',
      order: 3,
      format: InvoiceId
    },
    transactionId: {
      type: 'string',
      title: 'Txn ID',
      name: 'transactionId',
      path: 'transactionId',
      order: 4,
      format: TransactionId
    },
    firstName: {
      type: 'string',
      title: 'First Name',
      name: 'firstName',
      path: ''
    },
    lastName: {
      type: 'string',
      title: 'Last Name',
      name: 'lastName',
      path: ''
    },
    invoiceAmount: {
      type: 'string',
      title: 'Invoice Amt',
      name: 'invoiceAmount',
      path: 'invoiceAmount',
      order: 5,
      sortable: true,
      format: Currency
    },
    invoiceTs: {
      type: 'date',
      title: 'Invoice Date',
      name: 'invoiceTs',
      path: 'invoiceTs',
      order: 6,
      sortable: true,
      sortDefault: 'desc',
      rangeStartName: 'invoice_ts_after',
      rangeEndName: 'invoice_ts_before',
      format: Date
    },
    cardPrefix: {
      type: 'string',
      title: 'Card Prefix',
      name: 'cardPrefix',
      path: 'creditCard[cardPrefix]',
      order: 7,
      format: CardPrefix
    },
    cardSuffix: {
      type: 'string',
      title: 'Card Suffix',
      name: 'cardSuffix',
      path: 'creditCard[cardSuffix]',
      order: 8,
      format: CardSuffix
    }
  }
}

export default dataAdapter
