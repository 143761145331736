export const SELECTED_PARAMS_TYPE = {
  setParam: 'setParam',
  setParams: 'setParams',
  updateSort: 'updateSort',
  clearParams: 'clearParams',
  clearParam: 'clearParam',
  clearArrayOfParams: 'clearArrayOfParams'
}

const selectedParamsReducer = (state, action) => {
  const { type, fieldName, fieldNames, value, params, defaultSearchParams } =
    action

  switch (type) {
    case SELECTED_PARAMS_TYPE.setParam:
      return {
        ...state,
        page: 1,
        [fieldName]: value
      }
    case SELECTED_PARAMS_TYPE.setParams:
      return { ...params, page: 1 }
    case SELECTED_PARAMS_TYPE.updateSort:
      const newSort = Object.keys(state.sort_by).reduce((newSort, key) => {
        if (key !== fieldName) {
          newSort[key] = state.sort_by[key]
        }
        return newSort
      }, {})
      if (value) newSort[fieldName] = value

      return {
        ...state,
        sort_by: newSort,
        page: 1
      }
    case SELECTED_PARAMS_TYPE.clearParams:
      return {
        ...defaultSearchParams,
        page: 1,
        /* we are assuming that when someone set per_page, 
        they want it to stick until they change it manually */
        per_page: state.per_page
      }
    case SELECTED_PARAMS_TYPE.clearParam:
      let newState = state
      delete newState[fieldName]
      return {
        ...newState,
        page: 1
      }
    case SELECTED_PARAMS_TYPE.clearArrayOfParams:
      newState = state
      fieldNames.forEach(field => delete newState[field])
      return {
        ...newState,
        page: 1
      }
    default:
      throw new Error('selectedParamsReducer: Invalid action')
  }
}

export default selectedParamsReducer
