import React from 'react'
import ListView from '../../common/list-view/list-view'
import dataAdapter from './data-adapter.ts'
import fetchAlerts from './fetch-alerts'
import MassActions from './mass-actions'
import SearchItems from './search-items'
import getVisibleColumns from '../../common/list-view/common/get-visible-columns'
import useSelectOptions from './use-select-options'
import ControlColumn from './control-column'

const Alerts = props => {
  const columns = getVisibleColumns(dataAdapter)
  const selectOptions = useSelectOptions()

  const options = {
    useLinkIndicator: true,
    useMassActions: true,
    useSaveSearch: true
  }

  return (
    <ListView
      dataAdapter={dataAdapter}
      selectOptions={selectOptions}
      columns={columns}
      fetchData={fetchAlerts}
      MassActions={MassActions}
      SearchItems={SearchItems}
      ControlColumn={ControlColumn}
      options={options}
    />
  )
}

export default Alerts
