import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const Disposition = ({ value }) => {
  const values = {
    won: 'green',
    lost: 'red'
  }

  const classes = useStyles({
    color: values[value] || 'yellow'
  })

  return <span className={classes.root}>{value}</span>
}

const useStyles = makeStyles(theme => ({
  root: props => ({
    color: theme.custom.columns.textColors[props.color]
  })
}))

export default Disposition
