import React from 'react'
import { TextField } from '@material-ui/core'
import { useField } from 'formik'

const CustomInput = props => {
  const [field, meta] = useField(props)
  return (
    <>
      <TextField
        fullWidth
        variant="outlined"
        error={!!meta.error}
        helperText={meta.error}
        {...field}
        {...props}
      />
    </>
  )
}

export default CustomInput
