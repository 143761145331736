import React, { useState, useEffect } from 'react'
import Alert from '@material-ui/lab/Alert'
import { Grid } from '@material-ui/core'

const EvidenceFieldErrorsDetails = ({ errors }) => {
  if (errors.length > 0) {
    return (
      <Alert severity="error">
        {errors.map((error: string, index: number) => (
          <Grid key={index}>{error}</Grid>
        ))}
      </Alert>
    )
  } else {
    return null
  }
}

export default EvidenceFieldErrorsDetails
