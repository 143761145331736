import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Paper from '@material-ui/core/Paper'
import Container from '@material-ui/core/Container'
import Select from 'react-select'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import { Button, IconButton, Icon } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { map } from 'lodash'
import { useSnackbar } from 'notistack'
import Jsona from 'jsona'

import http from '../../utils/http/local-with-errors'
import { TJsonaModel } from 'jsona/lib/JsonaTypes'

const apiFormatter = new Jsona()

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  searchNav: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 8,
    height: 60,
    justifyContent: 'space-between'
  },
  table: {
    '& tr td': {
      lineHeight: '42px'
    },
    '& tr th': {
      lineHeight: '42px'
    }
  },
  merchantOptions: {
    width: 400
  }
}))

const MidMerchantGroupsIndex = props => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  const [merchantOptions, setMerchantOptions] = useState([])
  const defaultMerchantOption = {
    label: 'No merchant associated (please update)',
    value: ''
  }
  const [merchantOption, setMerchantOption] = useState(defaultMerchantOption)

  useEffect(() => {
    async function fetchMerchantOptions() {
      const {
        data: { merchants, errorMessage },
        status
      } = await http({
        url: '/admin/merchants/options.json'
      })
      if (status === 200) {
        const merchantOptions = map(merchants, merchant => ({
          label: merchant.name,
          value: merchant.id
        }))
        merchantOptions.unshift(defaultMerchantOption)
        setMerchantOptions(merchantOptions)
      } else {
        errorMessage && enqueueSnackbar(errorMessage, { variant: 'error' })
      }
    }
    fetchMerchantOptions()
  }, [])

  const [midMerchantGroups, setMidMerchantGroups] = useState([])

  useEffect(() => {
    async function fetchMidMerchantGroups() {
      const {
        data: {
          mid_merchant_groups: rawMidMerchantGroups,
          error_message: errorMessage,
          redirect_url: redirectUrl
        },
        status
      } = await http({
        url: '/mid-merchant-groups.json',
        params: {
          merchantId: merchantOption.value
        }
      })
      if (status === 200) {
        const midMerchantGroups = apiFormatter.deserialize(
          rawMidMerchantGroups
        ) as TJsonaModel[]
        setMidMerchantGroups(midMerchantGroups)
      } else {
        errorMessage && enqueueSnackbar(errorMessage, { variant: 'error' })
        window.location.href = redirectUrl
      }
    }
    fetchMidMerchantGroups()
  }, [merchantOption])

  const history = useHistory()
  const createNew = () => {
    history.push('/ui/mid-merchant-groups/create')
  }

  const deleteMidMerchantGroup = async midMerchantGroupId => {
    const { status } = await http({
      method: 'delete',
      validateStatus: status => status < 500,
      url: `/mid-merchant-groups/${midMerchantGroupId}`
    })
    if (status === 204) {
      enqueueSnackbar('Successfully deleted group', {
        variant: 'success'
      })
      setMerchantOption({ ...merchantOption })
    } else {
      enqueueSnackbar("There was an issue and we've been nontified", {
        variant: 'error'
      })
    }
  }

  return (
    <Container maxWidth="xl">
      <Paper className={classes.root}>
        <div className={classes.searchNav}>
          <Select
            name="merchantId"
            value={merchantOption}
            onChange={setMerchantOption}
            options={merchantOptions}
            className={classes.merchantOptions}
          />
          <IconButton onClick={createNew}>
            <Icon color="primary">add_circle_outline</Icon>
          </IconButton>
        </div>
        {midMerchantGroups.length > 0 ? (
          <>
            <h1>{`Mid Merchant Groups: ${merchantOption.label}`}</h1>
            <div>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>Partner</TableCell>
                    <TableCell>Remote Id</TableCell>
                    {merchantOption.value !== '' && (
                      <TableCell>Mid Names</TableCell>
                    )}
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {map(midMerchantGroups, midMerchantGroup => (
                    <TableRow key={midMerchantGroup.id}>
                      <TableCell>{midMerchantGroup.partner_name}</TableCell>
                      <TableCell>{midMerchantGroup.remote_id}</TableCell>
                      {midMerchantGroup.merchant && (
                        <TableCell>
                          {midMerchantGroup.mid_names.join(', ')}
                        </TableCell>
                      )}
                      <TableCell>
                        <Link
                          to={`/ui/mid-merchant-groups/${midMerchantGroup.id}/edit`}
                        >
                          <Button
                            variant="contained"
                            size="small"
                            color="primary"
                          >
                            {midMerchantGroup.merchant
                              ? 'Edit'
                              : 'Add Merchant'}
                          </Button>
                        </Link>
                        {midMerchantGroup.merchant && (
                          <Button
                            variant="contained"
                            size="small"
                            onClick={() => {
                              const resp = confirm(
                                'Are you sure you want to delete?'
                              )
                              if (resp)
                                deleteMidMerchantGroup(midMerchantGroup.id)
                            }}
                          >
                            Delete
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </>
        ) : (
          <h1>{`Listing mid merchant groups: ${merchantOption.label}`}</h1>
        )}
      </Paper>
    </Container>
  )
}

export default MidMerchantGroupsIndex
