import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const Footer = () => {
  const classes = useStyles()

  return (
    <footer id="footer" className={classes.root}>
      &copy; {new Date().getFullYear()} SIFT SCIENCE, INC. ALL RIGHTS RESERVED.{' '}
      <a href="https://sift.com/tos/" target="_blank">
        TERMS OF SERVICE
      </a>{' '}
      <a href="https://sift.com/contact-us/" target="_blank">
        CONTACT US
      </a>
    </footer>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    padding: 10,
    textAlign: 'center',
    backgroundColor: 'white',
    fontSize: 10
  }
}))

export default Footer
