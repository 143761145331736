import axios from 'axios'
import { csrfToken } from './csrf-token'

const http = async ({ url, params = {}, method = 'get', ...options }) => {
  const settings = {
    method,
    headers: {
      Accept: '*/*',
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken
    },
    url
  }

  if (['post', 'put'].includes(method)) {
    settings.data = params
    settings.dataType = 'JSON'
    settings.headers.Accept = 'application/json'
  } else {
    settings.params = params
  }

  try {
    const response = await axios({ ...settings, ...options })
    return response
  } catch (error) {
    throw new Error('Connection error at: ' + url + ' - ' + error)
  }
}

export default http
