import dataAdapter from './data-adapter'
import { get } from 'lodash'

const parseAlerts = response => {
  const parsedAlertData = response.data.data.map(data => {
    const undisplayedData = {
      id: data.id,
      alertPortalId: data.alertPortalId,
      alertType: data.alertType
    }
    return Object.keys(dataAdapter.columns).reduce((acc, column) => {
      return {
        ...acc,
        [column]: get(data, dataAdapter.columns[column].path)
      }
    }, undisplayedData)
  })
  return {
    data: parsedAlertData,
    result_ids: response.data.data.map(data => data.id)
  }
}

export default parseAlerts
