import React, { useState } from 'react'

const Hoverable = ({ children }) => {
  const [hover, setHover] = useState(false)
  return React.cloneElement(children({ hover, setHover }), {
    onMouseEnter: () => setHover(true),
    onMouseLeave: () => setHover(false)
  })
}

export default Hoverable
