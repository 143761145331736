import React, { MutableRefObject, useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import DeliveryFieldHeader from './delivery-field-header'
import FieldsEditForm from './fields-edit-form'
import RenderingContent from './rendering-content'
import RenderingLoader from './rendering-loader'
import { findImageElement } from './rendering_image_placeholder_helpers'
import { useRenderingsContext } from './context/renderings-context'
import { RenderingDTO, ImageDTO, ExtendedRendering } from './dtos'
import { LOADING_STATUS, Tabs } from './enums'
import { ISaveResult } from './interfaces'

const ARRAY_ITEM_FIELDNAME_SEPARATOR = '#'

interface DeliveryFieldProps {
  rendering: ExtendedRendering
  renderingRefs: MutableRefObject<Map<string | number, HTMLElement>>
  onDataFieldClick: (
    rendering: RenderingDTO,
    dataFieldName?: string,
    isArray?: boolean
  ) => void
  onSetListRef: (
    rendering: RenderingDTO,
    ref: MutableRefObject<HTMLElement>
  ) => void
  selectedTab: Tabs
  onDatasetChange: (
    key: string,
    value: string | ImageDTO,
    renderingId: number
  ) => Promise<ISaveResult>
}

const useStyles = makeStyles(() => ({
  deliveryFieldWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    borderRadius: '8px',
    border: '1px solid #E0E0E0',
    overflow: 'hidden'
  },
  childRendering: {
    margin: '16px',
    marginTop: '0',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px'
  }
}))

const DeliveryField = ({
  rendering,
  renderingRefs,
  onDataFieldClick,
  onSetListRef,
  selectedTab,
  onDatasetChange
}: DeliveryFieldProps): React.ReactElement => {
  const {
    renderingsHashData,
    renderingsContentData,
    requestRenderingContent,
    onToggleIncludeInRendering
  } = useRenderingsContext()
  const wrapperRef = useRef(null)
  const classes = useStyles()

  useEffect(() => {
    if (wrapperRef) {
      onSetListRef(rendering, wrapperRef)
    }
  }, [wrapperRef])

  useEffect(() => {
    const renderingContentData = renderingsContentData?.[rendering.templateId]
    if (rendering && !renderingContentData) {
      requestRenderingContent(rendering)
    }
  }, [rendering, renderingsContentData])

  const onHtmlClick = event => {
    if (event?.target?.classList?.value?.includes?.('det-data-field')) {
      onDataFieldClick(rendering, event?.target?.dataset?.fieldname)
    } else if (
      event?.target?.dataset?.fieldname?.split(ARRAY_ITEM_FIELDNAME_SEPARATOR)
        ?.length > 1
    ) {
      onDataFieldClick(rendering, event?.target?.dataset?.fieldname, true)
    }
    const imageElement = findImageElement(
      event,
      'det-data-image-field',
      'det-rendering-content-wrapper'
    )

    if (imageElement) {
      onDataFieldClick(rendering, imageElement?.dataset?.fieldname)
    }
  }

  const renderingContentData = renderingsContentData?.[rendering.templateId]
  const childTemplateIds =
    rendering.filteredChildTemplateIds ?? rendering.childTemplateIds
  const isContentLoading =
    renderingContentData?.status === LOADING_STATUS.Loading

  return (
    <div
      className={`border-radius bg-workspace shadow ${classes.deliveryFieldWrapper}`}
      ref={wrapperRef}
    >
      <>
        <DeliveryFieldHeader
          rendering={rendering}
          onEditClick={() => onDataFieldClick(rendering)}
          onToggleIncludeInRendering={onToggleIncludeInRendering}
          selectedTab={selectedTab}
          workflowDescription={
            renderingsContentData?.[rendering.templateId]?.workflowDescription
          }
        />
        {selectedTab === Tabs.Rendered && isContentLoading && (
          <RenderingLoader />
        )}
        {selectedTab === Tabs.Rendered && !isContentLoading && (
          <RenderingContent
            onHtmlClick={onHtmlClick}
            renderingContentData={renderingContentData}
          />
        )}
        {selectedTab === Tabs.DataEntry && (
          <FieldsEditForm rendering={rendering} onBlur={onDatasetChange} />
        )}
      </>
      {childTemplateIds?.length ? (
        <div className={classes.childRendering}>
          {childTemplateIds.map(templateId => {
            const childRendering = renderingsHashData[templateId]
            if (childRendering) {
              return (
                <div
                  ref={el => renderingRefs.current.set(childRendering.id, el)}
                >
                  <DeliveryField
                    rendering={childRendering}
                    key={childRendering.id}
                    onDataFieldClick={onDataFieldClick}
                    onSetListRef={onSetListRef}
                    selectedTab={selectedTab}
                    onDatasetChange={onDatasetChange}
                    renderingRefs={renderingRefs}
                  />
                </div>
              )
            }

            return null
          })}
        </div>
      ) : null}
    </div>
  )
}

export default DeliveryField
