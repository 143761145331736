import React, { useState, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import moment from 'moment'

const formatTimestamp = (timestamp: string) =>
  moment(timestamp).format('MMMM Do YYYY \\a\\t h:mm:ss a')

const EventAlert = ({ deliveryEvent }) => {
  if(deliveryEvent.status === 'success'){
    return (
      <Alert severity="success">
        <AlertTitle>
          Categorized evidence delivered on {formatTimestamp(deliveryEvent.timestamp)}
        </AlertTitle>
      </Alert>
    )
  }else{
    return (
      <Alert severity="error">
        <AlertTitle>
           Error delivering categorized evidence on {formatTimestamp(deliveryEvent.timestamp)}
        </AlertTitle>
        {deliveryEvent.error}
      </Alert>
    )
  }
}

const DeliveryEventMessage = ({ deliveryEvent }) => {
  if(deliveryEvent === null){
    return null
  }else{
    return (
      <Grid container justify="space-between">
        <Grid item xs>
          <EventAlert deliveryEvent={deliveryEvent} />
        </Grid>
      </Grid>
    )
  }
}

export default DeliveryEventMessage
