import qs from 'qs'

const addSearchOptionsToUrl = params => {
  const filteredParams = Object.keys(params).reduce((acc, param) => {
    acc[param] = params[param]
    return acc
  }, {})

  const query = Object.keys(filteredParams).length
    ? `?${qs.stringify(filteredParams)}`
    : window.location.pathname

  window.history.pushState('', '', query)
}

export default addSearchOptionsToUrl
