import React from 'react'
import { Route } from 'react-router-dom'
import MidMerchantGroupsIndex from './index'
import MidMerchantGroupsResource from './resource'

const MidMerchantGroupsRouter = () => {
  return [
    <Route
      exact
      key={1}
      path="/ui/mid-merchant-groups"
      component={MidMerchantGroupsIndex}
    />,
    <Route
      exact
      key={2}
      path="/ui/mid-merchant-groups/:id/:action"
      component={MidMerchantGroupsResource}
    />,
    <Route
      exact
      key={3}
      path="/ui/mid-merchant-groups/:action"
      component={MidMerchantGroupsResource}
    />
  ]
}

export default MidMerchantGroupsRouter
