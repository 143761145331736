import React, { useEffect, useState } from 'react'
import { Link, useParams, useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Switch, TextField, FormControlLabel, Button } from '@material-ui/core'
import Select from 'react-select'
import Paper from '@material-ui/core/Paper'
import Container from '@material-ui/core/Container'
import { isEmpty, pick, pickBy, isNil, map, find } from 'lodash'
import set from 'lodash/fp/set'
import Jsona from 'jsona'
import { useSnackbar } from 'notistack'

import http from '../../utils/http/local-with-errors'

const apiFormatter = new Jsona()

const CaidsResource = props => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const { id, action } = useParams()

  const [merchant, setMerchant] = useState(null)
  const [mid, setMid] = useState(null)
  const [caid, setCaid] = useState({
    bin: '',
    caid: '',
    purchase_identifier: ''
  })
  const [shared, setShared] = useState(false)

  useEffect(() => {
    async function fetchCaid() {
      const {
        data: { caid: rawCaid, errorMessage },
        status
      } = await http({
        url: `/caids/${id}.json`
      })
      if (status === 200) {
        const caid = apiFormatter.deserialize(rawCaid)
        const shared =
          caid.purchase_identifier && caid.purchase_identifier.length > 0
        setShared(shared)
        setCaid(caid)
      } else {
        errorMessage && enqueueSnackbar(errorMessage, { variant: 'error' })
      }
    }
    if (action === 'edit') fetchCaid()
  }, [])

  const [merchantOptions, setMerchantOptions] = useState([])
  useEffect(() => {
    async function fetchMerchantOptions() {
      const {
        data: { merchants, errorMessage },
        status
      } = await http({
        url: '/admin/merchants/options.json'
      })
      if (status === 200) {
        const merchantOptions = map(merchants, merchant => ({
          label: merchant.name,
          value: merchant.id
        }))
        setMerchantOptions(merchantOptions)
      } else {
        errorMessage && enqueueSnackbar(errorMessage, { variant: 'error' })
      }
    }
    fetchMerchantOptions()
  }, [])

  useEffect(() => {
    if (!merchant) resetMerchant()
  }, [caid, merchantOptions])

  const [midOptions, setMidOptions] = useState([])
  useEffect(() => {
    async function fetchMidOptions() {
      const {
        data: { mids, errorMessage },
        status
      } = await http({
        url: `/admin/mids/options.json?merchantId=${merchant.value}`
      })
      if (status === 200) {
        const midOptions = map(mids, mid => ({
          label: mid.name,
          value: mid.id
        }))
        setMidOptions(midOptions.length === 0 ? 'missing' : midOptions)
      } else {
        errorMessage && enqueueSnackbar(errorMessage, { variant: 'error' })
      }
    }
    if (merchant) {
      fetchMidOptions()
    }
  }, [merchant])

  useEffect(() => {
    resetMid()
  }, [midOptions])

  const getMerchant = merchantId => {
    if (merchantOptions.length > 0 && merchantId) {
      return find(
        merchantOptions,
        merchantOption => merchantOption.value === merchantId
      )
    } else return null
  }

  const resetMerchant = merchant => {
    merchant = merchant || getMerchant(caid.merchant_id)
    merchant && setMerchant(merchant)
  }

  const getMid = midId => {
    if (midOptions === 'missing') return null
    if (midOptions.length > 0 && midId) {
      const foundMid = find(midOptions, midOption => midOption.value === midId)
      return foundMid || null
    } else return null
  }

  const resetMid = mid => {
    mid = mid || getMid(caid.mid_id)
    setMid(mid)
  }

  const updateCaid = (key, value) => {
    setCaid(prevCaid => set(key, value, prevCaid))
  }

  const isValid = () => {
    const caid = getFullCaid()
    const optionalFields = shared ? ['purchase_identifier'] : []
    const requiredFields = pick(caid, [
      'bin',
      'caid',
      'merchant_id',
      'mid_id',
      ...optionalFields
    ])
    const invalidFields = pickBy(
      requiredFields,
      (value, key) => isNil(value) || value === ''
    )
    return isEmpty(invalidFields)
  }

  const actionBar = () => {
    switch (action) {
      case 'edit':
        return (
          <Button
            variant="contained"
            size="small"
            color="primary"
            className={classes.editBtn}
            onClick={saveCaid}
            disabled={!isValid()}
          >
            Save
          </Button>
        )
      case 'create':
        return (
          <Button
            variant="contained"
            size="small"
            color="primary"
            className={classes.editBtn}
            onClick={createCaid}
            disabled={!isValid()}
          >
            Create
          </Button>
        )
    }
  }

  const saveCaid = async () => {
    const {
      data: { caid: rawCaid, errorMessage },
      status
    } = await http({
      method: 'put',
      params: { caid: getFullCaid() },
      url: `/caids/${id}.json`
    })
    if (status === 200) {
      const caid = apiFormatter.deserialize(rawCaid)
      setCaid(caid)
      enqueueSnackbar('Successfully saved caid', {
        variant: 'success'
      })
    } else {
      enqueueSnackbar(errorMessage, { variant: 'error' })
    }
  }

  const getFullCaid = () => {
    return {
      bin: caid.bin,
      caid: caid.caid,
      merchant_id: merchant && merchant.value,
      mid_id: mid && mid.value,
      purchase_identifier: caid.purchase_identifier
    }
  }

  const history = useHistory()
  const createCaid = async () => {
    const {
      data: { caid: rawCaid, errorMessage },
      status
    } = await http({
      method: 'post',
      params: { caid: getFullCaid() },
      url: '/caids'
    })
    if (status === 201) {
      enqueueSnackbar('Successfully created caid', {
        variant: 'success'
      })
      const caid = apiFormatter.deserialize(rawCaid)
      history.push(`/ui/caids/${caid.id}/edit`)
    } else {
      enqueueSnackbar(errorMessage, { variant: 'error' })
    }
  }

  return (
    <Container maxWidth="xl">
      <Paper className={classes.paper}>
        <Link to={'/ui/caids'}>{'<-- Back to index'}</Link>
        <h1>Caid</h1>
        <div className={classes.row}>
          <TextField
            id="bin"
            value={caid.bin}
            label="BIN (Bank Identification Num)"
            variant="outlined"
            onChange={event => {
              updateCaid('bin', event.target.value)
            }}
          />
          <TextField
            id="caid"
            value={caid.caid}
            label="CAID (Card Acceptor ID)"
            variant="outlined"
            onChange={event => {
              updateCaid('caid', event.target.value)
            }}
          />
          <FormControlLabel
            control={
              <Switch
                checked={shared}
                onChange={() => {
                  setShared(!shared)
                }}
                color="default"
              />
            }
            label="Shared CAID"
          />
        </div>
        {shared ? (
          <div className={classes.row}>
            <TextField
              id="purchase_identifier"
              value={caid.purchase_identifier}
              label="Purchase Identifier Filter"
              variant="outlined"
              onChange={event => {
                updateCaid('purchase_identifier', event.target.value)
              }}
            />
          </div>
        ) : (
          ''
        )}
        <div className={classes.row}>
          <Select
            name="merchant_id"
            value={merchant}
            onChange={merchant => {
              resetMerchant(merchant)
            }}
            noOptionsMesssage="Select a merchant"
            options={merchantOptions}
          />
        </div>
        {midOptions !== 'missing' ? (
          <div className={classes.row}>
            <Select
              name="mid_id"
              value={mid}
              onChange={mid => {
                resetMid(mid)
              }}
              noOptionsMesssage="Select a mid"
              options={midOptions}
            />
          </div>
        ) : (
          <div className={classes.midWarning}>
            The merchant you've selected has no MIDs associated. Please create a
            MID and return to this interface.
          </div>
        )}
        <div className={classes.actionBar}>{actionBar()}</div>
      </Paper>
    </Container>
  )
}

const useStyles = makeStyles(theme => ({
  paper: {
    padding: 24,
    '& h2': {
      borderBottom: 'solid 1px black'
    },
    '& h1': {
      textTransform: 'capitalize'
    }
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    margin: '12px 0',
    '& > *': {
      flexGrow: 1,
      marginRight: 8
    },
    '& > *:last-child': {
      marginRight: 0
    }
  },
  actionBar: {
    display: 'flex',
    flexFlow: 'row-reverse'
  },
  midWarning: {
    color: 'red'
  }
}))

export default CaidsResource
