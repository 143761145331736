import { useState, useEffect } from 'react'
import moment from 'moment'
import { createTimer, clearTimer } from '../../utils/timers'
import getSessionEnd from './get-session-end'

const ONE_SECOND = 1000
const COUNTDOWN_INTERVAL = 'countdownInterval'

const useCountdown = props => {
  const { idle, setIdle } = props
  const [expired, setExpired] = useState(false)
  const [timeRemainingMessage, setTimeRemainingMessage] = useState('')

  useEffect(() => {
    return () => clearTimer(COUNTDOWN_INTERVAL)
  }, [])

  useEffect(() => {
    if (idle) {
      const now = moment()

      // if there was a valid jwt_access, start the idle counter
      if (getSessionEnd()) {
        const msToEnd = moment.duration(moment.unix(getSessionEnd()).diff(now))
        startCountdown(msToEnd)
      }
    }
  }, [idle])

  useEffect(() => {
    if (expired)
      window.location.href =
        '/log_out?timeout&return=' +
        encodeURIComponent(window.location.pathname)
  }, [expired])

  const clearCountdown = () => {
    clearTimer(COUNTDOWN_INTERVAL)
    setIdle(false)
    setExpired(false)
    setTimeRemainingMessage('')
  }

  const startCountdown = msToEnd => {
    let duration = moment.duration({ milliseconds: msToEnd })

    const ticker = () => {
      duration = moment.duration(duration.asSeconds() - 1, 'seconds')
      const min = duration.minutes()
      const sec = duration.seconds()

      if (duration <= 0) {
        const aFewSecondsFromNow = moment().add(5, 'seconds')
        // the jwt indicates the session should end
        if (aFewSecondsFromNow >= moment.unix(getSessionEnd())) {
          setExpired(true)
          // the session must have been extended on another window
        } else {
          clearCountdown()
        }
      } else {
        setTimeRemainingMessage(`
        ${min > 0 ? min + ' minutes and' : ''} ${sec} seconds
      `)
      }
    }

    createTimer({
      name: COUNTDOWN_INTERVAL,
      fn: ticker,
      ms: ONE_SECOND,
      isInterval: true
    })
  }
  return [expired, clearCountdown, timeRemainingMessage]
}

export default useCountdown
