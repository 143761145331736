/**
 * Formatter for parameters that will be sent as part of a
 * query string.
 */
const formatQueryParams = params => {
  return Object.keys(params).reduce((acc, param) => {
    if (params[param]) {
      if (param === 'sort_by') {
        acc[param] = formatSortByParams(params, param)
      } else {
        acc[param] = params[param]
      }
    }
    return acc
  }, {})
}

/**
 * Formats the Sort By Columns and their respective sorting
 * order designation (ie. Asc or Desc) so they can be sent
 * via a "query" string.
 *
 * Example:
 *  - http://localhost:3000/some/endpoint?sort_by=+column1&-column2
 */
const formatSortByParams = (params, fieldName) => {
  const sortByColumns = []
  const columns = params[fieldName]
  const sortByKeys = Object.keys(columns)

  sortByKeys.forEach(sortByKey => {
    const ascOrDesc = columns[sortByKey]
    sortByColumns.push(sortByColumn(ascOrDesc, sortByKey))
  })
  return sortByColumns.join(',')
}

/**
 * Creates a string that designates the column being sorted by
 * and it's corresponding ascending or descending symbol.
 */
const sortByColumn = (ascOrDesc, column) => {
  if (ascOrDesc === 'asc') {
    return ascColumn(column)
  }
  return descColumn(column)
}

// Column name and Asc Symbol
const ascColumn = columnName => `+${columnName}`

// Column name and Desc Symbol
const descColumn = columnName => `-${columnName}`

export { formatQueryParams as default, formatSortByParams }
