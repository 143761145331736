import dataAdapter from './data-adapter'
import { get } from 'lodash'

const parseTransactions = response => {
  const parsedTransactionData = response.data.data.map(data => {
    const undisplayedData = { id: Number(data.xomiId), links: data.links }
    return Object.keys(dataAdapter.columns).reduce((acc, column) => {
      return {
        ...acc,
        [column]: get(data, dataAdapter.columns[column].path)
      }
    }, undisplayedData)
  })
  return {
    data: parsedTransactionData,
    result_ids: response.data.data.map(data => Number(data.xomiId))
  }
}

export default parseTransactions
