import React, { useEffect, useState, useRef, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Icon,
  IconButton,
  InputBase,
  MenuItem,
  ListItemIcon,
  ListSubheader,
  ListItemText,
  Link
} from '@material-ui/core'
import qs from 'qs'
import { useSnackbar } from 'notistack'
import ListViewContext from '../list-view-context'
import { useQuery } from 'react-query'
import axios from '../../../utils/http/axios-local'

const WorkspacesMenu = props => {
  const classes = useStyles()
  const {
    Data: { selectedParams }
  } = useContext(ListViewContext)

  const saveFilterInputEl = useRef(null)
  const { enqueueSnackbar } = useSnackbar()

  const [showDelete, setShowDelete] = useState(false)
  const [showSave, setShowSave] = useState(false)
  const [title, setTitle] = useState('')

  useEffect(() => {
    showSave && saveFilterInputEl.current.focus()
  }, [showSave])

  const savedFilters = useQuery(
    'savedFilters',
    async () => {
      const response = await axios.get('/saved_searches')
      return response.data
    },
    { staleTime: Infinity }
  )

  const handleDeleteSavedSearch = async id => {
    axios
      .delete(`/saved_searches/${id}`)
      .then(() => {
        savedFilters.refetch()
        enqueueSnackbar('Saved filter deleted.', {
          variant: 'success'
        })
      })
      .catch(() => {
        enqueueSnackbar('There was a problem with deleting the filter.', {
          variant: 'error'
        })
      })
  }

  const handleSaveFilter = async e => {
    e.preventDefault()
    if (!title) return

    const params = {
      saved_search: {
        context: 'disputes',
        title: title,
        api_params: JSON.stringify(selectedParams)
      }
    }

    axios
      .post('/saved_searches', params)
      .then(() => {
        savedFilters.refetch()
        enqueueSnackbar(`Selected search parameters saved as "${title}".`, {
          variant: 'success'
        })
        setTitle('')
        setShowSave(false)
        props.closeMenu()
      })
      .catch(() => {
        enqueueSnackbar('There was a problem with saving the filter.', {
          variant: 'error'
        })
      })
  }

  return (
    <>
      <ListSubheader className={classes.header}>
        SAVED FILTERS
        <div className={classes.iconContainer}>
          <IconButton
            aria-label="Edit Filters"
            size="small"
            onClick={() => setShowDelete(!showDelete)}
          >
            <Icon fontSize="small">edit</Icon>
          </IconButton>
        </div>
      </ListSubheader>

      {!savedFilters.isLoading &&
        savedFilters.data.map(filter => (
          <div className={classes.item} key={filter.id}>
            <Link
              href={`${qs.stringify(filter.api_params, {
                addQueryPrefix: true
              })}`}
            >
              {filter.title}
            </Link>
            <div className={classes.iconContainer}>
              {showDelete && (
                <IconButton
                  aria-label="Delete Filter"
                  size="small"
                  onClick={() => handleDeleteSavedSearch(filter.id)}
                >
                  <Icon fontSize="small">delete</Icon>
                </IconButton>
              )}
            </div>
          </div>
        ))}

      {showSave ? (
        <form onSubmit={handleSaveFilter}>
          <div className={classes.item}>
            <InputBase
              inputRef={saveFilterInputEl}
              className={classes.input}
              placeholder="Type filter name"
              value={title}
              onChange={e => setTitle(e.target.value)}
            />
            <div className={classes.iconContainer}>
              <IconButton size="small" type="submit" aria-label="Save Filter">
                <Icon size="small">save</Icon>
              </IconButton>
            </div>
          </div>
        </form>
      ) : (
        <MenuItem onClick={() => setShowSave(true)}>
          <ListItemText primary="Save current filter" />
          <div className={classes.iconContainer}>
            <ListItemIcon classes={{ root: classes.icon }}>
              <Icon size="small">save</Icon>
            </ListItemIcon>
          </div>
        </MenuItem>
      )}
    </>
  )
}

const useStyles = makeStyles({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 15px'
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 15px'
  },
  iconContainer: {
    width: 26,
    height: 26,
    marginLeft: 10
  },
  icon: {
    minWidth: 35
  },
  linkContainer: {
    display: 'flex',
    padding: '0px 15px'
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1
  }
})

export default WorkspacesMenu
