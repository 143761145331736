import React from 'react'

interface StylesProps {
  styles: string[]
}

const Styles = ({ styles }: StylesProps): React.ReactElement => {
  return (
    <>
      {styles.map(style => (
        <style dangerouslySetInnerHTML={{ __html: style }} />
      ))}
    </>
  )
}

export default React.memo(Styles)
