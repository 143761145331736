import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import { Icon, Tooltip } from '@material-ui/core'

const CustomControls = () => {
  return (
    <>
      <Tooltip title="Add Dispute">
        <IconButton onClick={() => (window.location.href = '/disputes/new')}>
          <Icon color="primary">add_circle_outline</Icon>
        </IconButton>
      </Tooltip>
    </>
  )
}
export default CustomControls
