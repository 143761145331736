import React, { CSSProperties } from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

export const DraggableRow = ({ children, style, className, ...restProps }) => {
  const { transform, transition, setNodeRef, isDragging } = useSortable({
    id: restProps?.row?.id
  })

  const dndStyle: CSSProperties = {
    transform: CSS.Transform.toString(transform), // let dnd-kit do its thing
    transition: transition,
    opacity: isDragging ? 0.8 : 1,
    zIndex: isDragging ? 1 : 0,
    position: 'relative'
  }

  return (
    <tr
      {...restProps}
      ref={setNodeRef}
      style={{ ...(style || {}), ...dndStyle }}
      className={className}
    >
      {children}
    </tr>
  )
}
