import React from 'react'
import * as moment from 'moment'
import CopyToClipboard from '../../../../utils/copy-to-clipboard'

const AlertTs = ({ value }) => {
  const formattedDate = value ? moment(value).utc().format('YYYY-MM-DD') : ''

  return <CopyToClipboard text={formattedDate}>{formattedDate}</CopyToClipboard>
}

export default AlertTs
