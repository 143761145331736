import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { WarningFilled } from '@siftscience/focus-components/icons/WarningFilled'
import { IconButton } from '@siftscience/focus-components/button'
import { Eye } from '@siftscience/focus-components/icons'
import { Delete } from '@siftscience/focus-components/icons/Delete'
import { ImageDTO } from '../dtos'

const ERROR_ICON_COLOR = '#EB3B2E'

const useStyles = makeStyles(() => ({
  imageFieldWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'relative',
    flexGrow: 1,
    padding: '16px',
    gap: '16px',
    border: '1px solid #E0E0E0'
  },
  imageFieldRightBlock: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: '8px'
  },
  imageActionsBlock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '8px'
  },
  imageDisplay: {
    maxWidth: '120px',
    maxHeight: '120px'
  },
  error: {
    position: 'absolute',
    top: '10px',
    right: '10px'
  },
  icon: {
    width: '16px',
    height: '16px'
  }
}))

interface UploadedImageViewerProps {
  isMerchantLibraryImage: boolean
  imageError: boolean
  imageInfo: ImageDTO
  onViewImage: () => void
  onDeleteClick: () => Promise<void>
}

const UploadedImageViewer = ({
  isMerchantLibraryImage,
  imageError,
  imageInfo,
  onViewImage,
  onDeleteClick
}: UploadedImageViewerProps): React.ReactElement => {
  const classes = useStyles()

  // User can't delete merchant library image
  const isDeleteVisible = !isMerchantLibraryImage

  return (
    <div className={classes.imageFieldWrapper}>
      {imageError && (
        <div className={classes.error}>
          <WarningFilled color={ERROR_ICON_COLOR} className={classes.icon} />
        </div>
      )}
      <img src={imageInfo?.src as string} className={classes.imageDisplay} />
      <div className={classes.imageFieldRightBlock}>
        <div className={classes.imageActionsBlock}>
          <IconButton variant="secondary-ghost" onClick={onViewImage}>
            <Eye />
          </IconButton>
          {isDeleteVisible ? (
            <IconButton variant="secondary-ghost" onClick={onDeleteClick}>
              <Delete />
            </IconButton>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default UploadedImageViewer
