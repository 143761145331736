import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Select from 'react-select'
import { Button, IconButton, Icon } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import Container from '@material-ui/core/Container'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import { makeStyles } from '@material-ui/core/styles'
import { useSnackbar } from 'notistack'

import http from '../../utils/http/local-with-errors'
import LoadingIndicator from '../../common/loading-indicator'
import { get, map, find, groupBy } from 'lodash'
import Jsona from 'jsona'

const apiFormatter = new Jsona()

const InquiryDefaultsIndex = props => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  const [inquiryDefaults, setInquiryDefaults] = useState([])
  const [loadingState, setLoadingState] = useState('loaded')

  const [searchParams, setSearchParams] = useState({
    merchant: { label: 'Select a merchant', value: '' },
    caid: { label: 'All', value: '' }
  })

  const [merchantOptions, setMerchantOptions] = useState([])
  useEffect(() => {
    async function fetchMerchantOptions() {
      const {
        data: { merchants, errorMessage },
        status
      } = await http({
        url: '/admin/merchants/options.json'
      })
      if (status === 200) {
        const merchantOptions = map(merchants, merchant => ({
          label: merchant.name,
          value: merchant.id
        }))
        merchantOptions.unshift({ label: 'Select a merchant', value: '' })
        const url = new URL(window.location.href)
        const merchantId = url.searchParams.get('merchantId')
        if (merchantId) {
          const merchant = find(
            merchantOptions,
            option => option.value === parseInt(merchantId)
          )
          if (merchant) setSearchParams({ ...searchParams, merchant })
        }
        setMerchantOptions(merchantOptions)
      } else {
        errorMessage && enqueueSnackbar(errorMessage, { variant: 'error' })
      }
    }
    fetchMerchantOptions()
  }, [])

  const [caidOptions, setCaidOptions] = useState([])
  useEffect(() => {
    async function fetchCaidOptions() {
      const {
        data: { caids, errorMessage },
        status
      } = await http({
        url: '/inquiry-defaults/caid-options.json',
        params: {
          merchantId: searchParams.merchant.value
        }
      })
      if (status === 200) {
        const caidOptions = map(caids, caid => ({
          label: caid.caid,
          value: caid.id
        }))
        caidOptions.unshift({ label: 'All', value: '' })
        setCaidOptions(caidOptions)
      } else {
        errorMessage && enqueueSnackbar(errorMessage, { variant: 'error' })
      }
    }
    if (searchParams.merchant.value !== '') fetchCaidOptions()
  }, [searchParams.merchant])

  useEffect(() => {
    async function fetchInquiryDefaults() {
      setLoadingState('loading')
      const {
        data: { inquiryDefaults: rawInquiryDefaults, errorMessage },
        status
      } = await http({
        url: '/inquiry-defaults.json',
        params: {
          merchantId: searchParams.merchant.value,
          caidId: searchParams.caid.value
        }
      })
      if (status === 200) {
        const inquiryDefaults = apiFormatter.deserialize(rawInquiryDefaults)
        setInquiryDefaults(inquiryDefaults)
        setLoadingState('loaded')
      } else {
        errorMessage && enqueueSnackbar(errorMessage, { variant: 'error' })
        setLoadingState('error')
      }
    }
    if (searchParams.merchant.value !== '') fetchInquiryDefaults()
  }, [searchParams])

  const history = useHistory()
  const createNew = async () => {
    history.push('/ui/inquiry-defaults/new')
  }

  const deleteInquiryDefault = async id => {
    const { status } = await http({
      method: 'delete',
      validateStatus: status => status < 500,
      url: `/inquiry-defaults/${id}`
    })
    if (status === 204) {
      enqueueSnackbar('Successfully deleted inquiry default', {
        variant: 'success'
      })
      setSearchParams({ ...searchParams })
    } else {
      enqueueSnackbar("There was an issue and we've been nontified", {
        variant: 'error'
      })
    }
  }

  const groupedPartnerHeaderCells = partnerName => {
    switch (partnerName) {
      case 'VMPI':
        return <TableCell>CAID</TableCell>
      case 'By Merchant':
        return ''
      default:
        return <TableCell>Mids</TableCell>
    }
  }
  const renderContent = () => {
    switch (loadingState) {
      case 'loaded':
        return inquiryDefaults.length > 0
          ? map(
              groupBy(
                inquiryDefaults,
                inquiryDefault =>
                  (inquiryDefault.caid && 'VMPI') ||
                  (inquiryDefault.mid_merchant_group &&
                    inquiryDefault.mid_merchant_group.partner_name) ||
                  'By Merchant'
              ),
              (groupedInquiryDefaults, partnerName) => (
                <>
                  <h2>{partnerName}</h2>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Merchant</TableCell>
                        {groupedPartnerHeaderCells(partnerName)}
                        <TableCell>Lower Amount</TableCell>
                        <TableCell>Upper Amount</TableCell>
                        <TableCell>Start Date</TableCell>
                        <TableCell>End Date</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows(groupedInquiryDefaults, partnerName)}
                    </TableBody>
                  </Table>
                </>
              )
            )
          : ''
      case 'loading':
        return <LoadingIndicator />
      case 'error':
        return <div>There was a problem</div>
    }
  }

  const groupedPartnerTableCells = (inquiryDefault, partnerName) => {
    switch (partnerName) {
      case 'VMPI':
        return <TableCell>{get(inquiryDefault, 'caid.caid')}</TableCell>
      case 'By Merchant':
        return ''
      default:
        return (
          <TableCell>
            {get(inquiryDefault, 'mid_merchant_group.mid_names', []).join(', ')}
          </TableCell>
        )
    }
  }
  const rows = (groupedInquiryDefaults, partnerName) =>
    map(groupedInquiryDefaults, inquiryDefault => (
      <TableRow key={inquiryDefault.id}>
        <TableCell>{get(inquiryDefault, 'merchant.name')}</TableCell>
        {groupedPartnerTableCells(inquiryDefault, partnerName)}
        <TableCell>{inquiryDefault.lower_amount}</TableCell>
        <TableCell>{inquiryDefault.amount}</TableCell>
        <TableCell>
          {inquiryDefault.start_date === '-4713-11-24'
            ? 'Beginning of Time'
            : inquiryDefault.start_date}
        </TableCell>
        <TableCell>
          {inquiryDefault.end_date === '5874897-12-31'
            ? 'End of Time'
            : inquiryDefault.end_date}
        </TableCell>
        <TableCell>
          <Link to={`/ui/inquiry-defaults/${inquiryDefault.id}/edit`}>
            <Button
              variant="contained"
              size="small"
              color="primary"
              className={classes.editBtn}
            >
              Edit
            </Button>
          </Link>
          <Button
            variant="contained"
            size="small"
            className={classes.deleteBtn}
            onClick={() => {
              const resp = confirm('Are you sure you want to delete?')
              if (resp) deleteInquiryDefault(inquiryDefault.id)
            }}
          >
            Delete
          </Button>
          <Link to={`/ui/inquiry-defaults/${inquiryDefault.id}/clone`}>
            <Button
              variant="contained"
              size="small"
              className={classes.cloneBtn}
            >
              Clone
            </Button>
          </Link>
        </TableCell>
      </TableRow>
    ))

  return (
    <Container maxWidth="xl">
      <Paper className={classes.root}>
        <div className={classes.searchNav}>
          <div>
            <Select
              name="merchantId"
              value={searchParams.merchant}
              onChange={merchant => {
                const caid = { label: 'All', value: '' }
                setSearchParams({ ...searchParams, merchant, caid })
              }}
              options={merchantOptions}
              className={classes.selects}
            />
            {caidOptions.length > 1 ? (
              <Select
                name="caidId"
                value={searchParams.caid}
                onChange={caid => {
                  setSearchParams({ ...searchParams, caid })
                }}
                options={caidOptions}
                className={classes.selects}
              />
            ) : (
              ''
            )}
          </div>
          <IconButton onClick={createNew}>
            <Icon color="primary">add_circle_outline</Icon>
          </IconButton>
        </div>
        {renderContent()}
      </Paper>
    </Container>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  searchNav: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 8,
    height: 60,
    justifyContent: 'space-between',
    '& > div': {
      display: 'flex'
    }
  },
  selects: {
    width: 300,
    marginRight: 5
  },
  table: {
    '& tr td': {
      lineHeight: '42px'
    },
    '& tr th': {
      lineHeight: '42px'
    }
  },
  editBtn: {
    marginRight: 5
  },
  deleteBtn: {
    marginRight: 5,
    color: theme.custom.columns.textColors['white'],
    backgroundColor: theme.custom.columns.textColors['red']
  },
  cloneBtn: {
    color: theme.custom.columns.textColors['white'],
    backgroundColor: theme.custom.columns.textColors['green']
  }
}))

export default InquiryDefaultsIndex
