import React, { useState } from 'react'
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core'

const ConfirmationModal = ({
  onCancel,
  onConfirm,
  text,
  title,
  isOpen
}: {
  onCancel: () => unknown
  onConfirm: () => unknown
  text: string
  title: string
  isOpen: boolean
}) => {
  return (
    <>
      <Dialog
        maxWidth="xs"
        aria-labelledby="confirmation-dialog-title"
        open={isOpen}
      >
        <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
        <DialogContent>{text}</DialogContent>
        <DialogActions>
          <Button onClick={() => onCancel()} color="primary">
            Cancel
          </Button>
          <Button onClick={() => onConfirm()} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ConfirmationModal
