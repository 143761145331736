import React from 'react'
import ThemeProvider from '@material-ui/styles/ThemeProvider'
import { createTheme } from '@material-ui/core/styles'
import grey from '@material-ui/core/colors/grey'
import blueGrey from '@material-ui/core/colors/blueGrey'
import red from '@material-ui/core/colors/red'

const theme = createTheme({
  palette: {
    primary: {
      main: '#248de3'
    },
    secondary: blueGrey,
    delete: red,
    action: {
      hover: '#E2E2E2',
      focus: '#FFFFFF'
    }
  },
  overrides: {
    MuiTableCell: {
      root: { padding: '0px 8px', whiteSpace: 'nowrap', fontWeight: 300 }
    },
    MuiTableRow: {
      root: {
        '&:nth-child(odd)': { background: grey[100] }
      }
    },
    MuiOutlinedInput: {
      input: {
        padding: 9.5
      }
    },
    MuiInputLabel: {
      outlined: {
        transform: 'translate(14px, 12px) scale(1)'
      }
    },
    MuiIcon: {
      root: { fontSize: 24 }
    }
  },
  custom: {
    columns: {
      base: {
        color: 'white',
        padding: 5,
        borderRadius: 5,
        fontSize: 10
      },
      bgColors: {
        white: '#ffffff',
        red: '#de5644',
        yellow: '#faad00',
        green: '#29B362',
        grey: '#455B66',
        lightGrey: '#e0e0e0',
        blue: '#1e3044',
        orange: '#ff9800',
        purple: '#8d70b2',
        deepOrange: '#ff8a65'
      },
      textColors: {
        white: '#ffffff',
        green: '#29B362',
        red: '#de5644',
        yellow: '#faad00',
        orange: '#ff9800',
        grey: '#808080'
      }
    }
  }
})

const Theme = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}
export default Theme
