import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { FormHelperText, FormLabel } from '@material-ui/core'
import { useField } from 'formik'
import Select from 'react-select'

const CustomSelect = props => {
  const classes = useStyles()
  const { value, options, isLoading = false, label, onChange, multiple } = props
  const [field, meta] = useField(props)
  const id = field.name + 'Id'
  const showError = meta.touched && Boolean(meta.error)

  return (
    <>
      {isLoading ? null : (
        <>
          <FormLabel className={classes.label} error={showError} htmlFor={id}>
            {label}
          </FormLabel>
          <Select
            isMulti={multiple}
            id={id}
            name={field.name}
            placeholder={label}
            value={
              multiple
                ? Array.isArray(value)
                  ? value.map(v => options.find(o => o.value === v))
                  : value
                : options.find(option => value === option.value)
            }
            options={options}
            onChange={onChange}
          />
          {showError && (
            <FormHelperText className={classes.helperText} error={showError}>
              {meta.error}
            </FormHelperText>
          )}
        </>
      )}
    </>
  )
}

const useStyles = makeStyles(theme => ({
  label: {
    fontSize: 12,
    color: theme.palette.text.secondary
  },
  helperText: {
    marginLeft: 14
  }
}))

export default CustomSelect
