import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import dataAdapter from './data-adapter'
import Grid from '@material-ui/core/Grid'
import MultipleSelect from '../../common/list-view/search/components/multiple-select'
import SearchFilterInput from '../../common/list-view/search/components/input'
import CustomDateRangePicker from '../../common/list-view/search/components/date-pickers/custom-date-range-picker'
import SelectionDisplay from '../../common/list-view/search/components/date-pickers/selection-display'
import parseParamsForMultiSelect from '../../common/list-view/common/parse-params-for-multiselect'

const SearchItems = props => {
  const {
    formState,
    selectOptions,
    handleChange,
    handleMultiSelectChange,
    handleSetDates
  } = props
  const classes = useStyles()

  const parseParams = parseParamsForMultiSelect({
    dataAdapter,
    formState,
    selectOptions,
    handleMultiSelectChange
  })

  const transactionTs = dataAdapter.columns['transactionTs']

  return (
    <>
      <Grid container className={classes.darkRow}>
        <Grid item xs={6}>
          <MultipleSelect {...parseParams('merchant')} />
        </Grid>
        <Grid item xs={6}>
          <MultipleSelect {...parseParams('mid')} />
        </Grid>
        <Grid item xs={6}>
          <MultipleSelect {...parseParams('gatewayId')} />
        </Grid>
        <Grid item xs={6}>
          <MultipleSelect {...parseParams('transactionType')} />
        </Grid>
      </Grid>

      <Grid container className={classes.lightRow}>
        <Grid item xs={3}>
          <SearchFilterInput
            {...{ handleChange, formState }}
            {...dataAdapter.columns['firstName']}
          />
        </Grid>
        <Grid item xs={3}>
          <SearchFilterInput
            {...{ handleChange, formState }}
            {...dataAdapter.columns['lastName']}
          />
        </Grid>
        <Grid item xs={3}>
          <SearchFilterInput
            {...{ handleChange, formState }}
            {...dataAdapter.columns['transactionId']}
          />
        </Grid>
        <Grid item xs={3}>
          <SearchFilterInput
            {...{ handleChange, formState }}
            {...dataAdapter.columns['invoiceId']}
          />
        </Grid>
      </Grid>

      <Grid container className={classes.darkRow}>
        <Grid item xs={6}>
          <div className={classes.cardInfo}>
            <SearchFilterInput
              {...{ handleChange, formState }}
              {...dataAdapter.columns['cardPrefix']}
              type="number"
            />
            <SearchFilterInput
              {...{ handleChange, formState }}
              {...dataAdapter.columns['cardSuffix']}
              type="number"
            />
            <SearchFilterInput
              {...{ handleChange, formState }}
              {...dataAdapter.columns['transactionAmount']}
              type="number"
            />
          </div>
        </Grid>
      </Grid>

      <Grid container className={classes.lightRow}>
        <Grid item xs={6}>
          <div className={classes.datePicker}>
            Txn Date:
            <CustomDateRangePicker
              name={transactionTs.name}
              handleSetDates={handleSetDates(transactionTs.name)}
              initialStartDate={formState[transactionTs.rangeStartName]}
              initialEndDate={formState[transactionTs.rangeEndName]}
            />
          </div>
        </Grid>
        <Grid item xs={6}>
          <SelectionDisplay {...props} />
        </Grid>
      </Grid>
    </>
  )
}

const allRows = {
  borderRadius: 5,
  padding: '10px 0px'
}
const useStyles = makeStyles(theme => {
  return {
    lightRow: {
      ...allRows
    },
    darkRow: {
      ...allRows,
      backgroundColor: theme.palette.secondary[50]
    },
    cardInfo: {
      display: 'flex'
    },
    datePicker: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      marginTop: 8
    }
  }
})

export default SearchItems
