import { useQuery } from 'react-query'
import axios from '../../utils/http/axios-local'

const getMerchantOptions = async () => {
  const {
    data: { merchants }
  } = await axios.get('/admin/merchants/options.json')
  return merchants.map(merchant => ({
    label: merchant.name,
    value: merchant.id
  }))
}

const useMerchantOptions = () => {
  return useQuery('merchantOptions', getMerchantOptions)
}

export default useMerchantOptions
