import moment from 'moment'

export const rangeChoices = {
  lastThirtyDays: '30 Days',
  lastFortyFiveDays: '45 Days',
  lastSixtyDays: '60 Days',
  lastYearOfDays: '1 Year',
  lastWeek: '7 Days'
}

const addDynamicDateRanges = Data => {
  const dataColumns = Data.dataAdapter.columns
  const dateFields = Object.keys(dataColumns)
    .filter(column => dataColumns[column].type === 'date')
    .map(column => dataColumns[column].name)
  return {
    ...Data.selectedParams,
    ...dateFields.reduce((acc, paramName) => {
      const thisRange = Data.selectedParams['defaultRange']
      if (thisRange) {
        const ranges = getDynamicDateRanges(thisRange)
        const thisColumn = Data.getColumnByName(paramName)
        acc[thisColumn.rangeStartName] = ranges.after
        acc[thisColumn.rangeEndName] = ranges.before
      }
      return acc
    }, {})
  }
}

const getDynamicDateRanges = range => {
  let after = moment()
  let before = moment()
  const tomorrow = moment().add(1, 'days')

  switch (range) {
    case rangeChoices['lastThirtyDays']:
      after = moment().subtract(30, 'days')
      before = tomorrow
      break
    case rangeChoices['lastFortyFiveDays']:
      after = moment().subtract(45, 'days')
      before = tomorrow
      break
    case rangeChoices['lastSixtyDays']:
      after = moment().subtract(60, 'days')
      before = tomorrow
      break
    case rangeChoices['lastYearOfDays']:
      after = moment().subtract(365, 'days')
      before = tomorrow
      break
    case rangeChoices['lastQuarter']:
      after = moment().subtract(1, 'quarter').startOf('quarter')
      before = moment().subtract(1, 'quarter').endOf('quarter')
      break
    case rangeChoices['lastYear']:
      after = moment().subtract(1, 'year').startOf('year')
      before = moment().subtract(1, 'year').endOf('year')
      break
    case rangeChoices['lastWeek']:
      after = moment().subtract(7, 'days').startOf('day')
      before = moment().subtract(7, 'days').endOf('day')
      break
  }

  return {
    after: after.format('YYYY-MM-DD'),
    before: before.format('YYYY-MM-DD')
  }
}

export default addDynamicDateRanges
