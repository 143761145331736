import React, { createContext, useContext, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { DisputeDTO } from '../dtos'
import { useDispute } from '../useDet'

interface IDisputeContext {
  disputeId?: string
  isDisputeLoading: boolean
  dispute: DisputeDTO
}

const DisputeContext = createContext<IDisputeContext>({})

export const DisputeProvider = ({ children }) => {
  const { id: disputeId } = useParams<{ id: string }>()
  const { disputeData, isDisputeLoading } = useDispute(disputeId)

  const contextValue = useMemo(
    () => ({
      disputeId,
      dispute: disputeData,
      isDisputeLoading
    }),
    [disputeId, disputeData, isDisputeLoading]
  )

  return (
    <DisputeContext.Provider value={contextValue}>
      {children}
    </DisputeContext.Provider>
  )
}

export const useDisputeContext = () => {
  return useContext(DisputeContext)
}
