import React from 'react'
import { Route } from 'react-router-dom'
import InquiryDefaultsIndex from './inquiry-defaults-index'
import InquiryDefaultsResource from './inquiry-defaults-resource'

const InquiryDefaultsRouter = () => {
  return [
    <Route
      exact
      key={1}
      path="/ui/inquiry-defaults"
      component={InquiryDefaultsIndex}
    />,
    <Route
      exact
      key={2}
      path="/ui/inquiry-defaults/:id/:action"
      component={InquiryDefaultsResource}
    />,
    <Route
      exact
      key={3}
      path="/ui/inquiry-defaults/:action"
      component={InquiryDefaultsResource}
    />
  ]
}

export default InquiryDefaultsRouter
