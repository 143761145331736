import React, { useEffect, useState, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
// https://www.npmjs.com/package/react-datepicker
import DateRangePicker from 'react-datepicker'

import { IconButton, Button } from '@material-ui/core'
import DateRangeIcon from '@material-ui/icons/DateRange'
import parseISO from 'date-fns/parseISO'
import moment from 'moment'

import { rangeChoices } from '../../add-dynamic-date-ranges'

const CustomDateRangePicker = props => {
  const {
    name,
    handleSetDates,
    allowDynamicRanges = false,
    initialStartDate,
    initialEndDate,
    initialDynamicRange
  } = props
  const classes = useStyles()
  const [startDate, setStartDate] = useState(initialStartDate || null)
  const [endDate, setEndDate] = useState(initialEndDate || null)
  const [dynamicRange, setDynamicRange] = useState(initialDynamicRange || null)
  const [shouldCloseOnSelect, setShouldCloseOnSelect] = useState(false)
  const pickerRef = useRef(null)

  useEffect(() => {
    if (!startDate && !dynamicRange) return
    handleSetDates({ startDate, endDate, dynamicRange })
  }, [endDate, dynamicRange])

  const pickDate = date => {
    if (!startDate) {
      setStartDate(moment(date).format('YYYY-MM-DD'))
      setShouldCloseOnSelect(true)
    } else {
      setEndDate(moment(date).format('YYYY-MM-DD'))
    }
  }

  const onCalendarOpen = () => {
    setShouldCloseOnSelect(false)
    setStartDate(null)
    setEndDate(null)
    setDynamicRange(null)
  }

  const onCalendarClose = () => {
    setShouldCloseOnSelect(false)
    if (!endDate) {
      setStartDate(null)
      setEndDate(null)
    }
    setDynamicRange(null)
  }

  const setDynamicDateRange = range => {
    setDynamicRange(range)
    setStartDate(null)
    setEndDate(null)
    pickerRef.current.setOpen()
  }

  const RangeChoices = () =>
    Object.keys(rangeChoices).map(range => (
      <Button
        key={range}
        size="small"
        variant="contained"
        color="primary"
        className={classes.rangeButtons}
        onClick={() => setDynamicDateRange(rangeChoices[range])}
      >
        {rangeChoices[range]}
      </Button>
    ))

  return (
    <DateRangePicker
      ref={pickerRef}
      onChange={pickDate}
      onCalendarOpen={onCalendarOpen}
      onCalendarClose={onCalendarClose}
      selectsStart
      selectsEnd
      startDate={startDate ? parseISO(startDate) : null}
      endDate={endDate ? parseISO(endDate) : null}
      shouldCloseOnSelect={shouldCloseOnSelect}
      allowSameDay
      minDate={startDate ? parseISO(startDate) : moment().subtract(10, 'years')}
      customInput={
        <IconButton size="small" data-testid={`${name}-picker`}>
          <DateRangeIcon />
        </IconButton>
      }
    >
      {allowDynamicRanges ? (
        <div className={classes.rangeButtonContainer}>
          <RangeChoices />
        </div>
      ) : null}
    </DateRangePicker>
  )
}

const useStyles = makeStyles({
  rangeButtonContainer: { marginBottom: 3 },
  rangeButtons: { padding: 0, margin: 1, fontSize: 10, minWidth: 58 }
})

export default CustomDateRangePicker
