import { NotificationType } from './NotificationReducers'

interface INotificationStore {
  notification?: string
  notificationType?: NotificationType
}

const NotificationStore: INotificationStore = {
  notification: '',
  notificationType: ''
}

export { NotificationStore as default, INotificationStore }
