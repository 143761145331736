import React from 'react'

const Currency = ({ value }) => {
  return (
    <div style={{ textAlign: 'right' }}>
      {value ? parseFloat(value).toFixed(2) : null}
    </div>
  )
}
export default Currency
