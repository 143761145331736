import React, { useState } from 'react'
import { Modal } from '@siftscience/focus-components/modal'
import { Action } from '@siftscience/focus-components/action-bar'
import { Text } from '@siftscience/focus-components/text'
import { ButtonVariant } from '@siftscience/focus-components/button'
import { makeStyles } from '@material-ui/core/styles'
import { VerificationWarningFilled } from '@siftscience/focus-components/icons/VerificationWarningFilled'
import { Send } from '@siftscience/focus-components/icons/Send'
import FinalizePreview from './finalize-preview'
import axios from '../../utils/http/axios-local'
import { DisputeDTO } from './dtos'

const MODAL_ACTION_LABEL = {
  send: 'Send',
  readyToSend: 'Ready to Send',
  cancel: 'Cancel'
}

const MODAL_ACTIONS_MAP: Action = {
  send: {
    label: (
      <>
        <Send />
        <span>{MODAL_ACTION_LABEL.send}</span>
      </>
    ),
    size: 'medium',
    id: MODAL_ACTION_LABEL.send
  },
  readyToSend: {
    label: (
      <>
        <Send />
        <span>{MODAL_ACTION_LABEL.readyToSend}</span>
      </>
    ),
    size: 'medium',
    id: MODAL_ACTION_LABEL.readyToSend
  },
  cancel: {
    label: MODAL_ACTION_LABEL.cancel,
    variant: 'secondary' as ButtonVariant,
    size: 'medium',
    lined: true,
    id: MODAL_ACTION_LABEL.cancel
  }
}

const useStyles = makeStyles(() => ({
  modalContent: {
    overflow: 'scroll'
  },
  modalFooterContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '8px'
  }
}))

const MODAL_FOOTER_CONTENT = () => {
  const classes = useStyles()

  return (
    <div className={classes.modalFooterContent}>
      <VerificationWarningFilled color="#EB3B2E" />
      <Text size="small">
        You won’t be able to modify response once it’s sent
      </Text>
    </div>
  )
}

interface FinalizeModalProps {
  disputeId: string
  onCloseFinalizeModal: () => void
  onFinalize: (data: DisputeDTO) => void
  isManualDelivery: boolean
}

const FinalizeModal = ({
  disputeId,
  onCloseFinalizeModal,
  onFinalize,
  isManualDelivery
}: FinalizeModalProps): React.ReactElement => {
  const [isLoading, setIsLoading] = useState(false)
  const classes = useStyles()

  const onCloseModal = () => {
    onCloseFinalizeModal()
  }

  const modalActions = (): Action[] => {
    const actions: Action[] = []

    if (isManualDelivery) {
      actions.push(MODAL_ACTIONS_MAP.readyToSend)
    } else {
      actions.push(MODAL_ACTIONS_MAP.send)
    }

    actions.push(MODAL_ACTIONS_MAP.cancel)

    return actions
  }

  const onModalAction = (action: Action) => {
    if (action.id === MODAL_ACTION_LABEL.cancel) {
      onCloseModal()
      return
    }
    if (
      action.id === MODAL_ACTION_LABEL.send ||
      action.id === MODAL_ACTION_LABEL.readyToSend
    ) {
      setIsLoading(true)
      axios
        .post<DisputeDTO>('/det/disputes/finalize', {
          dispute_id: disputeId
        })
        .then(({ data }) => {
          setIsLoading(false)
          onFinalize(data)
          onCloseModal()
        })
        .catch(error => {
          console.log('Error while finalizing response', error)
          setIsLoading(false)
        })
    }
  }

  return (
    <div className="det-modal">
      <Modal
        contentContainerClassName={classes.modalContent}
        variant="fixed"
        title={'Send Response'}
        onCloseButtonClick={onCloseModal}
        onClickOutside={onCloseModal}
        actions={modalActions()}
        onAction={onModalAction}
        footerContent={<MODAL_FOOTER_CONTENT />}
        size="large"
      >
        <FinalizePreview disputeId={disputeId} isParentLoading={isLoading} />
      </Modal>
    </div>
  )
}

export default FinalizeModal
