import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'

const Notes = ({ value }) => {
  const classes = useStyles()
  return (
    <>
      {value ? (
        <Tooltip
          title={<pre className={classes.note}>{value}</pre>}
          interactive
        >
          <i className="material-icons yellow-text">assignment</i>
        </Tooltip>
      ) : (
        ''
      )}
    </>
  )
}

const useStyles = makeStyles(theme => ({
  note: { fontSize: 12 }
}))

export default Notes
