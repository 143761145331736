import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Tabs, Tab } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  tabs: {
    marginLeft: 30,
    '& a': {
      minWidth: 'auto'
    },
    '& .MuiTab-wrapper': {
      textTransform: 'capitalize',
      fontSize: theme.typography.fontSize
    }
  }
}))

const tabs = ['login']

const LoginNav = (): React.ReactChild => {
  const [currentTab, setCurrentTab] = useState(tabs[0])
  const classes = useStyles()

  const activeLocation = () => {
    const currentLocation = tabs.find(tab =>
      window.location.pathname.includes(tab)
    )
    setCurrentTab(currentLocation || tabs[0])
  }
  useEffect(activeLocation, [])

  return (
    <>
      <Tabs
        value={currentTab}
        className={classes.tabs}
        onChange={(_, newValue) => setCurrentTab(newValue)}
      >
        <Tab value="login" component="a" label="Login" href="/ui/login" />
      </Tabs>
    </>
  )
}

export default LoginNav
