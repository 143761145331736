import React, { useEffect, useMemo, useRef } from 'react'
import { ColumnDef, Row } from '@tanstack/react-table'
import { Table } from '@siftscience/focus-components/table'
import { TextInput } from '@siftscience/focus-components/input'
import { Button, IconButton } from '@siftscience/focus-components/button'
import { Delete } from '@siftscience/focus-components/icons/Delete'
import { Title } from '@siftscience/focus-components/text'
import { v4 as uuid } from 'uuid'
import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  type DragEndEvent,
  type UniqueIdentifier,
  useSensor,
  useSensors
} from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { makeStyles } from '@material-ui/core/styles'
import { capitalize, get, snakeCase, isEqual } from 'lodash'
import { getDatasetPath } from '../../utils/det'
import { DataField, DatasetDTO, ImageDTO } from './dtos'
import { DraggableRow } from './table-components/draggable-row'
import { RowDragHandleCell } from './table-components/row-drag-handle-cell'

const COLUMN_PINNING_STATE = {
  right: ['delete']
}

export type RowData = Record<string, string>

const useStyles = makeStyles(() => ({
  arrayTable: {
    width: '100%'
  },
  actions: {
    marginTop: '16px',
    marginBottom: '16px'
  }
}))

interface ArrayFieldProps {
  dataset: DatasetDTO
  dataField: DataField
  changes: Record<
    string,
    string | Record<string, string>[] | Record<string, string> | ImageDTO
  >
  onArrayChange: (newArray: Record<string, string>[], field: DataField) => void
  onChangeRow: (newArray: Record<string, string>[], field: DataField) => void
  onInputBlur: (field: DataField) => void
  focusedField?: { name: string; isArray: boolean }
  onAutoFocus?: (event) => void
}

const ArrayField = ({
  dataset,
  dataField,
  changes,
  onArrayChange,
  onChangeRow,
  onInputBlur,
  onAutoFocus,
  focusedField
}: ArrayFieldProps): React.ReactElement => {
  // optimization for input change in Table
  const dataIdsRef = useRef([])
  const changesRef = useRef(null)
  const classes = useStyles()

  useEffect(() => {
    changesRef.current = changes
  }, [changes])

  const data: RowData[] = useMemo(() => {
    const arrayData =
      changes[dataField?.datasetPath] ??
      get(dataset, getDatasetPath(dataField?.datasetPath))

    // eslint-disable-next-line no-unused-expressions
    arrayData?.forEach(item => {
      // just for the sake of drag-n-drop
      if (!item.det_internal_id) {
        item.det_internal_id = uuid()
      }
    })

    return (arrayData || []) as unknown as Record<string, string>[]
  }, [dataField, changes])

  const dataIds: UniqueIdentifier[] = useMemo(() => {
    const updatedDataIds = data?.map(item => item.det_internal_id)
    const currentDataIds = dataIdsRef.current
    const isTheSameAsExisting = isEqual(currentDataIds, updatedDataIds)
    if (isTheSameAsExisting) {
      // if ids weren't changed, we do not want to create a new array
      // this part is essential for inputs in table to work properly
      // and not be recreated on each change.
      return currentDataIds
    } else {
      dataIdsRef.current = updatedDataIds
      return updatedDataIds
    }
  }, [data, dataIdsRef])

  const onDeleteRow = (row: Row<RowData>) => {
    const index = row.index
    const existingValue = (changesRef.current[dataField?.datasetPath] ??
      get(dataset, getDatasetPath(dataField?.datasetPath)) ??
      []) as Record<string, string>[]
    const newValue = [...existingValue]
    newValue.splice(index, 1)
    onChangeRow(newValue, dataField)
  }

  const onTableInputChange = (
    value: string,
    row: Row<RowData>,
    key: string
  ) => {
    const index = row.index
    const existingValue = (changesRef.current[dataField?.datasetPath] ??
      get(dataset, getDatasetPath(dataField?.datasetPath)) ??
      []) as Record<string, string>[]
    const newValue = existingValue.map(value => ({ ...value }))
    newValue[index][key] = value
    onArrayChange(newValue, dataField)
  }

  const columns: ColumnDef<RowData>[] = useMemo(() => {
    let highlightAccessorKey
    let highlightRowIndex
    if (focusedField?.isArray) {
      const hightlightedCellPath = focusedField?.name?.split('#')
      const hightlightedFieldName = hightlightedCellPath[0]
      if (hightlightedFieldName === dataField.name) {
        highlightAccessorKey = hightlightedCellPath[1]
        highlightRowIndex = +hightlightedCellPath[2]
      }
    }

    // We have properties.properties in older schemas and properties in newer, so we need to support both.
    const properties =
      dataField?.jsonSchema?.items?.[0]?.properties?.properties ||
      dataField?.jsonSchema?.items?.[0]?.properties ||
      {}
    const requiredFields =
      dataField?.jsonSchema?.items?.[0]?.properties?.required ||
      dataField?.jsonSchema?.items?.[0]?.required ||
      []
    const requiredFieldsHash =
      Array.isArray(requiredFields) &&
      requiredFields?.reduce((hash, fieldTitle) => {
        hash[fieldTitle] = true
        return hash
      }, {})
    const allColumns = Object.keys(properties).map(key => {
      const snakeCaseKey = snakeCase(key)
      const title = properties[key]?.title || key
      return {
        accessorKey: snakeCaseKey,
        header: capitalize(title),
        id: dataField.id + '.' + snakeCaseKey,
        cell: ({ getValue, row }) => {
          const value = getValue<string>()
          const isAutoFocused =
            snakeCaseKey === highlightAccessorKey &&
            row.index === highlightRowIndex
          const isRequired = requiredFieldsHash[key] || false

          return (
            <TextInput
              value={value}
              onChange={value =>
                onTableInputChange(value.target.value, row, snakeCaseKey)
              }
              onBlur={() => onInputBlur(dataField)}
              containerStyle={{ width: '100%' }}
              autoFocus={isAutoFocused}
              onFocus={event => {
                if (isAutoFocused) {
                  onAutoFocus(event)
                }
              }}
              required={isRequired}
              containerClassName={`${isRequired && !value ? 'error' : ''}`}
            />
          )
        }
      }
    })

    allColumns.unshift({
      id: 'drag-handle',
      header: 'Move',
      cell: ({ row }) => <RowDragHandleCell rowId={row.id} />,
      size: 60
    })

    allColumns.push({
      accessorKey: 'delete',
      header: '',
      id: `delete`,
      cell: ({ row }) => {
        return (
          <IconButton
            variant="secondary-ghost"
            onClick={() => onDeleteRow(row)}
          >
            <Delete />
          </IconButton>
        )
      }
    })
    return allColumns
  }, [dataset, dataField])

  const onAddRow = () => {
    const existingValue = (changes[dataField?.datasetPath] ??
      get(dataset, getDatasetPath(dataField?.datasetPath)) ??
      []) as Record<string, string>[]
    const properties = dataField?.jsonSchema?.items?.[0]?.properties || {}
    const newRowEmptyObject = Object.keys(properties).reduce((hash, item) => {
      const snakeCaseItem = snakeCase(item)
      hash[snakeCaseItem] = ''
      return hash
    }, {})
    const newValue = [...existingValue, newRowEmptyObject]
    onArrayChange(newValue, dataField)
  }

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event
    if (active && over && active.id !== over.id) {
      const existingValue = (changesRef.current[dataField?.datasetPath] ??
        get(dataset, getDatasetPath(dataField?.datasetPath)) ??
        []) as Record<string, string>[]
      const newValue = [...existingValue]
      const oldIndex = dataIds.indexOf(active.id)
      const newIndex = dataIds.indexOf(over.id)
      const element = newValue[oldIndex]

      if (oldIndex === -1 || newIndex === -1) {
        // Do not do anything in case of internal id mismatch
        return
      }

      newValue.splice(oldIndex, 1) // remove dragged element from array
      newValue.splice(newIndex, 0, element) // insert dragged element into array
      onChangeRow(newValue, dataField)
    }
  }

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  )

  const customRowsWrapperComponent = useMemo(
    () => props => {
      return (
        <SortableContext
          id={dataField?.name}
          items={dataIds}
          strategy={verticalListSortingStrategy}
        >
          {props?.children}
        </SortableContext>
      )
    },
    [dataField, dataIds, verticalListSortingStrategy]
  )

  const table = useMemo(
    () => (
      <Table
        columns={columns}
        data={data || []}
        initialState={{
          columnPinning: COLUMN_PINNING_STATE
        }}
        getRowId={row => row.det_internal_id}
        customRowComponent={DraggableRow}
        customRowsWrapperComponent={customRowsWrapperComponent}
        enableColumnPinning
      />
    ),
    [data, columns, DraggableRow]
  )

  return (
    <div className={classes.arrayTable}>
      <Title>{dataField.name}:</Title>
      <DndContext
        collisionDetection={closestCenter}
        modifiers={[restrictToVerticalAxis]}
        onDragEnd={handleDragEnd}
        sensors={sensors}
      >
        {table}
      </DndContext>
      <div className={classes.actions}>
        <Button variant="secondary" lined onClick={onAddRow}>
          + Add Row
        </Button>
      </div>
    </div>
  )
}

export default ArrayField
