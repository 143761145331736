import React, { useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import SearchToggle from './search-toggle'
import Chips from './chips'
import ColumnSelect from './column-select'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import Tooltip from '@material-ui/core/Tooltip'
import ListViewContext from '../list-view-context'

const MainBar = props => {
  const classes = useStyles()
  const { options, clearSearch, CustomControls } = useContext(ListViewContext)
  const [showColumnSelect, setShowColumnSelect] = useState(false)

  return (
    <div id="main-bar" className={classes.container}>
      <div className={classes.searchToggle}>
        <SearchToggle />
      </div>
      <div className={classes.chips}>
        <Chips />
      </div>
      <div className={classes.controls}>
        {options.useColumnSelector ? (
          <>
            <Tooltip title="Choose Columns">
              <IconButton
                onClick={() => setShowColumnSelect(!showColumnSelect)}
              >
                <Icon>table_chart</Icon>
              </IconButton>
            </Tooltip>
            <ColumnSelect
              {...{
                showColumnSelect,
                setShowColumnSelect
              }}
            />
          </>
        ) : null}

        {CustomControls ? <CustomControls /> : null}

        <Tooltip title="Clear Search">
          <IconButton onClick={clearSearch}>
            <Icon>refresh</Icon>
          </IconButton>
        </Tooltip>
      </div>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  searchToggle: {
    width: 170
  },
  chips: {
    width: '100%'
  },
  controls: {
    width: 200,
    display: 'flex',
    justifyContent: 'flex-end'
  }
}))

export default MainBar
