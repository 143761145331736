import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import { Button } from '@material-ui/core'

const ControlColumn = props => {
  const { row } = props
  const classes = useStyles()

  return (
    <TableCell className={classes.root}>
      <Button
        href={`/alerts/${row.id}`}
        className={classes.reviewBtn}
        variant="contained"
        size="small"
      >
        Details
      </Button>
    </TableCell>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    textAlign: 'center',
    padding: 5
  },
  reviewBtn: {
    width: 80,
    backgroundColor: theme.custom.columns.textColors['grey']
  }
}))

export default ControlColumn
