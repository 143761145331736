import React, { useState, useContext, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import AssignmentIndTwoToneIcon from '@material-ui/icons/AssignmentIndTwoTone'
import NoteAddTwoToneIcon from '@material-ui/icons/NoteAddTwoTone'
import Select from 'react-select'
import NotesDialog from './notes-dialog'
import { useSnackbar } from 'notistack'
import { massAssignToSelf, updateAssignments } from './update-assignments'
import { useCurrentUser } from '../../contexts/CurrentUserContext'
import {
  DispatchCheckboxContext,
  CheckboxContext
} from '../../common/list-view/common/checkboxes/checkbox-context'
import { Icon } from '@material-ui/core'
import qs from 'qs'
import axios from '../../utils/http/axios-local'
import { CHECKBOX_TYPE } from '../../common/list-view/common/checkboxes/checkbox-reducer'

const MassActions = props => {
  const { selectOptions, refetch, setShowMassActions } = props
  const classes = useStyles()
  const { currentUser } = useCurrentUser()
  const { enqueueSnackbar } = useSnackbar()
  const [assignee, setAssignee] = useState('')
  const [tags, setTags] = useState([])
  const [lifecycleStatus, setLifecycleStatus] = useState('')
  const [disposition, setDisposition] = useState('')
  const [showNoteDialog, setShowNoteDialog] = useState(false)
  const dispatchCheckboxes = useContext(DispatchCheckboxContext)
  const checkboxes = useContext(CheckboxContext)
  const count = checkboxes.selectedIds.length
  const [tagOptions, setTagOptions] = useState([])

  useEffect(() => {
    if (checkboxes.selectedIds && checkboxes.selectedIds.length) {
      const fetchData = async () => {
        const response = await axios.get('/disputes/get_tag_options', {
          params: { dispute_ids: checkboxes.selectedIds }
        })
        setTagOptions(response.data)
      }
      fetchData()
    }
  }, [checkboxes.selectedIds])

  const assignToSelf = async () => {
    await massAssignToSelf(currentUser.id, checkboxes.selectedIds)
    enqueueSnackbar(
      `Assigned ${count} Dispute${count > 1 ? 's' : ''} to you.`,
      { variant: 'success' }
    )
    setShowMassActions(false)
    refetch()
  }

  const saveAssignmentsForm = async () => {
    if (!hasValuesToUpdate(tags, lifecycleStatus, disposition, assignee)) {
      enqueueSnackbar('Nothing was selected!', { variant: 'warning' })
      return false
    }

    await updateAssignments(
      checkboxes.selectedIds,
      tags.map(tag => tag.value),
      lifecycleStatus.value,
      disposition.value,
      assignee.value
    )
    enqueueSnackbar(`${count} Dispute${count > 1 ? 's' : ''} updated.`, {
      variant: 'success'
    })
    clearForm()
    setShowMassActions(false)
    refetch()
  }

  const hasValuesToUpdate = (tags, lifecycleStatus, disposition, assignee) => {
    return !!(
      tags.length ||
      (lifecycleStatus && lifecycleStatus.value) ||
      (disposition && disposition.value) ||
      (assignee && assignee.value)
    )
  }

  const closeMassActions = () => {
    clearForm()
    dispatchCheckboxes({ type: CHECKBOX_TYPE.toggleAll, checked: false })
  }

  const clearForm = () => {
    setTags('')
    setLifecycleStatus('')
    setDisposition('')
    setAssignee('')
  }

  return (
    <div id="mass-actions" className={classes.container}>
      <div className={classes.actions}>
        <Tooltip title="Assign to me">
          <IconButton onClick={assignToSelf}>
            <AssignmentIndTwoToneIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="Add a note">
          <IconButton onClick={() => setShowNoteDialog(true)}>
            <NoteAddTwoToneIcon />
          </IconButton>
        </Tooltip>

        <NotesDialog
          {...{
            showNoteDialog,
            setShowNoteDialog,
            checkboxes,
            setShowMassActions,
            refetch
          }}
        />
      </div>

      <div className={classes.form}>
        <Select
          isMulti
          name="tags"
          placeholder="Tags"
          value={tags}
          options={[
            { label: '[Remove all tags]', value: 'Clear' },
            ...tagOptions
          ]}
          onChange={value => setTags(value || [])}
          isLoading={selectOptions.isLoading}
          className={classes.tags}
          isClearable={true}
        />

        <Select
          name="lifecycleStatus"
          placeholder="Status"
          value={lifecycleStatus}
          options={selectOptions.data.data['lifecycleStatus']}
          onChange={value => setLifecycleStatus(value || '')}
          isLoading={selectOptions.isLoading}
          className={classes.selects}
          isClearable={true}
        />

        <Select
          name="disposition"
          placeholder="Outcome"
          value={disposition}
          options={selectOptions.data.data['disposition']}
          onChange={value => setDisposition(value || '')}
          isLoading={selectOptions.isLoading}
          className={classes.selects}
          isClearable={true}
        />

        <Select
          name="assignTo"
          placeholder="Assign To"
          value={assignee}
          options={[
            { label: 'Unassign', value: 'Unassign' },
            ...selectOptions.data.data['assignedTo']
          ]}
          onChange={value => setAssignee(value || '')}
          isLoading={selectOptions.isLoading}
          className={classes.selects}
          isClearable={true}
        />

        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={saveAssignmentsForm}
          className={classes.apply}
        >
          Apply
        </Button>
        <Button
          variant="contained"
          size="small"
          color="default"
          onClick={closeMassActions}
        >
          Quit
        </Button>

        <Tooltip title="Download dispute response documents into a zip">
          <IconButton
            href={`/disputes/pdf_zip.zip?${qs.stringify(
              {
                result_ids: checkboxes.selectedIds,
                page: 'disputes'
              },
              { arrayFormat: 'brackets' }
            )}`}
          >
            <Icon>file_download</Icon>
          </IconButton>
        </Tooltip>
      </div>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    zIndex: 20
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-evenly'
  },
  form: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%'
  },
  tags: {
    marginRight: 5,
    width: '100%'
  },
  selects: {
    marginRight: 5,
    width: 300
  },
  apply: {
    marginRight: 5
  }
}))

export default MassActions
