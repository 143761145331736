import React, { useMemo, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Title, Text } from '@siftscience/focus-components/text'
import { TabBar, TabButton } from '@siftscience/focus-components/tabs'
import { Select } from '@siftscience/focus-components/select'
import { Button } from '@siftscience/focus-components/button'
import { SegmentedControl } from '@siftscience/focus-components/segmented-control'
import {
  Dropdown,
  DropdownMenuContainer
} from '@siftscience/focus-components/dropdown'
import { AcceptLiability, Checkmark } from '@siftscience/focus-components/icons'
import { Theme } from '@material-ui/core'
import Counter from './counter'
import axios from '../../utils/http/axios-local'
import { formatDate } from './utils'
import { Filters, RenderingStatus, SAVING_STATUS, Tabs } from './enums'
import { DisputeDTO, LifecycleStatus, RenderingDTO, UserDTO } from './dtos'
import { ILockedState } from './interfaces'

const FILTER_ITEMS = ['Incomplete', 'Enhanceable', 'All Fields']

const TAB_ITEMS = ['Rendered', 'Data Entry']

const MORE_ACTIONS_VALUES = {
  acceptLiability: 'accept_liability'
}

interface HeaderProps {
  renderingsData: RenderingDTO[]
  onFilterSelect: (filter: Filters, index: number) => void
  selectedFilter: Filters
  selectedTab: Tabs
  savingStatus: SAVING_STATUS
  lockedStatus: ILockedState
  onSelectTab: (tabs: Tabs, index: number) => void
  onFinalize: () => void
  disableEditing: (dispute: DisputeDTO) => void
  disableManualSubmit: (dispute: DisputeDTO) => void
  manuallySubmittedStatus: boolean
  isManualDelivery: boolean
  setPageLoading: (value: boolean) => void
  dispute: DisputeDTO
  disputeId: string
}

interface StyleProps {
  locked: boolean
}

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  headerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    height: props => (props.locked ? '64px' : '96px'),
    padding: '24px 32px 0 32px',
    backgroundColor: 'white' // TODO check for proper bg color const
  },
  headerLeft: {
    height: '72px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  },
  headerLeftTop: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-end'
  },
  savingStatus: {
    marginLeft: '12px',
    display: 'flex',
    paddingBottom: '2px'
  },
  headerRight: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    gap: '8px'
  },
  counterWrapper: {
    marginLeft: '8px'
  },
  checkmarkIcon: {
    width: '16px',
    height: '16px',
    marginLeft: '4px'
  },
  icon: {
    width: '16px',
    height: '16px'
  },
  moreActionsItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '8px'
  },
  acceptLiability: {
    backgroundColor: '#EBEBEB',
    padding: '4px 8px',
    borderRadius: '40px',
    marginLeft: '12px',
    display: 'flex'
  },
  disabledSubmitDisputeManuallyButton: {
    color: 'gray'
  }
}))

const Header = ({
  renderingsData,
  onFilterSelect,
  onFinalize,
  selectedFilter,
  selectedTab,
  savingStatus,
  onSelectTab,
  dispute,
  disputeId,
  lockedStatus,
  disableEditing,
  isManualDelivery,
  disableManualSubmit,
  manuallySubmittedStatus,
  setPageLoading
}: HeaderProps): React.ReactElement => {
  const [assigneeOptions, setAssigneeOptions] = useState<UserDTO[]>([])
  const [assignedUser, setAssignedUser] = useState<UserDTO>({})
  const classes = useStyles({
    locked:
      lockedStatus.locked ||
      lockedStatus.expired ||
      lockedStatus.acceptedLiability
  })

  const filtersCount = useMemo(() => {
    return renderingsData.reduce(
      (hash, item) => {
        if (item.status === RenderingStatus.INCOMPLETE) {
          hash.incomplete += 1
        }

        if (item.status === RenderingStatus.ENHANCEABLE) {
          hash.enhanceable += 1
        }
        return hash
      },
      { incomplete: 0, enhanceable: 0 }
    )
  }, [renderingsData])

  useEffect(() => {
    if (dispute) {
      setAssignedUser(dispute.assignee)
    }
  }, [dispute])

  useEffect(() => {
    axios
      .get<UserDTO[]>(`/det/disputes/${disputeId}/assignee_options`)
      .then(({ data }) => {
        if (data) {
          data.unshift({ id: -1, name: 'Unassign' } as UserDTO)
          setAssigneeOptions(data)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  const onFilterClick = (item, index) => {
    onFilterSelect(item, index)
  }

  const onTabClick = (item, index) => {
    onSelectTab(item, index)
  }

  const onAssignedUserChange = (newAssignee: UserDTO) => {
    const isUnassignAction = assignedUser && newAssignee?.id === -1

    if (!isUnassignAction && newAssignee.id === -1) {
      return
    }

    if (isUnassignAction) {
      setAssignedUser({})
      axios
        .delete<DisputeDTO>(
          `/det/disputes/${disputeId}/assignee?user_id=${assignedUser.id}`
        )
        .catch(error => {
          console.log(error)
        })
    } else {
      setAssignedUser(newAssignee)
      axios
        .post<DisputeDTO>(
          `/det/disputes/${disputeId}/assignee?user_id=${newAssignee.id}`
        )
        .catch(error => {
          console.log(error)
        })
    }
  }

  const onMoreActionClick = async (action: string) => {
    setPageLoading(true)
    if (action === MORE_ACTIONS_VALUES.acceptLiability) {
      try {
        const { data: updatedDispute } = await axios.post<DisputeDTO>(
          '/det/disputes/accept_liability',
          {
            dispute_id: disputeId
          }
        )

        if (
          updatedDispute.lifecycleStatus === LifecycleStatus.acceptLiability
        ) {
          disableEditing(updatedDispute)
        }
      } catch (error) {
        console.log('error::: ', error)
      }
    }
    setPageLoading(false)
  }

  const onFinalizeClick = () => {
    onFinalize()
  }

  const onDisputeSubmittedManuallyClick = async () => {
    try {
      const { data: updatedDispute } = await axios.post<DisputeDTO>(
        `/det/disputes/${disputeId}/submit_manually`
      )
      disableManualSubmit(updatedDispute)
    } catch (error) {
      console.log(error)
    }
  }

  const moreActionsItems = [
    {
      label: 'Accept Liability',
      icon: <AcceptLiability className={classes.icon} />,
      value: MORE_ACTIONS_VALUES.acceptLiability
    }
  ]

  const isLocked =
    lockedStatus?.locked ||
    lockedStatus?.expired ||
    lockedStatus?.acceptedLiability

  return (
    <div className={`shadow-s ${classes.headerWrapper}`}>
      <div className={classes.headerLeft}>
        <div className={classes.headerLeftTop}>
          <Title size="large">Delivery Fields</Title>
          {savingStatus === SAVING_STATUS.saving && (
            <Text
              className={classes.savingStatus}
              size="xsmall"
              color="secondary"
            >
              Saving changes...
            </Text>
          )}
          {savingStatus === SAVING_STATUS.saved && (
            <Text
              className={classes.savingStatus}
              size="xsmall"
              color="secondary"
            >
              Saved
              <Checkmark className={classes.checkmarkIcon} color="#B3B3B3" />
            </Text>
          )}
          {lockedStatus.expired && (
            <Text
              className={classes.savingStatus}
              size="xsmall"
              color="secondary"
            >
              Dispute expired on {formatDate(dispute.expirationDate)}
            </Text>
          )}
          {lockedStatus.acceptedLiability && (
            <div className={classes.acceptLiability}>
              <Text size="xsmall">Accepted Liability</Text>
            </div>
          )}
          {dispute.sentOn && !dispute.deliveredOn && (
            <Text
              className={classes.savingStatus}
              size="xsmall"
              color="secondary"
            >
              Sent on {formatDate(dispute.sentOn)}
            </Text>
          )}
          {dispute.deliveredOn && (
            <Text
              className={classes.savingStatus}
              size="xsmall"
              color="secondary"
            >
              Delivered on {formatDate(dispute.deliveredOn)}
            </Text>
          )}
        </div>
        {isLocked ? null : (
          <TabBar
            items={FILTER_ITEMS}
            onSelect={onFilterClick}
            selectedIndex={selectedFilter}
            itemRenderer={(item, index, selected, onSelect) => {
              return (
                <TabButton
                  item={item}
                  index={index}
                  onSelect={onSelect}
                  selected={selected}
                  key={item as string}
                >
                  {item}
                  {index === Filters.Incomplete && (
                    <span className={classes.counterWrapper}>
                      <Counter value={filtersCount.incomplete?.toString()} />
                    </span>
                  )}
                  {index === Filters.Enhanceable && (
                    <span className={classes.counterWrapper}>
                      <Counter value={filtersCount.enhanceable?.toString()} />
                    </span>
                  )}
                </TabButton>
              )
            }}
          />
        )}
      </div>
      {isLocked ? (
        isManualDelivery && (
          <Button
            variant="secondary"
            lined
            onClick={onDisputeSubmittedManuallyClick}
            disabled={manuallySubmittedStatus}
          >
            <Text
              size="xsmall"
              className={`${
                manuallySubmittedStatus
                  ? classes.disabledSubmitDisputeManuallyButton
                  : ''
              }`}
            >
              Dispute Delivered Manually
            </Text>
          </Button>
        )
      ) : (
        <>
          <SegmentedControl
            items={TAB_ITEMS}
            selectedIndex={selectedTab}
            onSelect={onTabClick}
          />
          <div className={classes.headerRight}>
            <Select
              items={assigneeOptions}
              selectedItemRenderer={(assignedUser: UserDTO) => {
                return assignedUser.name
              }}
              selectedItem={assigneeOptions.find(
                (assignee: UserDTO) => assignee?.id === assignedUser?.id
              )}
              onChange={onAssignedUserChange}
              getItemLabel={(item: UserDTO) => {
                return item.name
              }}
              placeholder="Assign to ..."
              variant="secondary"
              strategy="fixed"
              stretch={false} // false is true and true is false :thumbs_up:
            />
            <Dropdown
              placement="bottom"
              displayRenderer={({ getToggleButtonProps }) => (
                <Button variant="secondary" lined {...getToggleButtonProps()}>
                  ...
                </Button>
              )}
              dropdownRenderer={({ isOpen, getMenuProps, closeMenu }) => {
                if (!isOpen) {
                  return null
                }

                const { style, ...props } = getMenuProps(undefined, {
                  suppressRefError: true
                })

                return (
                  <DropdownMenuContainer
                    {...props}
                    style={{ ...style, width: '170px', padding: '4px' }}
                  >
                    {moreActionsItems.map(({ label, value, icon }) => (
                      <Button
                        className={classes.moreActionsItem}
                        variant="secondary-ghost"
                        onClick={() => {
                          onMoreActionClick(value)
                          closeMenu()
                        }}
                      >
                        {icon}
                        <Text
                          className={classes.savingStatus}
                          size="xsmall"
                          color="secondary"
                        >
                          {label}
                        </Text>
                      </Button>
                    ))}
                  </DropdownMenuContainer>
                )
              }}
            />
            <Button onClick={onFinalizeClick}>Finalize</Button>
          </div>
        </>
      )}
    </div>
  )
}

export default Header
