import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import axios from '../../utils/http/axios-local'
import type { AxiosResponse } from 'axios'
import openFile from '../../utils/open-file'
import LoadingIndicator from '../../common/loading-indicator'
import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  MenuItem,
  MenuList
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { makeStyles } from '@material-ui/core/styles'
import RED from '@material-ui/core/colors/red'
import { Edit, PictureAsPdf, GetApp } from '@material-ui/icons'
import { useSnackbar } from 'notistack'
import PreviewNode from '../preview-node'
import EvidenceFieldErrorsAlert from './evidence-field-errors-alert'
import TemplateErrorsAlert from '../evidence-preview/template-errors-alert'
import DeliveryEventMessage from './delivery-event-message'
import moment from 'moment'
import ActionCable from 'actioncable-modules'

type DeliveryEvent = SuccessfulDeliveryEvent | FailedDeliveryEvent
interface SuccessfulDeliveryEvent {
  status: 'success'
  timestamp: string
}
interface FailedDeliveryEvent {
  status: 'failure'
  timestamp: string
  error: string
}

const menuItemStyles = makeStyles({
  error: {
    backgroundColor: RED[50],
    color: 'yellow'
  }
})

const ResponsePreview = () => {
  const { enqueueSnackbar } = useSnackbar()
  const { id }: { id: string } = useParams()

  const [dispute, setDispute] = useState(null)
  const [responseDocument, setResponseDocument] = useState(null)
  const [deliveryEvents, setDeliveryEvents] = useState<DeliveryEvent[]>([])
  const [deliveringResponse, setDeliveringResponse] = useState(false)
  const [deliveryEventsLoading, setDeliveryEventsLoading] = useState(true)
  const [error, setError] = useState('')
  const [deliveryFieldErrors, setDeliveryFieldErrors] = useState([])

  const actionCable = ActionCable.createConsumer()

  useEffect(() => {
    const evidenceDeliveryChannel = actionCable.subscriptions.create(
      { channel: 'EvidenceDeliveryChannel', dispute_id: id },
      {
        connected: () => {
          console.log('WebSocket connected', this)
        },
        disconnected: () => {
          // Called when the subscription has been terminated by the server
        },
        received: data => {
          // Called when there's incoming data on the websocket for this channel
          setDeliveryEvents([data, ...deliveryEvents])
          if (data.status === 'success') {
            enqueueSnackbar('Successfully delivered dispute response', {
              variant: 'success'
            })
          } else {
            enqueueSnackbar('Failed to deliver dispute response', {
              variant: 'error'
            })
          }
          setDeliveringResponse(false)
        }
      }
    )
    axios.get(`/api/disputes/${id}.json`).then(({ data }) => setDispute(data))
    axios
      .get(`/api/disputes/${id}/representment/preview.json`)
      .then(({ data }) => {
        setDeliveryFieldErrors(data.errors)
        setResponseDocument(data.delivery_fields)
      })
      .then(() => {
        axios
          .get(`/api/disputes/${id}/representment/delivery_events`)
          .then(({ data }) => setDeliveryEvents(data))
          .finally(() => setDeliveryEventsLoading(false))
      })
      .catch(e => {
        setError(e)
      })
    return () => {
      evidenceDeliveryChannel.unsubscribe()
    }
  }, [])

  const previewConcatenatedEvidenceContent = () => {
    const url = `/api/disputes/${id}/preview_concatenated_evidence_content`
    axios
      .get(url, { responseType: 'arraybuffer' })
      .then((response: AxiosResponse) => {
        if (response.status !== 204) {
          openFile(response.data, response.headers['content-type'])
        } else {
          enqueueSnackbar('Response Document Not Available', {
            variant: 'warning'
          })
        }
      })
  }

  const deliverySuccess = deliveryEvents.find(
    ({ status }) => status === 'success'
  )

  const sendResponseDisabled =
    deliveringResponse || deliveryEventsLoading || !!deliverySuccess

  const menuItemClasses = menuItemStyles()

  const getMenuItemClass = deliveryField => {
    if (deliveryField.errors.length > 0) {
      return menuItemClasses.error
    }
  }
  if (error) {
    // raise exception if there is one, so the ErrorBoundary renders the error dialog
    throw error
  }
  if (!dispute || !responseDocument) {
    return <LoadingIndicator />
  }
  return (
    <Container>
      <Grid container alignItems="center" justify="space-between" spacing={1}>
        <Grid container item xs={5} spacing={1} alignItems="center">
          <Grid item xs>
            <h2>Finalized Response</h2>
          </Grid>
        </Grid>
        <Grid container item xs={2} justify="flex-end">
          <Button
            variant="contained"
            color="primary"
            disabled={sendResponseDisabled}
            onClick={() => {
              setDeliveringResponse(true)
              axios
                .post(`/api/disputes/${id}/representment/deliver`)
                .catch(e => {
                  enqueueSnackbar('Failed to deliver dispute response', {
                    variant: 'error'
                  })
                  const event = e.response?.data || {
                    status: 'failure',
                    timestamp: moment().toString(),
                    error: 'unexpected error'
                  }
                  setDeliveryEvents([event, ...deliveryEvents])
                  setDeliveringResponse(false)
                })
            }}
          >
            Send Response
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        justify="space-between"
        style={{ borderBottom: '2px solid rgba(0,0,0,.1)' }}
      >
        <Grid item xs>
          <span style={{ color: 'rgba(0,0,0,.5)' }}>{dispute.case_number}</span>
        </Grid>
        <DeliveryEventMessage
          deliveryEvent={deliverySuccess || deliveryEvents[0] || null}
        />
      </Grid>
      <EvidenceFieldErrorsAlert deliveryFields={responseDocument} />
      <TemplateErrorsAlert errors={deliveryFieldErrors} />
      <Grid container justify="space-between">
        <Grid item xs={2}>
          <h3>Response Preview</h3>
        </Grid>
        <Grid container item xs={10} justify="flex-end">
          <Grid item xs xs={3}>
            <a
              href={`/responses/${id}/download`}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                marginTop: '18.720px',
                cursor: 'pointer'
              }}
            >
              <PictureAsPdf style={{ marginRight: 5 }} /> Download
            </a>
          </Grid>

          <Grid item xs xs={3}>
            <a
              onClick={previewConcatenatedEvidenceContent}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                marginTop: '18.720px',
                cursor: 'pointer'
              }}
            >
              <PictureAsPdf style={{ marginRight: 5 }} /> Response Document
            </a>
          </Grid>
          <Grid item xs={3}>
            <a
              href={`/responses/${id}/edit`}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                marginTop: '18.720px'
              }}
            >
              <Edit style={{ marginRight: 5 }} /> Edit Response
            </a>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Card>
            <CardContent>
              <h4>Response Document Sections</h4>
              <MenuList>
                {Object.keys(responseDocument).map(k => (
                  <MenuItem
                    classes={{ root: getMenuItemClass(responseDocument[k]) }}
                    key={k}
                  >
                    <a href={`#${k}`}>{k}</a>
                  </MenuItem>
                ))}
              </MenuList>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={9}>
          {Object.keys(responseDocument).map(k => {
            return (
              <Grid item key={k} xs={12}>
                <PreviewNode
                  content={responseDocument[k].value}
                  type={responseDocument[k].type}
                  category={k}
                  disabled={false}
                  errors={responseDocument[k].errors}
                />
              </Grid>
            )
          })}
        </Grid>
      </Grid>
    </Container>
  )
}

export default ResponsePreview
