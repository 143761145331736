const getVisibleColumns = dataAdapter => ({
  isLoading: false,
  data: Object.keys(dataAdapter.columns)
    .filter(key => dataAdapter.columns[key].order)
    .reduce((columns, key) => {
      columns[key] = true
      return columns
    }, {})
})

export default getVisibleColumns
