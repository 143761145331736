import React from 'react'
import { DisputeProvider } from './dispute-context'
import { RenderingsProvider } from './renderings-context'
import { DatasetProvider } from './dataset-context'
import { SavingProvider } from './saving-context'

// Combines all DET-related providers
export const DETProvider = ({ children }) => (
  <DisputeProvider>
    <RenderingsProvider>
      <DatasetProvider>
        <SavingProvider>{children}</SavingProvider>
      </DatasetProvider>
    </RenderingsProvider>
  </DisputeProvider>
)
