import React from 'react'
import * as moment from 'moment'
import CopyToClipboard from '../../../../utils/copy-to-clipboard'

const TransactionTs = ({ value }) => {
  const formattedDate = value ? moment(value).format('YYYY-MM-DD hh:mm A') : ''

  return <CopyToClipboard text={formattedDate}>{formattedDate}</CopyToClipboard>
}

export default TransactionTs
