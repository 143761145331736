const createColumnGroups = allColumns => {
  const perColumn = Math.ceil(allColumns.length / 4)
  let groupKey = 0
  let count = 0

  return allColumns.reduce((colArr, column) => {
    if (count === 0) colArr[groupKey] = []
    colArr[groupKey].push(column)
    count++

    if (count === perColumn) {
      groupKey++
      count = 0
    }
    return colArr
  }, [])
}
export default createColumnGroups
