import axios from '../../utils/http/axios-disputer'
import parseAlerts from './parse-alerts'
import formatQueryParams from '../../utils/http/format-query-params'

const fetchAlerts = async (params = {}) => {
  const alerts = await axios.get('/api/alerts', {
    params: formatQueryParams(params)
  })
  return parseAlerts(alerts)
}
export default fetchAlerts
