import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { IRenderingContent } from './interfaces'
import { insertImagePlaceholders } from './rendering_image_placeholder_helpers'

const useStyles = makeStyles(() => ({
  deliveryFieldBody: {
    padding: '16px',
    width: '100%'
  }
}))

interface RenderingContentProps {
  renderingContentData: IRenderingContent
  onHtmlClick: React.MouseEventHandler
}

const RenderingContent = ({
  onHtmlClick,
  renderingContentData
}: RenderingContentProps): React.ReactElement => {
  const [modifiedContent, setModifiedContent] = useState<string | null>(null)
  const classes = useStyles()

  useEffect(() => {
    if (renderingContentData?.content) {
      const withImagesPlaceholders = insertImagePlaceholders(
        renderingContentData.content
      )
      setModifiedContent(withImagesPlaceholders)
    }
  }, [renderingContentData?.content])

  if (!modifiedContent) {
    return null
  }

  return (
    <div
      className={`${classes.deliveryFieldBody} det-rendering-content-wrapper`}
      dangerouslySetInnerHTML={{ __html: modifiedContent }} // TODO add html sanitization, consider using dompurify library
      onClick={onHtmlClick}
    />
  )
}

export default RenderingContent
