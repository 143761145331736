import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import dataAdapter from './data-adapter'
import Grid from '@material-ui/core/Grid'
import MultipleSelect from '../../common/list-view/search/components/multiple-select'
import SearchFilterInput from '../../common/list-view/search/components/input'
import CustomDateRangePicker from '../../common/list-view/search/components/date-pickers/custom-date-range-picker'
import SelectionDisplay from '../../common/list-view/search/components/date-pickers/selection-display'
import parseParamsForMultiSelect from '../../common/list-view/common/parse-params-for-multiselect'

const SearchItems = props => {
  const {
    formState,
    setFormState,
    selectOptions,
    handleChange,
    handleMultiSelectChange,
    handleSetDates
  } = props
  const classes = useStyles()

  const parseParams = parseParamsForMultiSelect({
    dataAdapter,
    formState,
    handleMultiSelectChange,
    selectOptions
  })

  const requestDate = dataAdapter.columns['requestDate']
  const transactionTs = dataAdapter.columns['transactionTs']
  const expirationDate = dataAdapter.columns['expirationDate']

  return (
    <>
      <Grid container className={classes.lightRow}>
        <Grid item xs={12}>
          <MultipleSelect {...parseParams('assignedTo')} />
        </Grid>
        <Grid item xs={12}>
          <MultipleSelect {...parseParams('tags')} />
        </Grid>
      </Grid>

      <Grid container className={classes.darkRow}>
        <Grid item xs={6}>
          <MultipleSelect {...parseParams('merchant')} />
        </Grid>
        <Grid item xs={6}>
          <MultipleSelect {...parseParams('mid')} />
        </Grid>

        <Grid item xs={6}>
          <div className={classes.container}>
            Request
            <CustomDateRangePicker
              name={requestDate.name}
              initialStartDate={formState[requestDate.rangeStartName]}
              initialEndDate={formState[requestDate.rangeEndName]}
              initialDynamicRange={formState[`${requestDate.name}_range`]}
              handleSetDates={handleSetDates(requestDate.name)}
              allowDynamicRanges={true}
            />
            Transaction
            <CustomDateRangePicker
              name={transactionTs.name}
              initialStartDate={formState[transactionTs.rangeStartName]}
              initialEndDate={formState[transactionTs.rangeEndName]}
              initialDynamicRange={formState[`${transactionTs.name}_range`]}
              handleSetDates={handleSetDates(transactionTs.name)}
              allowDynamicRanges={true}
            />
            Expiration
            <CustomDateRangePicker
              name={expirationDate.name}
              initialStartDate={formState[expirationDate.rangeStartName]}
              initialEndDate={formState[expirationDate.rangeEndName]}
              initialDynamicRange={formState[`${expirationDate.name}_range`]}
              handleSetDates={handleSetDates(expirationDate.name)}
              allowDynamicRanges={true}
            />
          </div>
        </Grid>
        <Grid item xs={6}>
          <SelectionDisplay {...{ setFormState, formState }} />
        </Grid>
      </Grid>

      <Grid container className={classes.lightRow}>
        <Grid item xs={6}>
          <MultipleSelect {...parseParams('portal')} />
        </Grid>
        <Grid item xs={6}>
          <MultipleSelect {...parseParams('disposition')} />
        </Grid>
        <Grid item xs={6}>
          <MultipleSelect {...parseParams('cardType')} />
        </Grid>
        <Grid item xs={6}>
          <div className={classes.cardInfo}>
            <SearchFilterInput
              {...{ handleChange, formState }}
              {...dataAdapter.columns['cardPrefix']}
              type="number"
            />
            <SearchFilterInput
              {...{ handleChange, formState }}
              {...dataAdapter.columns['cardSuffix']}
              type="number"
            />
            <SearchFilterInput
              {...{ handleChange, formState }}
              {...dataAdapter.columns['disputeAmount']}
              type="number"
              inputProps={{ step: 'any' }}
            />
          </div>
        </Grid>
      </Grid>
      <Grid container className={classes.darkRow}>
        <Grid item xs={12}>
          <MultipleSelect {...parseParams('reportingCategory')} />
        </Grid>
        <Grid item xs={6}>
          <MultipleSelect {...parseParams('reasonCode')} />
        </Grid>
        <Grid item xs={6}>
          <MultipleSelect {...parseParams('stage')} />
        </Grid>
      </Grid>

      <Grid container className={classes.lightRow}>
        <Grid item xs={3}>
          <SearchFilterInput
            {...{ handleChange, formState }}
            {...dataAdapter.columns['caseNumber']}
          />
        </Grid>
        <Grid item xs={3}>
          <SearchFilterInput
            {...{ handleChange, formState }}
            {...dataAdapter.columns['referenceNumber']}
          />
        </Grid>
        <Grid item xs={3}>
          <SearchFilterInput
            {...{ handleChange, formState }}
            {...dataAdapter.columns['invoiceId']}
          />
        </Grid>
        <Grid item xs={3}>
          <SearchFilterInput
            {...{ handleChange, formState }}
            {...dataAdapter.columns['transactionId']}
          />
        </Grid>
      </Grid>

      <Grid container className={classes.darkRow}>
        <Grid item xs={6}>
          <MultipleSelect {...parseParams('lifecycleStatus')} />
        </Grid>
        <Grid item xs={6}>
          <MultipleSelect {...parseParams('remoteDeliveryStatus')} />
        </Grid>
      </Grid>
    </>
  )
}

const allRows = {
  borderRadius: 5,
  padding: '10px 0px'
}
const useStyles = makeStyles(theme => {
  return {
    lightRow: {
      ...allRows
    },
    darkRow: {
      ...allRows,
      backgroundColor: theme.palette.secondary[50]
    },
    cardInfo: {
      display: 'flex'
    },
    container: {
      display: 'flex',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      marginTop: 8,
      flexWrap: 'wrap'
    }
  }
})

export default SearchItems
