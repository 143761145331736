import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import { Button, IconButton, Icon } from '@material-ui/core'
import axios from '../../../client/utils/http/axios-disputer'
import Rollbar from '../../utils/rollbar'

const ControlColumn = props => {
  const { row } = props
  const classes = useStyles({
    respondBtnColor: row.hasLinks ? 'green' : 'yellow'
  })

  const shouldHaveDocs = row =>
    ['ready_to_send', 'document_sent'].includes(row.lifecycleStatus)

  const onClick = () => {
    axios
      .post('/api/response_events/', {
        response_event: {
          event_type: 'respond',
          dispute_id: row.id
        }
      })
      .catch(error => {
        // Catch block to not report Connection Aborted error
        // to Rollbar. It is not an issue. It happens, because user is
        // redirected to the different page. As a result Browser treats this
        // request as aborted while BE still properly processing it

        if (error.code !== 'ECONNABORTED') {
          // Unexpected issue
          Rollbar.error('Error response events', error)
        }
      })
  }

  return (
    <>
      {shouldHaveDocs(row) ? (
        <>
          <TableCell>
            <IconButton href={`/responses/${row.id}.pdf`}>
              <Icon>picture_as_pdf</Icon>
            </IconButton>
          </TableCell>
          <TableCell>
            <Button
              href={`/responses/${row.id}/review_router`}
              className={classes.reviewBtn}
              variant="contained"
              size="small"
            >
              Review
            </Button>
          </TableCell>
        </>
      ) : (
        <>
          <TableCell></TableCell>
          <TableCell>
            <Button
              href={`/responses/${row.id}/edit`}
              className={classes.respondBtn}
              variant="contained"
              size="small"
              onClick={onClick}
            >
              Respond
            </Button>
          </TableCell>
        </>
      )}
    </>
  )
}

const useStyles = makeStyles(theme => ({
  reviewBtn: {
    width: 80,
    backgroundColor: theme.custom.columns.textColors['grey']
  },
  respondBtn: props => ({
    color: theme.custom.columns.textColors['white'],
    backgroundColor: theme.custom.columns.textColors[props.respondBtnColor]
  })
}))

export default ControlColumn
