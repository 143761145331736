export const saveToLocalStorage = (name, data) => {
  localStorage.setItem(name, JSON.stringify(data))
}

export const getFromLocalStorage = name => {
  try {
    return JSON.parse(localStorage.getItem(name))
  } catch (e) {
    return null
  }
}

export const deleteFromLocalStorage = name => localStorage.removeItem(name)
