import { useEffect } from 'react'
import { throttle } from 'lodash'
import getSessionEnd from './get-session-end'
import moment from 'moment'

const ONE_SECOND = 1000
const ONE_MINUTE = ONE_SECOND * 60
const FIVE_MINUTES = ONE_MINUTE * 5

const useKeepAlive = props => {
  const { handleExtend, idle, expired } = props
  let cleanupEventListener = () => {}

  useEffect(() => {
    cleanupEventListener = bindEventListener(keepAlive)
    return () => {
      cleanupEventListener()
    }
  }, [idle])

  useEffect(() => {
    expired && cleanupEventListener() && keepAlive.cancel()
  }, [expired])

  const bindEventListener = keepAlive => {
    document.addEventListener('mousemove', keepAlive)
    return () => {
      document.removeEventListener('mousemove', keepAlive)
    }
  }

  const throttledExtend = throttle(handleExtend, FIVE_MINUTES)

  const keepAlive = () => {
    // the user's session is already expired (on another tab), bail
    if (moment.unix(getSessionEnd()) < moment())
      window.location.href = '/ui/login'
    // if we are currently idle, we want to extend immediately
    if (idle) {
      handleExtend()
      // if we aren't idle, throttle to keep traffic low
    } else {
      throttledExtend()
    }
  }
}

export default useKeepAlive
