import React, { useEffect, useMemo, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import createColumnGroups from './create-column-groups'
import Button from '@material-ui/core/Button'
import { useSnackbar } from 'notistack'
import Rollbar from '../../../utils/rollbar'
import { snakeCase } from 'lodash'
import useCheckboxes from '../common/checkboxes/use-checkboxes'
import ListViewContext from '../list-view-context'
import axios from '../../../utils/http/axios-local'
import { useCurrentUser } from '../../../contexts/CurrentUserContext'
import { CHECKBOX_TYPE } from '../common/checkboxes/checkbox-reducer'

const ColumnSelect = props => {
  const { showColumnSelect, setShowColumnSelect } = props
  const classes = useStyles()

  const {
    Data: { dataAdapter },
    columns
  } = useContext(ListViewContext)

  const { currentUser } = useCurrentUser()
  const { enqueueSnackbar } = useSnackbar()
  const [checkboxes, dispatchCheckboxes] = useCheckboxes()
  const columnGroups = useMemo(
    () =>
      !columns.isLoading
        ? createColumnGroups(Object.keys(columns.data).map(column => column))
        : [],
    [columns.isLoading]
  )

  useEffect(() => {
    if (columns.isLoading) return
    dispatchCheckboxes({ type: CHECKBOX_TYPE.initObj, data: columns.data })
  }, [columns.isLoading])

  const saveSelectedColumns = () => {
    const formattedColumns = checkboxes.selectedIds.map(column =>
      // the server stores these in snake_case, so we need to convert them
      snakeCase(column)
    )

    const params = {
      user: { id: currentUser.id },
      display_type: 'disputes',
      settings: {
        settings: {
          list_columns: {
            disputes: formattedColumns
          }
        }
      }
    }

    return axios
      .put(`/users/${currentUser.id}.json`, params)
      .then(response => {
        if (response.status === 200) {
          columns.refetch()
          enqueueSnackbar('Visible columns saved!', { variant: 'success' })
        } else {
          Rollbar.error('Error saving columns', response)
          enqueueSnackbar('There was a problem saving the visible columns.', {
            variant: 'error'
          })
        }
      })
      .catch(err => {
        Rollbar.error('Error saving columns', err)
        enqueueSnackbar('There was a problem saving the visible columns.', {
          variant: 'error'
        })
      })
      .then(() => {
        setShowColumnSelect(false)
      })
  }

  return (
    <Dialog open={showColumnSelect}>
      <div className={classes.controls}>
        <div className={classes.buttonContainer}>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={() =>
              dispatchCheckboxes({
                type: CHECKBOX_TYPE.toggleAll,
                checked: true
              })
            }
            className={classes.buttons}
          >
            select all
          </Button>
          <Button
            size="small"
            variant="contained"
            color="default"
            onClick={() =>
              dispatchCheckboxes({
                type: CHECKBOX_TYPE.toggleAll,
                checked: false
              })
            }
            className={classes.buttons}
          >
            clear all
          </Button>
        </div>
        <IconButton
          data-testid="column-select-close"
          className={classes.close}
          onClick={() => setShowColumnSelect(false)}
        >
          <Icon>clear</Icon>
        </IconButton>
      </div>
      <DialogContent>
        {columns.isLoading
          ? 'Loading...'
          : columnGroups.map(columnGroup => {
              return (
                <FormControl key={columnGroup[0]}>
                  <FormGroup>
                    {columnGroup.map(column => {
                      return (
                        <FormControlLabel
                          key={column}
                          control={
                            <Checkbox
                              checked={checkboxes.checkboxes[column]}
                              onChange={e =>
                                dispatchCheckboxes({
                                  type: CHECKBOX_TYPE.toggle,
                                  id: column,
                                  checked: e.target.checked
                                })
                              }
                            />
                          }
                          label={dataAdapter.columns[column].title}
                        />
                      )
                    })}
                  </FormGroup>
                </FormControl>
              )
            })}
      </DialogContent>
      <DialogActions>
        <Button onClick={saveSelectedColumns} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const useStyles = makeStyles({
  controls: {
    display: 'flex'
  },
  buttonContainer: {
    width: '100%',
    padding: 15
  },
  buttons: {
    marginRight: 4
  },
  close: {
    width: 60
  }
})

export default ColumnSelect
