import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Dialog } from '@material-ui/core'
import SearchFilterButtons from './components/buttons'
import ListViewContext from '../list-view-context'
import { SELECTED_PARAMS_TYPE } from '../data-handler/selected-params-reducer'

const Search = props => {
  const classes = useStyles()

  const {
    Data: { dispatchSelectedParams, getColumnByName },
    SearchItems,
    formState,
    setFormState,
    menuOpen,
    setMenuOpen,
    selectOptions
  } = useContext(ListViewContext)

  const cleanValue = object =>
    typeof object === 'string' ? object.trim() : object

  const handleChange = fieldName => event => {
    const value = cleanValue(event.target.value)
    const newFormState = { ...formState }

    if (value) {
      newFormState[fieldName] = value
    } else {
      delete newFormState[fieldName]
    }

    setFormState({ ...newFormState })
  }

  const handleMultiSelectChange =
    fieldName => multiple => (event, newValue) => {
      const newState = { ...formState }
      let value
      if (multiple) {
        value = newValue.map(v => v.value)
      } else {
        value = newValue !== null ? newValue.value : null
      }

      if ((Array.isArray(value) && !value.length) || value === null) {
        delete newState[fieldName]
      } else {
        newState[fieldName] = value
      }

      setFormState({ ...newState })
    }

  const handleSetDates =
    field =>
    ({ startDate, endDate, dynamicRange }) => {
      const column = getColumnByName(field)
      if (startDate && endDate) {
        formState[column.rangeStartName] = startDate
        formState[column.rangeEndName] = endDate
        if (`${field}_range` in formState) delete formState[`${field}_range`]
      } else {
        formState[`${field}_range`] = dynamicRange
        if (column.rangeStartName in formState)
          delete formState[column.rangeStartName]
        if (column.rangeEndName in formState)
          delete formState[column.rangeEndName]
      }

      setFormState({ ...formState })
    }

  const updateSelectedParams = () => {
    dispatchSelectedParams({
      type: SELECTED_PARAMS_TYPE.setParams,
      params: formState
    })
    setMenuOpen(false)
  }

  const handleKeyEvent = event => {
    if (event.key === 'Enter') {
      updateSelectedParams()
    }
  }

  return (
    <Dialog
      open={menuOpen}
      maxWidth="sm"
      classes={{ paperWidthSm: classes.dialog }}
    >
      <div className={classes.container} onKeyUp={handleKeyEvent}>
        <SearchFilterButtons {...{ updateSelectedParams }} />
        <SearchItems
          {...{
            formState,
            setFormState,
            selectOptions,
            handleChange,
            handleMultiSelectChange,
            handleSetDates
          }}
        />
      </div>
    </Dialog>
  )
}

const useStyles = makeStyles({
  dialog: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 'calc(100% - 150px)',
    maxWidth: 775,
    overflow: 'visible',
    overflowY: 'auto'
  },
  container: {
    padding: 15
  }
})

export default Search
