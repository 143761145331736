import React, { useEffect, useMemo, useRef } from 'react'
import { ColumnDef, Row } from '@tanstack/react-table'
import { Table } from '@siftscience/focus-components/table'
import { TextInput } from '@siftscience/focus-components/input'
import { Button, IconButton } from '@siftscience/focus-components/button'
import { Delete } from '@siftscience/focus-components/icons/Delete'
import { Title } from '@siftscience/focus-components/text'
import { makeStyles } from '@material-ui/core/styles'
import { capitalize, get } from 'lodash'
import { getDatasetPath } from '../../utils/det'
import { DataField, DatasetDTO, ImageDTO } from './dtos'

const COLUMN_PINNING_STATE = {
  right: ['delete']
}

export type RowData = Record<string, string>

const useStyles = makeStyles(() => ({
  arrayTable: {
    width: '100%'
  },
  actions: {
    marginTop: '16px',
    marginBottom: '16px'
  }
}))

interface ArrayFieldProps {
  dataset: DatasetDTO
  dataField: DataField
  changes: Record<
    string,
    string | Record<string, string>[] | Record<string, string> | ImageDTO
  >
  onArrayChange: (newArray: Record<string, string>[], field: DataField) => void
  onRowDelete: (newArray: Record<string, string>[], field: DataField) => void
  onInputBlur: (field: DataField) => void
  focusedField?: { name: string; isArray: boolean }
}

const ArrayField = ({
  dataset,
  dataField,
  changes,
  onArrayChange,
  onRowDelete,
  onInputBlur,
  focusedField
}: ArrayFieldProps): React.ReactElement => {
  // optimization for input change in Table
  const changesRef = useRef(null)
  const classes = useStyles()

  useEffect(() => {
    changesRef.current = changes
  }, [changes])

  const data: RowData[] = useMemo(() => {
    const arrayData =
      changes[dataField?.datasetPath] ??
      get(dataset, getDatasetPath(dataField?.datasetPath))
    return arrayData as unknown as Record<string, string>[]
  }, [dataField, dataField, changes])

  const onDeleteRow = (row: Row<RowData>) => {
    const index = row.index
    const existingValue = (changesRef.current[dataField?.datasetPath] ??
      get(dataset, getDatasetPath(dataField?.datasetPath)) ??
      []) as Record<string, string>[]
    const newValue = [...existingValue]
    newValue.splice(index, 1)
    onRowDelete(newValue, dataField)
  }

  const onTableInputChange = (
    value: string,
    row: Row<RowData>,
    key: string
  ) => {
    const index = row.index
    const existingValue = (changesRef.current[dataField?.datasetPath] ??
      get(dataset, getDatasetPath(dataField?.datasetPath)) ??
      []) as Record<string, string>[]
    const newValue = existingValue.map(value => ({ ...value }))
    newValue[index][key] = value
    onArrayChange(newValue, dataField)
  }

  const columns: ColumnDef<RowData>[] = useMemo(() => {
    let highlightAccessorKey
    let highlightRowIndex
    if (focusedField?.isArray) {
      const hightlightedCellPath = focusedField?.name?.split('#')
      const hightlightedFieldName = hightlightedCellPath[0]
      if (hightlightedFieldName === dataField.name) {
        highlightAccessorKey = hightlightedCellPath[1]
        highlightRowIndex = +hightlightedCellPath[2]
      }
    }

    // We have properties.properties in older schemas and properties in newer, so we need to support both.
    const properties =
      dataField?.jsonSchema?.items?.[0]?.properties?.properties ||
      dataField?.jsonSchema?.items?.[0]?.properties ||
      {}
    const requiredFields =
      dataField?.jsonSchema?.items?.[0]?.properties?.required ||
      dataField?.jsonSchema?.items?.[0]?.required ||
      []
    const requiredFieldsHash =
      Array.isArray(requiredFields) &&
      requiredFields?.reduce((hash, fieldTitle) => {
        hash[fieldTitle] = true
        return hash
      }, {})
    const allColumns = Object.keys(properties).map(key => {
      const title = properties[key]?.title || key
      return {
        accessorKey: key,
        header: capitalize(title),
        id: dataField.id + '.' + key,
        cell: ({ getValue, row }) => {
          const value = getValue<string>()
          const isAutoFocused =
            key === highlightAccessorKey && row.index === highlightRowIndex
          const isRequired = requiredFieldsHash[key] || false

          return (
            <TextInput
              value={value}
              onChange={value =>
                onTableInputChange(value.target.value, row, key)
              }
              onBlur={() => onInputBlur(dataField)}
              containerStyle={{ width: '100%' }}
              autoFocus={isAutoFocused}
              required={isRequired}
              containerClassName={`${isRequired && !value ? 'error' : ''}`}
            />
          )
        }
      }
    })

    allColumns.push({
      accessorKey: 'delete',
      header: '',
      id: `delete`,
      cell: ({ row }) => {
        return (
          <IconButton
            variant="secondary-ghost"
            onClick={() => onDeleteRow(row)}
          >
            <Delete />
          </IconButton>
        )
      }
    })
    return allColumns
  }, [dataset, dataField])

  const onAddRow = () => {
    const existingValue = (changes[dataField?.datasetPath] ??
      get(dataset, getDatasetPath(dataField?.datasetPath)) ??
      []) as Record<string, string>[]
    const properties = dataField?.jsonSchema?.items?.[0]?.properties || {}
    const newRowEmptyObject = Object.keys(properties).reduce((hash, item) => {
      hash[item] = ''
      return hash
    }, {})
    const newValue = [...existingValue, newRowEmptyObject]
    onArrayChange(newValue, dataField)
  }

  return (
    <div className={classes.arrayTable}>
      <Title>{dataField.name}:</Title>
      <Table
        columns={columns}
        data={data || []}
        initialState={{
          columnPinning: COLUMN_PINNING_STATE
        }}
        getRowId={row => row.id}
        enableColumnPinning
      />
      <div className={classes.actions}>
        <Button variant="secondary" lined onClick={onAddRow}>
          + Add Row
        </Button>
      </div>
    </div>
  )
}

export default ArrayField
