import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const StatusColors = {
  Error: 'red',
  Expired: 'red',
  New: 'orange',
  Resolved: 'green',
  Other: 'grey',
  Retry: 'orange'
}

const Status = ({ value: { status, minutes } }) => {
  const classes = useStyles({
    color: StatusColors[status] || 'black'
  })

  const displayValue = () => {
    if (status === 'Retry') {
      if (minutes === 0) {
        return 'Retrying'
      }
      return `Retrying in ${minutes} mins`
    }
    return status
  }

  return <span className={classes.root}>{displayValue()}</span>
}

const useStyles = makeStyles(theme => ({
  root: props => ({
    color: theme.custom.columns.textColors[props.color]
  })
}))

export default Status
