import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'

const BORDER_COLOR = '#E0E0E0'

interface StyleProps {
  color: string
  border: boolean
}

// TODO: Pass styles dynamically
interface BadgeProps {
  color?: string
  width?: string
  marginTop?: string
  padding?: string
  borderRadius?: string
  border?: boolean
  children: React.ReactElement
}

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  badgeWrapper: {
    backgroundColor: props => props.color,
    border: props => (props.border ? `1px solid ${BORDER_COLOR}` : undefined),
    width: props => props.width,
    marginTop: props => props.marginTop,
    height: '32px',
    padding: props => (props.padding ? props.padding : '0 12px 0 12px'),
    borderRadius: props => (props.borderRadius ? props.borderRadius : '14px'),
    minWidth: '5px'
  }
}))

const Badge = ({
  children,
  color,
  border,
  width,
  marginTop,
  padding,
  borderRadius
}: BadgeProps): React.ReactElement => {
  const classes = useStyles({
    color,
    border,
    width,
    marginTop,
    padding,
    borderRadius
  })

  return <div className={classes.badgeWrapper}>{children}</div>
}

export default Badge
