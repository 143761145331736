import React, { useEffect, useRef, useState } from 'react'
import { FixedSizeList as List } from 'react-window'
import _ from 'lodash'
import { useSnackbar } from 'notistack'
import { Button, Grid, Input } from '@material-ui/core'
import Edit from '@material-ui/icons/Edit'
import Done from '@material-ui/icons/Done'
import ChevronRight from '@material-ui/icons/ChevronRight'
import 'ace-builds/src-noconflict/ace'
import AceEditor from 'react-ace'
import 'ace-builds/src-noconflict/mode-handlebars'
import 'ace-builds/src-noconflict/mode-json'
import 'ace-builds/src-noconflict/theme-tomorrow'
import 'ace-builds/src-noconflict/ext-language_tools'
import axios from '../../utils/http/axios-local'
import {
  getFromLocalStorage,
  saveToLocalStorage
} from '../../utils/local-storage'
import { useStyles as useSiftStyles } from '../../styles/evidences/sift'

const renderRow =
  (list: Array<string>, searchIndex: null | number) =>
  ({ index, style }) => {
    const row = list[index]
    return (
      <div
        style={{
          ...style,
          whiteSpace: 'pre',
          backgroundColor:
            searchIndex === index ? 'rgba(255, 255, 0, 0.2)' : null
        }}
      >
        {row}
      </div>
    )
  }

const findRow = list => search => list.findIndex(s => s.match(search))

const SamplePayload = ({ width, height, setPayload, renderPreview }) => {
  const siftClasses = useSiftStyles()
  const [localPayload, setLocalPayload] = useState(
    getFromLocalStorage(`evidencePayload`) || '{ "foo": "bar" }'
  )
  const [search, setSearch] = useState('')
  const [searchIndex, setSearchIndex] = useState(null)
  const [payloadEditable, setPayloadEditable] = useState(false)
  const [disputeId, setDisputeId] = useState('')
  const listRef = useRef(null)
  const { enqueueSnackbar } = useSnackbar()
  const list = localPayload.split('\n')

  const setPayloadHandler = (payload: string) => {
    setLocalPayload(payload)
    setPayload(payload)
    saveToLocalStorage('evidencePayload', payload)
  }

  useEffect(() => {
    setPayload(localPayload)
  }, [])

  useEffect(() => {
    const storedPayload = getFromLocalStorage(`evidencePayload`)
    if (storedPayload) {
      setLocalPayload(storedPayload)
    }
  }, [])

  useEffect(() => {
    if (!search) {
      setSearchIndex(null)
      return
    }
    setSearchIndex(findRow(list)(search))
  }, [search])

  useEffect(() => {
    if (!searchIndex || !listRef || !listRef.current) {
      return
    }
    listRef.current.scrollToItem(searchIndex, 'top')
  }, [searchIndex])

  const fetchSampleDispute = () => {
    axios
      .get('/api/evidence_templates/sample_dispute.json', {
        params: {
          dispute_id: disputeId
        }
      })
      .then(({ data }) => {
        if (Object.keys(data).length) {
          const parsed = JSON.stringify(data, null, 2)
          setPayloadHandler(parsed)
          renderPreview(parsed)
        } else {
          enqueueSnackbar('No dispute found', {
            variant: 'warning'
          })
        }
      })
  }

  return (
    <Grid container style={{ width, height }}>
      <Grid
        item
        container
        className={siftClasses.blueHeader}
        style={{ marginBottom: '20px' }}
      >
        <Grid item container />
        <Grid item container xs={9}>
          <Grid item xs={3} style={{ color: 'white' }}>
            <Input
              style={{ color: 'white' }}
              placeholder="Dispute ID"
              onChange={({ target: { value } }) => setDisputeId(value)}
            />
          </Grid>
          <Grid item xs={4}>
            <Button
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
              className={siftClasses.siftButton}
              onClick={fetchSampleDispute}
            >
              <ChevronRight />
              Fetch
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <Button
            className={siftClasses.siftButton}
            onClick={() => setPayloadEditable(!payloadEditable)}
          >
            {payloadEditable ? (
              <>
                <Done /> Done
              </>
            ) : (
              <>
                <Edit /> Edit
              </>
            )}
          </Button>
        </Grid>
      </Grid>
      <AceEditor
        mode="json"
        theme="tomorrow"
        onChange={setPayloadHandler}
        fontSize={14}
        height={typeof height === 'number' ? `${height}px` : height}
        width={typeof width === 'number' ? `${width}px` : width}
        value={localPayload}
        readOnly={!payloadEditable}
        showPrintMargin
        showGutter
        highlightActiveLine
        enableBasicAutocompletion
        enableLiveAutocompletion
      />
      <Grid></Grid>
    </Grid>
  )
}

export { SamplePayload as default, renderRow, findRow }
