import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@siftscience/focus-components/button'
import { ResponsePreviewDTO } from './dtos'

const useStyles = makeStyles(() => ({
  navButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  }
}))

interface PreviewNavigationButtonProps {
  preview: ResponsePreviewDTO
  onClick: (preview: ResponsePreviewDTO) => void
}

const PreviewNavigationButton = ({
  preview,
  onClick
}: PreviewNavigationButtonProps) => {
  const classes = useStyles()

  return (
    <Button
      className={classes.navButton}
      variant="primary-ghost"
      size="large"
      onClick={() => onClick(preview)}
    >
      <span>{preview.templateDisplayName}</span>
    </Button>
  )
}

interface FinalizePreviewNavigationProps {
  previewData: ResponsePreviewDTO[]
  onNavigationItemClick: (preview: ResponsePreviewDTO) => void
}

const FinalizePreviewNavigation = ({
  previewData,
  onNavigationItemClick
}: FinalizePreviewNavigationProps): React.ReactElement => {
  const generatePreviewNavigationButton = (preview: ResponsePreviewDTO) => {
    return (
      <PreviewNavigationButton
        preview={preview}
        onClick={onNavigationItemClick}
        key={`navigation-${preview.templateId}-${preview.templateDisplayName}`}
      />
    )
  }

  return <div>{previewData?.map(generatePreviewNavigationButton)}</div>
}

export default FinalizePreviewNavigation
