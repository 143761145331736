import axios from '../../utils/http/axios-local'
import { disputesParser } from './disputes-parser'
import formatQueryParams from '../../utils/http/format-query-params'

const fetchDisputes = async (params = {}) => {
  const disputes = await axios.get('/api/disputes_list', {
    params: formatQueryParams(params)
  })
  return disputesParser(disputes)
}
export default fetchDisputes
