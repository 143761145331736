export enum RenderingStatus {
  COMPLETE = 'complete',
  INCOMPLETE = 'incomplete',
  ENHANCEABLE = 'enhance'
}

export enum ProcessorFieldType {
  STRING = 'string',
  PDF = 'pdf'
}

export enum DataFieldType {
  TEXT = 'text',
  DATE = 'date',
  IMAGE = 'image',
  BOOLEAN = 'boolean',
  ARRAY = 'array'
}

export enum Filters {
  Incomplete,
  Enhanceable,
  AllFields
}

export enum Tabs {
  Rendered,
  DataEntry
}

export enum SAVING_STATUS {
  saving,
  saved,
  error
}

export enum DIRTY_STATUS {
  Dirty,
  Loading,
  UpToDate,
  Latest
}

export enum LOADING_STATUS {
  Loading,
  Loaded,
  Errored
}
