import React from 'react'
import { Button } from '@material-ui/core'

type ButtonProps = {
  children: any
  variant: string
  size: string
}

export const SubmitButton = ({ children }: ButtonProps) => (
  <Button type="submit" variant="contained" color="primary">
    {children}
  </Button>
)
