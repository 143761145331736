import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Modal } from '@siftscience/focus-components/modal'
import { Action } from '@siftscience/focus-components/action-bar'
import { ButtonVariant } from '@siftscience/focus-components/button'
import FieldsEditForm from './fields-edit-form'
import { DatasetDTO, ImageDTO, RenderingDTO } from './dtos'
import { ISaveResult } from './interfaces'

const MODAL_ACTION_LABEL = {
  save: 'Save',
  cancel: 'Cancel'
}
const MODAL_ACTIONS: Action[] = [
  { label: MODAL_ACTION_LABEL.save },
  {
    label: MODAL_ACTION_LABEL.cancel,
    variant: 'secondary' as ButtonVariant,
    lined: true
  }
]

const useStyles = makeStyles(() => ({
  modalContent: {
    overflow: 'scroll'
  }
}))

interface ModalState {
  rendering?: RenderingDTO
  fieldName?: string
  isArray?: boolean
}

interface EditModalProps {
  modalState: ModalState
  dataset: DatasetDTO
  disputeId: string
  changes: Record<string, string | ImageDTO>
  onCloseModal: () => void
  onSave: (
    changes: Record<string, string | ImageDTO>,
    disputeId: string,
    renderingId: number,
    setDataset: (data) => void
  ) => Promise<ISaveResult>
  onDatasetChange: (
    key: string,
    value: string | ImageDTO,
    renderingId: number
  ) => void
  setDataset: (dataset: DatasetDTO) => void
}

const EditModal = ({
  changes,
  modalState,
  onCloseModal,
  onSave,
  disputeId,
  dataset,
  onDatasetChange,
  setDataset
}: EditModalProps): React.ReactElement => {
  const classes = useStyles()

  const onModalAction = (action: Action) => {
    if (action.label === MODAL_ACTION_LABEL.cancel) {
      onCloseModal()
      return
    }

    if (action.label === MODAL_ACTION_LABEL.save) {
      const renderingId = modalState?.rendering?.id
      onSave(changes, disputeId, renderingId, setDataset)
      onCloseModal()
    }
  }

  return (
    <div className="det-modal">
      <Modal
        contentContainerClassName={classes.modalContent}
        visible={!!modalState}
        title={`Data fields in ${modalState?.rendering?.displayName} template`}
        onCloseButtonClick={onCloseModal}
        onClickOutside={onCloseModal}
        actions={MODAL_ACTIONS}
        onAction={onModalAction}
        variant="fixed"
      >
        <FieldsEditForm
          disputeId={disputeId}
          rendering={modalState?.rendering}
          dataset={dataset}
          focusedField={{
            name: modalState?.fieldName,
            isArray: modalState?.isArray
          }}
          onChange={onDatasetChange}
          isModal
        />
      </Modal>
    </div>
  )
}

export default EditModal
