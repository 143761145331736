import React from 'react'
import { Base64 } from 'js-base64'
import { GetApp } from '@material-ui/icons'
import { Card, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import axios from '../../utils/http/axios-local'
import openFile from '../../utils/open-file'
import EvidenceFieldErrorsDetails from './evidence-field-errors-details'

const useStyles = makeStyles(() => ({
  previewTextNode: {
    padding: '10px'
  }
}))

const PreviewNode = ({
  content,
  category,
  type,
  errors,
  disabled
}: {
  content: any
  category: string
  type: string
  errors: []
  disabled: boolean
}) => {
  const classes = useStyles()
  const TEXT_FIELD_TYPES = [`string`, `yaml`]

  const handleDownload = () => {
    if (!content) {
      return
    }
    axios
      .post(
        `/api/rendering`,
        {
          data_type: 'pdf',
          content: Base64.encode(content)
        },
        {
          responseType: 'arraybuffer'
        }
      )
      .then(response => {
        openFile(response.data, response.headers['content-type'])
      })
  }

  return (
    <div key={category}>
      <Grid item xs={12}>
        <h3 id={category}>{category}</h3>
      </Grid>
      <Grid item xs={12}>
        <EvidenceFieldErrorsDetails errors={errors} />
      </Grid>
      {disabled ? (
        <Grid item xs={12}>
          Disabled
        </Grid>
      ) : (
        <>
          {type !== 'string' && (
            <h5 style={{ display: 'flex', alignItems: 'center' }}>
              Download
              <GetApp style={{ cursor: 'pointer' }} onClick={handleDownload} />
            </h5>
          )}
          <Card>
            {TEXT_FIELD_TYPES.includes(type) ? (
              <div
                className={classes.previewTextNode}
                data-testid="preview-textarea"
              >
                {content}
              </div>
            ) : (
              <iframe
                title={category}
                style={{ width: '100%', height: '800px' }}
                srcDoc={content}
              />
            )}
          </Card>
        </>
      )}
    </div>
  )
}

export default PreviewNode
