import React, { useEffect, useState, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Text, Title } from '@siftscience/focus-components/text'
import { TextInput } from '@siftscience/focus-components/input'
import { Checkbox } from '@siftscience/focus-components/checkbox'
import { get } from 'lodash'
import ArrayField from './array-field'
import ImageUploader from './image-uploader/image-uploader'
import DatePicker from './date-picker'
import { getDatasetPath } from '../../utils/det'
import { useDatasetContext } from './context/dataset-context'
import { DataFieldType } from './enums'
import { DataField, ImageDTO, RenderingDTO } from './dtos'
import { ISaveResult } from './interfaces'

interface FieldsEditFormProps {
  rendering: RenderingDTO
  isModal?: boolean
  focusedField?: { name: string; isArray: boolean }
  onChange?: (
    key: string,
    value: string | Record<string, string>[] | ImageDTO,
    renderingId: number
  ) => Promise<ISaveResult>
  onBlur?: (
    key: string,
    value: string | Record<string, string>[] | ImageDTO,
    renderingId: number
  ) => Promise<ISaveResult>
}

const useStyles = makeStyles(() => ({
  formWrapper: {
    padding: '16px'
  },
  titles: {
    width: '100%',
    marginBottom: '4px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    gap: '8px'
  },
  title: {
    minWidth: '200px'
  },
  inputWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    gap: '8px',
    marginBottom: '12px',
    '&:last-child': {
      marginBottom: '0'
    }
  },
  inputLabel: {
    width: '200px',
    padding: '6px 8px 6px 12px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    gap: '8px',
    backgroundColor: '#F7F7F7' // TODO move to consts
  },
  input: {
    flexGrow: 1,
    maxWidth: '100%'
  },
  checkboxWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '100%',
    width: '100%'
  },
  labelName: {
    maxWidth: '90%',
    wordWrap: 'break-word'
  }
}))

const FieldsEditForm = ({
  rendering,
  isModal,
  focusedField,
  onChange,
  onBlur
}: FieldsEditFormProps): React.ReactElement => {
  const { dataset } = useDatasetContext()
  const focusRef = useRef(false)
  const changesRef = useRef({})
  const [changes, setChanges] = useState<
    Record<string, string | Record<string, string>[] | ImageDTO>
  >({})
  const classes = useStyles()

  useEffect(() => {
    changesRef.current = changes
  }, [changes])

  const onInputChange = (event, field: DataField) => {
    const value = event.target.value
    setChanges(prevChanges => ({ ...prevChanges, [field.datasetPath]: value }))
    if (onChange) {
      const renderingId = rendering?.id
      onChange(field.datasetPath, value, renderingId)
    }
  }

  const onArrayChange = (
    newArray: Record<string, string>[],
    field: DataField
  ) => {
    setChanges(prevChanges => ({
      ...prevChanges,
      [field.datasetPath]: newArray
    }))
    if (onChange) {
      const renderingId = rendering?.id
      onChange(field.datasetPath, newArray, renderingId)
    }
  }

  const onInputBlur = (field: DataField) => {
    if (onBlur) {
      const path = field.datasetPath
      const value = changesRef.current[path]
      if (value === undefined) {
        return
      }

      const renderingId = rendering?.id
      onBlur(path, value, renderingId)
    }
  }

  const onChangeRow = (
    newArray: Record<string, string>[],
    field: DataField
  ) => {
    setChanges(prevChanges => ({
      ...prevChanges,
      [field.datasetPath]: newArray
    }))

    const renderingId = rendering?.id

    if (onChange) {
      onChange(field.datasetPath, newArray, renderingId)
    }

    if (onBlur) {
      if (newArray === undefined) {
        return
      }

      onBlur(field.datasetPath, newArray, renderingId)
    }
  }

  const onUploadImage = async (
    field: DataField,
    imageInfo: ImageDTO
  ): Promise<ISaveResult> => {
    const renderingId = rendering?.id

    if (imageInfo) {
      setChanges(prevChanges => ({
        ...prevChanges,
        [field.datasetPath]: imageInfo
      }))
      if (onChange) {
        return onChange(field.datasetPath, imageInfo, renderingId)
      }

      if (onBlur) {
        return onBlur(field.datasetPath, imageInfo, renderingId)
      }
    }
  }

  const onChangeCaption = (field: DataField, description: string) => {
    const path = `${field.datasetPath}.caption`
    setChanges(prevChanges => ({
      ...prevChanges,
      [path]: description
    }))

    const renderingId = rendering?.id

    if (onChange) {
      onChange(path, description, renderingId)
    }
  }

  const onBlurCaption = (field: DataField, description: string) => {
    const path = `${field.datasetPath}.caption`
    setChanges(prevChanges => ({
      ...prevChanges,
      [path]: description
    }))

    const renderingId = rendering?.id

    if (onBlur) {
      onBlur(path, description, renderingId)
    }
  }

  const onDeleteImage = (
    field: DataField,
    imageInfo: ImageDTO
  ): Promise<ISaveResult> => {
    setChanges(prevChanges => ({
      ...prevChanges,
      [field.datasetPath]: null // image was removed
    }))

    const renderingId = rendering?.id

    if (onChange) {
      return onChange(field.datasetPath, imageInfo, renderingId)
    }

    if (onBlur) {
      return onBlur(field.datasetPath, imageInfo, renderingId)
    }
  }

  const onAutoFocus = event => {
    // autofocus can be applied only once per this component lifecycle
    if (!focusRef.current) {
      event.target.select()
      focusRef.current = true
    }
  }

  return (
    <div className={isModal ? '' : classes.formWrapper}>
      {isModal && (
        <div className={classes.titles}>
          <div className={classes.title}>
            <Title size="xsmall" color="secondary">
              Data Field
            </Title>
          </div>
          <div className={classes.title}>
            <Title size="xsmall" color="secondary">
              Value
            </Title>
          </div>
        </div>
      )}
      {rendering?.dataFields?.map?.(field => {
        const value =
          changes[field?.datasetPath] ??
          get(dataset, getDatasetPath(field?.datasetPath))

        return (
          <div className={classes.inputWrapper}>
            {field?.dataType && field?.dataType !== DataFieldType.ARRAY && (
              <div className={`${classes.inputLabel} border-radius-s`}>
                <Text size="small" className={classes.labelName}>
                  {field?.name}
                </Text>
                {field?.required && <Text size="small">*</Text>}
              </div>
            )}
            <div className={`${classes.input}`}>
              {field?.dataType === DataFieldType.TEXT && (
                <TextInput
                  value={value as string}
                  onChange={value => onInputChange(value, field)}
                  onBlur={() => onInputBlur(field)}
                  containerStyle={{ width: '100%' }}
                  required={field?.required}
                  autoFocus={field.name === focusedField?.name}
                  onFocus={event => {
                    if (field.name === focusedField?.name) {
                      onAutoFocus(event)
                    }
                  }}
                  containerClassName={`${
                    field?.required && !value ? 'error' : ''
                  }`}
                />
              )}
              {field?.dataType === DataFieldType.BOOLEAN && (
                <div className={classes.checkboxWrapper}>
                  <Checkbox
                    defaultChecked={value === 'true'}
                    onChange={value =>
                      onInputChange(
                        { target: { value: `${value.target.checked}` } },
                        field
                      )
                    }
                    onBlur={() => onInputBlur(field)}
                    required={field?.required}
                    autoFocus={field.name === focusedField?.name}
                    className={`${field?.required && !value ? 'error' : ''}`}
                  />
                </div>
              )}
              {field?.dataType === DataFieldType.DATE && (
                <DatePicker
                  value={value as string}
                  dataField={field}
                  onChange={onInputChange}
                  onBlur={onInputBlur}
                  required={field?.required}
                  autoFocus={field.name === focusedField?.name}
                  className={`${field?.required && !value ? 'error' : ''}`}
                />
              )}
              {field?.dataType === DataFieldType.ARRAY && (
                <ArrayField
                  dataset={dataset}
                  dataField={field}
                  changes={changes}
                  onArrayChange={onArrayChange}
                  onChangeRow={onChangeRow}
                  onInputBlur={onInputBlur}
                  focusedField={focusedField}
                  onAutoFocus={onAutoFocus}
                />
              )}
              {field?.dataType === DataFieldType.IMAGE && (
                <ImageUploader
                  focusedField={focusedField}
                  changes={changes}
                  dataField={field}
                  dataset={dataset}
                  onUploadImage={onUploadImage}
                  onDeleteImage={onDeleteImage}
                  onChangeCaption={onChangeCaption}
                  onBlurCaption={onBlurCaption}
                />
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default FieldsEditForm
