import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import Logo from '../../images/sift-logo-color-rgb.svg'

const useStyles = makeStyles({
  headerRow: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '3rem'
  },
  headerColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: 20
  },
  image: {
    width: '100%',
    height: '100%'
  },
  title: {
    fontSize: '24px',
    fontWeight: 400,
    lineHeight: '110%',
    textAlign: 'center'
  },
  subTitle: {
    fontSize: '14px',
    textAlign: 'center',
    fontStyle: 'italic'
  }
})

const SessionHeader = ({
  title,
  subTitle = ''
}: {
  title: string
  subTitle?: string
}): React.ReactElement => {
  const cls = useStyles()

  return (
    <>
      <div className={cls.headerRow}>
        <Logo className={cls.image} />
      </div>
      <div className={cls.headerColumn}>
        <Typography variant="h2" className={cls.title} gutterBottom>
          {title}
        </Typography>
        {subTitle ? (
          <Typography className={cls.subTitle}>{subTitle}</Typography>
        ) : null}
      </div>
    </>
  )
}

export default SessionHeader
