import React, { createContext } from 'react'
import useCheckboxes from './use-checkboxes'

export const CheckboxContext = createContext()
export const DispatchCheckboxContext = createContext()

export const CheckboxProvider = ({ children }) => {
  const [checkboxes, dispatchCheckboxes] = useCheckboxes()

  return (
    <CheckboxContext.Provider value={checkboxes}>
      <DispatchCheckboxContext.Provider value={dispatchCheckboxes}>
        {children}
      </DispatchCheckboxContext.Provider>
    </CheckboxContext.Provider>
  )
}
