import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Text } from '@siftscience/focus-components/text'
import {
  LightbulbFilled,
  VerificationWarningFilled
} from '@siftscience/focus-components/icons'
import { RenderingDTO, TemplateType } from './dtos'
import { useRenderingsContext } from './context/renderings-context'
import { Filters, RenderingStatus } from './enums'

const useStyles = makeStyles(() => ({
  navButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '10px 16px',
    cursor: 'pointer',
    border: 'none',
    background: 'none',
    color: '#242424',
    '&:hover:enabled': {
      backgroundColor: '#E0E0E0'
    },
    '&:disabled': {
      cursor: 'not-allowed',
      color: '#8A8A8A'
    }
  },
  navButtonActive: {
    backgroundColor: '#EAF1FE',
    color: '#295DED',
    fontWeight: 600
  },
  childRenderingNavigation: {
    marginLeft: '16px',
    borderLeft: '1px solid #E0E0E0'
  }
}))

interface NavigationButtonProps {
  selectedFilter: Filters
  renderingData: RenderingDTO
  onClick: (rendering: RenderingDTO) => void
}

const NavigationButton = ({
  selectedFilter,
  renderingData,
  onClick
}: NavigationButtonProps): React.ReactElement => {
  const { renderingsHashData, activeRenderingId } = useRenderingsContext()
  const classes = useStyles()
  const isDisabled = useMemo(() => {
    if (renderingData.templateType === TemplateType.configurationPanel) {
      return false
    }

    if (selectedFilter === Filters.AllFields) {
      return false
    }

    if (
      selectedFilter === Filters.Incomplete &&
      renderingData.status === RenderingStatus.INCOMPLETE
    ) {
      return false
    }
    if (
      selectedFilter === Filters.Enhanceable &&
      renderingData.status === RenderingStatus.ENHANCEABLE
    ) {
      return false
    }

    return true
  }, [selectedFilter, renderingData, renderingsHashData])

  const isButtonActive =
    activeRenderingId?.toString() === renderingData?.id?.toString()

  return (
    <div>
      <button
        className={`${classes.navButton} ${
          isButtonActive ? classes.navButtonActive : ''
        }`}
        onClick={() => onClick(renderingData)}
        disabled={isDisabled}
      >
        <Text size="small">{renderingData.displayName}</Text>
        {renderingData.templateType ===
        TemplateType.configurationPanel ? null : (
          <>
            {renderingData?.status === RenderingStatus.ENHANCEABLE && (
              <LightbulbFilled color="#F2B200" />
            )}
            {renderingData?.status === RenderingStatus.INCOMPLETE && (
              <VerificationWarningFilled color="#EB3B2E" />
            )}
          </>
        )}
      </button>
      {renderingData.childTemplateIds?.length ? (
        <div className={classes.childRenderingNavigation}>
          {renderingData.childTemplateIds.map(templateId => {
            const rendering = renderingsHashData[templateId]
            if (!rendering) {
              return null
            }
            return (
              <NavigationButton
                selectedFilter={selectedFilter}
                renderingData={rendering}
                onClick={onClick}
                key={`navigation-button-${templateId}`}
              />
            )
          })}
        </div>
      ) : null}
    </div>
  )
}

export default NavigationButton
