import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Tabs, Tab } from '@material-ui/core'

const tabs = [
  'disputes',
  'inquiries',
  'alerts',
  'transactions',
  'invoices',
  'reports'
]

const MainNav = ({ MenuLogo }) => {
  const classes = useStyles()
  const thisLocation =
    tabs.filter(tab => window.location.pathname.includes(tab))[0] || false
  const [currentTab, setCurrentTab] = useState(thisLocation)

  useEffect(() => setCurrentTab(thisLocation), [thisLocation])

  return (
    <>
      <MenuLogo />
      <Tabs
        value={currentTab}
        className={classes.tabs}
        onChange={(_, newValue) => setCurrentTab(newValue)}
        classes={{ indicator: classes.indicator }}
      >
        <Tab
          value="disputes"
          component="a"
          label="Disputes"
          href="/ui/disputes"
        />
        <Tab
          value="inquiries"
          component="a"
          label="Inquiries"
          href="/inquiries"
        />
        <Tab value="alerts" component="a" label="Alerts" href="/ui/alerts" />
        <Tab
          value="transactions"
          component="a"
          label="Transactions"
          href="/ui/transactions"
        />
        <Tab
          value="invoices"
          component="a"
          label="Invoices"
          href="/ui/invoices"
        />
        <Tab value="reports" component="a" label="Reports" href="/ui/reports" />
      </Tabs>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  tabs: {
    marginLeft: 30,
    minWidth: 475,
    '& a': {
      minWidth: 'auto'
    },
    '& .MuiTab-wrapper': {
      textTransform: 'capitalize',
      fontSize: theme.typography.fontSize
    }
  },
  indicator: {
    backgroundColor: theme.palette.primary.contrastText
  }
}))

export default MainNav
