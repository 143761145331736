import { useQuery } from 'react-query'
import axios from '../../utils/http/axios-local'

const getSelectOptions = async () => axios.get('/api/invoices/select_options')

const useSelectOptions = () => {
  return useQuery('invoiceSearchOptions', getSelectOptions, {
    staleTime: Infinity
  })
}

export default useSelectOptions
