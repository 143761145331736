import React from 'react'
import { makeStyles } from '@material-ui/core'
import axios from '../../../utils/http/axios-local'
import { useQuery } from 'react-query'
import { get } from 'lodash'
import linkDisputeId from '../../../utils/link-dispute-id'
import disputeDataAdapter from '../../../pages/disputes/data-adapter'

const LinkIndicator = () => {
  const classes = useStyles()

  const dispute = useQuery(
    'linkDispute',
    async () => {
      const response = await axios.get(`/api/disputes_list/${linkDisputeId}`)
      return response.data
    },
    { staleTime: Infinity }
  )

  const columnsToDisplay = [
    'caseNumber',
    'referenceNumber',
    'reasonCode',
    'disputeAmount',
    'transactionTs',
    'cardPrefix',
    'cardSuffix',
    'mid'
  ]

  return (
    <>
      {!dispute.isLoading && (
        <div className={classes.root} id="link-indicator">
          {columnsToDisplay.map(column => {
            const data = get(
              dispute.data,
              disputeDataAdapter.columns[column].path
            )
            const DataFormatter = disputeDataAdapter.columns[column].format
            return data ? (
              <div className={classes.item} key={column}>
                <div className={classes.label}>
                  {disputeDataAdapter.columns[column].title}
                </div>
                <div className={classes.data}>
                  {typeof DataFormatter === 'function' ? (
                    <DataFormatter value={data} />
                  ) : (
                    data
                  )}
                </div>
              </div>
            ) : null
          })}
        </div>
      )}
    </>
  )
}

const useStyles = makeStyles(theme => {
  return {
    root: {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: theme.palette.secondary.dark,
      margin: 10
    },
    item: {
      padding: '15px 20px'
    },
    label: {
      textAlign: 'center',
      color: theme.palette.secondary.light
    },
    data: {
      textAlign: 'center',
      color: theme.palette.secondary.contrastText
    }
  }
})

export default LinkIndicator
