import React, { ReactChild } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { styled } from '@material-ui/core/styles'

const Text = styled(Typography)({
  marginRight: '1rem'
})

const LinkButton = styled(Link)({
  color: '#00ADF3'
})

const Row = styled(Grid)({
  display: 'flex',
  justifyContent: 'center'
})

const SessionFooter = ({ text, linkText, to }): ReactChild => (
  <Grid container alignItems="center" justify="center" alignContent="center">
    <Row item xs={12} zeroMinWidth>
      <Text display="inline">{text}</Text>
      <LinkButton to={to}>{linkText}</LinkButton>
    </Row>
  </Grid>
)

export default SessionFooter
