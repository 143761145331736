import React from 'react'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'

const SearchFilterInput = props => {
  const { name, title, formState, handleChange, type, inputProps } = props
  const classes = useStyles()

  return (
    <div className={classes.inputContainer}>
      <TextField
        id={`${name}-id`}
        name={name}
        label={title}
        placeholder={title}
        value={formState[name] || ''}
        onChange={handleChange(name)}
        variant="outlined"
        margin="normal"
        className={classes.input}
        type={type}
        inputProps={inputProps}
      />
    </div>
  )
}

const useStyles = makeStyles({
  inputContainer: {
    margin: '5px 10px'
  },
  input: {
    marginTop: 0
  }
})

export default SearchFilterInput
