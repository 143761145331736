import http from './local'

// Normally http will throw an error if the response has a status
// 400 or greater. validateStatus set always to true will instead
// return the json response with status code
const httpWithErrors = options =>
  http({
    validateStatus: () => true,
    ...options
  })

export default httpWithErrors
