export default ({
    dataAdapter,
    handleMultiSelectChange,
    formState,
    selectOptions
  }) =>
  name => {
    if (!(name in dataAdapter.columns)) {
      throw new Error(
        `The column "${name}" was used in search-items, but does not have a matching data-adapter definition`
      )
    }
    const field = dataAdapter.columns[name]

    return {
      name: field.name,
      placeholder: field.title,
      value: formState[field.name],
      options: selectOptions.isLoading
        ? []
        : selectOptions.data.data[field.name],
      isLoading: selectOptions.isLoading,
      handleChange: handleMultiSelectChange(field.name),
      multiple: true
    }
  }
