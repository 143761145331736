import { currentUserCookie, ICurrentUserCookie } from './CurrentUserCookie'
import CurrentUserStore, { ICurrentUserStore } from './CurrentUserStore'

export type UserActionType = 'RELOAD_CURRENT_USER'
export const USER_ACTION_TYPES: Record<string, UserActionType> = {
  reloadCurrentUser: 'RELOAD_CURRENT_USER'
}

interface Action {
  type: UserActionType
  payload?: ICurrentUserStore
}

/**
 * The main Reducer function for altering any of the
 * current user attributes/data.
 */
const CurrentUserReducer = (
  state: ICurrentUserStore,
  action: Action
): ICurrentUserStore => {
  /**
   * All events should be registered in the reducer to proper updating
   * of the current user context object takes place accordingly and
   * triggers downstream rendering events as needed.
   */
  switch (action.type) {
    case USER_ACTION_TYPES.reloadCurrentUser:
      // Pull Current User Information from the Header JWT Token Cookie
      const currentUser: ICurrentUserCookie = currentUserCookie()

      if (currentUser && currentUser.email && state.id !== currentUser.id) {
        // Update from jwt token
        return {
          ...state,
          ...currentUser,
          id: currentUser.userId,
          masqueradeUser: currentUser.masqueradeUser
        }
      } else {
        return { ...CurrentUserStore } // Load Defaults if no jwt token
      }
    default:
      return state
  }
}

export { CurrentUserReducer as default, Action }
