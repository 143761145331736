import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { WarningFilled } from '@siftscience/focus-components/icons/WarningFilled'
import { Text } from '@siftscience/focus-components/text'
import { Button } from '@siftscience/focus-components/button'
import { TextInput } from '@siftscience/focus-components/input'
import { DataField } from '../dtos'

const ERROR_ICON_COLOR = '#EB3B2E'

const useStyles = makeStyles(() => ({
  noImageFieldWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    flexGrow: 1,
    padding: '16px 46px',
    gap: '8px',
    border: '1px dashed #E0E0E0'
  },
  inputArea: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '8px'
  },
  error: {
    position: 'absolute',
    top: '10px',
    right: '10px'
  },
  icon: {
    width: '16px',
    height: '16px'
  }
}))

interface UploadBlockProps {
  imageError: boolean
  dataField: DataField
  focusedField: { name: string; isArray: boolean }
  imageRef: React.Ref<HTMLInputElement>
  handleSelectImage: (event) => void
  handleDragOver: (event) => void
  handleDrop: (event) => void
  onUploadClick: (event) => void
  handlePaste: (event) => void
}

const UploadBlock = ({
  imageError,
  dataField,
  focusedField,
  imageRef,
  handleSelectImage,
  handleDragOver,
  handleDrop,
  onUploadClick,
  handlePaste
}: UploadBlockProps): React.ReactElement => {
  const classes = useStyles()

  return (
    <div
      className={classes.noImageFieldWrapper}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      {imageError && (
        <div className={classes.error}>
          <WarningFilled color={ERROR_ICON_COLOR} className={classes.icon} />
        </div>
      )}
      <Text size="small">Drag and drop, upload or paste image here</Text>
      <div className={classes.inputArea}>
        <Button variant="secondary" onClick={onUploadClick}>
          Upload
        </Button>
        <Text size="small" color="subtle">
          or
        </Text>
        <TextInput
          containerStyle={{ width: '188px' }}
          value="Click to paste image here"
          onPaste={handlePaste}
          autoFocus={dataField?.name === focusedField?.name}
        />
      </div>
      <input
        ref={imageRef}
        type="file"
        accept="image/*"
        hidden
        onChange={handleSelectImage}
      />
    </div>
  )
}

export default UploadBlock
