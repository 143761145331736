/*
The idle timer needs to check the session end time before determining 
whether or not the session is idle because the user might have multiple tabs
open. If they do, more than one of these processes could be running and updating
the session.
*/

import { useState, useEffect } from 'react'
import { createTimer, clearTimer } from '../../utils/timers'
import getSessionEnd from './get-session-end'
import moment from 'moment'

const IDLE_TIMER = 'idleTimer'

const useIdleTimer = props => {
  const [idle, setIdle] = useState(false)

  useEffect(() => {
    return () => clearTimer(IDLE_TIMER)
  }, [])

  useEffect(() => {
    !idle && checkIdle()
  }, [idle])

  const checkIdle = () => {
    clearTimer(IDLE_TIMER)
    const now = moment()
    const sessionEnd = getSessionEnd()
    const idleTime = moment.unix(sessionEnd).subtract(5, 'minutes')
    const msToIdle = moment.duration(idleTime.diff(now))

    if (now.isBefore(idleTime)) {
      createTimer({ name: IDLE_TIMER, fn: checkIdle, ms: msToIdle })
    } else {
      setIdle(true)
    }
  }

  return [idle, setIdle]
}

export default useIdleTimer
