import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import { isEmpty } from 'lodash'

const MultipleSelect = props => {
  const {
    name,
    placeholder,
    value,
    options,
    isLoading,
    handleChange,
    multiple
  } = props
  const classes = useStyles()

  const defaultProps = {
    id: `${name}-id`,
    size: 'small',
    options,
    getOptionLabel: option => (option !== undefined ? option.label : null),
    loading: isLoading,
    multiple,
    autoComplete: false,
    selectOnFocus: true,
    clearOnBlur: true,
    handleHomeEndKeys: true,
    filterSelectedOptions: true,
    onChange: handleChange(multiple),
    renderInput: params => (
      <TextField
        {...params}
        variant="outlined"
        label={placeholder}
        placeholder={placeholder}
      />
    )
  }

  return (
    <div className={classes.inputContainer}>
      {multiple ? (
        <Autocomplete
          {...defaultProps}
          value={
            !isEmpty(value) && !isEmpty(options)
              ? value.map(v =>
                  options.find(option => String(v) === String(option.value))
                )
              : []
          }
        />
      ) : (
        <Autocomplete
          {...defaultProps}
          value={
            !isEmpty(value) && !isEmpty(options)
              ? options.find(option => option.value === value)
              : null
          }
        />
      )}
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: 4
  },
  inputContainer: {
    margin: '5px 10px'
  }
}))

export default MultipleSelect
