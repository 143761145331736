import CardPrefix from '../../common/list-view/data-table/custom-columns/card-prefix'
import CardSuffix from '../../common/list-view/data-table/custom-columns/card-suffix'
import TransactionTs from '../../common/list-view/data-table/custom-columns/expiration'
import InvoiceId from '../../common/list-view/data-table/custom-columns/invoice-id'
import TransactionId from '../../common/list-view/data-table/custom-columns/transaction-id'
import Currency from '../../common/list-view/data-table/custom-columns/currency'

import { rangeChoices } from '../../common/list-view/search/add-dynamic-date-ranges'

import DataAdapter from '../../common/list-view/data-handler/interfaces/data-adapter-interface'

const dataAdapter: DataAdapter = {
  name: 'transactions',
  pagination: {
    page: 1,
    perPage: 50
  },
  columns: {
    gatewayId: {
      type: 'string',
      title: 'Gateway',
      name: 'gateway',
      path: 'gatewayName',
      order: 1
    },
    merchant: {
      type: 'number[]',
      title: 'Merchant',
      name: 'merchant',
      path: 'merchantName',
      order: 2
    },
    mid: {
      type: 'number[]',
      title: 'Mid',
      name: 'midName',
      path: 'midName',
      order: 3
    },
    transactionId: {
      type: 'string',
      title: 'Txn ID',
      name: 'transactionId',
      path: 'transactionId',
      order: 4,
      format: TransactionId
    },
    invoiceId: {
      type: 'string',
      title: 'Invoice ID',
      name: 'invoiceId',
      path: 'invoiceId',
      order: 5,
      format: InvoiceId
    },
    transactionType: {
      type: 'string',
      title: 'Txn Type',
      name: 'transactionType',
      path: 'transactionType',
      order: 6
    },
    transactionAmount: {
      type: 'string',
      title: 'Amount',
      name: 'transactionAmount',
      path: 'transactionAmount',
      order: 7,
      sortable: true,
      format: Currency
    },
    cardPrefix: {
      type: 'string',
      title: 'Card Prefix',
      name: 'cardPrefix',
      path: 'cardPrefix',
      order: 8,
      format: CardPrefix
    },
    cardSuffix: {
      type: 'string',
      title: 'Card Suffix',
      name: 'cardSuffix',
      path: 'cardSuffix',
      order: 9,
      format: CardSuffix
    },
    transactionTs: {
      type: 'date',
      title: 'Transaction Date',
      name: 'transactionTs',
      path: 'transactionTs',
      defaultRange: rangeChoices.lastWeek,
      rangeStartName: 'transaction_ts_after',
      rangeEndName: 'transaction_ts_before',
      order: 10,
      sortable: true,
      format: TransactionTs
    },
    firstName: {
      type: 'string',
      title: 'First Name',
      name: 'firstName',
      path: 'firstName'
    },
    lastName: {
      type: 'string',
      title: 'Last Name',
      name: 'lastName',
      path: 'lastName'
    }
  }
}

export default dataAdapter
