import React, { useState, useEffect } from 'react'
import {
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Drawer,
  Toolbar,
  Divider,
  makeStyles,
  TextField
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { Route, Link, HashRouter } from 'react-router-dom'
import axios from '../../utils/http/axios-local'
import EvidenceTemplates from '../evidence-templates'
import SubTemplates from '../sub-templates'
import EvidencePreview from '../evidence-preview'
import UnauthorizedAccessError from '../../common/errors/unauthorized-access-error'

const drawerWidth = 240

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerHeader: {
    color: 'black'
  },
  content: {
    flexGrow: 1
  }
}))

const EvidenceConfiguration = () => {
  const classes = useStyles()
  const [portals, setPortals] = useState<[]>([])
  const [processor, setProcessor] = useState<Record<string, unknown>>(null)
  const [permitted, setPermission] = useState<boolean>(true)
  const routes = {
    'Template Editor': '/',
    'Sub Template Editor': '/sub-templates',
    'Evidence Preview': '/evidence-preview'
  }

  useEffect(() => {
    axios.get('/api/permitted').then(response => {
      setPermission(response.data.permitted)
    })
  }, [])

  useEffect(() => {
    axios.get('/api/portals').then(response => {
      setPortals(response.data)
    })
  }, [])

  if (!permitted) return <UnauthorizedAccessError />

  return (
    <HashRouter>
      <div className={classes.root}>
        <Drawer
          variant="permanent"
          className={classes.drawer}
          classes={{ paper: classes.drawerPaper }}
        >
          <Toolbar />
          <List
            subheader={
              <ListSubheader className={classes.drawerHeader}>
                <h1>Evidence Configuration</h1>
              </ListSubheader>
            }
          >
            <Divider />
            <ListItem data-testid="processor-dropdown">
              <Autocomplete
                value={processor}
                disableClearable={true}
                onChange={(_, value) => setProcessor(value)}
                options={portals}
                getOptionLabel={option => (option.name ? option.name : '')}
                renderInput={params => (
                  <TextField {...params} label="Processor" />
                )}
                fullWidth={true}
                data-testid="processor-select"
              />
            </ListItem>
            <Divider />
            {Object.keys(routes).map((routeName, index) => (
              <ListItem
                component={Link}
                to={routes[routeName]}
                selected={`#${routes[routeName]}` === location.hash}
                button
                key={index}
              >
                <ListItemText primary={routeName} />
              </ListItem>
            ))}
          </List>
        </Drawer>
        <div className={classes.content}>
          <Route exact path="/">
            <EvidenceTemplates processor={processor ? processor.id : null} />
          </Route>
          <Route exact path="/sub-templates">
            <SubTemplates />
          </Route>
          <Route exact path="/evidence-preview">
            <EvidencePreview processor={processor ? processor.id : null} />
          </Route>
        </div>
      </div>
    </HashRouter>
  )
}

export default EvidenceConfiguration
