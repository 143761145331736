import React, { useContext } from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import {
  DispatchCheckboxContext,
  CheckboxContext
} from '../common/checkboxes/checkbox-context'
import { CHECKBOX_TYPE } from '../common/checkboxes/checkbox-reducer'

const Checkboxes = props => {
  const { id } = props
  const dispatchCheckboxes = useContext(DispatchCheckboxContext)
  const checkboxes = useContext(CheckboxContext)

  return (
    <>
      {!checkboxes.isLoading && checkboxes.checkboxes[id] !== undefined ? (
        <Checkbox
          checked={checkboxes.checkboxes[id]}
          onChange={e =>
            dispatchCheckboxes({
              type: CHECKBOX_TYPE.toggle,
              id: id,
              checked: e.target.checked
            })
          }
        />
      ) : null}
    </>
  )
}

export default Checkboxes
