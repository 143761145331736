import { INotificationStore } from './NotificationStore'

type Notification = string
type UserId = number
export type NotificationType =
  | 'warning'
  | 'error'
  | 'success'
  | 'info'
  | 'clearNotification'
  | ''

export const NOTIFICATION_TYPES: Record<string, NotificationType> = {
  warning: 'warning',
  error: 'error',
  success: 'success',
  info: 'info',
  clearNotification: 'clearNotification'
}

interface Payload {
  userId?: UserId
  notification?: Notification
}

interface Action {
  payload?: Payload
  type: NotificationType
}

const Reducers = (
  state: INotificationStore,
  action: Action
): INotificationStore => {
  switch (action.type) {
    case NOTIFICATION_TYPES.warning:
      return {
        ...state,
        notification: action.payload.notification,
        notificationType: NOTIFICATION_TYPES.warning
      }

    case NOTIFICATION_TYPES.error:
      return {
        ...state,
        notification: action.payload.notification,
        notificationType: NOTIFICATION_TYPES.error
      }
    case NOTIFICATION_TYPES.success:
      return {
        ...state,
        notification: action.payload.notification,
        notificationType: NOTIFICATION_TYPES.success
      }
    case NOTIFICATION_TYPES.info:
      return {
        ...state,
        notification: action.payload.notification,
        notificationType: NOTIFICATION_TYPES.info
      }
    case NOTIFICATION_TYPES.clearNotification:
      return { ...state, notification: '', notificationType: '' }
    default:
      return state
  }
}

export { Reducers as default, Action }
