import React from 'react'
import Theme from '../../theme'
import ErrorBoundary from '../../error-boundary'
import Header from './header'
import { SnackbarProvider } from 'notistack'
import CurrentUserProvider from '../../contexts/CurrentUserContext'

const HeaderWrapper = () => {
  return (
    <Theme>
      <SnackbarProvider maxSnack={3}>
        <CurrentUserProvider>
          <ErrorBoundary>
            <Header />
          </ErrorBoundary>
        </CurrentUserProvider>
      </SnackbarProvider>
    </Theme>
  )
}

export default HeaderWrapper
