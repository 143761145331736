import { useState } from 'react'
import axiosDisputer from '../utils/http/axios-disputer'

const useHttp = props => {
  const { url, params = {}, method = 'get', parser } = props
  const [state, setState] = useState({ isLoading: true, data: null })

  const getData = async () => {
    const response = await axiosDisputer({ url, params, method })
    const newState = {
      isLoading: false,
      data: parser ? parser(response) : response.data
    }
    setState(newState)
    return newState
  }

  return [state, getData, setState]
}

export default useHttp
