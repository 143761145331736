import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react'
import { ImageDTO, RenderingDTO, TemplateType } from '../dtos'
import { DIRTY_STATUS, SAVING_STATUS } from '../enums'
import { ISaveResult } from '../interfaces'
import {
  getRenderingsByTypeHash,
  getRenderingsToRender,
  prepareChanges,
  saveAllChanges
} from '../../../utils/det'
import axios from '../../../utils/http/axios-local'
import { useRenderingsContext } from './renderings-context'
import { useDisputeContext } from './dispute-context'

interface ISavingContext {
  saveChanges?: (
    changes: Record<string, string | ImageDTO>,
    disputeId: string,
    currentRenderingId: number,
    setDataset: (data) => void
  ) => Promise<ISaveResult>
  savingStatus: SAVING_STATUS | null
}

const SavingContext = createContext<ISavingContext>({})

export const SavingProvider = ({ children }) => {
  const [savingStatus, setSavingStatus] = useState<SAVING_STATUS | null>(null)
  const {
    renderingsData,
    dirtyRenderings,
    setDirtyRenderings,
    reRenderAsync,
    onDirtyRenderingClick,
    setRenderingsData,
    setStylesheetRenderingsData,
    setRenderingsHashData
  } = useRenderingsContext()
  const { dispute } = useDisputeContext()
  const saveChanges = useCallback(
    async (
      changes: Record<string, string | ImageDTO>,
      disputeId: string,
      currentRenderingId: number,
      setDataset: (data) => void
    ): Promise<ISaveResult> => {
      if (Object.keys(changes || {})?.length) {
        setSavingStatus(SAVING_STATUS.saving)
        const { dataset, dirtyRenderingIds, ...restSaveResults } =
          await saveAllChanges(disputeId, changes)

        if (dataset) {
          setDataset(dataset)
        }

        if (dirtyRenderingIds) {
          const newDirtyRenderings = dirtyRenderingIds.reduce(
            (hash, item) => {
              hash[item] = DIRTY_STATUS.Dirty
              return hash
            },
            { ...dirtyRenderings }
          )

          setDirtyRenderings(newDirtyRenderings)

          dirtyRenderingIds.forEach(renderingId => {
            const rendering = renderingsData.find(
              rendering => rendering.id === +renderingId
            )

            if (currentRenderingId === rendering.id) {
              reRenderAsync(
                renderingId,
                () => onDirtyRenderingClick(rendering),
                true
              )
            } else if (rendering) {
              reRenderAsync(renderingId, () => onDirtyRenderingClick(rendering))
            }
          })
        }

        setSavingStatus(SAVING_STATUS.saved)

        try {
          // reload renderings list with the latest applied segmentation rules
          // temporary solution for the demo
          const { data: renderings } = await axios.get<RenderingDTO[]>(
            `/det/renderings?dispute_id=${disputeId}`
          )
          const renderingsByType = getRenderingsByTypeHash(renderings)

          const renderingsToDisplay = getRenderingsToRender(
            renderingsByType,
            dispute
          )

          setRenderingsData(renderingsToDisplay)
          setStylesheetRenderingsData(renderingsByType[TemplateType.stylesheet])

          const renderingsHash = renderingsToDisplay.reduce((hash, item) => {
            hash[item.templateId] = item
            return hash
          }, {})
          setRenderingsHashData(renderingsHash)
        } catch (error) {
          console.log(error)
        }

        return {
          ...restSaveResults,
          dataset,
          dirtyRenderingIds
        }
      }
    },
    [prepareChanges, renderingsData]
  )

  const contextValue = useMemo(
    () => ({
      saveChanges,
      savingStatus
    }),
    [saveChanges, savingStatus]
  )

  return (
    <SavingContext.Provider value={contextValue}>
      {children}
    </SavingContext.Provider>
  )
}

export const useSavingContext = () => {
  return useContext(SavingContext)
}
